import {LabeledDropdownInput} from "../../../../components/input/LabeledDropdownInput";
import {findError} from "../../../../components/input/InputTypes";
import {filterPractitioners, findProvider} from "./CalendarUtil";
import {LabeledYesNoInput} from "../../../../components/input/LabeledYesNoInput";
import React from "react";
import {space} from "../../../../provider_api";
import IEventInstanceProto2 = space.kenko.proto.IEventInstanceProto2;
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import IErrorProto = space.kenko.proto.IErrorProto;
import IKenkoEventDetails = space.kenko.proto.IKenkoEventDetails;

type Props = {
  workingCopy: IEventInstanceProto2
  errors: IErrorProto[];
  updateKenkoEventDetails: (lambda: (event?: IKenkoEventDetails | null) => IKenkoEventDetails) => void;
  officeOptions: { value: string, label: string }[]
}

/**
 * Each calendar event type has its own editor. This could be important down the road
 * since event types could get quite different! Think of private appointments vs public
 * classes (w/ lots of students) or even some kind of appointment with 2 teachers.
 *
 * Note that it's safe to assume that in these editors, the correct 'oneof' is already
 * selected in the request proto.
 */
export const AvailabilityBlockEditor = (props: Props) => {

  const profile = useProviderProfile();

  // Some hackery here -- on the backend, the available offices is an array, but
  // in practice we only use one value.
  let selectedLocation = props.workingCopy.details?.kenkoDetails?.availability?.locationId;

  return <>
    <LabeledDropdownInput<string> label={'Choose a provider'}
                                  value={props.workingCopy.details?.kenkoDetails?.availability?.providerId}
                                  error={findError(props.errors, 'provider')}
                                  options={filterPractitioners(profile.businessProfile?.providers)
                                      .map(provider => {
                                        return {
                                          value: provider.providerId as string,
                                          label: provider.firstName + ' ' + provider.lastName
                                        }
                                      })}
                                  onChange={v => props.updateKenkoEventDetails(previous => {
                                    return {
                                      ...previous,
                                      availability: {
                                        ...previous?.availability,
                                        providerId: findProvider(profile.businessProfile?.providers, v)?.providerId
                                      }
                                    }
                                  })}/>

    <LabeledDropdownInput<string> label={'Choose a location'}
                                  value={selectedLocation}
                                  error={findError(props.errors, 'offices')}
                                  options={props.officeOptions}
                                  onChange={v => {
                                    console.log('offices', v);
                                    props.updateKenkoEventDetails(previous => {
                                      return {
                                        ...previous,
                                        availability: {
                                          ...previous?.availability,
                                          locationId: v,
                                        }
                                      }
                                    })
                                  }}/>

    {profile.businessProfile?.proto?.virtualAppointmentInfo &&
        <LabeledYesNoInput label={'Virtual appointments available?'}
                           value={props.workingCopy.details?.kenkoDetails?.availability?.availableForVirtualAppts || false}
                           onChange={v => {
                             props.updateKenkoEventDetails(previous => {
                               return {
                                 ...previous,
                                 availability: {
                                   ...previous?.availability,
                                   availableForVirtualAppts: v,
                                 }
                               }
                             })
                           }}/>}
  </>
}