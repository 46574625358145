import React from "react";
import {space} from "../../../../provider_api";
import ILicenseOrCredentialsProto = space.kenko.proto.ILicenseOrCredentialsProto;
import {findError} from "../../../../components/input/InputTypes";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {PhotoUpload} from "../../../../components/input/PhotoUpload";
import {EditableComponentProps} from "../../../../components/input/EditableList";


export const LicenseCredentialComponent = (props: EditableComponentProps<ILicenseOrCredentialsProto>) => {

  return (<div className={'HorizontalFlex Flex1 Gap20'} style={{maxWidth:'100%'}}>

    <div className={'VerticalFlex Flex1 Gap20'} style={{maxWidth:'95%'}}>

      <LabeledTextInput label={'Licenses/Credentials'} inputType={'text'}
                        value={props.value.licenseOrCredential}
                        error={findError(props.errors, 'license')}
                        onChange={v => {
                          props.onChange({
                            ...props.value,
                            licenseOrCredential: v
                          });
                          props.onClearError('license')
                        }}/>

      <PhotoUpload locationName={"Supporting Documentation"}
                   error={findError(props.errors, 'documentation')}
                   imageUrl={props.value.supportDocumentationUrl}
                   onChange={v => {
                     props.onChange({
                       ...props.value,
                       supportDocumentationUrl: v
                     });
                     props.onClearError('documentation');
                   }}/>
    </div>

    <div>
      <div className={'EditableListClose'} onClick={e => {
        e.preventDefault();
        props.onDelete();
      }}>
        <img className={'PhotoUploadCloseImage'} src={require('../../../../images/icons/close.png')} alt="Delete"/>
      </div>
    </div>

  </div>);
}
