import {ProfileChecklistItem} from "./ProfileChecklistItem";
import {
  businessProfileHasErrors,
  hasAtLeast1Provider,
  hasAtLeast1Service,
  providersHaveErrors
} from "../../WebsiteValidator";
import React, {useContext} from "react";
import {ProviderProfileContext} from "../../../../ProviderProfileProvider";

/**
 * When the business profile has never published a website they get this.
 * @constructor
 */
export const CompletionChecklist = () => {

  const profile = useContext(ProviderProfileContext);

  return <div className={'CompleteProfileChecklist'}>

    <div className={'CompleteProfileChecklistHeader'}>
      <h1>Thank you for joining Kensu!</h1>
      <h2>Your profile is <strong>70%</strong> complete.<br/>There are just a few steps remaining.</h2>
    </div>
    <hr/>
    <ProfileChecklistItem title={'1. Complete Business Profile'}
                          description={<>
                            Provide additional information about your business hours, upload photos and
                            documents you would like to share with future clients so we can build out your
                            Kensu website.
                          </>}
                          link={'/profile'}
                          buttonLabelIncomplete={'Complete profile'}
                          buttonLabelComplete={'Edit profile'}
                          completed={!businessProfileHasErrors(profile.businessProfile)}/>

    <ProfileChecklistItem title={'2. Add Team Information'}
                          description={<>
                            Add information about your team. 80% of clients are more likely to book a service
                            if the practitioner has a detailed and professional bio that includes their
                            qualifications, specialties, and a photo.
                            {!hasAtLeast1Provider(profile.businessProfile) &&
                                <div className={'ErrorText'} style={{marginTop: 10}}>
                                  * At least one practitioner is required in your profile
                                </div>}
                            {providersHaveErrors(profile.businessProfile) &&
                                <div className={'ErrorText'} style={{marginTop: 10}}>
                                  * We detected some missing information in your provider profile(s)
                                </div>}
                          </>}
                          link={'/team'}
                          buttonLabelIncomplete={'Add team'}
                          buttonLabelComplete={'Edit team'}
                          completed={hasAtLeast1Provider(profile.businessProfile) &&
                              !providersHaveErrors(profile.businessProfile)}/>

    <ProfileChecklistItem title={'3. Add Services'}
                          description={<>
                            Add information about the services you will offer. This will help clients navigate
                            your practitioner page and find your services on Kensu’s marketplace.

                            {!hasAtLeast1Service(profile.businessProfile) &&
                                <div className={'ErrorText'} style={{marginTop: 10}}>
                                  * At least one service offering is required
                                </div>}
                          </>}
                          link={'/services'}
                          buttonLabelIncomplete={'Add services'}
                          buttonLabelComplete={'Edit services'}
                          completed={hasAtLeast1Service(profile.businessProfile)}/>

    <ProfileChecklistItem title={'4. Add Availability and Appointments (recommended)'}
                          description={<>
                            Update your Kensu calendar with any existing appointments. This helps you keep all
                            of your booking information in one place. We provide seamless integrations with
                            Google Calendar and Outlook.
                          </>}
                          link={'/calendar'}
                          buttonLabelIncomplete={'Add appointments'}
                          buttonLabelComplete={'Edit appointments'}
                          completed={false}/>

    {/*<ProfileChecklistItem title={'5. Add Financial Information'}*/}
    {/*                      description={<>*/}
    {/*                        Add your billing and payment information so you can start receiving bookings as*/}
    {/*                        soon as your website goes live. You will not be charged anything until your*/}
    {/*                        [11]th booking has been made successfully. See payment terms.*/}
    {/*                      </>}*/}
    {/*                      link={'#'}*/}
    {/*                      buttonLabel={'Add financial information'}*/}
    {/*                      completed={false}/>*/}

    <ProfileChecklistItem title={'5. Preview your Kensu Website'}
                          description={<>
                            Once you’re satisfied with the preview of your website, submit it for review.
                            This typically takes 24 hours. Once approved, you can publish your page and
                            start connecting with clients!
                          </>}
                          link={'/website'}
                          buttonLabelIncomplete={'Preview my page'}
                          buttonLabelComplete={'Preview my page'}
                          completed={false}/>

  </div>
}