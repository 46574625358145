import React from 'react';
import './InputTypes.css';
import {MultiSelect} from "./MultiSelectInput";
import Select, {ActionMeta} from "react-select";

type Props<T> = {
  label: string;
  value: T[];
  error?: string;
  options: { value: T, label: string }[]
  onChange: (value: T[]) => void;
}

// TODO -- this uses the 0th as the default by default
export const LabeledMultiSelect = (props: Props<any>) => {

  return <div className={'VerticalFlex'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>
    
    <Select
        defaultValue={props.options.filter(v => {
          return props.value.indexOf(v.value) >= 0;
        })}
        isMulti
        name="cats"
        options={props.options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(value, action) => {
          props.onChange([...value.map(v => v.value)]);
        }}
    />

    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>

}