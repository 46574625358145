import React from "react";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {LabeledPhoneInput} from "../../../../components/input/LabeledPhoneInput";
import {LabeledAddressInput} from "../../../../components/input/LabeledAddressInput";
import {LabeledTimeZoneInput} from "../../../../components/input/LabeledTimeZoneInput";
import {space} from "../../../../provider_api";
import {findError} from "../../../../components/input/InputTypes";
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;
import IErrorProto = space.kenko.proto.IErrorProto;
import {LabeledYesNoInput} from "../../../../components/input/LabeledYesNoInput";

type Props = {
  // The initial value of the thing.
  value: IBusinessLocationProto
  errorContext: IErrorProto[],
  onChange: (newValue: IBusinessLocationProto) => void;
  onDelete: () => void;
}

export const BusinessLocation = (props: Props) => {

  return <div className={'InputSection'}>
    <LabeledTextInput label={'Location name'} value={props.value.locationName}
                      helpText={'This location name will be public to clients'}
                      error={findError(props.errorContext, 'locationName')}
                      onChange={value => props.onChange({
                        ...props.value,
                        locationName: value
                      })}
                      inputType={'text'}/>

    <LabeledAddressInput label={'Address'} value={props.value.address}
                         error={findError(props.errorContext, 'address')}
                         disabled={props.value.hasBeenPublished ?? false}
                         onChange={value => props.onChange({
                           ...props.value,
                           address: value?.formatted_address,
                           lat: value?.geometry?.location?.lat(),
                           lng: value?.geometry?.location?.lng(),
                         })}/>

    <LabeledTimeZoneInput label={'Time Zone'}
                          value={props.value.timeZone}
                          error={findError(props.errorContext, 'timeZone')}
                          disabled={props.value.hasBeenPublished ?? false}
                          onChange={value => props.onChange({
                            ...props.value,
                            timeZone: value,
                          })}/>

    <LabeledPhoneInput label={'Phone number'} value={props.value.phone}
                       error={findError(props.errorContext, 'phone')}
                       onChange={value => props.onChange({
                         ...props.value,
                         phone: value,
                       })}/>

    <LabeledTextInput label={'Email address'} value={props.value.email}
                      inputType={'email'}
                      error={findError(props.errorContext, 'email')}
                      onChange={value => props.onChange({
                        ...props.value,
                        email: value,
                      })}/>

    {props.value.hasBeenPublished &&
        <LabeledYesNoInput label={'Disable this location'} value={props.value.disabled ?? false}
                           onChange={value => props.onChange({
                             ...props.value,
                             disabled: value
                           })}/>}

    {!props.value.hasBeenPublished &&
        <div>
          <button className={'ActionButton'} onClick={props.onDelete}>Delete this location</button>
        </div>
    }
  </div>

}