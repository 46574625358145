import React, {FormEvent, ReactNode} from 'react';
import './InputTypes.css';
import {filterErrors, findError} from "./InputTypes";
import 'react-phone-number-input/style.css'
import '../../App/Styles.css'
import {space} from "../../provider_api";
import IErrorProto = space.kenko.proto.IErrorProto;

/**
 * The type <T> is the type of data that gets edited, typically
 * a string or protocol buffer.
 */
type Props<T> = {
  label: string;
  helpText?: string; // an optional string underneath 
  
  value: T[];
  
  // The paths here like '2.degree' or '0.license'. Or for general
  // errors, it is called 'general' - this can be used when there's
  // a missing value
  errors: IErrorProto[];

  // For generating a blank instance (when the add button is chosen)
  tBuilder: () => T;
  
  renderItem: (props: EditableComponentProps<T>) => ReactNode;

  // When the value is changed.
  onChange: (value: T[]) => void;
  
  // This is the path to clear when an error changed.
  onClearError: (pathToClear: string) => void;
  
  
  disabled?: boolean,
}

/**
 * The interface that the editable component builder must adhere to.
 */
export type EditableComponentProps<T> = {
  value: T,
  
  errors: IErrorProto[],

  // Subclass must call this when a value is edited. Any relevant errors
  // should get cleared out when edited.
  onChange: (value: T) => void;
  
  onClearError: (path: string) => void,

  // Must call this when a row is deleted 
  // TODO: this could maybe live in this class?
  onDelete: () => void;
}

/**
 * This is meant to be a generic way to make a list of things.
 *
 * It can be a list of strings, or it can be a list of some custom
 * protocol buffer like "education".
 *
 * Image uploads must also be supported within this, for the use case of
 * having multiple degrees, each of which needs an image upload. This then
 * gets real crazy
 */
export function EditableList<T>(props: Props<T>) {

  const onAddButton = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onChange([...props.value, props.tBuilder()]);
  }

  const onDeleteRow = (i: number) => {
    props.onChange(props.value.filter((value, index) => {
      return index != i;
    }));
  }
  
  const generalError = (findError(props.errors, 'general'));

  return <div className={'VerticalFlex'} style={{maxWidth: '100%'}}>

    <div className={'TeamMemberEducationSectionTopRow'}>
      <h2>{props.label}</h2>
      <div className={'TextInputAddItem'}>
        <button className={'AddLocationButton'} onClick={onAddButton}>Add {props.label}</button>
      </div>
    </div>

    <div className={'TeamMemberEducationList'}>
      {props.value.map((row, i) => {
        return <div key={i}>
          {props.renderItem({
            value: props.value[i],
            errors: filterErrors(props.errors, i + '.'),
            onClearError: (pathToClear) => {
              props.onClearError(i + '.' + pathToClear);
            },
            onDelete: () => onDeleteRow(i),
            onChange: (newValue => {
              props.onChange(props.value.map((existing, existingIndex) => {
                return existingIndex == i ?
                    // Any error message gets cleared out.
                    newValue : existing;
              }));
            })
          })}
        </div>
      })}
    </div>

    {generalError && <div className={'ErrorText'}>{generalError}</div>}


    {props.helpText && <div className={'InputHelpText'}>{props.helpText}</div>}
  </div>

}