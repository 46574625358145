import React, {useEffect, useRef, useState} from 'react';
import './InputTypes.css';
import 'react-phone-number-input/style.css'
import '../../App/Styles.css'
import {APIProvider, useMapsLibrary} from '@vis.gl/react-google-maps';
import {LabeledTextInput} from "./LabeledTextInput";


type Props = {
  label: string;
  helpText?: string; // an optional string underneath 
  value: string | undefined | null;
  error: string | undefined | null,
  onChange: (location: google.maps.places.PlaceResult | null) => void,
  disabled?: boolean,
  // formatted_address is like "2143 Cumberland Ave, Charlotte, NC 28203, USA"
  // geometry.location is the other useful one like {"lat":35.1955508,"lng":-80.844284}
}

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  defaultValue: string,
}

export const LabeledAddressInput = (props: Props) => {

  const API_KEY = ("AIzaSyCFJhMw3rXpVRXvhxjFTmELaqdHigbQ2nw");

  const [placeAutocomplete, setPlaceAutocomplete] =
      useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) {
      return;
    }

    placeAutocomplete.addListener('place_changed', () => {
      console.log(JSON.stringify(placeAutocomplete.getPlace()));
      props.onChange(placeAutocomplete.getPlace());
    });

  }, [placeAutocomplete]);

  if (props.disabled) {
    return <LabeledTextInput label={props.label}
                             value={props.value}
                             onChange={() => {}}
                             disabled={true}
                             inputType={"text"}/>
  }

  return <div className={'VerticalFlex'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>

    <APIProvider apiKey={API_KEY} solutionChannel=''>
      <PlaceAutocomplete onPlaceSelect={props.onChange} 
                         defaultValue={props.value || ''}/>
    </APIProvider>

    {props.helpText && <div className={'InputHelpText'}>{props.helpText}</div>}
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>

}

const PlaceAutocomplete = (props: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
      useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) {
      return;
    }

    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));

  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) {
      return;
    }

    placeAutocomplete.addListener('place_changed', () => {
      console.log(JSON.stringify(placeAutocomplete.getPlace()));
      props.onPlaceSelect(placeAutocomplete.getPlace());
    });

  }, [props.onPlaceSelect, placeAutocomplete]);

  return <input className='LabeledTextInput' defaultValue={props.defaultValue} ref={inputRef}/>;
};


