import React from 'react';
import './InputTypes.css';
import TimezoneSelect from "react-timezone-select"
import {LabeledTextInput} from "./LabeledTextInput";

type Props = {
  label: string;
  value: string | undefined | null,
  error: string | undefined | null,
  onChange: (value: string) => void;
  disabled?: boolean,
}

export const LabeledTimeZoneInput = (props: Props) => {

  if (props.disabled) {
    return <LabeledTextInput label={props.label}
                             value={props.value}
                             onChange={() => {}}
                             disabled={true}
                             inputType={"text"}/>
  }
  
  return <div className={'VerticalFlex'} style={{minWidth: 130}}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>

    <TimezoneSelect
        isDisabled={props.disabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 0,
            height: 45,
            margin: 0,
          }),
        }}
        classNames={{
          // input: (s) => 'TimeZoneInputLabel',
          container: (s) => 'TimeZoneInputContainer',
        }}
        value={props.value || ''} onChange={v => props.onChange(v.value)}/>

    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>
}