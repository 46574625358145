import React from 'react';
import './InputTypes.css';
import PhoneInput from 'react-phone-number-input'

// The value is in in E.164 format e.g. "+12345678900".
type Props = {
  label: string;
  value: string | null | undefined
  error: string | null | undefined
  onChange: (value: string) => void;
}

export const LabeledPhoneInput = (props: Props) => {

  return <div className={'VerticalFlex Flex1'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>
    <PhoneInput
        placeholder="Enter phone number"
        defaultCountry={'US'}
        value={props.value || ''}
        onChange={v => props.onChange(v as string)}/>
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>
  
}