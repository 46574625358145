import React from 'react';
import './InputTypes.css';
import 'react-phone-number-input/style.css'
import '../../App/Styles.css'

type Props = {
  label: string;
  helpText?: string; // an optional string underneath 
  
  value?: string | null | undefined;
  error?: string | null | undefined;
  
  onChange: (value: string) => void;
  inputType: 'text' | 'tel' | 'email' | 'textarea' | 'number'
  disabled?: boolean,
}

export const LabeledTextInput = (props: Props) => {

  return <div className={'VerticalFlex Flex1'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>

    {props.inputType != 'textarea' &&
        <input className={'LabeledTextInput'} type={props.inputType} 
               value={props.value || ''}
               disabled={props.disabled}
               onChange={event => props.onChange(event.target.value)}/>}

    {props.inputType == 'textarea' &&
        <textarea className={'LabeledTextAreaInput'}
                  value={props.value || ''}
                  rows={6}
                  disabled={props.disabled}
                  onChange={event => props.onChange(event.target.value)}/>}

    {props.helpText && <div className={'InputHelpText'}>{props.helpText}</div>}
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>

}