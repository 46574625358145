import React, {useState} from "react";
import {SendRpc} from "../../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {com} from "../../../../provider_api";
import CreateConnectedAccountResponse = com.onkensu.platform.billing.CreateConnectedAccountResponse;
import CreateConnectedAccountLinkRequest = com.onkensu.platform.billing.CreateConnectedAccountLinkRequest;
import CreateConnectedAccountLinkResponse = com.onkensu.platform.billing.CreateConnectedAccountLinkResponse;
import {useProviderProfile} from "../../../../ProviderProfileProvider";

export const Billing = () => {

  const {getIdTokenClaims} = useAuth0();
  const {business} = useProviderProfile();

  // The business might already have a connected account.
  const [connectedAccountId, setConnectedAccountId] = useState(business?.stripeAccountId);

  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
  const [error, setError] = useState(false);

  const createAccount = () => {

    SendRpc(getIdTokenClaims, 'create_connected_account', new Uint8Array(0))
        .then(bytes => {
          const response = CreateConnectedAccountResponse.decode(bytes);
          setConnectedAccountId(response.accountId);
        })
        .catch(e => {
          console.warn(e);
          setError(true);
        });
  }

  const createAccountLink = () => {

    let request = CreateConnectedAccountLinkRequest.encode(new CreateConnectedAccountLinkRequest({
      accountId: connectedAccountId
    })).finish();

    SendRpc(getIdTokenClaims, 'create_connected_account_link', request)

        .then(bytes => {
          const response = CreateConnectedAccountLinkResponse.decode(bytes);
          window.location.href = response.accountLinkUrl;

        })
        .catch(e => {
          console.warn(e);
          setError(true);
        });
  }

  return <div className={'NonScrollingToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Billing</h1>
      </div>
    </div>

    <div className={'ProviderToolAreaContent VerticalScroll Padding20'}>

      {!connectedAccountId && <>
        <h2>Stripe integration</h2>
        <p>
          KENSU partners with Stripe to help you receive payments and keep your personal bank and details
          secure.
        </p>
      </>}

      {connectedAccountId && <>
        <h2>Stripe integration</h2>
        <p>
          Add information to your Stripe account to start accepting money
        </p>
      </>}

      {!accountCreatePending && !connectedAccountId && (
          <button onClick={createAccount}>Create an account!</button>
      )}
      {connectedAccountId && !accountLinkCreatePending && (
          <button onClick={createAccountLink}>Add information</button>
      )}

      {error && <p className="error">Something went wrong!</p>}
      {(connectedAccountId || accountCreatePending || accountLinkCreatePending) && (
          <div className="dev-callout">
            {connectedAccountId &&
                <p>Your connected account ID is: <code className="bold">{connectedAccountId}</code></p>}
            {accountCreatePending && <p>Creating a connected account...</p>}
            {accountLinkCreatePending && <p>Creating a new Account Link...</p>}
          </div>
      )}
    </div>
  </div>;
}