import {IClientServiceProto} from "./compiled";

interface DateDivProps {
  service: IClientServiceProto,
  dates: Date[],
  onTimeSelected: (timestamp: number) => void;
}

/**
 * Returns a column, with the date as a header and all available times underneath.
 */
export const DateDiv = (props: DateDivProps) => {
  return <>
    <div className={'BookingOverlayDate'}>
      {props.dates[0].toLocaleDateString(undefined,
          {weekday: 'short', day: 'numeric', month: 'short'})}</div>

    <div className={'BookingOverlayTimeSlotsContainer'}>

      {props.dates.map(date => {
        return <button key={date.getTime()}
                       className={'BookingOverlaySelectable BookingOverlaySelectableTimeSlot'}
                       onClick={() => props.onTimeSelected(date.getTime())}>
          {date.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'})}
        </button>
      })}
    </div>
  </>
}