import React from 'react';
import './InputTypes.css';
import {CountryDropdown} from "react-country-region-selector";

type Props = {
  label: string;
  helpText?: string;
  error?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const LabeledYesNoInput = (props: Props) => {

  return <div className={'VerticalFlex Flex1'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>
    <input className={'CreateProfileCheckbox'}
        type={'checkbox'} checked={props.value || false}
           onChange={(e) => {props.onChange(e.target.checked)}}/>
    {props.helpText && <div className={'InputHelpText'}>{props.helpText}</div>}
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>
  
}