import {com} from "./compiled";
import React, {useEffect, useState} from "react";
import {SendRpc} from "../util";
import {useAuth0} from "@auth0/auth0-react";
import GetCheckoutSessionRequest = com.onkensu.platform.billing.GetCheckoutSessionRequest;
import GetCheckoutSessionResponse = com.onkensu.platform.billing.GetCheckoutSessionResponse;

type Props = {
  sessionId: string,

}

export const RedeemClass = (props: Props) => {

  const {getIdTokenClaims} = useAuth0();

  const [session, setSession] = useState<string>();

  useEffect(() => {
    SendRpc(getIdTokenClaims, 'get_checkout_session', GetCheckoutSessionRequest.encode(
        new GetCheckoutSessionRequest({sessionId: props.sessionId})).finish())
        .then(r => {
          let response = GetCheckoutSessionResponse.decode(r);
          console.log('response', response.json);
          setSession(JSON.parse(response.json));

        })
        .catch(e => {
          console.log('Error booking.. ', e);
        });
  }, []);

  return <>
    <pre>
      <code>
      {JSON.stringify(session, null, 2)}
        </code>
    </pre>

  </>;
}