import {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "./RpcSender";
import {GetUserProfileRequest, GetUserProfileResponse, IClientProfileProto} from "../components";

export const UserProfileContext = createContext<{
  profile: IClientProfileProto | null | undefined,
  setProfile: (profile: IClientProfileProto | null | undefined) => void,
  profileLoading: boolean,
  profileError: string | null,
}>({
  profile: null,
  setProfile: () => {},
  profileLoading: true,
  profileError: null,
});

export const useProfile = () => {
  return useContext(UserProfileContext)
}

interface Props {
  children?: ReactElement
}

/**
 * This should return the proto configuration for this unicorn.
 * It contains all the unicorn's special characteristics, like
 * the logo, description, which modules are on the website, etc.
 *
 *
 * @return {JSX.Element}
 * @constructor
 */
export const UserProfileProvider = (props: Props) => {

  const {user, getIdTokenClaims} = useAuth0();

  const [userProfile, setUserProfile] = useState<IClientProfileProto | null | undefined>();
  const [profileLoading, setProfileLoading] = useState<boolean>(false);
  const [profileError, setProfileError] = useState<string | null>(null);

  useEffect(() => {

    if (!user) {
      setUserProfile(null);
      setProfileLoading(false);
      setProfileError(null);
      return;
    }

    // Download it immediately at the beginning...
    console.log('[Fetching user profile...]');
    setUserProfile(null);
    setProfileLoading(true);
    setProfileError(null);

    SendRpc(getIdTokenClaims, "get_user_profile",
        GetUserProfileRequest.encode(new GetUserProfileRequest()).finish())
        .then(response => {
          let profileResponse = GetUserProfileResponse.decode(response);
          console.log("User profile: " + JSON.stringify(profileResponse.toJSON()))
          setUserProfile(profileResponse.profile);
        })
        .catch(error => {
          console.log('user error', error)
          setProfileError(error)
        })
        .finally(() => {
          setProfileLoading(false);
        });

  }, [user]);

  return (<UserProfileContext.Provider value={{
    profile: userProfile,
    setProfile: setUserProfile,
    profileLoading: profileLoading,
    profileError: profileError
  }}>
    {props.children}
  </UserProfileContext.Provider>);
};
