/* global google */

import {Route, Routes} from "react-router";
import React, {useContext} from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import {ProviderProfileContext} from "../ProviderProfileProvider";
import {Header} from "./Header";
import {ProviderHome} from "./Home/ProviderHome";
import {RedeemBusinessInvite} from "./RedeemBusinessInvite/RedeemBusinessInvite";
import {RedeemTeamMemberInvite} from "./RedeemTeamMemberInvite/RedeemTeamMemberInvite";
import {AdminHeader} from "./AdminHeader/AdminHeader";
import {LoginGate} from "./LoginGate";
import {CheckBusinessInvite} from "./RedeemBusinessInvite/CheckBusinessInvite";

/**
 * This is the parent of everything on the site. It acts as a login gate
 * so we only allow people in the whitelist. If the user is not logged
 * in they are prompted to log in.
 *
 * If they're not in the whitelist they are just not permitted.
 *
 * If they're in, then the rest of the app renders.
 */
export const App = () => {

  return (
      <div className="App">
        <AdminHeader/>
        <Header/>
        <Body/>
      </div>
  );
}

/**
 * This is extracted from above so I can do if/else clauses for all the possible
 * options rather than {!profileError && !profileLoading && profile && ..} nonsense.
 */
const Body = () => {

  const {isLoading, user, loginWithRedirect} = useAuth0();
  const {effectiveUser, profileError, profileLoading, businessProfile, authenticatedUserRoles} =
      useContext(ProviderProfileContext);

  if (isLoading) {
    return <div>Loading user...</div>
  }

  if (!user) {

    console.log('no user')

    // If there's no auth0 user, they typically have to go to the login gate. But for
    // redeeming a business invite, they're allowed to see if the invite is valid BEFORE
    // authenticating.
    return <Routes>
      <Route path="/join" element={<CheckBusinessInvite/>}/>
      <Route path="*" element={<LoginGate/>}/>
    </Routes>
  }

  // Ok so there IS a user now... we'll try to load their profile then pull up the whole app.

  if (!businessProfile) {
    console.log('no business profile')
  }
  
  if (profileLoading) {
    console.log('loading profile')
    return <div>Loading profile...</div>;
  }

  if (profileError) {
    return <div style={{color: 'red'}}>{profileError}</div>;
  }

  // Effective user is being used as a proxy for "Is there an existing account for
  // this login". If not, they need an invite.
  if (!effectiveUser) {
    return <Routes>
      <Route path="/join" element={<CheckBusinessInvite/>}/>
      <Route path="/redeem" element={<RedeemBusinessInvite/>}/>
      <Route path="/providerInvite/:providerId/:inviteCode" element={<RedeemTeamMemberInvite/>}/>
      <Route path="*" element={<div>
        We're sorry but we don't see a provider profile for this login.
        <br/><br/>
        <a href={'mailto:info@kenko.space'}>Contact us</a> if you're interested in becoming a Kensu provider
      </div>}/>
    </Routes>
  }

  // All is good! There are sub-routes in ProviderHome.
  return <ProviderHome/>
}

export default App;
