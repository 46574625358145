import {IApiBusinessProfileProto, space} from "./compiled";
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;

type Props = {
  business: IApiBusinessProfileProto | undefined,
  onSelect: (location: IBusinessLocationProto) => void;
}

export const ChooseLocation = (props: Props) => {

  return <div style={{display: 'flex', flexDirection: "column", textAlign: 'start'}}>
    <b>Select Service Location</b>
    <br/>

    {props.business?.locations?.map(location => {
      return <button key={'loc' + location.locationId} className={'BookingOverlaySelectable'} onClick={() => {props.onSelect(location)}}>
        <div><b>{location.locationName}</b></div>
        <div>{location.address}</div>
      </button>
    })}
  </div>

}