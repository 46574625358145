import React from 'react';
import {UserProfileProvider} from "./util/UserProfileProvider";
import {BookingOverlay, IClientAppointmentProto, space} from "./components";
import {Auth0ProviderWithNavigate} from "./util/Auth0ProviderWithNavigate";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from 'react-dom/client';


/**
 * This is the standalone version of the thing; it includes the browser router
 * and auth0 provider required.
 * 
 * Probably other react apps could just embed the BookingOverlay directly
 * if they wanted to?
 */
export const BookingOverlayApp = (props: BookingOverlayProps) => {

  return <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <UserProfileProvider>
          <BookingOverlay providerId={props.providerId}
                          onAppointmentsUpdated={props.onAppointmentsUpdated}
                          businessId={props.businessId}
                          serviceSku={props.serviceSku}
                          onCancel={props.onCancel}
                          locationId={props.locationId}
                          version={props.version}/>
        </UserProfileProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>;
}

export type BookingOverlayProps = {

  // The version is here in case the use is viewing a draft or
  // otherwise unpublished version. If unset, the live version is used. 
  businessId: string,
  version?: string | undefined,

  // This is optional, if specified the view filters based on this location.
  locationId?: string | undefined,

  // Also optional, if unset there's a screen to select a service.
  serviceSku?: string | undefined;

  // Optional, if a provider was chosen already
  providerId?: string | undefined,

  onCancel?: () => void;

  // This happens when the RPC succeeds; user may not have closed
  // the modal yet! It should update the list of appointments.
  onAppointmentsUpdated?: ((appointments: IClientAppointmentProto[]) => void) | undefined;
}

export const ShowBookingOverlay = (props: BookingOverlayProps) => {
  const root = createRoot(document.getElementById('injected-react-content') as HTMLElement);
  root.render(<BookingOverlayApp
      businessId={props.businessId}
      serviceSku={props.serviceSku}
      providerId={props.providerId}
      version={props.version}
      onAppointmentsUpdated={props.onAppointmentsUpdated}
      locationId={props.locationId}
      onCancel={() => { root.render(<div/>);}}
  />);
}
