import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {IApiBusinessProfileProto, space} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {useParams} from "react-router-dom";
import {Calendar, Event, momentLocalizer, SlotInfo} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {SendRpc} from "../../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {NewEventModal} from "./NewEventModal";
import {EditEventModal} from "./EditEventModal";
import withDragAndDrop, {EventInteractionArgs} from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import {ConfirmEditPrompt} from "./ConfirmEditPrompt";
import {filterPractitioners, findLocation, findProvider, findProviderIndex, findService} from "./CalendarUtil";
import {CalendarEventType} from "./EventModal";
import GetEventsRequest = space.kenko.proto.GetEventsRequest;
import GetEventsResponse = space.kenko.proto.GetEventsResponse;
import DebugDeleteCalendarEventsRequest = space.kenko.proto.DebugDeleteCalendarEventsRequest;
import DebugDeleteCalendarEventsResponse = space.kenko.proto.DebugDeleteCalendarEventsResponse;
import IEventInstanceProto = space.kenko.proto.IEventInstanceProto;
import IEventDetailsProto = space.kenko.proto.IEventDetailsProto;
import UpdateInstanceRequest = space.kenko.proto.UpdateInstanceRequest;
import UpdateInstanceResponse = space.kenko.proto.UpdateInstanceResponse;
import IEventInstanceProto2 = space.kenko.proto.IEventInstanceProto2;
import KenkoEventDetails = space.kenko.proto.KenkoEventDetails;
import IAppointmentEventType = space.kenko.proto.IAppointmentEventType;
import IAvailabilityEventType = space.kenko.proto.IAvailabilityEventType;
import IClassEventType = space.kenko.proto.IClassEventType;

// This is the thing that gets stuffed into the react calendar as the "resource"
// property. It's an arbitrary thing to let you go back and look up whatever associated
// data you need outside the calendar
// TODO just get rid of this now that it's just one field?? 
type CalendarEventResource = {
  instance: IEventInstanceProto2
};

// Very important that this is OUTSIDE of the ProviderCalendar definition.
// Otherwise changing the time range on the calendar does not work, it resets
// to exactly the previous date. I don't know what's going on.
const DnDCalendar = withDragAndDrop(Calendar)

const getColor = (i: number) => {
  switch (i % 10) {
    case 0:
      return '#5e3d7e';
    case 1:
      return '#d58639';
    case 2:
      return '#286891';
    case 3:
      return '#a949a1';
    case 4:
      return '#038d13';
    case 5:
      return '#b23921';
    case 6:
      return '#734d3f';
    case 7:
      return '#a98172';
    case 8:
      return '#0b492f';
    case 9:
      return '#404d40';
    default:
      return '#124567'
  }

}

const getTitle = (businessProfile: IApiBusinessProfileProto | null | undefined, details: KenkoEventDetails) => {
  switch (details.EventType) {
    case "appointment":
      const appointment = details.appointment as IAppointmentEventType;
      return findService(businessProfile, appointment.serviceSku)?.name + " with " + details.appointment?.clientName

    case "availability":
      const availability = details.availability as IAvailabilityEventType;

      const locations: string[] = [];

      if (availability.locationId && businessProfile?.locations) {
        const location = findLocation(businessProfile, availability.locationId);
        locations.push(location?.locationName || 'Unnamed');
      }

      if (availability.availableForVirtualAppts) {
        locations.push("Virtual");
      }

      const locationString = `(${locations.join(', ')})`;

      return 'Available';

    case "class":

      const classEvent = details.class as IClassEventType;
      const attendees = classEvent.clients?.length;
      return `${classEvent.className} (${attendees} ${attendees == 1 ? 'signup' : 'signups'})`

  }

  return '';
}

// This is the page for editing (or adding) a service.
export const ProviderCalendar = () => {

  // This is the person whose calendar you're viewing or editing.
  // If unspecified, it's yours.
  const {providerIdParam} = useParams();
  const profile = useProviderProfile();
  const {getIdTokenClaims, loginWithRedirect} = useAuth0();
  const localizer = momentLocalizer(moment);

  // Authoritative events list, from the server
  const [events, setEvents] = useState<IEventInstanceProto2[]>([]);

  /**
   * This is the modal overlay, if one exists! By default this is undefined and just
   * the base calendar is shown. There are different overlays for creating new events
   * (NewEventModal), editing events (EditEventModal), and confirmations (ConfirmEditPrompt).
   * Only one is done at any time.
   */
  const [overlay, setOverlay] = useState<ReactElement>();

  /**
   * Toggles for which providers are shown.
   */
  const [providerFilter, setProviderFilter] = useState<string[]>(
      profile.businessProfile?.providers?.map(provider => {
        return provider.providerId as string
      }) || []);

  /**
   * Toggles which event types are shown
   */
  const [eventTypeFilter, setEventTypeFilter] = useState<CalendarEventType[]>(['appointment', 'availability', 'class']);

  // This loads events on the original page load.
  useEffect(() => {
    getEvents()
  }, [])

  const dateOffsetFromToday = (offsetDays: number) => {
    const d = new Date();
    d.setDate(d.getDate() + offsetDays)
    return d;
  }

  const eventTypeShown = (eventType: CalendarEventType) => {
    return eventTypeFilter.indexOf(eventType) >= 0;
  }

  // The range of the calendar view, used to limit the number of events
  // that are sent down from the server
  const startRange = useRef(dateOffsetFromToday(-7))
  const endRange = useRef(dateOffsetFromToday(7))

  /**
   * HACK!! do i really need a switch statement on each person??
   */
  const getProviderForEvent = (instance: IEventInstanceProto) => {
    if (instance.details?.kenkoDetails?.availability) {
      return instance.details.kenkoDetails.availability.providerId;
    }

    if (instance.details?.kenkoDetails?.appointment) {
      return instance.details.kenkoDetails.appointment.providerId;
    }

    if (instance.details?.kenkoDetails?.class) {
      return instance.details.kenkoDetails.class.teacherProviderId;
    }

    return null;
  }

  const getEventType = (instance: IEventInstanceProto): CalendarEventType | null => {
    if (instance.details?.kenkoDetails?.availability) {
      return 'availability';
    }

    if (instance.details?.kenkoDetails?.appointment) {
      return 'appointment'
    }

    if (instance.details?.kenkoDetails?.class) {
      return 'class';
    }

    return null;
  }

  const toCalendarEvents = useMemo(() => {
    let calendarEvents: Event[] = [];
    events.forEach(instance => {
      const details = instance.details as IEventDetailsProto;
      if (instance.details) {

        // If this provider's calendar is hidden, don't add the event.
        const eventProvider = getProviderForEvent(instance) as string;
        if (!eventProvider) {
          // TODO forbid these events with no providers
          console.warn('No provider for event', instance);
        }

        const eventType = getEventType(instance);
        if (!eventType) {
          console.warn('Event has no type', instance)
        }

        if (eventProvider && providerFilter.indexOf(eventProvider) < 0) {
          return;
        }

        if (eventType && !eventTypeShown(eventType)) {
          return;
        }

        calendarEvents.push({
          start: new Date(details.startTimestamp as number),
          end: new Date(details.endTimestamp as number),
          title: getTitle(profile.businessProfile, details.kenkoDetails as KenkoEventDetails),
          // Resource is a generic field on the calendar that lets us correlate to the
          // original data
          //
          // The full calendar instance backing this thing. Overkill?
          resource: {
            instance: instance
          }
        })
      }
    })
    return calendarEvents;
  }, [events, providerFilter, eventTypeFilter])

  const availabilityBlocks = useMemo(() => {

    const blocks: IEventInstanceProto[] = [];

    events.forEach(instance => {
      if (instance.details?.kenkoDetails?.availability) {
        blocks.push(instance);
      }
    })

    return blocks;

  }, [events])


  const getEvents = () => {
    const request = new GetEventsRequest({
      calendarIds: [{
        businessId: profile.business?.businessId,
        calendarId: '100'
      }],
      startTimestampMs: startRange.current.getTime(),
      endTimestampMs: endRange.current.getTime(),
    })

    SendRpc(getIdTokenClaims, 'get_events', GetEventsRequest.encode(request).finish()
    , loginWithRedirect)
        .then(value => {
          const response = GetEventsResponse.decode(value);
          console.log(response.events);
          setEvents(response.events);
        }).catch(e => {
      alert('error ' + e);
      console.log('error', e);
    });
  }

  const debugResetRpc = () => {
    let request = new DebugDeleteCalendarEventsRequest();
    SendRpc(getIdTokenClaims, 'debug_delete_events',
        DebugDeleteCalendarEventsRequest.encode(request).finish())
        .then(value => {
          const response = DebugDeleteCalendarEventsResponse.decode(value);
          if (response.okay) {
            setEvents([]);
          } else {
            alert('error deleting events');
          }
        }).catch(e => {
      alert('error deleting events ' + e)
    });
  }

  // There are cases (like editing all future instances of an event)
  // where multiple events may be updated in a response.
  const updateEventsFromServer = (
      existingEventId: string,
      newEvents: IEventInstanceProto2[]) => {

    // This has to replace an event that existed already

    // First clear out any instances with the old event id
    // then add the new ones.
    setEvents(events => [
      ...events.filter(e => e.eventId != existingEventId),
      ...newEvents
    ]);
    setOverlay(undefined);
  };

  const handleSelectSlot = useCallback((slot: SlotInfo) => {
    // If another modal is open don't mess with it.
    if (!overlay) {
      setOverlay(<NewEventModal
          startTime={slot.start}
          endTime={slot.end}
          calendarViewStart={startRange.current}
          calendarViewEnd={endRange.current}
          onCreate={created => {
            setEvents(events => [...events, ...created])
            setOverlay(undefined);
          }}
          onCancel={() => setOverlay(undefined)}
      />)
    }
  }, [events, overlay]);

  const handleSelectEvent = useCallback((event: Event) => {
    // If another modal is open don't mess with it.
    if (!overlay) {
      const existingEvent = event.resource as CalendarEventResource;
      setOverlay(<EditEventModal
          existingEvent={existingEvent.instance}
          calendarViewStart={startRange.current}
          calendarViewEnd={endRange.current}
          onUpdated={(events) => {
            updateEventsFromServer(existingEvent.instance.eventId as string, events);
            setOverlay(undefined);
          }}
          onCancel={() => setOverlay(undefined)}
      />)
    }
  }, [overlay, startRange, endRange]);

  const getSlotStyle = (startDate: Date, resourceId?: number | string): React.HTMLAttributes<HTMLDivElement> => {

    for (let i = 0; i < availabilityBlocks.length; i++) {
      let block = availabilityBlocks[i];

      // The date parameter is the *start* of the calendar event; we can assume
      // the end is 15 minutes later.
      const endDate = new Date(startDate);
      endDate.setMinutes(endDate.getMinutes() + 15);

      // If this block is totally contained in an availability block then
      // i guess it's available
      if (startDate.getTime() >= (block.details?.startTimestamp as number) &&
          endDate.getTime() <= (block.details?.endTimestamp as number)) {

        return {
          className: 'slot-enabled',
          style: {}
        }
      }
    }

    return {
      className: 'slot-disabled',
      style: {}
    }
  }

  /**
   * Sets styles for calendar events. This is a callback from the calendar.
   * Availability blocks look a bit different than appointments.
   */
  const getEventStyle = (event: Event, start: Date, end: Date, isSelected: boolean) => {

    const existingEvent = event.resource as CalendarEventResource;


    switch (getEventType(existingEvent.instance)) {
      case 'availability': {
        const eventProvider = existingEvent.instance.details?.kenkoDetails?.availability?.providerId
        const providerColor = getColor(findProviderIndex(profile.businessProfile?.providers, eventProvider));

        return {
          style: {
            backgroundColor: 'white',
            borderColor: providerColor,
            color: 'black',
            borderWidth: 2,
          }
        }
      }
      case "appointment": {
        const eventProvider = existingEvent.instance.details?.kenkoDetails?.appointment?.providerId
        const providerColor = getColor(findProviderIndex(profile.businessProfile?.providers, eventProvider));
        return {
          style: {
            backgroundColor: providerColor,
            borderColor: providerColor,
            color: 'white',
            borderWidth: 2,
          }
        }
      }
      case "class": {
        const classEvent = existingEvent.instance.details?.kenkoDetails?.class
        const eventProvider = classEvent?.teacherProviderId
        console.log('event provider', eventProvider)
        const providerColor = getColor(findProviderIndex(profile.businessProfile?.providers, eventProvider));
        return {
          style: {
            backgroundColor: providerColor,
            borderColor: providerColor,
            color: 'white',
            borderWidth: 2,
          }
        }
      }
      default: {
        return {
          style: {}
        }
      }
    }
  }

  const handleEventResize = useCallback((event: EventInteractionArgs<Event>) => {

    const existingEvent = event.event.resource as CalendarEventResource

    setOverlay(<ConfirmEditPrompt
        onConfirm={(updateFutureEvents) => {
          if (!updateFutureEvents) {

            // TODO -- consolidate with the edit RPC in EditEventModal
            // Just this instance. Okay! 
            const request = new UpdateInstanceRequest({
              startTimestampMs: startRange.current.getTime(),
              endTimestampMs: endRange.current.getTime(),

              instance: {
                ...existingEvent.instance,
                details: {
                  ...existingEvent.instance.details,
                  startTimestamp: (event.start as Date).getTime(),
                  endTimestamp: (event.end as Date).getTime(),
                }
              }
            })

            // alert('creating request ' + request)
            console.log(request)

            SendRpc(getIdTokenClaims, "update_event",
                UpdateInstanceRequest.encode(request).finish())
                .then(value => {
                  const response = UpdateInstanceResponse.decode(value);
                  if (response.errors.length) {
                    alert('Error! ' + response.errors.map(e => e.error).join(', '));
                  }
                  if (response.updatedEvent) {
                    updateEventsFromServer(existingEvent.instance.eventId as string,
                        response.updatedEvent);
                    setOverlay(undefined);
                  }
                })
                .catch(e => {
                  alert('error ' + e);
                })
          } else {

            // Update all future events.
            window.alert('Not implemented!');
          }
        }

        }
        onCancel={() => setOverlay(undefined)}/>
    )

  }, []);

  const toggleProviderShown = (providerId: string) => {
    setProviderFilter(existing => {
      if (existing.indexOf(providerId) < 0) {
        return [...existing, providerId];
      } else {
        return existing.filter(e => e != providerId);
      }
    })
  }

  const toggleEventTypeShown = (eventType: CalendarEventType) => {
    setEventTypeFilter(existing => {
      if (existing.indexOf(eventType) < 0) {
        return [...existing, eventType];
      } else {
        return existing.filter(e => e != eventType);
      }
    })
  }

  // This is a special tool that doesn't scroll! THe calendar has a scrollbar
  // within itself.
  return <div className={'NonScrollingToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>
          {`${profile.businessProfile?.proto?.businessName} Calendar`}
        </h1>
        {profile.authenticatedUserRoles.indexOf('admin') >= 0 &&
            <button onClick={debugResetRpc}>delete all events</button>
        }
      </div>
    </div>

    <div className={'Flex1 HorizontalFlex'} style={{overflow: 'hidden', marginTop: 10}}>

      <div className={'CalendarLeftNav'}>
        <h4>Team Members</h4>
        {filterPractitioners(profile.businessProfile?.providers).map((provider, i) => {

          let thisProviderId = provider.providerId as string;
          const providerShown = providerFilter.indexOf(thisProviderId) >= 0;
          const providerColor = getColor(i);

          return <div className={'CalendarTeamMemberContainer'}>
            <div className={'CalendarTeamMemberCheckbox'}
                 onClick={() => toggleProviderShown(thisProviderId)}
                 style={{
                   backgroundColor: providerShown ? providerColor : '#fff',
                   borderColor: providerColor,
                 }}/>
            <div>{provider.firstName} {provider.lastName}</div>
          </  div>
        })}

        <h4>Event types</h4>
        <div className={'CalendarTeamMemberContainer'}>
          <input className={'AppointmentTypeCheckbox'} type={'checkbox'} checked={eventTypeShown('appointment')}
                 onChange={e => toggleEventTypeShown('appointment')}/>
          <div>Appointments</div>
        </div>
        <div className={'CalendarTeamMemberContainer'}>
          <input className={'AppointmentTypeCheckbox'} type={'checkbox'} checked={eventTypeShown('availability')}
                 onChange={e => toggleEventTypeShown('availability')}/>
          <div>Availability</div>
        </div>
        <div className={'CalendarTeamMemberContainer'}>
          <input className={'AppointmentTypeCheckbox'} type={'checkbox'} checked={eventTypeShown('class')}
                 onChange={e => toggleEventTypeShown('class')}/>
          <div>Classes</div>
        </div>
      </div>

      <DnDCalendar
          localizer={localizer}
          dayLayoutAlgorithm={'overlap'}
          defaultDate={new Date()}
          defaultView="week"
          views={['week', 'day']}
          events={toCalendarEvents}
          style={{width: '100%', height: '100%', overflowY: 'scroll'}}
          timeslots={4}
          step={15}
          selectable
          eventPropGetter={getEventStyle}
          slotPropGetter={getSlotStyle}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          onEventResize={handleEventResize}
          onEventDrop={handleEventResize}
          onRangeChange={(range, view) => {
            console.log('range change', range);
            if (Array.isArray(range)) {
              startRange.current = range[0];
              endRange.current = range[range.length - 1];
            } else {
              startRange.current = range.start;
              endRange.current = range.end;
            }
            getEvents();
          }}/>
    </div>

    {overlay}
  </div>
};

