
export const ServiceTypes = [
  {value: "Acupuncture", label: 'Acupuncture'},
  {value: "Traditional Chinese Medicine", label: 'Traditional Chinese Medicine'},
  {value: 'Diet & Nutrition', label: 'Diet & Nutrition'},
  {value: 'Massage Therapy', label: 'Massage Therapy'},
  {value: 'Sound Therapy', label: 'Sound Therapy'},
  {value: 'Meditation', label: 'Meditation'},
  {value: 'Naturopathy', label: 'Naturopathy'},
  {value: 'Homeopathy', label: 'Homeopathy'},
  {value: 'Yoga', label: 'Yoga'},
  {value: 'Chiropractic', label: 'Chiropractic'},
  {value: 'Physical Therapy', label: 'Physical Therapy'},
  {value: 'Skincare', label: 'Skincare'},
];
