import React from 'react';
import {space} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link} from "react-router-dom";
import IPracticeDetailsProto = space.kenko.proto.IPracticeDetailsProto;

export const Services = () => {

  const {businessProfile} = useProviderProfile();

  const extractCategories = (practice: IPracticeDetailsProto | undefined | null) => {

    let categories: (string | null | undefined) [] = [];

    practice?.services?.forEach(service => {

      let category = service.category;
      if (categories.indexOf(category) < 0) {
        categories.push(category);
      }
    })

    return categories;
  }

  return <div className={'NonScrollingToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Service Offerings</h1>
        <Link to={'add'} state={{newService: true}}>
          <button className={'ActionButton'}>
            Add
          </button>
        </Link>
      </div>
    </div>

    <div className={'ProviderToolAreaContent VerticalScroll Padding20'}>

      {extractCategories(businessProfile?.practice)?.map(category => {

        return <>
          <h2 style={{marginBlockStart: 0}}>{category || 'Uncategorized'}</h2>

          {businessProfile?.practice?.services && businessProfile.practice.services.filter((service, idx, array) => {
            return category == service.category;
          }).map(service => {
            return <Link to={`/services/edit/${service.sku}`}>
              <div className={'ServiceBox'}>
                <div className={'ServiceName'}>{service.name}</div>
                <div className={'ServiceLength'}>{service.lengthMinutes} min</div>
              </div>
            </Link>
          })}
        </>
      })}

      {!businessProfile?.practice?.services?.length && <div>
        <br/><br/>
        You don't have any services defined! Click "Add" above to create your first service.
      </div>}

    </div>
  </div>

};

