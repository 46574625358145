import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {CheckProviderInviteRequest, CheckProviderInviteResponse,} from '../../provider_api';
import {SendRpc, SendUnauthenticatedRpc} from '../../rpcSender';
import {useAuth0} from '@auth0/auth0-react';
import {useProviderProfile} from "../../ProviderProfileProvider";
import {AcceptProviderInviteRequest, AcceptProviderInviteResponse} from "../../provider_api";
import {RpcState} from "../../RpcState";
import {WelcomeToKensu} from "./WelcomeToKensu";

/**
 */
export const CheckBusinessInvite = () => {


  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('inviteCode');

  const {loginWithRedirect} = useAuth0();
  const {business} = useProviderProfile();
  const navigate = useNavigate();

  // This login redirects back to the redeem invite page...
  const auth0Login = () => {
    loginWithRedirect({
      appState: {
        returnTo: `/redeem?inviteCode=${inviteCode}`
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }

  // if the rpc is out
  const [rpcState, setRpcState] = useState<RpcState>({state: 'none'});
  const [rpcResponse, setRpcResponse] = useState<CheckProviderInviteResponse>();

  // The effect for checking an invite code; this only happens if there is no authenticated user.
  useEffect(() => {

    // This invite only goes out if there's an invite code and it's been confirmed that
    // there is no authenticated user. This then checks whether the invite is valid.
    if (!inviteCode || business || rpcState.state != 'none') {
      return;
    }

    setRpcState({state: 'pending'})

    let request = CheckProviderInviteRequest.encode(new CheckProviderInviteRequest({
      inviteCode: inviteCode,
    })).finish();

    SendUnauthenticatedRpc('action/check_provider_invite', request).then(r => {
      let response = CheckProviderInviteResponse.decode(r);
      if (response.okay) {
        setRpcState({state: 'success'});
      } else {
        setRpcState({state: 'error', message: 'Server error checking invite.'});
      }
      setRpcResponse(response);
    }).catch(e => {
      console.log(e);
      setRpcState({state: 'error', message: 'Error checking your invite. Please try again later'});
    });

  }, [inviteCode]);


  if (rpcState.state == 'pending') {
    return <div>Looking up your invite...</div>
  }

  if (rpcState.state == 'success') {
    return <WelcomeToKensu onContinue={auth0Login}/>;
  }

  return <div className={'ErrorText'}>
    Error checking invite code. it may be invalid or expired
  </div>

}
