import React from "react";
import {useAuth0} from '@auth0/auth0-react';
import {Link} from "react-router-dom";
import {AdminHeader} from "./AdminHeader/AdminHeader";

interface Props {
}

export const Header = (props: Props) => {

  const {user, logout} = useAuth0();
  const host = window.location.host

  return (
      <div className={'AppSection BeigeSection StickyHeader'}>
        <div className={'AppSectionContent Flex1'}>

          {/*<AdminHeader/>*/}

          <div className={'Header'}>
            <div className={'HeaderLeftElement'}>
              <Link style={{display: 'flex', justifyContent: 'left'}} to={'/'}>
                <img alt={'Kensu Logo'} src={require('../images/kensulogo.png')}
                     className={'HeaderLogo'}/>
              </Link>
              {host.startsWith("localhost") && <span style={{color: 'blue'}}>local&nbsp;</span>}
              {host.endsWith("thymebook.com") && <span style={{color: 'green'}}>dev&nbsp;</span>}
            </div>

            {user && <div className={'HeaderRightElement'}>
              <button className={'StandardButton'} onClick={() => logout({
                logoutParams: {
                  // Chooses correctly between localhost, thymebook.com, etc.
                  returnTo: window.location.origin
                }
              })}>LOGOUT</button>
            </div>}

          </div>
        </div>
      </div>
  );
} 