import React, {useMemo} from "react";
import {LabeledDropdownInput} from "../../../../components/input/LabeledDropdownInput";
import {findError} from "../../../../components/input/InputTypes";
import {filterPractitioners, findProvider, getServicesForProvider} from "./CalendarUtil";
import {LabeledYesNoInput} from "../../../../components/input/LabeledYesNoInput";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {LabeledPhoneInput} from "../../../../components/input/LabeledPhoneInput";
import {space} from "../../../../provider_api";
import IErrorProto = space.kenko.proto.IErrorProto;
import IEventInstanceProto2 = space.kenko.proto.IEventInstanceProto2;
import IKenkoEventDetails = space.kenko.proto.IKenkoEventDetails;
import {useProviderProfile} from "../../../../ProviderProfileProvider";

type Props = {
  workingCopy: IEventInstanceProto2
  errors: IErrorProto[];
  updateKenkoEventDetails: (lambda: (event?: IKenkoEventDetails | null) => IKenkoEventDetails) => void;
  officeOptions: { value: string, label: string }[]
}


/**
 * This is kinda like the AppointmentEditor, but you need to be able to see the number of people
 * who have signed up for the class and other details.
 *
 */
export const ClassEditor = (props: Props) => {

  const profile = useProviderProfile();

  return <>
    <div className={'HorizontalFlex Gap20'}>

      <LabeledTextInput label={'Class Name'}
                        inputType={'text'}
                        value={props.workingCopy.details?.kenkoDetails?.class?.className}
                        error={findError(props.errors, 'className')}
                        onChange={v => {
                          props.updateKenkoEventDetails(previous => {
                            return {
                              ...previous,
                              class: {
                                ...previous?.class,
                                className: v
                              }
                            }
                          })
                        }}/>

      <LabeledDropdownInput<string> label={'Choose a teacher'}
                                    value={props.workingCopy.details?.kenkoDetails?.class?.teacherProviderId}
                                    error={findError(props.errors, 'provider')}
                                    options={filterPractitioners(profile.businessProfile?.providers)
                                        .map(provider => {
                                          return {
                                            value: provider.providerId as string,
                                            label: provider.firstName + ' ' + provider.lastName
                                          }
                                        })}
                                    onChange={v => {
                                      console.log('founded founded', v)
                                      props.updateKenkoEventDetails(previous => {
                                        return {
                                          ...previous,
                                          class: {
                                            ...previous?.class,
                                            teacherProviderId: findProvider(profile.businessProfile?.providers, v)?.providerId
                                          }
                                        }
                                      })
                                    }}/>


    </div>
    <div className={'HorizontalFlex Gap20'}>

      <LabeledDropdownInput<string> label={'Choose a location'}
                                    value={props.workingCopy.details?.kenkoDetails?.class?.locationId}
                                    error={findError(props.errors, 'location')}
                                    options={props.officeOptions}
                                    onChange={v => {
                                      props.updateKenkoEventDetails(previous => {
                                        return {
                                          ...previous,
                                          class: {
                                            ...previous?.class,
                                            locationId: v
                                          }
                                        }
                                      })
                                    }}/>

      {profile.businessProfile?.proto?.virtualAppointmentInfo &&
          <LabeledYesNoInput label={'Virtual class?'}
                             value={props.workingCopy.details?.kenkoDetails?.class?.isVirtualAppt || false}
                             onChange={v => {
                               props.updateKenkoEventDetails(previous => {
                                 return {
                                   ...previous,
                                   class: {
                                     ...previous?.class,
                                     isVirtualAppt: v,
                                   }
                                 }
                               })
                             }}/>}

      <LabeledTextInput label={'Max attendees'}
                        inputType={'number'}
                        value={props.workingCopy?.details?.kenkoDetails?.class?.maxAttendees?.toString()}
                        error={findError(props.errors, 'maxAttendees')}
                        onChange={v => {
                          props.updateKenkoEventDetails(previous => {
                            return {
                              ...previous,
                              class: {
                                ...previous?.class,
                                maxAttendees: parseInt(v)
                              }
                            }
                          })
                        }}/>

      <LabeledTextInput label={'Price'}
                        inputType={'number'}
                        value={props.workingCopy.details?.kenkoDetails?.class?.price?.price?.toString()}
                        error={findError(props.errors, 'price')}
                        onChange={v => {
                          props.updateKenkoEventDetails(previous => {
                            return {
                              ...previous,
                              class: {
                                ...previous?.class,
                                price: {
                                  currencyCode: 'USD',
                                  price: parseInt(v)
                                } 
                              }
                            }
                          })
                        }}/>
    </div>

    <div className={'HorizontalFlex Gap20'}>

      <div className={'VerticalFlex'} style={{marginTop: 5, marginBottom: 5, gap: 5}}>
        <b>Attendees (currently 2 paid)</b>
        <div>Bo Boghosian (remove)</div>
        <div>Lu Shinka (remove)</div>
      </div>
    </div>
  </>
}
