import {com} from "./compiled";
import React, {useEffect, useState} from "react";
import {SendRpc} from "../util";
import {useAuth0} from "@auth0/auth0-react";
import CreateCheckoutSessionResponse = com.onkensu.platform.billing.CreateCheckoutSessionResponse;
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

type Props = {
  // Returns when the payment has completed.
  onComplete: (sessionId: string) => void;
  
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe("pk_test_51R7JnVPHY9Mg8ZAUq5XFg0VcJRLjvNVRKdA2SoSPtuGXaK3JXqR1glFYsy7u7e4jkpejBRhGPmkpD1W4XmYVKvJV00cHCIG84b");

export const Payment = (props: Props) => {

  const {getIdTokenClaims} = useAuth0();

  const [clientSecret, setClientSecret] = useState<string>();
  const [sessionId, setSessionId] = useState<string>();

  useEffect(() => {
    SendRpc(getIdTokenClaims, 'create_checkout_session', new Uint8Array(0))
        .then(r => {
          let response = CreateCheckoutSessionResponse.decode(r);
          console.log('response', JSON.stringify(response.toJSON()));
          setClientSecret(response.clientSecret);
          setSessionId(response.sessionId);
        })
        .catch(e => {
          console.log('Error booking.. ', e);
        });
  }, []);

  return <>

    {clientSecret && <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          clientSecret: clientSecret,
          onComplete: () => props.onComplete(sessionId as string),
    }}
        
    >
      <EmbeddedCheckout/>
    </EmbeddedCheckoutProvider>
    }

  </>;
}