import React from 'react';
import './InputTypes.css';
import Select from 'react-select';

type Props<T extends any> = {
  label: string;
  value: T | null | undefined;
  error: string | null | undefined;
  options: { value: T, label: string }[]
  onChange: (value: T) => void;
}

// TODO -- this uses the 0th as the default by default
export const LabeledDropdownInput = <T extends any>(props: Props<T>) => {

  // pick the select value from the options. the client just passes in the value
  // string but this API demands both the value and the label
  const findDefaultValue = (v: T | null | undefined) => {

    for (let i = 0; i < props.options.length; i++) {
      if (props.options[i].value == v) {
        return props.options[i];
      }
    }

    return null;
  }

  return <div className={'Flex1 VerticalFlex'} style={{minWidth: 130}}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>

    <Select options={props.options}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: 0,
                height: 45,
                margin: 0,
              }),
            }}
            classNames={{
              // input: (s) => 'TimeZoneInputLabel',
              container: (s) => 'TimeZoneInputContainer',
            }}
            
            defaultValue={findDefaultValue(props.value)}
            onChange={v => {
              if (v) {
                props.onChange(v.value)
              }
            }}
    />

    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>

}