import React, {useMemo, useState} from "react";
import {space} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {findError} from "../../../../components/input/InputTypes";
import './Calendar.css';
import {LabeledTimeZoneInput} from "../../../../components/input/LabeledTimeZoneInput";
import {RepeatStrategyEditor} from "./RepeatStrategyEditor";
import {LabeledDropdownInput} from "../../../../components/input/LabeledDropdownInput";
import {filterPractitioners, findProvider, getServicesForProvider} from "./CalendarUtil";
import {LabeledPhoneInput} from "../../../../components/input/LabeledPhoneInput";
import {TimeInput15MinInterval} from "../../../../components/input/TimeInput15MinInterval";
import {LabeledDateInput} from "../../../../components/input/LabeledDateInput";
import RepeatStrategyProto = space.kenko.proto.RepeatStrategyProto;
import IErrorProto = space.kenko.proto.IErrorProto;
import IEventInstanceProto2 = space.kenko.proto.IEventInstanceProto2;
import IKenkoEventDetails = space.kenko.proto.IKenkoEventDetails;
import KenkoEventDetails = space.kenko.proto.KenkoEventDetails;
import {MultiSelect} from "../../../../components/input/MultiSelectInput";
import {BusinessProfile} from "../BusinessProfile/BusinessProfile";
import {LabeledYesNoInput} from "../../../../components/input/LabeledYesNoInput";
import {AvailabilityBlockEditor} from "./AvailabilityBlockEditor";
import {AppointmentEditor} from "./AppointmentEditor";
import {ClassEditor} from "./ClassEditor";

type Props = {

  // If this is a new event, this instance should be set to whatever default values
  // are desired. For editing, this is the existing event.
  instance: IEventInstanceProto2,

  rpcError?: string | undefined,

  // Any errors that may have come down in the rpc response, the paths are like
  // "startTimestamp", "provider", etc.
  errors: IErrorProto[];

  // The UI shows up a little differently depending whether it's an edit or
  // a new instance.
  isEditing: boolean,

  // The range of the current calendar view, this is used to request all
  // the instances of the newly created event. 
  // calendarViewStart: Date,
  // calendarViewEnd: Date,

  onCreate: (event: IEventInstanceProto2) => void,
  onDelete: () => void,
  onCancel: () => void,
}

export type CalendarEventType = 'availability' | 'appointment' | 'class';

/**
 * This is the overlay for editing and creating events. It itself does not send
 * any RPCs, the caller is responsible for that.
 */
export const EventModal = (props: Props) => {

  const profile = useProviderProfile();

  // Instead of notifying the parent every time any value changes, keep the changes here
  // until a save. 
  const [workingCopy, setWorkingCopy] = useState<IEventInstanceProto2>(props.instance);

  // This is a shorthand that calls setRequest, but used when editing just the event type.
  // The specific event type editors (availability, appointment) use this extensively.
  const updateKenkoEventDetails = (lambda: (event?: IKenkoEventDetails | null) => IKenkoEventDetails): void => {
    setWorkingCopy(instance => {
      return {
        ...instance,
        details: {
          ...instance.details,
          kenkoDetails: lambda(instance.details?.kenkoDetails)
        }
      }
    })
  }

  const eventTypeAsString = (eventType: IKenkoEventDetails | null | undefined): CalendarEventType | undefined => {
    if (!eventType) {
      return undefined;
    }

    // Weird --- the class contains the string oneof but not the interface.
    return (eventType as KenkoEventDetails).EventType;
  }

  const getDefaultEventType = (typeString: CalendarEventType | undefined | null): IKenkoEventDetails | undefined => {
    if (typeString == 'availability') {
      return new KenkoEventDetails({
        availability: {}
      })
    }

    if (typeString == 'appointment') {
      return new KenkoEventDetails({
        appointment: {}
      })
    }

    if (typeString == 'class') {
      return new KenkoEventDetails({
        class: {
          price: {
            currencyCode: 'USD',
          }
        }
      })
    }

  }


  const officeOptions = useMemo(() => {
    const options: { value: string, label: string }[] = [{
      value: "", label: "None"
    }
    ]
    profile.businessProfile?.locations?.forEach((office) => {
      options.push({
        value: office.locationId as string,
        label: office.locationName || ('Unnamed Location')
      });
    });
    return options;
  }, [profile.businessProfile]);


  const startTime = new Date(workingCopy.details?.startTimestamp as number)
  const endTime = new Date(workingCopy.details?.endTimestamp as number)

  return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>{props.isEditing ? 'Edit event' : 'New event'}</h2>

          <div className={'HorizontalFlex Gap20'}>

            <LabeledDateInput label={'Date'}
                              value={{
                                year: startTime.getFullYear(),
                                month: startTime.getMonth() + 1,
                                date: startTime.getDate(),
                              }}
                              error={findError(props.errors, 'title')}
                              onChange={v => {
                                setWorkingCopy(request => {
                                  const updatedStartTime = new Date(startTime);
                                  updatedStartTime.setFullYear(v.year, v.month - 1, v.date);

                                  const updatedEndTime = new Date(endTime);
                                  updatedEndTime.setFullYear(v.year, v.month - 1, v.date);

                                  return {
                                    ...request,
                                    details: {
                                      ...request.details,
                                      startTimestamp: updatedStartTime.getTime(),
                                      endTimestamp: updatedEndTime.getTime(),
                                    }
                                  }
                                })
                              }}/>

            <TimeInput15MinInterval label={'Start time'}
                                    value={{hour: startTime.getHours(), minute: startTime.getMinutes()}}
                                    error={findError(props.errors, 'startTimestamp')}
                                    onChange={v => {
                                      setWorkingCopy(request => {
                                        const updated = new Date(startTime);
                                        updated.setHours(v.hour);
                                        updated.setMinutes(v.minute);
                                        return {
                                          ...request,
                                          details: {
                                            ...request.details,
                                            startTimestamp: updated.getTime()
                                          }
                                        }
                                      })
                                    }}/>

            <TimeInput15MinInterval label={'End time'}
                                    value={{hour: endTime.getHours(), minute: endTime.getMinutes()}}
                                    error={findError(props.errors, 'endTimestamp')}
                                    onChange={v => {
                                      setWorkingCopy(request => {
                                        const updated = new Date(endTime);
                                        updated.setHours(v.hour);
                                        updated.setMinutes(v.minute);
                                        return {
                                          ...request,
                                          details: {
                                            ...request.details,
                                            endTimestamp: updated.getTime()
                                          }
                                        }
                                      })
                                    }}/>

            <LabeledTimeZoneInput label={'Time zone'}
                                  value={workingCopy.timeZoneId}
                                  error={findError(props.errors, 'timeZone')}
                                  onChange={v => setWorkingCopy(request => {
                                    return {
                                      ...request,
                                      timeZoneId: v
                                    }
                                  })}/>
          </div>

          <LabeledDropdownInput<string> label={'Event Type'}
                                        value={eventTypeAsString(workingCopy.details?.kenkoDetails)}
                                        error={findError(props.errors, 'eventType')}
                                        options={[
                                          {label: 'Availability', value: 'availability'},
                                          {label: 'Appointment', value: 'appointment'},
                                          {label: 'Class', value: 'class'}
                                        ]}
                                        onChange={v => updateKenkoEventDetails(existing => {
                                          return getDefaultEventType(v as CalendarEventType) ?? new KenkoEventDetails();
                                        })}/>

          {workingCopy.details?.kenkoDetails?.availability && <AvailabilityBlockEditor
              updateKenkoEventDetails={updateKenkoEventDetails}
              errors={props.errors}
              workingCopy={workingCopy}
              officeOptions={officeOptions}
          />}

          {workingCopy.details?.kenkoDetails?.appointment && <AppointmentEditor
              updateKenkoEventDetails={updateKenkoEventDetails}
              errors={props.errors}
              workingCopy={workingCopy}
              officeOptions={officeOptions}
          />}

          {workingCopy.details?.kenkoDetails?.class && <ClassEditor
              updateKenkoEventDetails={updateKenkoEventDetails}
              errors={props.errors}
              workingCopy={workingCopy}
              officeOptions={officeOptions}
          />}

          <RepeatStrategyEditor firstDay={new Date(workingCopy.details?.startTimestamp as number)}
                                proto={workingCopy.repeatStrategy ?? new RepeatStrategyProto()}
                                error={findError(props.errors, 'repeatStrategy')}
                                onChange={v => setWorkingCopy(request => {
                                  return {
                                    ...request,
                                    repeatStrategy: v
                                  }
                                })}/>

          <div className={'HorizontalFlex'} style={{justifyContent: 'end', marginTop: 14}}>

            <div className={'HorizontalFlex Gap20'}>
              <button className={'ActionButton ActionButtonSecondary'} onClick={props.onCancel}>Cancel</button>

              {props.isEditing &&
                  <button className={'ActionButton ActionButtonSecondary'} onClick={props.onDelete}>Delete</button>}

              <button className={'ActionButton'}
                      onClick={() => props.onCreate(workingCopy)}>{props.isEditing ? 'Update' : 'Create'}</button>

            </div>
          </div>

          {props.rpcError && <div className={'ErrorText'}>{props.rpcError}</div>}

        </div>
      </div>
  );
};

