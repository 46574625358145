import {useAuth0} from "@auth0/auth0-react";

export const CreateKensuAccount = () => {

  const {loginWithPopup} = useAuth0();
  
  
  return <div style={{display: 'flex', flexDirection: "column", alignItems:'center', textAlign: 'start'}}>
    <b>Please create a Kensu account to complete your booking.</b>
    
    <button className={'ActionButton'} onClick={loginWithPopup}>Login</button>
    
  </div>

    
}