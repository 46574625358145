import React from 'react';
import './Onboarding.css';
import {Link} from "react-router-dom";

type Props = {
  value: boolean | null | undefined;
  error: string | null | undefined;
  onChange: (value: boolean) => void;
}

export const AcceptTosInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'CreateProfileCheckboxContainer'}>
      <input type={'checkbox'} className={'CreateProfileCheckbox'}
             checked={props.value || false}
             onChange={e => {
               props.onChange(e.target.checked)
             }}/>
      <span>
      I agree to the&nbsp;
        <Link className={'BrownText'} target={'_blank'} to={'/privacy'}>Privacy Policy</Link>,&nbsp;
        <Link className={'BrownText'} target={'_blank'} to={'/provider_terms'}>Terms of Service</Link> and&nbsp;
        <Link className={'BrownText'} target={'_blank'} to={'/cookies'}>Cookie Policy</Link>.
      </span>
    </div>
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>;
}