import './Home.css';
import '../../../Styles.css';
import React, {useContext, useMemo, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {ProviderProfileContext} from "../../../../ProviderProfileProvider";
import {GetProviderProfileResponse, IClientAppointmentProto, space} from "../../../../provider_api";
import {Link} from "react-router-dom";
import {CompletionChecklist} from "./CompletionChecklist";
import {Dashboard} from "./Dashboard";
import {BookingOverlayApp} from "booking-overlay";
import ProfileReviewState = GetProviderProfileResponse.ProfileReviewState;
import ProviderApprovalState = space.kenko.proto.ProviderApprovalState;

export const Home = () => {

  const {user, getIdTokenClaims} = useAuth0();

  const {business, effectiveUser, profileReviewState, setEffectiveUser, settings} =
      useContext(ProviderProfileContext);

  const [isBooking, setIsBooking] = useState(false);

  const Banner = () => {
    if (profileReviewState == ProfileReviewState.IN_REVIEW) {
      return <div className={'PendingReviewBanner'}>
        You currently have a new version out for review.
      </div>
    } else if (profileReviewState == ProfileReviewState.APPROVED_READY_TO_PUBLISH) {
      return <div className={'PendingReviewBanner'}>
        Your new website has been approved and is ready to publish!
      </div>
    } else if (profileReviewState == ProfileReviewState.REJECTED) {
      return <div className={'PendingReviewBanner'}>
        We found issues with your most recent submission. Please go to the 'Websites' tab to see.
      </div>
    }
  };

  // This finds any appointments scheduled with the kenko team 
  // for configuring the appointment!!
  const scheduledAppointment = useMemo(() => {

    if (!effectiveUser?.appointments) {
      return;
    }

    for (let i = 0; i < effectiveUser.appointments.length; i++) {
      const appointment = effectiveUser.appointments[i];
      if (appointment.businessId == settings.kenkoBusinessId) {
        return appointment;
      }
    }

    return null;
  }, [effectiveUser]);

  const getContent = () => {

    if (business?.approvalState == ProviderApprovalState.PROVIDER_REJECTED) {
      return <div className={'WebsiteSubmissionStatePage'}>
        <h1>We’re not the right fit at this time.</h1>
        <div className={'WebsiteSubmissionStateText'}>
          Thank you again for your interest in Kensu and applying to Kensu. Unfortunately, we won't be able to extend
          you a membership offer at this time. We are getting more qualified applicants than we can accept at the
          moment. If you would like to stay in touch, please <a href={'#'}>sign up for our mailing list</a>.
          Thank you for taking the time to apply.
        </div>
      </div>
    }

    if (business?.approvalState == ProviderApprovalState.AWAITING_REVIEW) {

      return <div className={'WebsiteSubmissionStatePage'}>
        <h1>Let's connect!</h1>
        <div className={'WebsiteSubmissionStateText'}>
          Thank you for completing our onboarding questionnaire.
          Your next step is to schedule a 30 minute onboarding
          call with a member of our team.
        </div>

        {scheduledAppointment && <div>
          <b>Congrats!</b> We've got the appointment on our calendar.
          <br/><br/>
          {new Date(scheduledAppointment.startTimeMs as number).toLocaleString(undefined,
              {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                hour: "numeric",
                minute: 'numeric'
              })}
        </div>}

        {!scheduledAppointment && <div style={{display: 'flex', width: 300}}>
          <button className={'ActionButton'}
                  onClick={() => {
                    setIsBooking(true);
                  }}>Schedule my call
          </button>
        </div>}

        {isBooking && <BookingOverlayApp
            businessId={settings.kenkoBusinessId as string}
            serviceSku={settings.kenkoConsultationServiceSku as string}
            onAppointmentsUpdated={(appointments: IClientAppointmentProto[]) => {
              // A little awkward because the booking response comes from
              // the booking server so we have to just update a piece of
              // the provider profile.
              setEffectiveUser({
                ...effectiveUser,
                appointments: appointments
              });
            }}
            onCancel={() => {
              setIsBooking(false)
            }}
        />}

      </div>
    }

    if (business?.liveProfileVersion) {
      return <>
        {Banner}
        <Dashboard/>
      </>
    } else {

      // This is an onboarding situation, the business has not been published yet
      if (profileReviewState == ProfileReviewState.IN_REVIEW) {
        return <div className={'WebsiteSubmissionStatePage'}>

          <h1>We've received your website submission.</h1>
          <div className={'WebsiteSubmissionStateText'}>
            Please expect a response in <strong>24-48 hours</strong>. While your website is pending review, any
            changes you make will be saved in a new version. If you would like us to review an updated version,
            please <Link to={'/website'}>withdraw your submission</Link> and resubmit with your changes.
          </div>

          <div className={'WebsiteSubmissionStateText'}>
            Visit our Practitioner FAQs for more information or reach out to&nbsp;
            <a href={'mailto:info@kenko.space'}>info@kenko.space</a> with any questions.
          </div>
        </div>

      } else if (profileReviewState == ProfileReviewState.APPROVED_READY_TO_PUBLISH) {

        return <div className={'WebsiteSubmissionStatePage'}>
          <h1>Your submission has been approved!</h1>

          <div className={'WebsiteSubmissionStateText'}>
            You may publish your website any time by visiting the <Link to={'/website'}>Website</Link> tab.
          </div>
        </div>

      } else if (profileReviewState == ProfileReviewState.REJECTED) {

        return <div className={'WebsiteSubmissionStatePage'}>
          <h1>We found issues while reviewing your website.</h1>

          <div className={'WebsiteSubmissionStateText'}>
            Please review our feedback by visiting the <Link to={'/website'}>Website</Link> tab.
            Once you make the necessary changes please submit a new version for review.
          </div>
        </div>

      } else {
        // No review at all. This is the checklist situ.

        return <CompletionChecklist/>

      }
    }
  }

  return <div className={'NonScrollingToolPage'}>
    <div className={'ProviderToolAreaContent VerticalScroll Padding20'}>
      {getContent()}
    </div>
  </div>
}