import React, {ReactElement} from "react";
import {space} from "../../provider_api";
import SurveySessionType = space.kenko.proto.SurveySessionType;
import SurveyApptLocations = space.kenko.proto.SurveyApptLocations;
import SurveyRole = space.kenko.proto.SurveyRole;
import SurveyPracticeYearsInOperation = space.kenko.proto.SurveyPracticeYearsInOperation;

export type SurveyPage = {
  pageHeaderOverride?: string // originally 'ACCOUNT SETUP'
  title?: string,
  subtitle?: string,
  content: ReactElement,
  validate?: () => boolean;
  isPageVisible?: () => boolean;
}

export const sessionTypeToString = (sessionType: SurveySessionType) => {
  switch (sessionType) {
    case SurveySessionType.GROUP_CLASSES:
      return 'Group classes';
    case SurveySessionType.PRIVATE_SESSIONS:
      return 'Private 1:1 sessions';
    case SurveySessionType.SEMI_PRIVATE_SESSIONS:
      return 'Semi-private sessions (2+ clients)'
    default:
      return SurveySessionType[sessionType];
  }
}

export const apptLocationToString = (location: SurveyApptLocations) => {
  switch (location) {
    case space.kenko.proto.SurveyApptLocations.IN_HOME_APPOINTMENTS:
      return 'I travel to my clients (e.g., in-home appointments)'
    case space.kenko.proto.SurveyApptLocations.IN_PERSON_AT_BUSINESS:
      return 'In-person at a business location(s)'
    case space.kenko.proto.SurveyApptLocations.VIRTUAL_APPOINTMENTS:
      return 'Virtual appointments'
    default:
      return SurveyApptLocations[location];
  }
}

export const surveyRoleToString = (role: SurveyRole) => {
  switch (role) {
    case SurveyRole.OFFICE_MANAGER_OR_STAFF:
      return "Office manager or administrative staff";
    case SurveyRole.PRACTITIONER:
      return "Practitioner (e.g., therapist, acupuncturist)";
    case SurveyRole.BUSINESS_OWNER_AND_PRACTITIONER:
      return "Business owner and practitioner";
    case SurveyRole.BUSINESS_OWNER_NON_PRACTITIONER:
      return "Business owner (non-practitioner)";
    case SurveyRole.OTHER:
      return "Other";
    default:
      return SurveyRole[role];
  }
}

export const yearsInOperationToString = (
    years: SurveyPracticeYearsInOperation | undefined | null) => {
  if (!years) {
    return '';
  }
  
  switch (years) {
    case SurveyPracticeYearsInOperation.LESS_THAN_1:
      return "Less than 1 year";
    case SurveyPracticeYearsInOperation.ONE_TO_THREE:
      return "1-3 years";
    case SurveyPracticeYearsInOperation.FOUR_TO_SEVEN:
      return "4-7 years";
    case SurveyPracticeYearsInOperation.EIGHT_TO_TEN:
      return "8-10 years";
    case SurveyPracticeYearsInOperation.MORE_THAN_TEN:
      return "10+ years";
    default:
      return SurveyRole[years];
  }
}