import React, {useMemo} from "react";
import {LabeledDropdownInput} from "../../../../components/input/LabeledDropdownInput";
import {findError} from "../../../../components/input/InputTypes";
import {filterPractitioners, findProvider, getServicesForProvider} from "./CalendarUtil";
import {LabeledYesNoInput} from "../../../../components/input/LabeledYesNoInput";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {LabeledPhoneInput} from "../../../../components/input/LabeledPhoneInput";
import {space} from "../../../../provider_api";
import IErrorProto = space.kenko.proto.IErrorProto;
import IEventInstanceProto2 = space.kenko.proto.IEventInstanceProto2;
import IKenkoEventDetails = space.kenko.proto.IKenkoEventDetails;
import {useProviderProfile} from "../../../../ProviderProfileProvider";

type Props = {
  workingCopy: IEventInstanceProto2
  errors: IErrorProto[];
  updateKenkoEventDetails: (lambda: (event?: IKenkoEventDetails | null) => IKenkoEventDetails) => void;
  officeOptions: { value: string, label: string }[]
}


/**
 * Each calendar event type has its own editor. This could be important down the road
 * since event types could get quite different! Think of private appointments vs public
 * classes (w/ lots of students) or even some kind of appointment with 2 teachers.
 *
 * Note that it's safe to assume that in these editors, the correct 'oneof' is already
 * selected in the request proto.
 *
 * This is useMemo'd because not doing so cause the thing to rerender, and apparently
 * it created different DOM widgets because all of the inputs lost focus immediately
 * after typing a letter.
 */
export const AppointmentEditor = (props: Props) => {

  const profile = useProviderProfile();

  return <>
    <div className={'HorizontalFlex Gap20'}>

      <LabeledDropdownInput<string> label={'Choose a provider'}
                                    value={props.workingCopy.details?.kenkoDetails?.appointment?.providerId}
                                    error={findError(props.errors, 'provider')}
                                    options={filterPractitioners(profile.businessProfile?.providers)
                                        .map(provider => {
                                          return {
                                            value: provider.providerId as string,
                                            label: provider.firstName + ' ' + provider.lastName
                                          }
                                        })}
                                    onChange={v => {
                                      console.log('founded founded', v)
                                      props.updateKenkoEventDetails(previous => {
                                        return {
                                          ...previous,
                                          appointment: {
                                            ...previous?.appointment,
                                            providerId: findProvider(profile.businessProfile?.providers, v)?.providerId
                                          }
                                        }
                                      })
                                    }}/>

      <LabeledDropdownInput<string> label={'Choose a service'}
                                    value={props.workingCopy.details?.kenkoDetails?.appointment?.serviceSku}
                                    error={findError(props.errors, 'service')}
                                    options={getServicesForProvider(profile.businessProfile?.practice?.services,
                                        props.workingCopy.details?.kenkoDetails?.appointment?.providerId).map(value => {
                                      return {
                                        label: `${value.service.name} ($${value.price.price})`,
                                        value: value.service.sku || ''
                                      }
                                    })}
                                    onChange={v => {
                                      console.log('founded founded', v)
                                      props.updateKenkoEventDetails(previous => {
                                        return {
                                          ...previous,
                                          appointment: {
                                            ...previous?.appointment,
                                            serviceSku: v
                                          }
                                        }
                                      })
                                    }}/>

    </div>
    <div className={'HorizontalFlex Gap20'}>

      <LabeledDropdownInput<string> label={'Choose a location'}
                                    value={props.workingCopy.details?.kenkoDetails?.appointment?.locationId}
                                    error={findError(props.errors, 'location')}
                                    options={props.officeOptions}
                                    onChange={v => {
                                      props.updateKenkoEventDetails(previous => {
                                        return {
                                          ...previous,
                                          appointment: {
                                            ...previous?.appointment,
                                            locationId: v
                                          }
                                        }
                                      })
                                    }}/>

      {profile.businessProfile?.proto?.virtualAppointmentInfo &&
          <LabeledYesNoInput label={'Virtual appointment?'}
                             value={props.workingCopy.details?.kenkoDetails?.appointment?.isVirtualAppt || false}
                             onChange={v => {
                               props.updateKenkoEventDetails(previous => {
                                 return {
                                   ...previous,
                                   appointment: {
                                     ...previous?.appointment,
                                     isVirtualAppt: v,
                                   }
                                 }
                               })
                             }}/>}
    </div>

    <div className={'HorizontalFlex Gap20'}>

      <LabeledTextInput label={'Client name'}
                        value={props.workingCopy.details?.kenkoDetails?.appointment?.clientName}
                        error={findError(props.errors, 'clientName')}
                        inputType={'text'}
                        onChange={v => props.updateKenkoEventDetails(previous => {
                          return {
                            ...previous,
                            appointment: {
                              ...previous?.appointment,
                              clientName: v
                            }
                          }
                        })}/>

      <LabeledPhoneInput label={'Client phone number'}
                         value={props.workingCopy.details?.kenkoDetails?.appointment?.clientPhone}
                         error={findError(props.errors, 'clientPhone')}
                         onChange={v => props.updateKenkoEventDetails(previous => {
                           return {
                             ...previous,
                             appointment: {
                               ...previous?.appointment,
                               clientPhone: v
                             }
                           }
                         })}/>

      <LabeledTextInput label={'Client email'}
                        value={props.workingCopy.details?.kenkoDetails?.appointment?.clientEmail}
                        error={findError(props.errors, 'clientEmail')}
                        inputType={'email'}
                        onChange={v => props.updateKenkoEventDetails(previous => {
                          return {
                            ...previous,
                            appointment: {
                              ...previous?.appointment,
                              clientEmail: v
                            }
                          }
                        })}/>
    </div>
  </>
}
