import React, {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "./rpcSender";
import {
  GetProviderProfileRequest,
  GetProviderProfileResponse,
  IApiBusinessProfileProto,
  IApiBusinessProto,
  IApiUserProto, IProviderClientSettings,
} from "./provider_api";
import ProfileReviewState = GetProviderProfileResponse.ProfileReviewState;


// This context contains information about the provider (logged-in-user) and
// the business. It's possible that either is null.
// 
// Setters are provided so users of this context can update the profile,
// typically after doing a mutation via the server.
export const ProviderProfileContext = createContext<{

  authenticatedUserRoles: string[],
  effectiveUser: IApiUserProto | null | undefined,

  // In some cases these can get updated by other RPCs -- for instance when creating
  // a provider profile, it does update the user proto.
  setEffectiveUser: (user: IApiUserProto | null | undefined) => void;
  
  // These two can only be overridden by admins.
  setEffectiveUserId: (userId: string | null | undefined) => void;
  setEffectiveBusinessId: (userId: string | null | undefined) => void;

  businessProfile: IApiBusinessProfileProto | null | undefined,
  setBusinessProfile: (profile: IApiBusinessProfileProto | null | undefined) => void;
  
  // These 4 are kind of hacks? They're stuffed in here so the client app knows whether
  // or not to show the onboarding checklist, and a banner that there's a pending review.
  business: IApiBusinessProto | null | undefined,
  setBusiness: (business: IApiBusinessProto | null | undefined) => void,
  profileReviewState: ProfileReviewState,
  setProfileReviewState: (profileReviewState: ProfileReviewState) => void,
  profileLoading: boolean,
  profileError: string | null | undefined,
  settings: IProviderClientSettings,
  
}>({
  authenticatedUserRoles: [],
  effectiveUser: null,
  setEffectiveUser: () => {},
  setEffectiveUserId: () => {},
  setEffectiveBusinessId: () => {},
  businessProfile: null,
  setBusinessProfile: () => {},
  business: null,
  setBusiness: () => {},
  profileReviewState: ProfileReviewState.NONE,
  setProfileReviewState: () => {},
  profileLoading: true,
  profileError: null,
  settings: {}
});

export const useProviderProfile = () => {
  return useContext(ProviderProfileContext)
}

interface Props {
  children?: ReactElement
}

/**
 * Provides information both about the logged-in user (aka the PROVIDER)
 * and the business. This is loaded from the get_provider_profile action.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ProviderProfileProvider = (props: Props) => {

  const {user, getIdTokenClaims} = useAuth0();

  // If this is empty, it's showing the kenko dashboard for the authenticated user's
  // own business (which is a real case, because we use kenko as our own booking platform
  // for scheduling website consultations!) It also is good for dev to test a business
  // ourselves?
  // 
  // If this is set, then we view the dashboard for this business.
  const [effectiveBusinessId, setEffectiveBusinessId] = useState<string | null | undefined>();

  // This field is only meaningful if effectiveBusiness is not empty.
  //
  // If effectiveBusiness is set, but this is unset, then the effective business
  // is viewed with full admin privileges.
  //
  // If effectiveBusiness is set and this field is set (to a user on the business),
  // then the dashboard is shown from that user's perspective. This is important
  // for support calls, so we can see the dashboard exactly the same way that
  // the end user is seeing it.
  const [effectiveUserId, setEffectiveUserId] = useState<string | null | undefined>();

  // The state above (authenticatedUser, effectiveBusinessId, and effectiveUserId) determines
  // what gets downloaded in the fields below.

  // Effective and authenticate user are guaranteed to be always set by the server! In typical
  // cases they are exactly the same. But when the authenticated user is admin, they have a choice
  // to view a different effective user. 
  const [authenticatedUserRoles, setAuthenticatedUserRoles] = useState<string[]>([]);
  const [effectiveUser, setEffectiveUser] = useState<IApiUserProto | null | undefined>();

  // The business being viewed; only in admin mode this can be a different business than
  // that which is linked to the authenticated user's profile. Either way, it's always the
  // business in the effective user's profile.
  const [business, setBusiness] = useState<IApiBusinessProto | null | undefined>();
  const [businessProfile, setBusinessProfile] = useState<IApiBusinessProfileProto | null | undefined>();
  const [profileReviewState, setProfileReviewState] = useState(ProfileReviewState.NONE);

  const [profileLoading, setProfileLoading] = useState<boolean>(true);
  const [profileError, setProfileError] = useState<string | null>();

  const [settings, setSettings] = useState<IProviderClientSettings>({});
  
  useEffect(() => {

    if (!user) {
      setAuthenticatedUserRoles([]);
      setBusinessProfile(undefined);
      setEffectiveBusinessId(undefined);
      setEffectiveUserId(undefined);
      setEffectiveUser(undefined)
      setProfileLoading(true);
      setProfileError(undefined);
      setProfileReviewState(ProfileReviewState.NONE);
      return;
    }

    // Download it immediately at the beginning...
    console.log('[Fetching provider profile...]');
    setAuthenticatedUserRoles([]);
    setBusinessProfile(undefined);
    setEffectiveUser(undefined)
    setProfileLoading(true);
    setProfileError(undefined);
    setProfileReviewState(ProfileReviewState.NONE);

    SendRpc(getIdTokenClaims, "get_provider_profile",
        GetProviderProfileRequest.encode(new GetProviderProfileRequest({
          effectiveBusinessId: effectiveBusinessId
        })).finish())
        .then(response => {
          let profileResponse = GetProviderProfileResponse.decode(response);
          console.log("Provider profile: " + JSON.stringify(profileResponse.toJSON()))
          
          setAuthenticatedUserRoles(profileResponse.authenticatedUserRoles);
          setEffectiveUser(profileResponse.effectiveUser);
          setBusinessProfile(profileResponse.businessProfile);
          setBusiness(profileResponse.business);
          setProfileReviewState(profileResponse.profileReviewState);
          setSettings(profileResponse.clientSettings ?? {})

        })
        .catch(error => {
          setProfileError(`Error loading profile (${error})`)
        })
        .finally(() => {
          setProfileLoading(false);
        });

  }, [user, effectiveUserId, effectiveBusinessId]);

  return (<ProviderProfileContext.Provider value={{
    authenticatedUserRoles: authenticatedUserRoles,
    effectiveUser: effectiveUser,

    // Probably we don't need to update the authenticated user at all because if anything changed,
    // it would just be the roles
    setEffectiveUser: setEffectiveUser,
    setEffectiveUserId: setEffectiveUserId,
    setEffectiveBusinessId: setEffectiveBusinessId,
    businessProfile: businessProfile,
    setBusinessProfile: setBusinessProfile,
    business: business,
    setBusiness: setBusiness,
    profileReviewState: profileReviewState,
    setProfileReviewState: setProfileReviewState,
    profileLoading: profileLoading,
    profileError: profileError,
    settings: settings,
  }}>
    {props.children}
  </ProviderProfileContext.Provider>);
};
