import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../../rpcSender";
import '../../../Styles.css';
import {
  GetProviderProfileResponse,
  GetWebsiteVersionsRequest,
  GetWebsiteVersionsResponse,
  IApiBusinessProfileProto,
  SetLiveVersionRequest,
  SetLiveVersionResponse,
  space,
  SubmitProfileForReviewRequest,
  SubmitProfileForReviewResponse, WithdrawProfileReviewRequest, WithdrawProfileReviewResponse
} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import './Website.css'
import ApprovalStatus = space.kenko.proto.ApprovalStatus;
import ProfileReviewState = GetProviderProfileResponse.ProfileReviewState;
import {
  businessProfileHasErrors,
  hasAtLeast1Provider,
  hasAtLeast1Service,
  providersHaveErrors
} from "../../WebsiteValidator";
import {getBookingHost} from "../../../../util/HostUtils";

export const Website = () => {

  const {getIdTokenClaims} = useAuth0();

  const {business, setBusiness, setBusinessProfile, setProfileReviewState} = useProviderProfile();

  const [rpcError, setRpcError] = useState<string>();
  const [versions, setVersions] = useState<IApiBusinessProfileProto[]>([]);

  useEffect(() => {

    SendRpc(getIdTokenClaims, "get_website_versions",
        GetWebsiteVersionsRequest.encode(new GetWebsiteVersionsRequest()).finish())
        .then(bytes => {
          const response = GetWebsiteVersionsResponse.decode(bytes)
          if (!response.okay) {
            setRpcError('Error loading website versions');
            return;
          }
          setVersions(response.profiles);
        })
        .catch(e => {
          setRpcError(e);
        })

  }, [])

  const submit = () => {
    setRpcError('');
    SendRpc(getIdTokenClaims, "submit_profile_for_review",
        SubmitProfileForReviewRequest.encode(new SubmitProfileForReviewRequest()).finish())
        .then(bytes => {
          const response = SubmitProfileForReviewResponse.decode(bytes)
          if (!response.okay) {
            setRpcError(response.errors || 'Error submitting for review');
            return;
          }

          if (response.newProfile) {
            setVersions([...versions, response.newProfile]);
            setProfileReviewState(ProfileReviewState.NONE)
          }

          if (response.updatedDraftProfile) {
            setBusinessProfile(response.updatedDraftProfile);
          }

        })
        .catch(e => {
          setRpcError(e);
        })
  }

  const withdraw = (versionId: string) => {
    setRpcError('');
    SendRpc(getIdTokenClaims, "withdraw_profile_review",
        WithdrawProfileReviewRequest.encode(new WithdrawProfileReviewRequest({
          versionId: versionId
        })).finish())
        .then(bytes => {
          const response = WithdrawProfileReviewResponse.decode(bytes)
          if (!response.okay) {
            setRpcError(response.errors || 'Error submitting for review');
            return;
          }

          if (response.updatedProfile) {
            setVersions(versions.map(version => {
              return version.versionId == response.updatedProfile?.versionId ?
                  response.updatedProfile as IApiBusinessProfileProto : version;
            }));
            setProfileReviewState(ProfileReviewState.IN_REVIEW)
          }
        })
        .catch(e => {
          setRpcError(e);
        })
  }


  const sendSetLiveVersion = (version: string) => {
    setRpcError('');
    SendRpc(getIdTokenClaims, "set_live_version",
        SetLiveVersionRequest.encode(new SetLiveVersionRequest({
          version: version
        })).finish())
        .then(bytes => {
          const response = SetLiveVersionResponse.decode(bytes)
          if (!response.okay) {
            setRpcError(response.error || 'Unknown error setting live version');
            return;
          }

          if (response.updatedBusiness) {
            // The live version of the site comes down in this
            setBusiness(response.updatedBusiness);
          }
        })
        .catch(e => {
          setRpcError(e);
        })
  }

  const hasErrors = (profile: IApiBusinessProfileProto) => {

    if (profile.versionId != 'DRAFT') {
      return false;
    }

    return businessProfileHasErrors(profile) ||
        !hasAtLeast1Service(profile) ||
        !hasAtLeast1Provider(profile) ||
        providersHaveErrors(profile);
  }

  const errorStrings = (profile: IApiBusinessProfileProto): string[] => {

    let errors = []

    if (businessProfileHasErrors(profile)) {
      errors.push("Your business profile is missing information")
    }

    if (!hasAtLeast1Service(profile)) {
      errors.push("At least one service is required")
    }

    if (!hasAtLeast1Provider(profile)) {
      errors.push("At least one practitioner is required")
    }

    if (providersHaveErrors(profile)) {
      errors.push("Some providers are missing information")
    }


    return errors;
  }

  return <div className={'NonScrollingToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Your website</h1>
        <Link target={'_blank'}
              to={`${getBookingHost()}/p/test?v=DRAFT&b=${business?.businessId}`}>
          <button className={'ActionButton'}>
            Preview Website
          </button>
        </Link>
      </div>
    </div>

    <div className={'ProviderToolAreaContent VerticalScroll Padding20'}>
      <div className={'VersionHistoryTable'}>
        <table>
          <thead>
          <tr>
            <th>Version</th>
            <th>Last Edited</th>
            <th>Status</th>
            <th>Website Preview</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {versions?.sort((a, b) => -(a.versionId || '').localeCompare(b.versionId || ''))
              .map(version => {
                const isLiveVersion = version.versionId == business?.liveProfileVersion;
                return <tr>
                  <td>{version.versionId}</td>
                  <td>{version.lastModifiedTimestamp ? new Date(version.lastModifiedTimestamp as number).toLocaleString() : ''}</td>
                  <td>{
                    isLiveVersion ? <div className={'LiveVersion'}>LIVE</div> :
                        version.review?.status ? ApprovalStatus[version.review.status] : ''}
                  </td>
                  <td>
                    <Link target={'_blank'} to={
                      isLiveVersion ? `${getBookingHost()}/p/${version.proto?.shortUrl}` :
                          `${getBookingHost()}/p/test?v=${version.versionId}&b=${business?.businessId}`
                    }>
                      Website preview
                    </Link>
                  </td>
                  <td>
                    {!version.review?.status && <>

                      {hasErrors(version) && <div style={{textAlign: 'start'}}>
                        Fix errors before submitting:
                        <ul className={'ErrorText'}>
                          {errorStrings(version).map(e => {
                            return <li className={'ErrorText'}>{e}</li>
                          })}</ul></div>
                      }

                      {!hasErrors(version) && version.versionId == 'DRAFT' &&
                          <button className={'SubmitButton'} onClick={submit}>Submit for Review</button>}
                    </>}

                    {version.review?.status == ApprovalStatus.APPROVED &&
                        <button className={'SubmitButton'} onClick={() =>
                            sendSetLiveVersion(version.versionId || '')}>Publish</button>
                    }

                    {version.review?.status == ApprovalStatus.IN_REVIEW &&
                        <button className={'SubmitButton'} onClick={() => {
                          if (window.confirm('Really withdraw this version from review?')) {
                            withdraw(version.versionId as string)
                          }
                        }}>Withdraw review
                        </button>
                    }

                  </td>
                </tr>
              })}

          </tbody>
        </table>
      </div>

      {rpcError && <div className={'ErrorText'}>{rpcError}</div>}
    </div>
  </div>

}