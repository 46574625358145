/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.GetProviderProfileRequest = (function() {

    /**
     * Properties of a GetProviderProfileRequest.
     * @exports IGetProviderProfileRequest
     * @interface IGetProviderProfileRequest
     * @property {string|null} [effectiveBusinessId] GetProviderProfileRequest effectiveBusinessId
     * @property {string|null} [effectiveUserId] GetProviderProfileRequest effectiveUserId
     */

    /**
     * Constructs a new GetProviderProfileRequest.
     * @exports GetProviderProfileRequest
     * @classdesc Represents a GetProviderProfileRequest.
     * @implements IGetProviderProfileRequest
     * @constructor
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     */
    function GetProviderProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileRequest effectiveBusinessId.
     * @member {string} effectiveBusinessId
     * @memberof GetProviderProfileRequest
     * @instance
     */
    GetProviderProfileRequest.prototype.effectiveBusinessId = "";

    /**
     * GetProviderProfileRequest effectiveUserId.
     * @member {string} effectiveUserId
     * @memberof GetProviderProfileRequest
     * @instance
     */
    GetProviderProfileRequest.prototype.effectiveUserId = "";

    /**
     * Creates a new GetProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest instance
     */
    GetProviderProfileRequest.create = function create(properties) {
        return new GetProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified GetProviderProfileRequest message. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.effectiveBusinessId != null && Object.hasOwnProperty.call(message, "effectiveBusinessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.effectiveBusinessId);
        if (message.effectiveUserId != null && Object.hasOwnProperty.call(message, "effectiveUserId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.effectiveUserId);
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileRequest message, length delimited. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.effectiveBusinessId = reader.string();
                    break;
                }
            case 2: {
                    message.effectiveUserId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileRequest message.
     * @function verify
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.effectiveBusinessId != null && message.hasOwnProperty("effectiveBusinessId"))
            if (!$util.isString(message.effectiveBusinessId))
                return "effectiveBusinessId: string expected";
        if (message.effectiveUserId != null && message.hasOwnProperty("effectiveUserId"))
            if (!$util.isString(message.effectiveUserId))
                return "effectiveUserId: string expected";
        return null;
    };

    /**
     * Creates a GetProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     */
    GetProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileRequest)
            return object;
        var message = new $root.GetProviderProfileRequest();
        if (object.effectiveBusinessId != null)
            message.effectiveBusinessId = String(object.effectiveBusinessId);
        if (object.effectiveUserId != null)
            message.effectiveUserId = String(object.effectiveUserId);
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {GetProviderProfileRequest} message GetProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.effectiveBusinessId = "";
            object.effectiveUserId = "";
        }
        if (message.effectiveBusinessId != null && message.hasOwnProperty("effectiveBusinessId"))
            object.effectiveBusinessId = message.effectiveBusinessId;
        if (message.effectiveUserId != null && message.hasOwnProperty("effectiveUserId"))
            object.effectiveUserId = message.effectiveUserId;
        return object;
    };

    /**
     * Converts this GetProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof GetProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileRequest
     * @function getTypeUrl
     * @memberof GetProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileRequest";
    };

    return GetProviderProfileRequest;
})();

$root.GetProviderProfileResponse = (function() {

    /**
     * Properties of a GetProviderProfileResponse.
     * @exports IGetProviderProfileResponse
     * @interface IGetProviderProfileResponse
     * @property {boolean|null} [okay] GetProviderProfileResponse okay
     * @property {Array.<string>|null} [authenticatedUserRoles] GetProviderProfileResponse authenticatedUserRoles
     * @property {IApiUserProto|null} [effectiveUser] GetProviderProfileResponse effectiveUser
     * @property {IApiBusinessProto|null} [business] GetProviderProfileResponse business
     * @property {IApiBusinessProfileProto|null} [businessProfile] GetProviderProfileResponse businessProfile
     * @property {GetProviderProfileResponse.ProfileReviewState|null} [profileReviewState] GetProviderProfileResponse profileReviewState
     * @property {IProviderClientSettings|null} [clientSettings] GetProviderProfileResponse clientSettings
     */

    /**
     * Constructs a new GetProviderProfileResponse.
     * @exports GetProviderProfileResponse
     * @classdesc Represents a GetProviderProfileResponse.
     * @implements IGetProviderProfileResponse
     * @constructor
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     */
    function GetProviderProfileResponse(properties) {
        this.authenticatedUserRoles = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.okay = false;

    /**
     * GetProviderProfileResponse authenticatedUserRoles.
     * @member {Array.<string>} authenticatedUserRoles
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.authenticatedUserRoles = $util.emptyArray;

    /**
     * GetProviderProfileResponse effectiveUser.
     * @member {IApiUserProto|null|undefined} effectiveUser
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.effectiveUser = null;

    /**
     * GetProviderProfileResponse business.
     * @member {IApiBusinessProto|null|undefined} business
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.business = null;

    /**
     * GetProviderProfileResponse businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.businessProfile = null;

    /**
     * GetProviderProfileResponse profileReviewState.
     * @member {GetProviderProfileResponse.ProfileReviewState} profileReviewState
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.profileReviewState = 0;

    /**
     * GetProviderProfileResponse clientSettings.
     * @member {IProviderClientSettings|null|undefined} clientSettings
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.clientSettings = null;

    /**
     * Creates a new GetProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse instance
     */
    GetProviderProfileResponse.create = function create(properties) {
        return new GetProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified GetProviderProfileResponse message. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.authenticatedUserRoles != null && message.authenticatedUserRoles.length)
            for (var i = 0; i < message.authenticatedUserRoles.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.authenticatedUserRoles[i]);
        if (message.effectiveUser != null && Object.hasOwnProperty.call(message, "effectiveUser"))
            $root.ApiUserProto.encode(message.effectiveUser, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.business != null && Object.hasOwnProperty.call(message, "business"))
            $root.ApiBusinessProto.encode(message.business, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.profileReviewState != null && Object.hasOwnProperty.call(message, "profileReviewState"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.profileReviewState);
        if (message.clientSettings != null && Object.hasOwnProperty.call(message, "clientSettings"))
            $root.ProviderClientSettings.encode(message.clientSettings, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileResponse message, length delimited. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.authenticatedUserRoles && message.authenticatedUserRoles.length))
                        message.authenticatedUserRoles = [];
                    message.authenticatedUserRoles.push(reader.string());
                    break;
                }
            case 3: {
                    message.effectiveUser = $root.ApiUserProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.business = $root.ApiBusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.profileReviewState = reader.int32();
                    break;
                }
            case 100: {
                    message.clientSettings = $root.ProviderClientSettings.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileResponse message.
     * @function verify
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.authenticatedUserRoles != null && message.hasOwnProperty("authenticatedUserRoles")) {
            if (!Array.isArray(message.authenticatedUserRoles))
                return "authenticatedUserRoles: array expected";
            for (var i = 0; i < message.authenticatedUserRoles.length; ++i)
                if (!$util.isString(message.authenticatedUserRoles[i]))
                    return "authenticatedUserRoles: string[] expected";
        }
        if (message.effectiveUser != null && message.hasOwnProperty("effectiveUser")) {
            var error = $root.ApiUserProto.verify(message.effectiveUser);
            if (error)
                return "effectiveUser." + error;
        }
        if (message.business != null && message.hasOwnProperty("business")) {
            var error = $root.ApiBusinessProto.verify(message.business);
            if (error)
                return "business." + error;
        }
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        if (message.profileReviewState != null && message.hasOwnProperty("profileReviewState"))
            switch (message.profileReviewState) {
            default:
                return "profileReviewState: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.clientSettings != null && message.hasOwnProperty("clientSettings")) {
            var error = $root.ProviderClientSettings.verify(message.clientSettings);
            if (error)
                return "clientSettings." + error;
        }
        return null;
    };

    /**
     * Creates a GetProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     */
    GetProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileResponse)
            return object;
        var message = new $root.GetProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.authenticatedUserRoles) {
            if (!Array.isArray(object.authenticatedUserRoles))
                throw TypeError(".GetProviderProfileResponse.authenticatedUserRoles: array expected");
            message.authenticatedUserRoles = [];
            for (var i = 0; i < object.authenticatedUserRoles.length; ++i)
                message.authenticatedUserRoles[i] = String(object.authenticatedUserRoles[i]);
        }
        if (object.effectiveUser != null) {
            if (typeof object.effectiveUser !== "object")
                throw TypeError(".GetProviderProfileResponse.effectiveUser: object expected");
            message.effectiveUser = $root.ApiUserProto.fromObject(object.effectiveUser);
        }
        if (object.business != null) {
            if (typeof object.business !== "object")
                throw TypeError(".GetProviderProfileResponse.business: object expected");
            message.business = $root.ApiBusinessProto.fromObject(object.business);
        }
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".GetProviderProfileResponse.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        switch (object.profileReviewState) {
        default:
            if (typeof object.profileReviewState === "number") {
                message.profileReviewState = object.profileReviewState;
                break;
            }
            break;
        case "NONE":
        case 0:
            message.profileReviewState = 0;
            break;
        case "IN_REVIEW":
        case 1:
            message.profileReviewState = 1;
            break;
        case "APPROVED_READY_TO_PUBLISH":
        case 2:
            message.profileReviewState = 2;
            break;
        case "REJECTED":
        case 3:
            message.profileReviewState = 3;
            break;
        case "WITHDRAWN":
        case 4:
            message.profileReviewState = 4;
            break;
        }
        if (object.clientSettings != null) {
            if (typeof object.clientSettings !== "object")
                throw TypeError(".GetProviderProfileResponse.clientSettings: object expected");
            message.clientSettings = $root.ProviderClientSettings.fromObject(object.clientSettings);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {GetProviderProfileResponse} message GetProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.authenticatedUserRoles = [];
        if (options.defaults) {
            object.okay = false;
            object.effectiveUser = null;
            object.business = null;
            object.businessProfile = null;
            object.profileReviewState = options.enums === String ? "NONE" : 0;
            object.clientSettings = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.authenticatedUserRoles && message.authenticatedUserRoles.length) {
            object.authenticatedUserRoles = [];
            for (var j = 0; j < message.authenticatedUserRoles.length; ++j)
                object.authenticatedUserRoles[j] = message.authenticatedUserRoles[j];
        }
        if (message.effectiveUser != null && message.hasOwnProperty("effectiveUser"))
            object.effectiveUser = $root.ApiUserProto.toObject(message.effectiveUser, options);
        if (message.business != null && message.hasOwnProperty("business"))
            object.business = $root.ApiBusinessProto.toObject(message.business, options);
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        if (message.profileReviewState != null && message.hasOwnProperty("profileReviewState"))
            object.profileReviewState = options.enums === String ? $root.GetProviderProfileResponse.ProfileReviewState[message.profileReviewState] === undefined ? message.profileReviewState : $root.GetProviderProfileResponse.ProfileReviewState[message.profileReviewState] : message.profileReviewState;
        if (message.clientSettings != null && message.hasOwnProperty("clientSettings"))
            object.clientSettings = $root.ProviderClientSettings.toObject(message.clientSettings, options);
        return object;
    };

    /**
     * Converts this GetProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof GetProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileResponse
     * @function getTypeUrl
     * @memberof GetProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileResponse";
    };

    /**
     * ProfileReviewState enum.
     * @name GetProviderProfileResponse.ProfileReviewState
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} IN_REVIEW=1 IN_REVIEW value
     * @property {number} APPROVED_READY_TO_PUBLISH=2 APPROVED_READY_TO_PUBLISH value
     * @property {number} REJECTED=3 REJECTED value
     * @property {number} WITHDRAWN=4 WITHDRAWN value
     */
    GetProviderProfileResponse.ProfileReviewState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "IN_REVIEW"] = 1;
        values[valuesById[2] = "APPROVED_READY_TO_PUBLISH"] = 2;
        values[valuesById[3] = "REJECTED"] = 3;
        values[valuesById[4] = "WITHDRAWN"] = 4;
        return values;
    })();

    return GetProviderProfileResponse;
})();

$root.ProviderClientSettings = (function() {

    /**
     * Properties of a ProviderClientSettings.
     * @exports IProviderClientSettings
     * @interface IProviderClientSettings
     * @property {string|null} [kenkoBusinessId] ProviderClientSettings kenkoBusinessId
     * @property {string|null} [kenkoConsultationServiceSku] ProviderClientSettings kenkoConsultationServiceSku
     */

    /**
     * Constructs a new ProviderClientSettings.
     * @exports ProviderClientSettings
     * @classdesc Represents a ProviderClientSettings.
     * @implements IProviderClientSettings
     * @constructor
     * @param {IProviderClientSettings=} [properties] Properties to set
     */
    function ProviderClientSettings(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderClientSettings kenkoBusinessId.
     * @member {string} kenkoBusinessId
     * @memberof ProviderClientSettings
     * @instance
     */
    ProviderClientSettings.prototype.kenkoBusinessId = "";

    /**
     * ProviderClientSettings kenkoConsultationServiceSku.
     * @member {string} kenkoConsultationServiceSku
     * @memberof ProviderClientSettings
     * @instance
     */
    ProviderClientSettings.prototype.kenkoConsultationServiceSku = "";

    /**
     * Creates a new ProviderClientSettings instance using the specified properties.
     * @function create
     * @memberof ProviderClientSettings
     * @static
     * @param {IProviderClientSettings=} [properties] Properties to set
     * @returns {ProviderClientSettings} ProviderClientSettings instance
     */
    ProviderClientSettings.create = function create(properties) {
        return new ProviderClientSettings(properties);
    };

    /**
     * Encodes the specified ProviderClientSettings message. Does not implicitly {@link ProviderClientSettings.verify|verify} messages.
     * @function encode
     * @memberof ProviderClientSettings
     * @static
     * @param {IProviderClientSettings} message ProviderClientSettings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderClientSettings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.kenkoBusinessId != null && Object.hasOwnProperty.call(message, "kenkoBusinessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.kenkoBusinessId);
        if (message.kenkoConsultationServiceSku != null && Object.hasOwnProperty.call(message, "kenkoConsultationServiceSku"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.kenkoConsultationServiceSku);
        return writer;
    };

    /**
     * Encodes the specified ProviderClientSettings message, length delimited. Does not implicitly {@link ProviderClientSettings.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderClientSettings
     * @static
     * @param {IProviderClientSettings} message ProviderClientSettings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderClientSettings.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderClientSettings message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderClientSettings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderClientSettings} ProviderClientSettings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderClientSettings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderClientSettings();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.kenkoBusinessId = reader.string();
                    break;
                }
            case 2: {
                    message.kenkoConsultationServiceSku = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProviderClientSettings message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderClientSettings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderClientSettings} ProviderClientSettings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderClientSettings.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderClientSettings message.
     * @function verify
     * @memberof ProviderClientSettings
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderClientSettings.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.kenkoBusinessId != null && message.hasOwnProperty("kenkoBusinessId"))
            if (!$util.isString(message.kenkoBusinessId))
                return "kenkoBusinessId: string expected";
        if (message.kenkoConsultationServiceSku != null && message.hasOwnProperty("kenkoConsultationServiceSku"))
            if (!$util.isString(message.kenkoConsultationServiceSku))
                return "kenkoConsultationServiceSku: string expected";
        return null;
    };

    /**
     * Creates a ProviderClientSettings message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderClientSettings
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderClientSettings} ProviderClientSettings
     */
    ProviderClientSettings.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderClientSettings)
            return object;
        var message = new $root.ProviderClientSettings();
        if (object.kenkoBusinessId != null)
            message.kenkoBusinessId = String(object.kenkoBusinessId);
        if (object.kenkoConsultationServiceSku != null)
            message.kenkoConsultationServiceSku = String(object.kenkoConsultationServiceSku);
        return message;
    };

    /**
     * Creates a plain object from a ProviderClientSettings message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderClientSettings
     * @static
     * @param {ProviderClientSettings} message ProviderClientSettings
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderClientSettings.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.kenkoBusinessId = "";
            object.kenkoConsultationServiceSku = "";
        }
        if (message.kenkoBusinessId != null && message.hasOwnProperty("kenkoBusinessId"))
            object.kenkoBusinessId = message.kenkoBusinessId;
        if (message.kenkoConsultationServiceSku != null && message.hasOwnProperty("kenkoConsultationServiceSku"))
            object.kenkoConsultationServiceSku = message.kenkoConsultationServiceSku;
        return object;
    };

    /**
     * Converts this ProviderClientSettings to JSON.
     * @function toJSON
     * @memberof ProviderClientSettings
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderClientSettings.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProviderClientSettings
     * @function getTypeUrl
     * @memberof ProviderClientSettings
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProviderClientSettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProviderClientSettings";
    };

    return ProviderClientSettings;
})();

$root.ApiUserProto = (function() {

    /**
     * Properties of an ApiUserProto.
     * @exports IApiUserProto
     * @interface IApiUserProto
     * @property {string|null} [userId] ApiUserProto userId
     * @property {string|null} [businessId] ApiUserProto businessId
     * @property {string|null} [loginId] ApiUserProto loginId
     * @property {Array.<string>|null} [adminRoles] ApiUserProto adminRoles
     * @property {string|null} [lastLoginEmail] ApiUserProto lastLoginEmail
     * @property {number|Long|null} [lastLoginTimestamp] ApiUserProto lastLoginTimestamp
     * @property {space.kenko.proto.IUserProfileProto|null} [profile] ApiUserProto profile
     * @property {Array.<IClientAppointmentProto>|null} [appointments] ApiUserProto appointments
     * @property {Array.<string>|null} [businessRoles] ApiUserProto businessRoles
     */

    /**
     * Constructs a new ApiUserProto.
     * @exports ApiUserProto
     * @classdesc Represents an ApiUserProto.
     * @implements IApiUserProto
     * @constructor
     * @param {IApiUserProto=} [properties] Properties to set
     */
    function ApiUserProto(properties) {
        this.adminRoles = [];
        this.appointments = [];
        this.businessRoles = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApiUserProto userId.
     * @member {string} userId
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.userId = "";

    /**
     * ApiUserProto businessId.
     * @member {string} businessId
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.businessId = "";

    /**
     * ApiUserProto loginId.
     * @member {string} loginId
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.loginId = "";

    /**
     * ApiUserProto adminRoles.
     * @member {Array.<string>} adminRoles
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.adminRoles = $util.emptyArray;

    /**
     * ApiUserProto lastLoginEmail.
     * @member {string} lastLoginEmail
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.lastLoginEmail = "";

    /**
     * ApiUserProto lastLoginTimestamp.
     * @member {number|Long} lastLoginTimestamp
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.lastLoginTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ApiUserProto profile.
     * @member {space.kenko.proto.IUserProfileProto|null|undefined} profile
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.profile = null;

    /**
     * ApiUserProto appointments.
     * @member {Array.<IClientAppointmentProto>} appointments
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.appointments = $util.emptyArray;

    /**
     * ApiUserProto businessRoles.
     * @member {Array.<string>} businessRoles
     * @memberof ApiUserProto
     * @instance
     */
    ApiUserProto.prototype.businessRoles = $util.emptyArray;

    /**
     * Creates a new ApiUserProto instance using the specified properties.
     * @function create
     * @memberof ApiUserProto
     * @static
     * @param {IApiUserProto=} [properties] Properties to set
     * @returns {ApiUserProto} ApiUserProto instance
     */
    ApiUserProto.create = function create(properties) {
        return new ApiUserProto(properties);
    };

    /**
     * Encodes the specified ApiUserProto message. Does not implicitly {@link ApiUserProto.verify|verify} messages.
     * @function encode
     * @memberof ApiUserProto
     * @static
     * @param {IApiUserProto} message ApiUserProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiUserProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
        if (message.loginId != null && Object.hasOwnProperty.call(message, "loginId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.loginId);
        if (message.adminRoles != null && message.adminRoles.length)
            for (var i = 0; i < message.adminRoles.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.adminRoles[i]);
        if (message.lastLoginEmail != null && Object.hasOwnProperty.call(message, "lastLoginEmail"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastLoginEmail);
        if (message.lastLoginTimestamp != null && Object.hasOwnProperty.call(message, "lastLoginTimestamp"))
            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.lastLoginTimestamp);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.space.kenko.proto.UserProfileProto.encode(message.profile, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.appointments != null && message.appointments.length)
            for (var i = 0; i < message.appointments.length; ++i)
                $root.ClientAppointmentProto.encode(message.appointments[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.businessRoles != null && message.businessRoles.length)
            for (var i = 0; i < message.businessRoles.length; ++i)
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.businessRoles[i]);
        return writer;
    };

    /**
     * Encodes the specified ApiUserProto message, length delimited. Does not implicitly {@link ApiUserProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApiUserProto
     * @static
     * @param {IApiUserProto} message ApiUserProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiUserProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApiUserProto message from the specified reader or buffer.
     * @function decode
     * @memberof ApiUserProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApiUserProto} ApiUserProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiUserProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApiUserProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.userId = reader.string();
                    break;
                }
            case 2: {
                    message.businessId = reader.string();
                    break;
                }
            case 3: {
                    message.loginId = reader.string();
                    break;
                }
            case 4: {
                    if (!(message.adminRoles && message.adminRoles.length))
                        message.adminRoles = [];
                    message.adminRoles.push(reader.string());
                    break;
                }
            case 5: {
                    message.lastLoginEmail = reader.string();
                    break;
                }
            case 6: {
                    message.lastLoginTimestamp = reader.int64();
                    break;
                }
            case 7: {
                    message.profile = $root.space.kenko.proto.UserProfileProto.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    if (!(message.appointments && message.appointments.length))
                        message.appointments = [];
                    message.appointments.push($root.ClientAppointmentProto.decode(reader, reader.uint32()));
                    break;
                }
            case 9: {
                    if (!(message.businessRoles && message.businessRoles.length))
                        message.businessRoles = [];
                    message.businessRoles.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ApiUserProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApiUserProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApiUserProto} ApiUserProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiUserProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApiUserProto message.
     * @function verify
     * @memberof ApiUserProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApiUserProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.userId != null && message.hasOwnProperty("userId"))
            if (!$util.isString(message.userId))
                return "userId: string expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            if (!$util.isString(message.loginId))
                return "loginId: string expected";
        if (message.adminRoles != null && message.hasOwnProperty("adminRoles")) {
            if (!Array.isArray(message.adminRoles))
                return "adminRoles: array expected";
            for (var i = 0; i < message.adminRoles.length; ++i)
                if (!$util.isString(message.adminRoles[i]))
                    return "adminRoles: string[] expected";
        }
        if (message.lastLoginEmail != null && message.hasOwnProperty("lastLoginEmail"))
            if (!$util.isString(message.lastLoginEmail))
                return "lastLoginEmail: string expected";
        if (message.lastLoginTimestamp != null && message.hasOwnProperty("lastLoginTimestamp"))
            if (!$util.isInteger(message.lastLoginTimestamp) && !(message.lastLoginTimestamp && $util.isInteger(message.lastLoginTimestamp.low) && $util.isInteger(message.lastLoginTimestamp.high)))
                return "lastLoginTimestamp: integer|Long expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.space.kenko.proto.UserProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        if (message.appointments != null && message.hasOwnProperty("appointments")) {
            if (!Array.isArray(message.appointments))
                return "appointments: array expected";
            for (var i = 0; i < message.appointments.length; ++i) {
                var error = $root.ClientAppointmentProto.verify(message.appointments[i]);
                if (error)
                    return "appointments." + error;
            }
        }
        if (message.businessRoles != null && message.hasOwnProperty("businessRoles")) {
            if (!Array.isArray(message.businessRoles))
                return "businessRoles: array expected";
            for (var i = 0; i < message.businessRoles.length; ++i)
                if (!$util.isString(message.businessRoles[i]))
                    return "businessRoles: string[] expected";
        }
        return null;
    };

    /**
     * Creates an ApiUserProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApiUserProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApiUserProto} ApiUserProto
     */
    ApiUserProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ApiUserProto)
            return object;
        var message = new $root.ApiUserProto();
        if (object.userId != null)
            message.userId = String(object.userId);
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.loginId != null)
            message.loginId = String(object.loginId);
        if (object.adminRoles) {
            if (!Array.isArray(object.adminRoles))
                throw TypeError(".ApiUserProto.adminRoles: array expected");
            message.adminRoles = [];
            for (var i = 0; i < object.adminRoles.length; ++i)
                message.adminRoles[i] = String(object.adminRoles[i]);
        }
        if (object.lastLoginEmail != null)
            message.lastLoginEmail = String(object.lastLoginEmail);
        if (object.lastLoginTimestamp != null)
            if ($util.Long)
                (message.lastLoginTimestamp = $util.Long.fromValue(object.lastLoginTimestamp)).unsigned = false;
            else if (typeof object.lastLoginTimestamp === "string")
                message.lastLoginTimestamp = parseInt(object.lastLoginTimestamp, 10);
            else if (typeof object.lastLoginTimestamp === "number")
                message.lastLoginTimestamp = object.lastLoginTimestamp;
            else if (typeof object.lastLoginTimestamp === "object")
                message.lastLoginTimestamp = new $util.LongBits(object.lastLoginTimestamp.low >>> 0, object.lastLoginTimestamp.high >>> 0).toNumber();
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".ApiUserProto.profile: object expected");
            message.profile = $root.space.kenko.proto.UserProfileProto.fromObject(object.profile);
        }
        if (object.appointments) {
            if (!Array.isArray(object.appointments))
                throw TypeError(".ApiUserProto.appointments: array expected");
            message.appointments = [];
            for (var i = 0; i < object.appointments.length; ++i) {
                if (typeof object.appointments[i] !== "object")
                    throw TypeError(".ApiUserProto.appointments: object expected");
                message.appointments[i] = $root.ClientAppointmentProto.fromObject(object.appointments[i]);
            }
        }
        if (object.businessRoles) {
            if (!Array.isArray(object.businessRoles))
                throw TypeError(".ApiUserProto.businessRoles: array expected");
            message.businessRoles = [];
            for (var i = 0; i < object.businessRoles.length; ++i)
                message.businessRoles[i] = String(object.businessRoles[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from an ApiUserProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApiUserProto
     * @static
     * @param {ApiUserProto} message ApiUserProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApiUserProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.adminRoles = [];
            object.appointments = [];
            object.businessRoles = [];
        }
        if (options.defaults) {
            object.userId = "";
            object.businessId = "";
            object.loginId = "";
            object.lastLoginEmail = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.lastLoginTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastLoginTimestamp = options.longs === String ? "0" : 0;
            object.profile = null;
        }
        if (message.userId != null && message.hasOwnProperty("userId"))
            object.userId = message.userId;
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            object.loginId = message.loginId;
        if (message.adminRoles && message.adminRoles.length) {
            object.adminRoles = [];
            for (var j = 0; j < message.adminRoles.length; ++j)
                object.adminRoles[j] = message.adminRoles[j];
        }
        if (message.lastLoginEmail != null && message.hasOwnProperty("lastLoginEmail"))
            object.lastLoginEmail = message.lastLoginEmail;
        if (message.lastLoginTimestamp != null && message.hasOwnProperty("lastLoginTimestamp"))
            if (typeof message.lastLoginTimestamp === "number")
                object.lastLoginTimestamp = options.longs === String ? String(message.lastLoginTimestamp) : message.lastLoginTimestamp;
            else
                object.lastLoginTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.lastLoginTimestamp) : options.longs === Number ? new $util.LongBits(message.lastLoginTimestamp.low >>> 0, message.lastLoginTimestamp.high >>> 0).toNumber() : message.lastLoginTimestamp;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.space.kenko.proto.UserProfileProto.toObject(message.profile, options);
        if (message.appointments && message.appointments.length) {
            object.appointments = [];
            for (var j = 0; j < message.appointments.length; ++j)
                object.appointments[j] = $root.ClientAppointmentProto.toObject(message.appointments[j], options);
        }
        if (message.businessRoles && message.businessRoles.length) {
            object.businessRoles = [];
            for (var j = 0; j < message.businessRoles.length; ++j)
                object.businessRoles[j] = message.businessRoles[j];
        }
        return object;
    };

    /**
     * Converts this ApiUserProto to JSON.
     * @function toJSON
     * @memberof ApiUserProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApiUserProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ApiUserProto
     * @function getTypeUrl
     * @memberof ApiUserProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ApiUserProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ApiUserProto";
    };

    return ApiUserProto;
})();

$root.ApiBusinessProto = (function() {

    /**
     * Properties of an ApiBusinessProto.
     * @exports IApiBusinessProto
     * @interface IApiBusinessProto
     * @property {string|null} [businessId] ApiBusinessProto businessId
     * @property {space.kenko.proto.ProviderApprovalState|null} [approvalState] ApiBusinessProto approvalState
     * @property {space.kenko.proto.IProviderSurveyProto|null} [surveyProto] ApiBusinessProto surveyProto
     * @property {string|null} [shortUrl] ApiBusinessProto shortUrl
     * @property {string|null} [liveProfileVersion] ApiBusinessProto liveProfileVersion
     * @property {string|null} [stripeAccountId] ApiBusinessProto stripeAccountId
     */

    /**
     * Constructs a new ApiBusinessProto.
     * @exports ApiBusinessProto
     * @classdesc Represents an ApiBusinessProto.
     * @implements IApiBusinessProto
     * @constructor
     * @param {IApiBusinessProto=} [properties] Properties to set
     */
    function ApiBusinessProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApiBusinessProto businessId.
     * @member {string} businessId
     * @memberof ApiBusinessProto
     * @instance
     */
    ApiBusinessProto.prototype.businessId = "";

    /**
     * ApiBusinessProto approvalState.
     * @member {space.kenko.proto.ProviderApprovalState} approvalState
     * @memberof ApiBusinessProto
     * @instance
     */
    ApiBusinessProto.prototype.approvalState = 0;

    /**
     * ApiBusinessProto surveyProto.
     * @member {space.kenko.proto.IProviderSurveyProto|null|undefined} surveyProto
     * @memberof ApiBusinessProto
     * @instance
     */
    ApiBusinessProto.prototype.surveyProto = null;

    /**
     * ApiBusinessProto shortUrl.
     * @member {string} shortUrl
     * @memberof ApiBusinessProto
     * @instance
     */
    ApiBusinessProto.prototype.shortUrl = "";

    /**
     * ApiBusinessProto liveProfileVersion.
     * @member {string} liveProfileVersion
     * @memberof ApiBusinessProto
     * @instance
     */
    ApiBusinessProto.prototype.liveProfileVersion = "";

    /**
     * ApiBusinessProto stripeAccountId.
     * @member {string} stripeAccountId
     * @memberof ApiBusinessProto
     * @instance
     */
    ApiBusinessProto.prototype.stripeAccountId = "";

    /**
     * Creates a new ApiBusinessProto instance using the specified properties.
     * @function create
     * @memberof ApiBusinessProto
     * @static
     * @param {IApiBusinessProto=} [properties] Properties to set
     * @returns {ApiBusinessProto} ApiBusinessProto instance
     */
    ApiBusinessProto.create = function create(properties) {
        return new ApiBusinessProto(properties);
    };

    /**
     * Encodes the specified ApiBusinessProto message. Does not implicitly {@link ApiBusinessProto.verify|verify} messages.
     * @function encode
     * @memberof ApiBusinessProto
     * @static
     * @param {IApiBusinessProto} message ApiBusinessProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiBusinessProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
        if (message.approvalState != null && Object.hasOwnProperty.call(message, "approvalState"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.approvalState);
        if (message.surveyProto != null && Object.hasOwnProperty.call(message, "surveyProto"))
            $root.space.kenko.proto.ProviderSurveyProto.encode(message.surveyProto, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortUrl);
        if (message.liveProfileVersion != null && Object.hasOwnProperty.call(message, "liveProfileVersion"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.liveProfileVersion);
        if (message.stripeAccountId != null && Object.hasOwnProperty.call(message, "stripeAccountId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.stripeAccountId);
        return writer;
    };

    /**
     * Encodes the specified ApiBusinessProto message, length delimited. Does not implicitly {@link ApiBusinessProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApiBusinessProto
     * @static
     * @param {IApiBusinessProto} message ApiBusinessProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiBusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApiBusinessProto message from the specified reader or buffer.
     * @function decode
     * @memberof ApiBusinessProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApiBusinessProto} ApiBusinessProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiBusinessProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApiBusinessProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessId = reader.string();
                    break;
                }
            case 2: {
                    message.approvalState = reader.int32();
                    break;
                }
            case 3: {
                    message.surveyProto = $root.space.kenko.proto.ProviderSurveyProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.shortUrl = reader.string();
                    break;
                }
            case 5: {
                    message.liveProfileVersion = reader.string();
                    break;
                }
            case 6: {
                    message.stripeAccountId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ApiBusinessProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApiBusinessProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApiBusinessProto} ApiBusinessProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiBusinessProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApiBusinessProto message.
     * @function verify
     * @memberof ApiBusinessProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApiBusinessProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.approvalState != null && message.hasOwnProperty("approvalState"))
            switch (message.approvalState) {
            default:
                return "approvalState: enum value expected";
            case 0:
            case 10:
            case 11:
            case 20:
            case 30:
            case 40:
                break;
            }
        if (message.surveyProto != null && message.hasOwnProperty("surveyProto")) {
            var error = $root.space.kenko.proto.ProviderSurveyProto.verify(message.surveyProto);
            if (error)
                return "surveyProto." + error;
        }
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            if (!$util.isString(message.shortUrl))
                return "shortUrl: string expected";
        if (message.liveProfileVersion != null && message.hasOwnProperty("liveProfileVersion"))
            if (!$util.isString(message.liveProfileVersion))
                return "liveProfileVersion: string expected";
        if (message.stripeAccountId != null && message.hasOwnProperty("stripeAccountId"))
            if (!$util.isString(message.stripeAccountId))
                return "stripeAccountId: string expected";
        return null;
    };

    /**
     * Creates an ApiBusinessProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApiBusinessProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApiBusinessProto} ApiBusinessProto
     */
    ApiBusinessProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ApiBusinessProto)
            return object;
        var message = new $root.ApiBusinessProto();
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        switch (object.approvalState) {
        default:
            if (typeof object.approvalState === "number") {
                message.approvalState = object.approvalState;
                break;
            }
            break;
        case "APPROVAL_STATE_UNKNOWN":
        case 0:
            message.approvalState = 0;
            break;
        case "NEW_PROVIDER_MANUAL_APPROVE":
        case 10:
            message.approvalState = 10;
            break;
        case "NEW_PROVIDER_AUTO_APPROVE":
        case 11:
            message.approvalState = 11;
            break;
        case "AWAITING_REVIEW":
        case 20:
            message.approvalState = 20;
            break;
        case "PROVIDER_APPROVED":
        case 30:
            message.approvalState = 30;
            break;
        case "PROVIDER_REJECTED":
        case 40:
            message.approvalState = 40;
            break;
        }
        if (object.surveyProto != null) {
            if (typeof object.surveyProto !== "object")
                throw TypeError(".ApiBusinessProto.surveyProto: object expected");
            message.surveyProto = $root.space.kenko.proto.ProviderSurveyProto.fromObject(object.surveyProto);
        }
        if (object.shortUrl != null)
            message.shortUrl = String(object.shortUrl);
        if (object.liveProfileVersion != null)
            message.liveProfileVersion = String(object.liveProfileVersion);
        if (object.stripeAccountId != null)
            message.stripeAccountId = String(object.stripeAccountId);
        return message;
    };

    /**
     * Creates a plain object from an ApiBusinessProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApiBusinessProto
     * @static
     * @param {ApiBusinessProto} message ApiBusinessProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApiBusinessProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.businessId = "";
            object.approvalState = options.enums === String ? "APPROVAL_STATE_UNKNOWN" : 0;
            object.surveyProto = null;
            object.shortUrl = "";
            object.liveProfileVersion = "";
            object.stripeAccountId = "";
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.approvalState != null && message.hasOwnProperty("approvalState"))
            object.approvalState = options.enums === String ? $root.space.kenko.proto.ProviderApprovalState[message.approvalState] === undefined ? message.approvalState : $root.space.kenko.proto.ProviderApprovalState[message.approvalState] : message.approvalState;
        if (message.surveyProto != null && message.hasOwnProperty("surveyProto"))
            object.surveyProto = $root.space.kenko.proto.ProviderSurveyProto.toObject(message.surveyProto, options);
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            object.shortUrl = message.shortUrl;
        if (message.liveProfileVersion != null && message.hasOwnProperty("liveProfileVersion"))
            object.liveProfileVersion = message.liveProfileVersion;
        if (message.stripeAccountId != null && message.hasOwnProperty("stripeAccountId"))
            object.stripeAccountId = message.stripeAccountId;
        return object;
    };

    /**
     * Converts this ApiBusinessProto to JSON.
     * @function toJSON
     * @memberof ApiBusinessProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApiBusinessProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ApiBusinessProto
     * @function getTypeUrl
     * @memberof ApiBusinessProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ApiBusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ApiBusinessProto";
    };

    return ApiBusinessProto;
})();

$root.ApiBusinessProfileProto = (function() {

    /**
     * Properties of an ApiBusinessProfileProto.
     * @exports IApiBusinessProfileProto
     * @interface IApiBusinessProfileProto
     * @property {string|null} [versionId] ApiBusinessProfileProto versionId
     * @property {number|Long|null} [lastModifiedTimestamp] ApiBusinessProfileProto lastModifiedTimestamp
     * @property {number|Long|null} [reviewTimestamp] ApiBusinessProfileProto reviewTimestamp
     * @property {space.kenko.proto.IProfileReviewProto|null} [review] ApiBusinessProfileProto review
     * @property {space.kenko.proto.IBusinessProto|null} [proto] ApiBusinessProfileProto proto
     * @property {Array.<space.kenko.proto.IBusinessLocationProto>|null} [locations] ApiBusinessProfileProto locations
     * @property {Array.<space.kenko.proto.IProviderBioProto>|null} [providers] ApiBusinessProfileProto providers
     * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] ApiBusinessProfileProto errors
     * @property {space.kenko.proto.IPracticeDetailsProto|null} [practice] ApiBusinessProfileProto practice
     */

    /**
     * Constructs a new ApiBusinessProfileProto.
     * @exports ApiBusinessProfileProto
     * @classdesc Represents an ApiBusinessProfileProto.
     * @implements IApiBusinessProfileProto
     * @constructor
     * @param {IApiBusinessProfileProto=} [properties] Properties to set
     */
    function ApiBusinessProfileProto(properties) {
        this.locations = [];
        this.providers = [];
        this.errors = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApiBusinessProfileProto versionId.
     * @member {string} versionId
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.versionId = "";

    /**
     * ApiBusinessProfileProto lastModifiedTimestamp.
     * @member {number|Long} lastModifiedTimestamp
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.lastModifiedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ApiBusinessProfileProto reviewTimestamp.
     * @member {number|Long} reviewTimestamp
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.reviewTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ApiBusinessProfileProto review.
     * @member {space.kenko.proto.IProfileReviewProto|null|undefined} review
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.review = null;

    /**
     * ApiBusinessProfileProto proto.
     * @member {space.kenko.proto.IBusinessProto|null|undefined} proto
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.proto = null;

    /**
     * ApiBusinessProfileProto locations.
     * @member {Array.<space.kenko.proto.IBusinessLocationProto>} locations
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.locations = $util.emptyArray;

    /**
     * ApiBusinessProfileProto providers.
     * @member {Array.<space.kenko.proto.IProviderBioProto>} providers
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.providers = $util.emptyArray;

    /**
     * ApiBusinessProfileProto errors.
     * @member {Array.<space.kenko.proto.IErrorProto>} errors
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.errors = $util.emptyArray;

    /**
     * ApiBusinessProfileProto practice.
     * @member {space.kenko.proto.IPracticeDetailsProto|null|undefined} practice
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.practice = null;

    /**
     * Creates a new ApiBusinessProfileProto instance using the specified properties.
     * @function create
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto=} [properties] Properties to set
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto instance
     */
    ApiBusinessProfileProto.create = function create(properties) {
        return new ApiBusinessProfileProto(properties);
    };

    /**
     * Encodes the specified ApiBusinessProfileProto message. Does not implicitly {@link ApiBusinessProfileProto.verify|verify} messages.
     * @function encode
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto} message ApiBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiBusinessProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.versionId);
        if (message.lastModifiedTimestamp != null && Object.hasOwnProperty.call(message, "lastModifiedTimestamp"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.lastModifiedTimestamp);
        if (message.reviewTimestamp != null && Object.hasOwnProperty.call(message, "reviewTimestamp"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.reviewTimestamp);
        if (message.review != null && Object.hasOwnProperty.call(message, "review"))
            $root.space.kenko.proto.ProfileReviewProto.encode(message.review, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.proto != null && Object.hasOwnProperty.call(message, "proto"))
            $root.space.kenko.proto.BusinessProto.encode(message.proto, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.locations != null && message.locations.length)
            for (var i = 0; i < message.locations.length; ++i)
                $root.space.kenko.proto.BusinessLocationProto.encode(message.locations[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.providers != null && message.providers.length)
            for (var i = 0; i < message.providers.length; ++i)
                $root.space.kenko.proto.ProviderBioProto.encode(message.providers[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.errors != null && message.errors.length)
            for (var i = 0; i < message.errors.length; ++i)
                $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
        if (message.practice != null && Object.hasOwnProperty.call(message, "practice"))
            $root.space.kenko.proto.PracticeDetailsProto.encode(message.practice, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ApiBusinessProfileProto message, length delimited. Does not implicitly {@link ApiBusinessProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto} message ApiBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiBusinessProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApiBusinessProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiBusinessProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApiBusinessProfileProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.versionId = reader.string();
                    break;
                }
            case 2: {
                    message.lastModifiedTimestamp = reader.int64();
                    break;
                }
            case 3: {
                    message.reviewTimestamp = reader.int64();
                    break;
                }
            case 4: {
                    message.review = $root.space.kenko.proto.ProfileReviewProto.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.proto = $root.space.kenko.proto.BusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    if (!(message.locations && message.locations.length))
                        message.locations = [];
                    message.locations.push($root.space.kenko.proto.BusinessLocationProto.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    if (!(message.providers && message.providers.length))
                        message.providers = [];
                    message.providers.push($root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32()));
                    break;
                }
            case 50: {
                    if (!(message.errors && message.errors.length))
                        message.errors = [];
                    message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                    break;
                }
            case 100: {
                    message.practice = $root.space.kenko.proto.PracticeDetailsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ApiBusinessProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiBusinessProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApiBusinessProfileProto message.
     * @function verify
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApiBusinessProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            if (!$util.isString(message.versionId))
                return "versionId: string expected";
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (!$util.isInteger(message.lastModifiedTimestamp) && !(message.lastModifiedTimestamp && $util.isInteger(message.lastModifiedTimestamp.low) && $util.isInteger(message.lastModifiedTimestamp.high)))
                return "lastModifiedTimestamp: integer|Long expected";
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (!$util.isInteger(message.reviewTimestamp) && !(message.reviewTimestamp && $util.isInteger(message.reviewTimestamp.low) && $util.isInteger(message.reviewTimestamp.high)))
                return "reviewTimestamp: integer|Long expected";
        if (message.review != null && message.hasOwnProperty("review")) {
            var error = $root.space.kenko.proto.ProfileReviewProto.verify(message.review);
            if (error)
                return "review." + error;
        }
        if (message.proto != null && message.hasOwnProperty("proto")) {
            var error = $root.space.kenko.proto.BusinessProto.verify(message.proto);
            if (error)
                return "proto." + error;
        }
        if (message.locations != null && message.hasOwnProperty("locations")) {
            if (!Array.isArray(message.locations))
                return "locations: array expected";
            for (var i = 0; i < message.locations.length; ++i) {
                var error = $root.space.kenko.proto.BusinessLocationProto.verify(message.locations[i]);
                if (error)
                    return "locations." + error;
            }
        }
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (var i = 0; i < message.providers.length; ++i) {
                var error = $root.space.kenko.proto.ProviderBioProto.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        if (message.errors != null && message.hasOwnProperty("errors")) {
            if (!Array.isArray(message.errors))
                return "errors: array expected";
            for (var i = 0; i < message.errors.length; ++i) {
                var error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                if (error)
                    return "errors." + error;
            }
        }
        if (message.practice != null && message.hasOwnProperty("practice")) {
            var error = $root.space.kenko.proto.PracticeDetailsProto.verify(message.practice);
            if (error)
                return "practice." + error;
        }
        return null;
    };

    /**
     * Creates an ApiBusinessProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     */
    ApiBusinessProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ApiBusinessProfileProto)
            return object;
        var message = new $root.ApiBusinessProfileProto();
        if (object.versionId != null)
            message.versionId = String(object.versionId);
        if (object.lastModifiedTimestamp != null)
            if ($util.Long)
                (message.lastModifiedTimestamp = $util.Long.fromValue(object.lastModifiedTimestamp)).unsigned = false;
            else if (typeof object.lastModifiedTimestamp === "string")
                message.lastModifiedTimestamp = parseInt(object.lastModifiedTimestamp, 10);
            else if (typeof object.lastModifiedTimestamp === "number")
                message.lastModifiedTimestamp = object.lastModifiedTimestamp;
            else if (typeof object.lastModifiedTimestamp === "object")
                message.lastModifiedTimestamp = new $util.LongBits(object.lastModifiedTimestamp.low >>> 0, object.lastModifiedTimestamp.high >>> 0).toNumber();
        if (object.reviewTimestamp != null)
            if ($util.Long)
                (message.reviewTimestamp = $util.Long.fromValue(object.reviewTimestamp)).unsigned = false;
            else if (typeof object.reviewTimestamp === "string")
                message.reviewTimestamp = parseInt(object.reviewTimestamp, 10);
            else if (typeof object.reviewTimestamp === "number")
                message.reviewTimestamp = object.reviewTimestamp;
            else if (typeof object.reviewTimestamp === "object")
                message.reviewTimestamp = new $util.LongBits(object.reviewTimestamp.low >>> 0, object.reviewTimestamp.high >>> 0).toNumber();
        if (object.review != null) {
            if (typeof object.review !== "object")
                throw TypeError(".ApiBusinessProfileProto.review: object expected");
            message.review = $root.space.kenko.proto.ProfileReviewProto.fromObject(object.review);
        }
        if (object.proto != null) {
            if (typeof object.proto !== "object")
                throw TypeError(".ApiBusinessProfileProto.proto: object expected");
            message.proto = $root.space.kenko.proto.BusinessProto.fromObject(object.proto);
        }
        if (object.locations) {
            if (!Array.isArray(object.locations))
                throw TypeError(".ApiBusinessProfileProto.locations: array expected");
            message.locations = [];
            for (var i = 0; i < object.locations.length; ++i) {
                if (typeof object.locations[i] !== "object")
                    throw TypeError(".ApiBusinessProfileProto.locations: object expected");
                message.locations[i] = $root.space.kenko.proto.BusinessLocationProto.fromObject(object.locations[i]);
            }
        }
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".ApiBusinessProfileProto.providers: array expected");
            message.providers = [];
            for (var i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".ApiBusinessProfileProto.providers: object expected");
                message.providers[i] = $root.space.kenko.proto.ProviderBioProto.fromObject(object.providers[i]);
            }
        }
        if (object.errors) {
            if (!Array.isArray(object.errors))
                throw TypeError(".ApiBusinessProfileProto.errors: array expected");
            message.errors = [];
            for (var i = 0; i < object.errors.length; ++i) {
                if (typeof object.errors[i] !== "object")
                    throw TypeError(".ApiBusinessProfileProto.errors: object expected");
                message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
            }
        }
        if (object.practice != null) {
            if (typeof object.practice !== "object")
                throw TypeError(".ApiBusinessProfileProto.practice: object expected");
            message.practice = $root.space.kenko.proto.PracticeDetailsProto.fromObject(object.practice);
        }
        return message;
    };

    /**
     * Creates a plain object from an ApiBusinessProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {ApiBusinessProfileProto} message ApiBusinessProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApiBusinessProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.locations = [];
            object.providers = [];
            object.errors = [];
        }
        if (options.defaults) {
            object.versionId = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.lastModifiedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastModifiedTimestamp = options.longs === String ? "0" : 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.reviewTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.reviewTimestamp = options.longs === String ? "0" : 0;
            object.review = null;
            object.proto = null;
            object.practice = null;
        }
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            object.versionId = message.versionId;
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (typeof message.lastModifiedTimestamp === "number")
                object.lastModifiedTimestamp = options.longs === String ? String(message.lastModifiedTimestamp) : message.lastModifiedTimestamp;
            else
                object.lastModifiedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.lastModifiedTimestamp) : options.longs === Number ? new $util.LongBits(message.lastModifiedTimestamp.low >>> 0, message.lastModifiedTimestamp.high >>> 0).toNumber() : message.lastModifiedTimestamp;
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (typeof message.reviewTimestamp === "number")
                object.reviewTimestamp = options.longs === String ? String(message.reviewTimestamp) : message.reviewTimestamp;
            else
                object.reviewTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.reviewTimestamp) : options.longs === Number ? new $util.LongBits(message.reviewTimestamp.low >>> 0, message.reviewTimestamp.high >>> 0).toNumber() : message.reviewTimestamp;
        if (message.review != null && message.hasOwnProperty("review"))
            object.review = $root.space.kenko.proto.ProfileReviewProto.toObject(message.review, options);
        if (message.proto != null && message.hasOwnProperty("proto"))
            object.proto = $root.space.kenko.proto.BusinessProto.toObject(message.proto, options);
        if (message.locations && message.locations.length) {
            object.locations = [];
            for (var j = 0; j < message.locations.length; ++j)
                object.locations[j] = $root.space.kenko.proto.BusinessLocationProto.toObject(message.locations[j], options);
        }
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (var j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.space.kenko.proto.ProviderBioProto.toObject(message.providers[j], options);
        }
        if (message.errors && message.errors.length) {
            object.errors = [];
            for (var j = 0; j < message.errors.length; ++j)
                object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
        }
        if (message.practice != null && message.hasOwnProperty("practice"))
            object.practice = $root.space.kenko.proto.PracticeDetailsProto.toObject(message.practice, options);
        return object;
    };

    /**
     * Converts this ApiBusinessProfileProto to JSON.
     * @function toJSON
     * @memberof ApiBusinessProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApiBusinessProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ApiBusinessProfileProto
     * @function getTypeUrl
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ApiBusinessProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ApiBusinessProfileProto";
    };

    return ApiBusinessProfileProto;
})();

$root.UpdateBusinessProfileRequest = (function() {

    /**
     * Properties of an UpdateBusinessProfileRequest.
     * @exports IUpdateBusinessProfileRequest
     * @interface IUpdateBusinessProfileRequest
     * @property {IApiBusinessProfileProto|null} [businessProfile] UpdateBusinessProfileRequest businessProfile
     */

    /**
     * Constructs a new UpdateBusinessProfileRequest.
     * @exports UpdateBusinessProfileRequest
     * @classdesc Represents an UpdateBusinessProfileRequest.
     * @implements IUpdateBusinessProfileRequest
     * @constructor
     * @param {IUpdateBusinessProfileRequest=} [properties] Properties to set
     */
    function UpdateBusinessProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateBusinessProfileRequest businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof UpdateBusinessProfileRequest
     * @instance
     */
    UpdateBusinessProfileRequest.prototype.businessProfile = null;

    /**
     * Creates a new UpdateBusinessProfileRequest instance using the specified properties.
     * @function create
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {IUpdateBusinessProfileRequest=} [properties] Properties to set
     * @returns {UpdateBusinessProfileRequest} UpdateBusinessProfileRequest instance
     */
    UpdateBusinessProfileRequest.create = function create(properties) {
        return new UpdateBusinessProfileRequest(properties);
    };

    /**
     * Encodes the specified UpdateBusinessProfileRequest message. Does not implicitly {@link UpdateBusinessProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {IUpdateBusinessProfileRequest} message UpdateBusinessProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateBusinessProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateBusinessProfileRequest message, length delimited. Does not implicitly {@link UpdateBusinessProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {IUpdateBusinessProfileRequest} message UpdateBusinessProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateBusinessProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateBusinessProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateBusinessProfileRequest} UpdateBusinessProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateBusinessProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateBusinessProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateBusinessProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateBusinessProfileRequest} UpdateBusinessProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateBusinessProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateBusinessProfileRequest message.
     * @function verify
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateBusinessProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateBusinessProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateBusinessProfileRequest} UpdateBusinessProfileRequest
     */
    UpdateBusinessProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateBusinessProfileRequest)
            return object;
        var message = new $root.UpdateBusinessProfileRequest();
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".UpdateBusinessProfileRequest.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateBusinessProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {UpdateBusinessProfileRequest} message UpdateBusinessProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateBusinessProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.businessProfile = null;
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        return object;
    };

    /**
     * Converts this UpdateBusinessProfileRequest to JSON.
     * @function toJSON
     * @memberof UpdateBusinessProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateBusinessProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateBusinessProfileRequest
     * @function getTypeUrl
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateBusinessProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateBusinessProfileRequest";
    };

    return UpdateBusinessProfileRequest;
})();

$root.UpdateBusinessProfileResponse = (function() {

    /**
     * Properties of an UpdateBusinessProfileResponse.
     * @exports IUpdateBusinessProfileResponse
     * @interface IUpdateBusinessProfileResponse
     * @property {boolean|null} [okay] UpdateBusinessProfileResponse okay
     * @property {IApiBusinessProfileProto|null} [profile] UpdateBusinessProfileResponse profile
     */

    /**
     * Constructs a new UpdateBusinessProfileResponse.
     * @exports UpdateBusinessProfileResponse
     * @classdesc Represents an UpdateBusinessProfileResponse.
     * @implements IUpdateBusinessProfileResponse
     * @constructor
     * @param {IUpdateBusinessProfileResponse=} [properties] Properties to set
     */
    function UpdateBusinessProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateBusinessProfileResponse okay.
     * @member {boolean} okay
     * @memberof UpdateBusinessProfileResponse
     * @instance
     */
    UpdateBusinessProfileResponse.prototype.okay = false;

    /**
     * UpdateBusinessProfileResponse profile.
     * @member {IApiBusinessProfileProto|null|undefined} profile
     * @memberof UpdateBusinessProfileResponse
     * @instance
     */
    UpdateBusinessProfileResponse.prototype.profile = null;

    /**
     * Creates a new UpdateBusinessProfileResponse instance using the specified properties.
     * @function create
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {IUpdateBusinessProfileResponse=} [properties] Properties to set
     * @returns {UpdateBusinessProfileResponse} UpdateBusinessProfileResponse instance
     */
    UpdateBusinessProfileResponse.create = function create(properties) {
        return new UpdateBusinessProfileResponse(properties);
    };

    /**
     * Encodes the specified UpdateBusinessProfileResponse message. Does not implicitly {@link UpdateBusinessProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {IUpdateBusinessProfileResponse} message UpdateBusinessProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateBusinessProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ApiBusinessProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateBusinessProfileResponse message, length delimited. Does not implicitly {@link UpdateBusinessProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {IUpdateBusinessProfileResponse} message UpdateBusinessProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateBusinessProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateBusinessProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateBusinessProfileResponse} UpdateBusinessProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateBusinessProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateBusinessProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateBusinessProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateBusinessProfileResponse} UpdateBusinessProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateBusinessProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateBusinessProfileResponse message.
     * @function verify
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateBusinessProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateBusinessProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateBusinessProfileResponse} UpdateBusinessProfileResponse
     */
    UpdateBusinessProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateBusinessProfileResponse)
            return object;
        var message = new $root.UpdateBusinessProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".UpdateBusinessProfileResponse.profile: object expected");
            message.profile = $root.ApiBusinessProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateBusinessProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {UpdateBusinessProfileResponse} message UpdateBusinessProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateBusinessProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ApiBusinessProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this UpdateBusinessProfileResponse to JSON.
     * @function toJSON
     * @memberof UpdateBusinessProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateBusinessProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateBusinessProfileResponse
     * @function getTypeUrl
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateBusinessProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateBusinessProfileResponse";
    };

    return UpdateBusinessProfileResponse;
})();

$root.UpdateProviderProfileRequest = (function() {

    /**
     * Properties of an UpdateProviderProfileRequest.
     * @exports IUpdateProviderProfileRequest
     * @interface IUpdateProviderProfileRequest
     * @property {space.kenko.proto.IUserProfileProto|null} [profile] UpdateProviderProfileRequest profile
     */

    /**
     * Constructs a new UpdateProviderProfileRequest.
     * @exports UpdateProviderProfileRequest
     * @classdesc Represents an UpdateProviderProfileRequest.
     * @implements IUpdateProviderProfileRequest
     * @constructor
     * @param {IUpdateProviderProfileRequest=} [properties] Properties to set
     */
    function UpdateProviderProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateProviderProfileRequest profile.
     * @member {space.kenko.proto.IUserProfileProto|null|undefined} profile
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.profile = null;

    /**
     * Creates a new UpdateProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest=} [properties] Properties to set
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest instance
     */
    UpdateProviderProfileRequest.create = function create(properties) {
        return new UpdateProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified UpdateProviderProfileRequest message. Does not implicitly {@link UpdateProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest} message UpdateProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.space.kenko.proto.UserProfileProto.encode(message.profile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateProviderProfileRequest message, length delimited. Does not implicitly {@link UpdateProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest} message UpdateProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateProviderProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.profile = $root.space.kenko.proto.UserProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateProviderProfileRequest message.
     * @function verify
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.space.kenko.proto.UserProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     */
    UpdateProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateProviderProfileRequest)
            return object;
        var message = new $root.UpdateProviderProfileRequest();
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".UpdateProviderProfileRequest.profile: object expected");
            message.profile = $root.space.kenko.proto.UserProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {UpdateProviderProfileRequest} message UpdateProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateProviderProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.profile = null;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.space.kenko.proto.UserProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this UpdateProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof UpdateProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateProviderProfileRequest
     * @function getTypeUrl
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateProviderProfileRequest";
    };

    return UpdateProviderProfileRequest;
})();

$root.UpdateProviderProfileResponse = (function() {

    /**
     * Properties of an UpdateProviderProfileResponse.
     * @exports IUpdateProviderProfileResponse
     * @interface IUpdateProviderProfileResponse
     * @property {boolean|null} [okay] UpdateProviderProfileResponse okay
     * @property {Array.<space.kenko.proto.IErrorProto>|null} [fatalErrors] UpdateProviderProfileResponse fatalErrors
     * @property {IApiUserProto|null} [provider] UpdateProviderProfileResponse provider
     */

    /**
     * Constructs a new UpdateProviderProfileResponse.
     * @exports UpdateProviderProfileResponse
     * @classdesc Represents an UpdateProviderProfileResponse.
     * @implements IUpdateProviderProfileResponse
     * @constructor
     * @param {IUpdateProviderProfileResponse=} [properties] Properties to set
     */
    function UpdateProviderProfileResponse(properties) {
        this.fatalErrors = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.okay = false;

    /**
     * UpdateProviderProfileResponse fatalErrors.
     * @member {Array.<space.kenko.proto.IErrorProto>} fatalErrors
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.fatalErrors = $util.emptyArray;

    /**
     * UpdateProviderProfileResponse provider.
     * @member {IApiUserProto|null|undefined} provider
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.provider = null;

    /**
     * Creates a new UpdateProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse=} [properties] Properties to set
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse instance
     */
    UpdateProviderProfileResponse.create = function create(properties) {
        return new UpdateProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified UpdateProviderProfileResponse message. Does not implicitly {@link UpdateProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse} message UpdateProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.fatalErrors != null && message.fatalErrors.length)
            for (var i = 0; i < message.fatalErrors.length; ++i)
                $root.space.kenko.proto.ErrorProto.encode(message.fatalErrors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiUserProto.encode(message.provider, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateProviderProfileResponse message, length delimited. Does not implicitly {@link UpdateProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse} message UpdateProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateProviderProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.fatalErrors && message.fatalErrors.length))
                        message.fatalErrors = [];
                    message.fatalErrors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.provider = $root.ApiUserProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateProviderProfileResponse message.
     * @function verify
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.fatalErrors != null && message.hasOwnProperty("fatalErrors")) {
            if (!Array.isArray(message.fatalErrors))
                return "fatalErrors: array expected";
            for (var i = 0; i < message.fatalErrors.length; ++i) {
                var error = $root.space.kenko.proto.ErrorProto.verify(message.fatalErrors[i]);
                if (error)
                    return "fatalErrors." + error;
            }
        }
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiUserProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     */
    UpdateProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateProviderProfileResponse)
            return object;
        var message = new $root.UpdateProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.fatalErrors) {
            if (!Array.isArray(object.fatalErrors))
                throw TypeError(".UpdateProviderProfileResponse.fatalErrors: array expected");
            message.fatalErrors = [];
            for (var i = 0; i < object.fatalErrors.length; ++i) {
                if (typeof object.fatalErrors[i] !== "object")
                    throw TypeError(".UpdateProviderProfileResponse.fatalErrors: object expected");
                message.fatalErrors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.fatalErrors[i]);
            }
        }
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".UpdateProviderProfileResponse.provider: object expected");
            message.provider = $root.ApiUserProto.fromObject(object.provider);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {UpdateProviderProfileResponse} message UpdateProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.fatalErrors = [];
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.fatalErrors && message.fatalErrors.length) {
            object.fatalErrors = [];
            for (var j = 0; j < message.fatalErrors.length; ++j)
                object.fatalErrors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.fatalErrors[j], options);
        }
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiUserProto.toObject(message.provider, options);
        return object;
    };

    /**
     * Converts this UpdateProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof UpdateProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateProviderProfileResponse
     * @function getTypeUrl
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateProviderProfileResponse";
    };

    return UpdateProviderProfileResponse;
})();

$root.DebugDeleteProviderRequest = (function() {

    /**
     * Properties of a DebugDeleteProviderRequest.
     * @exports IDebugDeleteProviderRequest
     * @interface IDebugDeleteProviderRequest
     * @property {boolean|null} [resetOnly] DebugDeleteProviderRequest resetOnly
     * @property {boolean|null} [alphaAutoApprove] DebugDeleteProviderRequest alphaAutoApprove
     */

    /**
     * Constructs a new DebugDeleteProviderRequest.
     * @exports DebugDeleteProviderRequest
     * @classdesc Represents a DebugDeleteProviderRequest.
     * @implements IDebugDeleteProviderRequest
     * @constructor
     * @param {IDebugDeleteProviderRequest=} [properties] Properties to set
     */
    function DebugDeleteProviderRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DebugDeleteProviderRequest resetOnly.
     * @member {boolean} resetOnly
     * @memberof DebugDeleteProviderRequest
     * @instance
     */
    DebugDeleteProviderRequest.prototype.resetOnly = false;

    /**
     * DebugDeleteProviderRequest alphaAutoApprove.
     * @member {boolean} alphaAutoApprove
     * @memberof DebugDeleteProviderRequest
     * @instance
     */
    DebugDeleteProviderRequest.prototype.alphaAutoApprove = false;

    /**
     * Creates a new DebugDeleteProviderRequest instance using the specified properties.
     * @function create
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest=} [properties] Properties to set
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest instance
     */
    DebugDeleteProviderRequest.create = function create(properties) {
        return new DebugDeleteProviderRequest(properties);
    };

    /**
     * Encodes the specified DebugDeleteProviderRequest message. Does not implicitly {@link DebugDeleteProviderRequest.verify|verify} messages.
     * @function encode
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest} message DebugDeleteProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.resetOnly != null && Object.hasOwnProperty.call(message, "resetOnly"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.resetOnly);
        if (message.alphaAutoApprove != null && Object.hasOwnProperty.call(message, "alphaAutoApprove"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.alphaAutoApprove);
        return writer;
    };

    /**
     * Encodes the specified DebugDeleteProviderRequest message, length delimited. Does not implicitly {@link DebugDeleteProviderRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest} message DebugDeleteProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DebugDeleteProviderRequest message from the specified reader or buffer.
     * @function decode
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DebugDeleteProviderRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.resetOnly = reader.bool();
                    break;
                }
            case 2: {
                    message.alphaAutoApprove = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DebugDeleteProviderRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DebugDeleteProviderRequest message.
     * @function verify
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DebugDeleteProviderRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.resetOnly != null && message.hasOwnProperty("resetOnly"))
            if (typeof message.resetOnly !== "boolean")
                return "resetOnly: boolean expected";
        if (message.alphaAutoApprove != null && message.hasOwnProperty("alphaAutoApprove"))
            if (typeof message.alphaAutoApprove !== "boolean")
                return "alphaAutoApprove: boolean expected";
        return null;
    };

    /**
     * Creates a DebugDeleteProviderRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     */
    DebugDeleteProviderRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.DebugDeleteProviderRequest)
            return object;
        var message = new $root.DebugDeleteProviderRequest();
        if (object.resetOnly != null)
            message.resetOnly = Boolean(object.resetOnly);
        if (object.alphaAutoApprove != null)
            message.alphaAutoApprove = Boolean(object.alphaAutoApprove);
        return message;
    };

    /**
     * Creates a plain object from a DebugDeleteProviderRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {DebugDeleteProviderRequest} message DebugDeleteProviderRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DebugDeleteProviderRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.resetOnly = false;
            object.alphaAutoApprove = false;
        }
        if (message.resetOnly != null && message.hasOwnProperty("resetOnly"))
            object.resetOnly = message.resetOnly;
        if (message.alphaAutoApprove != null && message.hasOwnProperty("alphaAutoApprove"))
            object.alphaAutoApprove = message.alphaAutoApprove;
        return object;
    };

    /**
     * Converts this DebugDeleteProviderRequest to JSON.
     * @function toJSON
     * @memberof DebugDeleteProviderRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DebugDeleteProviderRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DebugDeleteProviderRequest
     * @function getTypeUrl
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DebugDeleteProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DebugDeleteProviderRequest";
    };

    return DebugDeleteProviderRequest;
})();

$root.DebugDeleteProviderResponse = (function() {

    /**
     * Properties of a DebugDeleteProviderResponse.
     * @exports IDebugDeleteProviderResponse
     * @interface IDebugDeleteProviderResponse
     * @property {boolean|null} [okay] DebugDeleteProviderResponse okay
     * @property {IApiUserProto|null} [provider] DebugDeleteProviderResponse provider
     * @property {IApiBusinessProto|null} [business] DebugDeleteProviderResponse business
     */

    /**
     * Constructs a new DebugDeleteProviderResponse.
     * @exports DebugDeleteProviderResponse
     * @classdesc Represents a DebugDeleteProviderResponse.
     * @implements IDebugDeleteProviderResponse
     * @constructor
     * @param {IDebugDeleteProviderResponse=} [properties] Properties to set
     */
    function DebugDeleteProviderResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DebugDeleteProviderResponse okay.
     * @member {boolean} okay
     * @memberof DebugDeleteProviderResponse
     * @instance
     */
    DebugDeleteProviderResponse.prototype.okay = false;

    /**
     * DebugDeleteProviderResponse provider.
     * @member {IApiUserProto|null|undefined} provider
     * @memberof DebugDeleteProviderResponse
     * @instance
     */
    DebugDeleteProviderResponse.prototype.provider = null;

    /**
     * DebugDeleteProviderResponse business.
     * @member {IApiBusinessProto|null|undefined} business
     * @memberof DebugDeleteProviderResponse
     * @instance
     */
    DebugDeleteProviderResponse.prototype.business = null;

    /**
     * Creates a new DebugDeleteProviderResponse instance using the specified properties.
     * @function create
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse=} [properties] Properties to set
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse instance
     */
    DebugDeleteProviderResponse.create = function create(properties) {
        return new DebugDeleteProviderResponse(properties);
    };

    /**
     * Encodes the specified DebugDeleteProviderResponse message. Does not implicitly {@link DebugDeleteProviderResponse.verify|verify} messages.
     * @function encode
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse} message DebugDeleteProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiUserProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.business != null && Object.hasOwnProperty.call(message, "business"))
            $root.ApiBusinessProto.encode(message.business, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified DebugDeleteProviderResponse message, length delimited. Does not implicitly {@link DebugDeleteProviderResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse} message DebugDeleteProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DebugDeleteProviderResponse message from the specified reader or buffer.
     * @function decode
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DebugDeleteProviderResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ApiUserProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.business = $root.ApiBusinessProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DebugDeleteProviderResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DebugDeleteProviderResponse message.
     * @function verify
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DebugDeleteProviderResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiUserProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        if (message.business != null && message.hasOwnProperty("business")) {
            var error = $root.ApiBusinessProto.verify(message.business);
            if (error)
                return "business." + error;
        }
        return null;
    };

    /**
     * Creates a DebugDeleteProviderResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     */
    DebugDeleteProviderResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.DebugDeleteProviderResponse)
            return object;
        var message = new $root.DebugDeleteProviderResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".DebugDeleteProviderResponse.provider: object expected");
            message.provider = $root.ApiUserProto.fromObject(object.provider);
        }
        if (object.business != null) {
            if (typeof object.business !== "object")
                throw TypeError(".DebugDeleteProviderResponse.business: object expected");
            message.business = $root.ApiBusinessProto.fromObject(object.business);
        }
        return message;
    };

    /**
     * Creates a plain object from a DebugDeleteProviderResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {DebugDeleteProviderResponse} message DebugDeleteProviderResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DebugDeleteProviderResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
            object.business = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiUserProto.toObject(message.provider, options);
        if (message.business != null && message.hasOwnProperty("business"))
            object.business = $root.ApiBusinessProto.toObject(message.business, options);
        return object;
    };

    /**
     * Converts this DebugDeleteProviderResponse to JSON.
     * @function toJSON
     * @memberof DebugDeleteProviderResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DebugDeleteProviderResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DebugDeleteProviderResponse
     * @function getTypeUrl
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DebugDeleteProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DebugDeleteProviderResponse";
    };

    return DebugDeleteProviderResponse;
})();

$root.CreateServiceRequest = (function() {

    /**
     * Properties of a CreateServiceRequest.
     * @exports ICreateServiceRequest
     * @interface ICreateServiceRequest
     * @property {space.kenko.proto.IServiceProto|null} [service] CreateServiceRequest service
     * @property {boolean|null} [isUpdate] CreateServiceRequest isUpdate
     */

    /**
     * Constructs a new CreateServiceRequest.
     * @exports CreateServiceRequest
     * @classdesc Represents a CreateServiceRequest.
     * @implements ICreateServiceRequest
     * @constructor
     * @param {ICreateServiceRequest=} [properties] Properties to set
     */
    function CreateServiceRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateServiceRequest service.
     * @member {space.kenko.proto.IServiceProto|null|undefined} service
     * @memberof CreateServiceRequest
     * @instance
     */
    CreateServiceRequest.prototype.service = null;

    /**
     * CreateServiceRequest isUpdate.
     * @member {boolean} isUpdate
     * @memberof CreateServiceRequest
     * @instance
     */
    CreateServiceRequest.prototype.isUpdate = false;

    /**
     * Creates a new CreateServiceRequest instance using the specified properties.
     * @function create
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest=} [properties] Properties to set
     * @returns {CreateServiceRequest} CreateServiceRequest instance
     */
    CreateServiceRequest.create = function create(properties) {
        return new CreateServiceRequest(properties);
    };

    /**
     * Encodes the specified CreateServiceRequest message. Does not implicitly {@link CreateServiceRequest.verify|verify} messages.
     * @function encode
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest} message CreateServiceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.service != null && Object.hasOwnProperty.call(message, "service"))
            $root.space.kenko.proto.ServiceProto.encode(message.service, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.isUpdate != null && Object.hasOwnProperty.call(message, "isUpdate"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isUpdate);
        return writer;
    };

    /**
     * Encodes the specified CreateServiceRequest message, length delimited. Does not implicitly {@link CreateServiceRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest} message CreateServiceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateServiceRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CreateServiceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateServiceRequest} CreateServiceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateServiceRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.service = $root.space.kenko.proto.ServiceProto.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.isUpdate = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateServiceRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateServiceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateServiceRequest} CreateServiceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateServiceRequest message.
     * @function verify
     * @memberof CreateServiceRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateServiceRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.service != null && message.hasOwnProperty("service")) {
            var error = $root.space.kenko.proto.ServiceProto.verify(message.service);
            if (error)
                return "service." + error;
        }
        if (message.isUpdate != null && message.hasOwnProperty("isUpdate"))
            if (typeof message.isUpdate !== "boolean")
                return "isUpdate: boolean expected";
        return null;
    };

    /**
     * Creates a CreateServiceRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateServiceRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateServiceRequest} CreateServiceRequest
     */
    CreateServiceRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateServiceRequest)
            return object;
        var message = new $root.CreateServiceRequest();
        if (object.service != null) {
            if (typeof object.service !== "object")
                throw TypeError(".CreateServiceRequest.service: object expected");
            message.service = $root.space.kenko.proto.ServiceProto.fromObject(object.service);
        }
        if (object.isUpdate != null)
            message.isUpdate = Boolean(object.isUpdate);
        return message;
    };

    /**
     * Creates a plain object from a CreateServiceRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateServiceRequest
     * @static
     * @param {CreateServiceRequest} message CreateServiceRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateServiceRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.service = null;
            object.isUpdate = false;
        }
        if (message.service != null && message.hasOwnProperty("service"))
            object.service = $root.space.kenko.proto.ServiceProto.toObject(message.service, options);
        if (message.isUpdate != null && message.hasOwnProperty("isUpdate"))
            object.isUpdate = message.isUpdate;
        return object;
    };

    /**
     * Converts this CreateServiceRequest to JSON.
     * @function toJSON
     * @memberof CreateServiceRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateServiceRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateServiceRequest
     * @function getTypeUrl
     * @memberof CreateServiceRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateServiceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateServiceRequest";
    };

    return CreateServiceRequest;
})();

$root.CreateServiceResponse = (function() {

    /**
     * Properties of a CreateServiceResponse.
     * @exports ICreateServiceResponse
     * @interface ICreateServiceResponse
     * @property {boolean|null} [okay] CreateServiceResponse okay
     * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] CreateServiceResponse errors
     * @property {IApiBusinessProfileProto|null} [newProfile] CreateServiceResponse newProfile
     */

    /**
     * Constructs a new CreateServiceResponse.
     * @exports CreateServiceResponse
     * @classdesc Represents a CreateServiceResponse.
     * @implements ICreateServiceResponse
     * @constructor
     * @param {ICreateServiceResponse=} [properties] Properties to set
     */
    function CreateServiceResponse(properties) {
        this.errors = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateServiceResponse okay.
     * @member {boolean} okay
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.okay = false;

    /**
     * CreateServiceResponse errors.
     * @member {Array.<space.kenko.proto.IErrorProto>} errors
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.errors = $util.emptyArray;

    /**
     * CreateServiceResponse newProfile.
     * @member {IApiBusinessProfileProto|null|undefined} newProfile
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.newProfile = null;

    /**
     * Creates a new CreateServiceResponse instance using the specified properties.
     * @function create
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse=} [properties] Properties to set
     * @returns {CreateServiceResponse} CreateServiceResponse instance
     */
    CreateServiceResponse.create = function create(properties) {
        return new CreateServiceResponse(properties);
    };

    /**
     * Encodes the specified CreateServiceResponse message. Does not implicitly {@link CreateServiceResponse.verify|verify} messages.
     * @function encode
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse} message CreateServiceResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.errors != null && message.errors.length)
            for (var i = 0; i < message.errors.length; ++i)
                $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ApiBusinessProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CreateServiceResponse message, length delimited. Does not implicitly {@link CreateServiceResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse} message CreateServiceResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateServiceResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CreateServiceResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateServiceResponse} CreateServiceResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateServiceResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.errors && message.errors.length))
                        message.errors = [];
                    message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.newProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateServiceResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateServiceResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateServiceResponse} CreateServiceResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateServiceResponse message.
     * @function verify
     * @memberof CreateServiceResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateServiceResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.errors != null && message.hasOwnProperty("errors")) {
            if (!Array.isArray(message.errors))
                return "errors: array expected";
            for (var i = 0; i < message.errors.length; ++i) {
                var error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                if (error)
                    return "errors." + error;
            }
        }
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        return null;
    };

    /**
     * Creates a CreateServiceResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateServiceResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateServiceResponse} CreateServiceResponse
     */
    CreateServiceResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateServiceResponse)
            return object;
        var message = new $root.CreateServiceResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.errors) {
            if (!Array.isArray(object.errors))
                throw TypeError(".CreateServiceResponse.errors: array expected");
            message.errors = [];
            for (var i = 0; i < object.errors.length; ++i) {
                if (typeof object.errors[i] !== "object")
                    throw TypeError(".CreateServiceResponse.errors: object expected");
                message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
            }
        }
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".CreateServiceResponse.newProfile: object expected");
            message.newProfile = $root.ApiBusinessProfileProto.fromObject(object.newProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a CreateServiceResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateServiceResponse
     * @static
     * @param {CreateServiceResponse} message CreateServiceResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateServiceResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.errors = [];
        if (options.defaults) {
            object.okay = false;
            object.newProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.errors && message.errors.length) {
            object.errors = [];
            for (var j = 0; j < message.errors.length; ++j)
                object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
        }
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ApiBusinessProfileProto.toObject(message.newProfile, options);
        return object;
    };

    /**
     * Converts this CreateServiceResponse to JSON.
     * @function toJSON
     * @memberof CreateServiceResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateServiceResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateServiceResponse
     * @function getTypeUrl
     * @memberof CreateServiceResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateServiceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateServiceResponse";
    };

    return CreateServiceResponse;
})();

$root.CreateOrUpdateTeamMemberRequest = (function() {

    /**
     * Properties of a CreateOrUpdateTeamMemberRequest.
     * @exports ICreateOrUpdateTeamMemberRequest
     * @interface ICreateOrUpdateTeamMemberRequest
     * @property {string|null} [providerId] CreateOrUpdateTeamMemberRequest providerId
     * @property {space.kenko.proto.IProviderBioProto|null} [bio] CreateOrUpdateTeamMemberRequest bio
     * @property {boolean|null} [isUpdate] CreateOrUpdateTeamMemberRequest isUpdate
     */

    /**
     * Constructs a new CreateOrUpdateTeamMemberRequest.
     * @exports CreateOrUpdateTeamMemberRequest
     * @classdesc Represents a CreateOrUpdateTeamMemberRequest.
     * @implements ICreateOrUpdateTeamMemberRequest
     * @constructor
     * @param {ICreateOrUpdateTeamMemberRequest=} [properties] Properties to set
     */
    function CreateOrUpdateTeamMemberRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateOrUpdateTeamMemberRequest providerId.
     * @member {string} providerId
     * @memberof CreateOrUpdateTeamMemberRequest
     * @instance
     */
    CreateOrUpdateTeamMemberRequest.prototype.providerId = "";

    /**
     * CreateOrUpdateTeamMemberRequest bio.
     * @member {space.kenko.proto.IProviderBioProto|null|undefined} bio
     * @memberof CreateOrUpdateTeamMemberRequest
     * @instance
     */
    CreateOrUpdateTeamMemberRequest.prototype.bio = null;

    /**
     * CreateOrUpdateTeamMemberRequest isUpdate.
     * @member {boolean} isUpdate
     * @memberof CreateOrUpdateTeamMemberRequest
     * @instance
     */
    CreateOrUpdateTeamMemberRequest.prototype.isUpdate = false;

    /**
     * Creates a new CreateOrUpdateTeamMemberRequest instance using the specified properties.
     * @function create
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {ICreateOrUpdateTeamMemberRequest=} [properties] Properties to set
     * @returns {CreateOrUpdateTeamMemberRequest} CreateOrUpdateTeamMemberRequest instance
     */
    CreateOrUpdateTeamMemberRequest.create = function create(properties) {
        return new CreateOrUpdateTeamMemberRequest(properties);
    };

    /**
     * Encodes the specified CreateOrUpdateTeamMemberRequest message. Does not implicitly {@link CreateOrUpdateTeamMemberRequest.verify|verify} messages.
     * @function encode
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {ICreateOrUpdateTeamMemberRequest} message CreateOrUpdateTeamMemberRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateOrUpdateTeamMemberRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.bio != null && Object.hasOwnProperty.call(message, "bio"))
            $root.space.kenko.proto.ProviderBioProto.encode(message.bio, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.isUpdate != null && Object.hasOwnProperty.call(message, "isUpdate"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isUpdate);
        return writer;
    };

    /**
     * Encodes the specified CreateOrUpdateTeamMemberRequest message, length delimited. Does not implicitly {@link CreateOrUpdateTeamMemberRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {ICreateOrUpdateTeamMemberRequest} message CreateOrUpdateTeamMemberRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateOrUpdateTeamMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateOrUpdateTeamMemberRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateOrUpdateTeamMemberRequest} CreateOrUpdateTeamMemberRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateOrUpdateTeamMemberRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateOrUpdateTeamMemberRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.bio = $root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.isUpdate = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateOrUpdateTeamMemberRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateOrUpdateTeamMemberRequest} CreateOrUpdateTeamMemberRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateOrUpdateTeamMemberRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateOrUpdateTeamMemberRequest message.
     * @function verify
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateOrUpdateTeamMemberRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.bio != null && message.hasOwnProperty("bio")) {
            var error = $root.space.kenko.proto.ProviderBioProto.verify(message.bio);
            if (error)
                return "bio." + error;
        }
        if (message.isUpdate != null && message.hasOwnProperty("isUpdate"))
            if (typeof message.isUpdate !== "boolean")
                return "isUpdate: boolean expected";
        return null;
    };

    /**
     * Creates a CreateOrUpdateTeamMemberRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateOrUpdateTeamMemberRequest} CreateOrUpdateTeamMemberRequest
     */
    CreateOrUpdateTeamMemberRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateOrUpdateTeamMemberRequest)
            return object;
        var message = new $root.CreateOrUpdateTeamMemberRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.bio != null) {
            if (typeof object.bio !== "object")
                throw TypeError(".CreateOrUpdateTeamMemberRequest.bio: object expected");
            message.bio = $root.space.kenko.proto.ProviderBioProto.fromObject(object.bio);
        }
        if (object.isUpdate != null)
            message.isUpdate = Boolean(object.isUpdate);
        return message;
    };

    /**
     * Creates a plain object from a CreateOrUpdateTeamMemberRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {CreateOrUpdateTeamMemberRequest} message CreateOrUpdateTeamMemberRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateOrUpdateTeamMemberRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.providerId = "";
            object.bio = null;
            object.isUpdate = false;
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.bio != null && message.hasOwnProperty("bio"))
            object.bio = $root.space.kenko.proto.ProviderBioProto.toObject(message.bio, options);
        if (message.isUpdate != null && message.hasOwnProperty("isUpdate"))
            object.isUpdate = message.isUpdate;
        return object;
    };

    /**
     * Converts this CreateOrUpdateTeamMemberRequest to JSON.
     * @function toJSON
     * @memberof CreateOrUpdateTeamMemberRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateOrUpdateTeamMemberRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateOrUpdateTeamMemberRequest
     * @function getTypeUrl
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateOrUpdateTeamMemberRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateOrUpdateTeamMemberRequest";
    };

    return CreateOrUpdateTeamMemberRequest;
})();

$root.CreateOrUpdateTeamMemberResponse = (function() {

    /**
     * Properties of a CreateOrUpdateTeamMemberResponse.
     * @exports ICreateOrUpdateTeamMemberResponse
     * @interface ICreateOrUpdateTeamMemberResponse
     * @property {boolean|null} [okay] CreateOrUpdateTeamMemberResponse okay
     * @property {Array.<space.kenko.proto.IErrorProto>|null} [fatalErrors] CreateOrUpdateTeamMemberResponse fatalErrors
     * @property {IApiBusinessProfileProto|null} [newProfile] CreateOrUpdateTeamMemberResponse newProfile
     * @property {boolean|null} [emailSendSuccess] CreateOrUpdateTeamMemberResponse emailSendSuccess
     */

    /**
     * Constructs a new CreateOrUpdateTeamMemberResponse.
     * @exports CreateOrUpdateTeamMemberResponse
     * @classdesc Represents a CreateOrUpdateTeamMemberResponse.
     * @implements ICreateOrUpdateTeamMemberResponse
     * @constructor
     * @param {ICreateOrUpdateTeamMemberResponse=} [properties] Properties to set
     */
    function CreateOrUpdateTeamMemberResponse(properties) {
        this.fatalErrors = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateOrUpdateTeamMemberResponse okay.
     * @member {boolean} okay
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     */
    CreateOrUpdateTeamMemberResponse.prototype.okay = false;

    /**
     * CreateOrUpdateTeamMemberResponse fatalErrors.
     * @member {Array.<space.kenko.proto.IErrorProto>} fatalErrors
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     */
    CreateOrUpdateTeamMemberResponse.prototype.fatalErrors = $util.emptyArray;

    /**
     * CreateOrUpdateTeamMemberResponse newProfile.
     * @member {IApiBusinessProfileProto|null|undefined} newProfile
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     */
    CreateOrUpdateTeamMemberResponse.prototype.newProfile = null;

    /**
     * CreateOrUpdateTeamMemberResponse emailSendSuccess.
     * @member {boolean} emailSendSuccess
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     */
    CreateOrUpdateTeamMemberResponse.prototype.emailSendSuccess = false;

    /**
     * Creates a new CreateOrUpdateTeamMemberResponse instance using the specified properties.
     * @function create
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {ICreateOrUpdateTeamMemberResponse=} [properties] Properties to set
     * @returns {CreateOrUpdateTeamMemberResponse} CreateOrUpdateTeamMemberResponse instance
     */
    CreateOrUpdateTeamMemberResponse.create = function create(properties) {
        return new CreateOrUpdateTeamMemberResponse(properties);
    };

    /**
     * Encodes the specified CreateOrUpdateTeamMemberResponse message. Does not implicitly {@link CreateOrUpdateTeamMemberResponse.verify|verify} messages.
     * @function encode
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {ICreateOrUpdateTeamMemberResponse} message CreateOrUpdateTeamMemberResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateOrUpdateTeamMemberResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.fatalErrors != null && message.fatalErrors.length)
            for (var i = 0; i < message.fatalErrors.length; ++i)
                $root.space.kenko.proto.ErrorProto.encode(message.fatalErrors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ApiBusinessProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.emailSendSuccess != null && Object.hasOwnProperty.call(message, "emailSendSuccess"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.emailSendSuccess);
        return writer;
    };

    /**
     * Encodes the specified CreateOrUpdateTeamMemberResponse message, length delimited. Does not implicitly {@link CreateOrUpdateTeamMemberResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {ICreateOrUpdateTeamMemberResponse} message CreateOrUpdateTeamMemberResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateOrUpdateTeamMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateOrUpdateTeamMemberResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateOrUpdateTeamMemberResponse} CreateOrUpdateTeamMemberResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateOrUpdateTeamMemberResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateOrUpdateTeamMemberResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.fatalErrors && message.fatalErrors.length))
                        message.fatalErrors = [];
                    message.fatalErrors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.newProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.emailSendSuccess = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateOrUpdateTeamMemberResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateOrUpdateTeamMemberResponse} CreateOrUpdateTeamMemberResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateOrUpdateTeamMemberResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateOrUpdateTeamMemberResponse message.
     * @function verify
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateOrUpdateTeamMemberResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.fatalErrors != null && message.hasOwnProperty("fatalErrors")) {
            if (!Array.isArray(message.fatalErrors))
                return "fatalErrors: array expected";
            for (var i = 0; i < message.fatalErrors.length; ++i) {
                var error = $root.space.kenko.proto.ErrorProto.verify(message.fatalErrors[i]);
                if (error)
                    return "fatalErrors." + error;
            }
        }
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        if (message.emailSendSuccess != null && message.hasOwnProperty("emailSendSuccess"))
            if (typeof message.emailSendSuccess !== "boolean")
                return "emailSendSuccess: boolean expected";
        return null;
    };

    /**
     * Creates a CreateOrUpdateTeamMemberResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateOrUpdateTeamMemberResponse} CreateOrUpdateTeamMemberResponse
     */
    CreateOrUpdateTeamMemberResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateOrUpdateTeamMemberResponse)
            return object;
        var message = new $root.CreateOrUpdateTeamMemberResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.fatalErrors) {
            if (!Array.isArray(object.fatalErrors))
                throw TypeError(".CreateOrUpdateTeamMemberResponse.fatalErrors: array expected");
            message.fatalErrors = [];
            for (var i = 0; i < object.fatalErrors.length; ++i) {
                if (typeof object.fatalErrors[i] !== "object")
                    throw TypeError(".CreateOrUpdateTeamMemberResponse.fatalErrors: object expected");
                message.fatalErrors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.fatalErrors[i]);
            }
        }
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".CreateOrUpdateTeamMemberResponse.newProfile: object expected");
            message.newProfile = $root.ApiBusinessProfileProto.fromObject(object.newProfile);
        }
        if (object.emailSendSuccess != null)
            message.emailSendSuccess = Boolean(object.emailSendSuccess);
        return message;
    };

    /**
     * Creates a plain object from a CreateOrUpdateTeamMemberResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {CreateOrUpdateTeamMemberResponse} message CreateOrUpdateTeamMemberResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateOrUpdateTeamMemberResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.fatalErrors = [];
        if (options.defaults) {
            object.okay = false;
            object.newProfile = null;
            object.emailSendSuccess = false;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.fatalErrors && message.fatalErrors.length) {
            object.fatalErrors = [];
            for (var j = 0; j < message.fatalErrors.length; ++j)
                object.fatalErrors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.fatalErrors[j], options);
        }
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ApiBusinessProfileProto.toObject(message.newProfile, options);
        if (message.emailSendSuccess != null && message.hasOwnProperty("emailSendSuccess"))
            object.emailSendSuccess = message.emailSendSuccess;
        return object;
    };

    /**
     * Converts this CreateOrUpdateTeamMemberResponse to JSON.
     * @function toJSON
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateOrUpdateTeamMemberResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateOrUpdateTeamMemberResponse
     * @function getTypeUrl
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateOrUpdateTeamMemberResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateOrUpdateTeamMemberResponse";
    };

    return CreateOrUpdateTeamMemberResponse;
})();

$root.EnableDisableTeamMemberRequest = (function() {

    /**
     * Properties of an EnableDisableTeamMemberRequest.
     * @exports IEnableDisableTeamMemberRequest
     * @interface IEnableDisableTeamMemberRequest
     * @property {string|null} [providerId] EnableDisableTeamMemberRequest providerId
     * @property {boolean|null} [isDisabled] EnableDisableTeamMemberRequest isDisabled
     */

    /**
     * Constructs a new EnableDisableTeamMemberRequest.
     * @exports EnableDisableTeamMemberRequest
     * @classdesc Represents an EnableDisableTeamMemberRequest.
     * @implements IEnableDisableTeamMemberRequest
     * @constructor
     * @param {IEnableDisableTeamMemberRequest=} [properties] Properties to set
     */
    function EnableDisableTeamMemberRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnableDisableTeamMemberRequest providerId.
     * @member {string} providerId
     * @memberof EnableDisableTeamMemberRequest
     * @instance
     */
    EnableDisableTeamMemberRequest.prototype.providerId = "";

    /**
     * EnableDisableTeamMemberRequest isDisabled.
     * @member {boolean} isDisabled
     * @memberof EnableDisableTeamMemberRequest
     * @instance
     */
    EnableDisableTeamMemberRequest.prototype.isDisabled = false;

    /**
     * Creates a new EnableDisableTeamMemberRequest instance using the specified properties.
     * @function create
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {IEnableDisableTeamMemberRequest=} [properties] Properties to set
     * @returns {EnableDisableTeamMemberRequest} EnableDisableTeamMemberRequest instance
     */
    EnableDisableTeamMemberRequest.create = function create(properties) {
        return new EnableDisableTeamMemberRequest(properties);
    };

    /**
     * Encodes the specified EnableDisableTeamMemberRequest message. Does not implicitly {@link EnableDisableTeamMemberRequest.verify|verify} messages.
     * @function encode
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {IEnableDisableTeamMemberRequest} message EnableDisableTeamMemberRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnableDisableTeamMemberRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDisabled);
        return writer;
    };

    /**
     * Encodes the specified EnableDisableTeamMemberRequest message, length delimited. Does not implicitly {@link EnableDisableTeamMemberRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {IEnableDisableTeamMemberRequest} message EnableDisableTeamMemberRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnableDisableTeamMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnableDisableTeamMemberRequest message from the specified reader or buffer.
     * @function decode
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {EnableDisableTeamMemberRequest} EnableDisableTeamMemberRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnableDisableTeamMemberRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.EnableDisableTeamMemberRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.isDisabled = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an EnableDisableTeamMemberRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {EnableDisableTeamMemberRequest} EnableDisableTeamMemberRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnableDisableTeamMemberRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnableDisableTeamMemberRequest message.
     * @function verify
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnableDisableTeamMemberRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
            if (typeof message.isDisabled !== "boolean")
                return "isDisabled: boolean expected";
        return null;
    };

    /**
     * Creates an EnableDisableTeamMemberRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {EnableDisableTeamMemberRequest} EnableDisableTeamMemberRequest
     */
    EnableDisableTeamMemberRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.EnableDisableTeamMemberRequest)
            return object;
        var message = new $root.EnableDisableTeamMemberRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.isDisabled != null)
            message.isDisabled = Boolean(object.isDisabled);
        return message;
    };

    /**
     * Creates a plain object from an EnableDisableTeamMemberRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {EnableDisableTeamMemberRequest} message EnableDisableTeamMemberRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnableDisableTeamMemberRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.providerId = "";
            object.isDisabled = false;
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
            object.isDisabled = message.isDisabled;
        return object;
    };

    /**
     * Converts this EnableDisableTeamMemberRequest to JSON.
     * @function toJSON
     * @memberof EnableDisableTeamMemberRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnableDisableTeamMemberRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnableDisableTeamMemberRequest
     * @function getTypeUrl
     * @memberof EnableDisableTeamMemberRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnableDisableTeamMemberRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/EnableDisableTeamMemberRequest";
    };

    return EnableDisableTeamMemberRequest;
})();

$root.EnableDisableTeamMemberResponse = (function() {

    /**
     * Properties of an EnableDisableTeamMemberResponse.
     * @exports IEnableDisableTeamMemberResponse
     * @interface IEnableDisableTeamMemberResponse
     * @property {boolean|null} [okay] EnableDisableTeamMemberResponse okay
     * @property {IApiBusinessProfileProto|null} [newProfile] EnableDisableTeamMemberResponse newProfile
     */

    /**
     * Constructs a new EnableDisableTeamMemberResponse.
     * @exports EnableDisableTeamMemberResponse
     * @classdesc Represents an EnableDisableTeamMemberResponse.
     * @implements IEnableDisableTeamMemberResponse
     * @constructor
     * @param {IEnableDisableTeamMemberResponse=} [properties] Properties to set
     */
    function EnableDisableTeamMemberResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnableDisableTeamMemberResponse okay.
     * @member {boolean} okay
     * @memberof EnableDisableTeamMemberResponse
     * @instance
     */
    EnableDisableTeamMemberResponse.prototype.okay = false;

    /**
     * EnableDisableTeamMemberResponse newProfile.
     * @member {IApiBusinessProfileProto|null|undefined} newProfile
     * @memberof EnableDisableTeamMemberResponse
     * @instance
     */
    EnableDisableTeamMemberResponse.prototype.newProfile = null;

    /**
     * Creates a new EnableDisableTeamMemberResponse instance using the specified properties.
     * @function create
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {IEnableDisableTeamMemberResponse=} [properties] Properties to set
     * @returns {EnableDisableTeamMemberResponse} EnableDisableTeamMemberResponse instance
     */
    EnableDisableTeamMemberResponse.create = function create(properties) {
        return new EnableDisableTeamMemberResponse(properties);
    };

    /**
     * Encodes the specified EnableDisableTeamMemberResponse message. Does not implicitly {@link EnableDisableTeamMemberResponse.verify|verify} messages.
     * @function encode
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {IEnableDisableTeamMemberResponse} message EnableDisableTeamMemberResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnableDisableTeamMemberResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ApiBusinessProfileProto.encode(message.newProfile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified EnableDisableTeamMemberResponse message, length delimited. Does not implicitly {@link EnableDisableTeamMemberResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {IEnableDisableTeamMemberResponse} message EnableDisableTeamMemberResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnableDisableTeamMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnableDisableTeamMemberResponse message from the specified reader or buffer.
     * @function decode
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {EnableDisableTeamMemberResponse} EnableDisableTeamMemberResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnableDisableTeamMemberResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.EnableDisableTeamMemberResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.newProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an EnableDisableTeamMemberResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {EnableDisableTeamMemberResponse} EnableDisableTeamMemberResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnableDisableTeamMemberResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnableDisableTeamMemberResponse message.
     * @function verify
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnableDisableTeamMemberResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        return null;
    };

    /**
     * Creates an EnableDisableTeamMemberResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {EnableDisableTeamMemberResponse} EnableDisableTeamMemberResponse
     */
    EnableDisableTeamMemberResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.EnableDisableTeamMemberResponse)
            return object;
        var message = new $root.EnableDisableTeamMemberResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".EnableDisableTeamMemberResponse.newProfile: object expected");
            message.newProfile = $root.ApiBusinessProfileProto.fromObject(object.newProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an EnableDisableTeamMemberResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {EnableDisableTeamMemberResponse} message EnableDisableTeamMemberResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnableDisableTeamMemberResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.newProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ApiBusinessProfileProto.toObject(message.newProfile, options);
        return object;
    };

    /**
     * Converts this EnableDisableTeamMemberResponse to JSON.
     * @function toJSON
     * @memberof EnableDisableTeamMemberResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnableDisableTeamMemberResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnableDisableTeamMemberResponse
     * @function getTypeUrl
     * @memberof EnableDisableTeamMemberResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnableDisableTeamMemberResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/EnableDisableTeamMemberResponse";
    };

    return EnableDisableTeamMemberResponse;
})();

$root.AcceptProviderInviteRequest = (function() {

    /**
     * Properties of an AcceptProviderInviteRequest.
     * @exports IAcceptProviderInviteRequest
     * @interface IAcceptProviderInviteRequest
     * @property {string|null} [inviteCode] AcceptProviderInviteRequest inviteCode
     * @property {boolean|null} [accepted] AcceptProviderInviteRequest accepted
     */

    /**
     * Constructs a new AcceptProviderInviteRequest.
     * @exports AcceptProviderInviteRequest
     * @classdesc Represents an AcceptProviderInviteRequest.
     * @implements IAcceptProviderInviteRequest
     * @constructor
     * @param {IAcceptProviderInviteRequest=} [properties] Properties to set
     */
    function AcceptProviderInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptProviderInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof AcceptProviderInviteRequest
     * @instance
     */
    AcceptProviderInviteRequest.prototype.inviteCode = "";

    /**
     * AcceptProviderInviteRequest accepted.
     * @member {boolean} accepted
     * @memberof AcceptProviderInviteRequest
     * @instance
     */
    AcceptProviderInviteRequest.prototype.accepted = false;

    /**
     * Creates a new AcceptProviderInviteRequest instance using the specified properties.
     * @function create
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest=} [properties] Properties to set
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest instance
     */
    AcceptProviderInviteRequest.create = function create(properties) {
        return new AcceptProviderInviteRequest(properties);
    };

    /**
     * Encodes the specified AcceptProviderInviteRequest message. Does not implicitly {@link AcceptProviderInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest} message AcceptProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
        if (message.accepted != null && Object.hasOwnProperty.call(message, "accepted"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.accepted);
        return writer;
    };

    /**
     * Encodes the specified AcceptProviderInviteRequest message, length delimited. Does not implicitly {@link AcceptProviderInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest} message AcceptProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptProviderInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptProviderInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.inviteCode = reader.string();
                    break;
                }
            case 2: {
                    message.accepted = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptProviderInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptProviderInviteRequest message.
     * @function verify
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptProviderInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            if (typeof message.accepted !== "boolean")
                return "accepted: boolean expected";
        return null;
    };

    /**
     * Creates an AcceptProviderInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     */
    AcceptProviderInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptProviderInviteRequest)
            return object;
        var message = new $root.AcceptProviderInviteRequest();
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        if (object.accepted != null)
            message.accepted = Boolean(object.accepted);
        return message;
    };

    /**
     * Creates a plain object from an AcceptProviderInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {AcceptProviderInviteRequest} message AcceptProviderInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptProviderInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.inviteCode = "";
            object.accepted = false;
        }
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            object.accepted = message.accepted;
        return object;
    };

    /**
     * Converts this AcceptProviderInviteRequest to JSON.
     * @function toJSON
     * @memberof AcceptProviderInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptProviderInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptProviderInviteRequest
     * @function getTypeUrl
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptProviderInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptProviderInviteRequest";
    };

    return AcceptProviderInviteRequest;
})();

$root.AcceptProviderInviteResponse = (function() {

    /**
     * Properties of an AcceptProviderInviteResponse.
     * @exports IAcceptProviderInviteResponse
     * @interface IAcceptProviderInviteResponse
     * @property {boolean|null} [okay] AcceptProviderInviteResponse okay
     * @property {IApiUserProto|null} [provider] AcceptProviderInviteResponse provider
     * @property {IApiBusinessProto|null} [business] AcceptProviderInviteResponse business
     */

    /**
     * Constructs a new AcceptProviderInviteResponse.
     * @exports AcceptProviderInviteResponse
     * @classdesc Represents an AcceptProviderInviteResponse.
     * @implements IAcceptProviderInviteResponse
     * @constructor
     * @param {IAcceptProviderInviteResponse=} [properties] Properties to set
     */
    function AcceptProviderInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptProviderInviteResponse okay.
     * @member {boolean} okay
     * @memberof AcceptProviderInviteResponse
     * @instance
     */
    AcceptProviderInviteResponse.prototype.okay = false;

    /**
     * AcceptProviderInviteResponse provider.
     * @member {IApiUserProto|null|undefined} provider
     * @memberof AcceptProviderInviteResponse
     * @instance
     */
    AcceptProviderInviteResponse.prototype.provider = null;

    /**
     * AcceptProviderInviteResponse business.
     * @member {IApiBusinessProto|null|undefined} business
     * @memberof AcceptProviderInviteResponse
     * @instance
     */
    AcceptProviderInviteResponse.prototype.business = null;

    /**
     * Creates a new AcceptProviderInviteResponse instance using the specified properties.
     * @function create
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse=} [properties] Properties to set
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse instance
     */
    AcceptProviderInviteResponse.create = function create(properties) {
        return new AcceptProviderInviteResponse(properties);
    };

    /**
     * Encodes the specified AcceptProviderInviteResponse message. Does not implicitly {@link AcceptProviderInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse} message AcceptProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiUserProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.business != null && Object.hasOwnProperty.call(message, "business"))
            $root.ApiBusinessProto.encode(message.business, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AcceptProviderInviteResponse message, length delimited. Does not implicitly {@link AcceptProviderInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse} message AcceptProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptProviderInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptProviderInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ApiUserProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.business = $root.ApiBusinessProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptProviderInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptProviderInviteResponse message.
     * @function verify
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptProviderInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiUserProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        if (message.business != null && message.hasOwnProperty("business")) {
            var error = $root.ApiBusinessProto.verify(message.business);
            if (error)
                return "business." + error;
        }
        return null;
    };

    /**
     * Creates an AcceptProviderInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     */
    AcceptProviderInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptProviderInviteResponse)
            return object;
        var message = new $root.AcceptProviderInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".AcceptProviderInviteResponse.provider: object expected");
            message.provider = $root.ApiUserProto.fromObject(object.provider);
        }
        if (object.business != null) {
            if (typeof object.business !== "object")
                throw TypeError(".AcceptProviderInviteResponse.business: object expected");
            message.business = $root.ApiBusinessProto.fromObject(object.business);
        }
        return message;
    };

    /**
     * Creates a plain object from an AcceptProviderInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {AcceptProviderInviteResponse} message AcceptProviderInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptProviderInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
            object.business = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiUserProto.toObject(message.provider, options);
        if (message.business != null && message.hasOwnProperty("business"))
            object.business = $root.ApiBusinessProto.toObject(message.business, options);
        return object;
    };

    /**
     * Converts this AcceptProviderInviteResponse to JSON.
     * @function toJSON
     * @memberof AcceptProviderInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptProviderInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptProviderInviteResponse
     * @function getTypeUrl
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptProviderInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptProviderInviteResponse";
    };

    return AcceptProviderInviteResponse;
})();

$root.CheckProviderInviteRequest = (function() {

    /**
     * Properties of a CheckProviderInviteRequest.
     * @exports ICheckProviderInviteRequest
     * @interface ICheckProviderInviteRequest
     * @property {string|null} [networkId] CheckProviderInviteRequest networkId
     * @property {string|null} [inviteCode] CheckProviderInviteRequest inviteCode
     */

    /**
     * Constructs a new CheckProviderInviteRequest.
     * @exports CheckProviderInviteRequest
     * @classdesc Represents a CheckProviderInviteRequest.
     * @implements ICheckProviderInviteRequest
     * @constructor
     * @param {ICheckProviderInviteRequest=} [properties] Properties to set
     */
    function CheckProviderInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckProviderInviteRequest networkId.
     * @member {string} networkId
     * @memberof CheckProviderInviteRequest
     * @instance
     */
    CheckProviderInviteRequest.prototype.networkId = "";

    /**
     * CheckProviderInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof CheckProviderInviteRequest
     * @instance
     */
    CheckProviderInviteRequest.prototype.inviteCode = "";

    /**
     * Creates a new CheckProviderInviteRequest instance using the specified properties.
     * @function create
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest=} [properties] Properties to set
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest instance
     */
    CheckProviderInviteRequest.create = function create(properties) {
        return new CheckProviderInviteRequest(properties);
    };

    /**
     * Encodes the specified CheckProviderInviteRequest message. Does not implicitly {@link CheckProviderInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest} message CheckProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.networkId != null && Object.hasOwnProperty.call(message, "networkId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.networkId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified CheckProviderInviteRequest message, length delimited. Does not implicitly {@link CheckProviderInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest} message CheckProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckProviderInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckProviderInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.networkId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckProviderInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckProviderInviteRequest message.
     * @function verify
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckProviderInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            if (!$util.isString(message.networkId))
                return "networkId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a CheckProviderInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     */
    CheckProviderInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckProviderInviteRequest)
            return object;
        var message = new $root.CheckProviderInviteRequest();
        if (object.networkId != null)
            message.networkId = String(object.networkId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a CheckProviderInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {CheckProviderInviteRequest} message CheckProviderInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckProviderInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.networkId = "";
            object.inviteCode = "";
        }
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            object.networkId = message.networkId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this CheckProviderInviteRequest to JSON.
     * @function toJSON
     * @memberof CheckProviderInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckProviderInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckProviderInviteRequest
     * @function getTypeUrl
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckProviderInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckProviderInviteRequest";
    };

    return CheckProviderInviteRequest;
})();

$root.CheckProviderInviteResponse = (function() {

    /**
     * Properties of a CheckProviderInviteResponse.
     * @exports ICheckProviderInviteResponse
     * @interface ICheckProviderInviteResponse
     * @property {boolean|null} [okay] CheckProviderInviteResponse okay
     */

    /**
     * Constructs a new CheckProviderInviteResponse.
     * @exports CheckProviderInviteResponse
     * @classdesc Represents a CheckProviderInviteResponse.
     * @implements ICheckProviderInviteResponse
     * @constructor
     * @param {ICheckProviderInviteResponse=} [properties] Properties to set
     */
    function CheckProviderInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckProviderInviteResponse okay.
     * @member {boolean} okay
     * @memberof CheckProviderInviteResponse
     * @instance
     */
    CheckProviderInviteResponse.prototype.okay = false;

    /**
     * Creates a new CheckProviderInviteResponse instance using the specified properties.
     * @function create
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse=} [properties] Properties to set
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse instance
     */
    CheckProviderInviteResponse.create = function create(properties) {
        return new CheckProviderInviteResponse(properties);
    };

    /**
     * Encodes the specified CheckProviderInviteResponse message. Does not implicitly {@link CheckProviderInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse} message CheckProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        return writer;
    };

    /**
     * Encodes the specified CheckProviderInviteResponse message, length delimited. Does not implicitly {@link CheckProviderInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse} message CheckProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckProviderInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckProviderInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckProviderInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckProviderInviteResponse message.
     * @function verify
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckProviderInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        return null;
    };

    /**
     * Creates a CheckProviderInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     */
    CheckProviderInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckProviderInviteResponse)
            return object;
        var message = new $root.CheckProviderInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        return message;
    };

    /**
     * Creates a plain object from a CheckProviderInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {CheckProviderInviteResponse} message CheckProviderInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckProviderInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        return object;
    };

    /**
     * Converts this CheckProviderInviteResponse to JSON.
     * @function toJSON
     * @memberof CheckProviderInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckProviderInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckProviderInviteResponse
     * @function getTypeUrl
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckProviderInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckProviderInviteResponse";
    };

    return CheckProviderInviteResponse;
})();

$root.GetWebsiteVersionsRequest = (function() {

    /**
     * Properties of a GetWebsiteVersionsRequest.
     * @exports IGetWebsiteVersionsRequest
     * @interface IGetWebsiteVersionsRequest
     */

    /**
     * Constructs a new GetWebsiteVersionsRequest.
     * @exports GetWebsiteVersionsRequest
     * @classdesc Represents a GetWebsiteVersionsRequest.
     * @implements IGetWebsiteVersionsRequest
     * @constructor
     * @param {IGetWebsiteVersionsRequest=} [properties] Properties to set
     */
    function GetWebsiteVersionsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetWebsiteVersionsRequest instance using the specified properties.
     * @function create
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {IGetWebsiteVersionsRequest=} [properties] Properties to set
     * @returns {GetWebsiteVersionsRequest} GetWebsiteVersionsRequest instance
     */
    GetWebsiteVersionsRequest.create = function create(properties) {
        return new GetWebsiteVersionsRequest(properties);
    };

    /**
     * Encodes the specified GetWebsiteVersionsRequest message. Does not implicitly {@link GetWebsiteVersionsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {IGetWebsiteVersionsRequest} message GetWebsiteVersionsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWebsiteVersionsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetWebsiteVersionsRequest message, length delimited. Does not implicitly {@link GetWebsiteVersionsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {IGetWebsiteVersionsRequest} message GetWebsiteVersionsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWebsiteVersionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWebsiteVersionsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetWebsiteVersionsRequest} GetWebsiteVersionsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWebsiteVersionsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetWebsiteVersionsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetWebsiteVersionsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetWebsiteVersionsRequest} GetWebsiteVersionsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWebsiteVersionsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWebsiteVersionsRequest message.
     * @function verify
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWebsiteVersionsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetWebsiteVersionsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetWebsiteVersionsRequest} GetWebsiteVersionsRequest
     */
    GetWebsiteVersionsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetWebsiteVersionsRequest)
            return object;
        return new $root.GetWebsiteVersionsRequest();
    };

    /**
     * Creates a plain object from a GetWebsiteVersionsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {GetWebsiteVersionsRequest} message GetWebsiteVersionsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWebsiteVersionsRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetWebsiteVersionsRequest to JSON.
     * @function toJSON
     * @memberof GetWebsiteVersionsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWebsiteVersionsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWebsiteVersionsRequest
     * @function getTypeUrl
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWebsiteVersionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetWebsiteVersionsRequest";
    };

    return GetWebsiteVersionsRequest;
})();

$root.GetWebsiteVersionsResponse = (function() {

    /**
     * Properties of a GetWebsiteVersionsResponse.
     * @exports IGetWebsiteVersionsResponse
     * @interface IGetWebsiteVersionsResponse
     * @property {boolean|null} [okay] GetWebsiteVersionsResponse okay
     * @property {Array.<IApiBusinessProfileProto>|null} [profiles] GetWebsiteVersionsResponse profiles
     */

    /**
     * Constructs a new GetWebsiteVersionsResponse.
     * @exports GetWebsiteVersionsResponse
     * @classdesc Represents a GetWebsiteVersionsResponse.
     * @implements IGetWebsiteVersionsResponse
     * @constructor
     * @param {IGetWebsiteVersionsResponse=} [properties] Properties to set
     */
    function GetWebsiteVersionsResponse(properties) {
        this.profiles = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetWebsiteVersionsResponse okay.
     * @member {boolean} okay
     * @memberof GetWebsiteVersionsResponse
     * @instance
     */
    GetWebsiteVersionsResponse.prototype.okay = false;

    /**
     * GetWebsiteVersionsResponse profiles.
     * @member {Array.<IApiBusinessProfileProto>} profiles
     * @memberof GetWebsiteVersionsResponse
     * @instance
     */
    GetWebsiteVersionsResponse.prototype.profiles = $util.emptyArray;

    /**
     * Creates a new GetWebsiteVersionsResponse instance using the specified properties.
     * @function create
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {IGetWebsiteVersionsResponse=} [properties] Properties to set
     * @returns {GetWebsiteVersionsResponse} GetWebsiteVersionsResponse instance
     */
    GetWebsiteVersionsResponse.create = function create(properties) {
        return new GetWebsiteVersionsResponse(properties);
    };

    /**
     * Encodes the specified GetWebsiteVersionsResponse message. Does not implicitly {@link GetWebsiteVersionsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {IGetWebsiteVersionsResponse} message GetWebsiteVersionsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWebsiteVersionsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profiles != null && message.profiles.length)
            for (var i = 0; i < message.profiles.length; ++i)
                $root.ApiBusinessProfileProto.encode(message.profiles[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetWebsiteVersionsResponse message, length delimited. Does not implicitly {@link GetWebsiteVersionsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {IGetWebsiteVersionsResponse} message GetWebsiteVersionsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWebsiteVersionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWebsiteVersionsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetWebsiteVersionsResponse} GetWebsiteVersionsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWebsiteVersionsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetWebsiteVersionsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.profiles && message.profiles.length))
                        message.profiles = [];
                    message.profiles.push($root.ApiBusinessProfileProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetWebsiteVersionsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetWebsiteVersionsResponse} GetWebsiteVersionsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWebsiteVersionsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWebsiteVersionsResponse message.
     * @function verify
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWebsiteVersionsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profiles != null && message.hasOwnProperty("profiles")) {
            if (!Array.isArray(message.profiles))
                return "profiles: array expected";
            for (var i = 0; i < message.profiles.length; ++i) {
                var error = $root.ApiBusinessProfileProto.verify(message.profiles[i]);
                if (error)
                    return "profiles." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetWebsiteVersionsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetWebsiteVersionsResponse} GetWebsiteVersionsResponse
     */
    GetWebsiteVersionsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetWebsiteVersionsResponse)
            return object;
        var message = new $root.GetWebsiteVersionsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profiles) {
            if (!Array.isArray(object.profiles))
                throw TypeError(".GetWebsiteVersionsResponse.profiles: array expected");
            message.profiles = [];
            for (var i = 0; i < object.profiles.length; ++i) {
                if (typeof object.profiles[i] !== "object")
                    throw TypeError(".GetWebsiteVersionsResponse.profiles: object expected");
                message.profiles[i] = $root.ApiBusinessProfileProto.fromObject(object.profiles[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetWebsiteVersionsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {GetWebsiteVersionsResponse} message GetWebsiteVersionsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWebsiteVersionsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.profiles = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profiles && message.profiles.length) {
            object.profiles = [];
            for (var j = 0; j < message.profiles.length; ++j)
                object.profiles[j] = $root.ApiBusinessProfileProto.toObject(message.profiles[j], options);
        }
        return object;
    };

    /**
     * Converts this GetWebsiteVersionsResponse to JSON.
     * @function toJSON
     * @memberof GetWebsiteVersionsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWebsiteVersionsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWebsiteVersionsResponse
     * @function getTypeUrl
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWebsiteVersionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetWebsiteVersionsResponse";
    };

    return GetWebsiteVersionsResponse;
})();

$root.SubmitProfileForReviewRequest = (function() {

    /**
     * Properties of a SubmitProfileForReviewRequest.
     * @exports ISubmitProfileForReviewRequest
     * @interface ISubmitProfileForReviewRequest
     */

    /**
     * Constructs a new SubmitProfileForReviewRequest.
     * @exports SubmitProfileForReviewRequest
     * @classdesc Represents a SubmitProfileForReviewRequest.
     * @implements ISubmitProfileForReviewRequest
     * @constructor
     * @param {ISubmitProfileForReviewRequest=} [properties] Properties to set
     */
    function SubmitProfileForReviewRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new SubmitProfileForReviewRequest instance using the specified properties.
     * @function create
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {ISubmitProfileForReviewRequest=} [properties] Properties to set
     * @returns {SubmitProfileForReviewRequest} SubmitProfileForReviewRequest instance
     */
    SubmitProfileForReviewRequest.create = function create(properties) {
        return new SubmitProfileForReviewRequest(properties);
    };

    /**
     * Encodes the specified SubmitProfileForReviewRequest message. Does not implicitly {@link SubmitProfileForReviewRequest.verify|verify} messages.
     * @function encode
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {ISubmitProfileForReviewRequest} message SubmitProfileForReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubmitProfileForReviewRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified SubmitProfileForReviewRequest message, length delimited. Does not implicitly {@link SubmitProfileForReviewRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {ISubmitProfileForReviewRequest} message SubmitProfileForReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubmitProfileForReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubmitProfileForReviewRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubmitProfileForReviewRequest} SubmitProfileForReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubmitProfileForReviewRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubmitProfileForReviewRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubmitProfileForReviewRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubmitProfileForReviewRequest} SubmitProfileForReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubmitProfileForReviewRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubmitProfileForReviewRequest message.
     * @function verify
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubmitProfileForReviewRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a SubmitProfileForReviewRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubmitProfileForReviewRequest} SubmitProfileForReviewRequest
     */
    SubmitProfileForReviewRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SubmitProfileForReviewRequest)
            return object;
        return new $root.SubmitProfileForReviewRequest();
    };

    /**
     * Creates a plain object from a SubmitProfileForReviewRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {SubmitProfileForReviewRequest} message SubmitProfileForReviewRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubmitProfileForReviewRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this SubmitProfileForReviewRequest to JSON.
     * @function toJSON
     * @memberof SubmitProfileForReviewRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubmitProfileForReviewRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubmitProfileForReviewRequest
     * @function getTypeUrl
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubmitProfileForReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubmitProfileForReviewRequest";
    };

    return SubmitProfileForReviewRequest;
})();

$root.SubmitProfileForReviewResponse = (function() {

    /**
     * Properties of a SubmitProfileForReviewResponse.
     * @exports ISubmitProfileForReviewResponse
     * @interface ISubmitProfileForReviewResponse
     * @property {boolean|null} [okay] SubmitProfileForReviewResponse okay
     * @property {string|null} [errors] SubmitProfileForReviewResponse errors
     * @property {IApiBusinessProfileProto|null} [newProfile] SubmitProfileForReviewResponse newProfile
     * @property {IApiBusinessProfileProto|null} [updatedDraftProfile] SubmitProfileForReviewResponse updatedDraftProfile
     */

    /**
     * Constructs a new SubmitProfileForReviewResponse.
     * @exports SubmitProfileForReviewResponse
     * @classdesc Represents a SubmitProfileForReviewResponse.
     * @implements ISubmitProfileForReviewResponse
     * @constructor
     * @param {ISubmitProfileForReviewResponse=} [properties] Properties to set
     */
    function SubmitProfileForReviewResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SubmitProfileForReviewResponse okay.
     * @member {boolean} okay
     * @memberof SubmitProfileForReviewResponse
     * @instance
     */
    SubmitProfileForReviewResponse.prototype.okay = false;

    /**
     * SubmitProfileForReviewResponse errors.
     * @member {string} errors
     * @memberof SubmitProfileForReviewResponse
     * @instance
     */
    SubmitProfileForReviewResponse.prototype.errors = "";

    /**
     * SubmitProfileForReviewResponse newProfile.
     * @member {IApiBusinessProfileProto|null|undefined} newProfile
     * @memberof SubmitProfileForReviewResponse
     * @instance
     */
    SubmitProfileForReviewResponse.prototype.newProfile = null;

    /**
     * SubmitProfileForReviewResponse updatedDraftProfile.
     * @member {IApiBusinessProfileProto|null|undefined} updatedDraftProfile
     * @memberof SubmitProfileForReviewResponse
     * @instance
     */
    SubmitProfileForReviewResponse.prototype.updatedDraftProfile = null;

    /**
     * Creates a new SubmitProfileForReviewResponse instance using the specified properties.
     * @function create
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {ISubmitProfileForReviewResponse=} [properties] Properties to set
     * @returns {SubmitProfileForReviewResponse} SubmitProfileForReviewResponse instance
     */
    SubmitProfileForReviewResponse.create = function create(properties) {
        return new SubmitProfileForReviewResponse(properties);
    };

    /**
     * Encodes the specified SubmitProfileForReviewResponse message. Does not implicitly {@link SubmitProfileForReviewResponse.verify|verify} messages.
     * @function encode
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {ISubmitProfileForReviewResponse} message SubmitProfileForReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubmitProfileForReviewResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.errors != null && Object.hasOwnProperty.call(message, "errors"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.errors);
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ApiBusinessProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.updatedDraftProfile != null && Object.hasOwnProperty.call(message, "updatedDraftProfile"))
            $root.ApiBusinessProfileProto.encode(message.updatedDraftProfile, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified SubmitProfileForReviewResponse message, length delimited. Does not implicitly {@link SubmitProfileForReviewResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {ISubmitProfileForReviewResponse} message SubmitProfileForReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubmitProfileForReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubmitProfileForReviewResponse message from the specified reader or buffer.
     * @function decode
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubmitProfileForReviewResponse} SubmitProfileForReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubmitProfileForReviewResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubmitProfileForReviewResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.errors = reader.string();
                    break;
                }
            case 3: {
                    message.newProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.updatedDraftProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubmitProfileForReviewResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubmitProfileForReviewResponse} SubmitProfileForReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubmitProfileForReviewResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubmitProfileForReviewResponse message.
     * @function verify
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubmitProfileForReviewResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.errors != null && message.hasOwnProperty("errors"))
            if (!$util.isString(message.errors))
                return "errors: string expected";
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        if (message.updatedDraftProfile != null && message.hasOwnProperty("updatedDraftProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.updatedDraftProfile);
            if (error)
                return "updatedDraftProfile." + error;
        }
        return null;
    };

    /**
     * Creates a SubmitProfileForReviewResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubmitProfileForReviewResponse} SubmitProfileForReviewResponse
     */
    SubmitProfileForReviewResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.SubmitProfileForReviewResponse)
            return object;
        var message = new $root.SubmitProfileForReviewResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.errors != null)
            message.errors = String(object.errors);
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".SubmitProfileForReviewResponse.newProfile: object expected");
            message.newProfile = $root.ApiBusinessProfileProto.fromObject(object.newProfile);
        }
        if (object.updatedDraftProfile != null) {
            if (typeof object.updatedDraftProfile !== "object")
                throw TypeError(".SubmitProfileForReviewResponse.updatedDraftProfile: object expected");
            message.updatedDraftProfile = $root.ApiBusinessProfileProto.fromObject(object.updatedDraftProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a SubmitProfileForReviewResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {SubmitProfileForReviewResponse} message SubmitProfileForReviewResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubmitProfileForReviewResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.errors = "";
            object.newProfile = null;
            object.updatedDraftProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.errors != null && message.hasOwnProperty("errors"))
            object.errors = message.errors;
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ApiBusinessProfileProto.toObject(message.newProfile, options);
        if (message.updatedDraftProfile != null && message.hasOwnProperty("updatedDraftProfile"))
            object.updatedDraftProfile = $root.ApiBusinessProfileProto.toObject(message.updatedDraftProfile, options);
        return object;
    };

    /**
     * Converts this SubmitProfileForReviewResponse to JSON.
     * @function toJSON
     * @memberof SubmitProfileForReviewResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubmitProfileForReviewResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubmitProfileForReviewResponse
     * @function getTypeUrl
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubmitProfileForReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubmitProfileForReviewResponse";
    };

    return SubmitProfileForReviewResponse;
})();

$root.WithdrawProfileReviewRequest = (function() {

    /**
     * Properties of a WithdrawProfileReviewRequest.
     * @exports IWithdrawProfileReviewRequest
     * @interface IWithdrawProfileReviewRequest
     * @property {string|null} [versionId] WithdrawProfileReviewRequest versionId
     */

    /**
     * Constructs a new WithdrawProfileReviewRequest.
     * @exports WithdrawProfileReviewRequest
     * @classdesc Represents a WithdrawProfileReviewRequest.
     * @implements IWithdrawProfileReviewRequest
     * @constructor
     * @param {IWithdrawProfileReviewRequest=} [properties] Properties to set
     */
    function WithdrawProfileReviewRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WithdrawProfileReviewRequest versionId.
     * @member {string} versionId
     * @memberof WithdrawProfileReviewRequest
     * @instance
     */
    WithdrawProfileReviewRequest.prototype.versionId = "";

    /**
     * Creates a new WithdrawProfileReviewRequest instance using the specified properties.
     * @function create
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {IWithdrawProfileReviewRequest=} [properties] Properties to set
     * @returns {WithdrawProfileReviewRequest} WithdrawProfileReviewRequest instance
     */
    WithdrawProfileReviewRequest.create = function create(properties) {
        return new WithdrawProfileReviewRequest(properties);
    };

    /**
     * Encodes the specified WithdrawProfileReviewRequest message. Does not implicitly {@link WithdrawProfileReviewRequest.verify|verify} messages.
     * @function encode
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {IWithdrawProfileReviewRequest} message WithdrawProfileReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawProfileReviewRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.versionId);
        return writer;
    };

    /**
     * Encodes the specified WithdrawProfileReviewRequest message, length delimited. Does not implicitly {@link WithdrawProfileReviewRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {IWithdrawProfileReviewRequest} message WithdrawProfileReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawProfileReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WithdrawProfileReviewRequest message from the specified reader or buffer.
     * @function decode
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WithdrawProfileReviewRequest} WithdrawProfileReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawProfileReviewRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.WithdrawProfileReviewRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.versionId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WithdrawProfileReviewRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WithdrawProfileReviewRequest} WithdrawProfileReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawProfileReviewRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WithdrawProfileReviewRequest message.
     * @function verify
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WithdrawProfileReviewRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            if (!$util.isString(message.versionId))
                return "versionId: string expected";
        return null;
    };

    /**
     * Creates a WithdrawProfileReviewRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WithdrawProfileReviewRequest} WithdrawProfileReviewRequest
     */
    WithdrawProfileReviewRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.WithdrawProfileReviewRequest)
            return object;
        var message = new $root.WithdrawProfileReviewRequest();
        if (object.versionId != null)
            message.versionId = String(object.versionId);
        return message;
    };

    /**
     * Creates a plain object from a WithdrawProfileReviewRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {WithdrawProfileReviewRequest} message WithdrawProfileReviewRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WithdrawProfileReviewRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.versionId = "";
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            object.versionId = message.versionId;
        return object;
    };

    /**
     * Converts this WithdrawProfileReviewRequest to JSON.
     * @function toJSON
     * @memberof WithdrawProfileReviewRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WithdrawProfileReviewRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WithdrawProfileReviewRequest
     * @function getTypeUrl
     * @memberof WithdrawProfileReviewRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WithdrawProfileReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WithdrawProfileReviewRequest";
    };

    return WithdrawProfileReviewRequest;
})();

$root.WithdrawProfileReviewResponse = (function() {

    /**
     * Properties of a WithdrawProfileReviewResponse.
     * @exports IWithdrawProfileReviewResponse
     * @interface IWithdrawProfileReviewResponse
     * @property {boolean|null} [okay] WithdrawProfileReviewResponse okay
     * @property {string|null} [errors] WithdrawProfileReviewResponse errors
     * @property {IApiBusinessProfileProto|null} [updatedProfile] WithdrawProfileReviewResponse updatedProfile
     */

    /**
     * Constructs a new WithdrawProfileReviewResponse.
     * @exports WithdrawProfileReviewResponse
     * @classdesc Represents a WithdrawProfileReviewResponse.
     * @implements IWithdrawProfileReviewResponse
     * @constructor
     * @param {IWithdrawProfileReviewResponse=} [properties] Properties to set
     */
    function WithdrawProfileReviewResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WithdrawProfileReviewResponse okay.
     * @member {boolean} okay
     * @memberof WithdrawProfileReviewResponse
     * @instance
     */
    WithdrawProfileReviewResponse.prototype.okay = false;

    /**
     * WithdrawProfileReviewResponse errors.
     * @member {string} errors
     * @memberof WithdrawProfileReviewResponse
     * @instance
     */
    WithdrawProfileReviewResponse.prototype.errors = "";

    /**
     * WithdrawProfileReviewResponse updatedProfile.
     * @member {IApiBusinessProfileProto|null|undefined} updatedProfile
     * @memberof WithdrawProfileReviewResponse
     * @instance
     */
    WithdrawProfileReviewResponse.prototype.updatedProfile = null;

    /**
     * Creates a new WithdrawProfileReviewResponse instance using the specified properties.
     * @function create
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {IWithdrawProfileReviewResponse=} [properties] Properties to set
     * @returns {WithdrawProfileReviewResponse} WithdrawProfileReviewResponse instance
     */
    WithdrawProfileReviewResponse.create = function create(properties) {
        return new WithdrawProfileReviewResponse(properties);
    };

    /**
     * Encodes the specified WithdrawProfileReviewResponse message. Does not implicitly {@link WithdrawProfileReviewResponse.verify|verify} messages.
     * @function encode
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {IWithdrawProfileReviewResponse} message WithdrawProfileReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawProfileReviewResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.errors != null && Object.hasOwnProperty.call(message, "errors"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.errors);
        if (message.updatedProfile != null && Object.hasOwnProperty.call(message, "updatedProfile"))
            $root.ApiBusinessProfileProto.encode(message.updatedProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified WithdrawProfileReviewResponse message, length delimited. Does not implicitly {@link WithdrawProfileReviewResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {IWithdrawProfileReviewResponse} message WithdrawProfileReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawProfileReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WithdrawProfileReviewResponse message from the specified reader or buffer.
     * @function decode
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WithdrawProfileReviewResponse} WithdrawProfileReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawProfileReviewResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.WithdrawProfileReviewResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.errors = reader.string();
                    break;
                }
            case 3: {
                    message.updatedProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WithdrawProfileReviewResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WithdrawProfileReviewResponse} WithdrawProfileReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawProfileReviewResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WithdrawProfileReviewResponse message.
     * @function verify
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WithdrawProfileReviewResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.errors != null && message.hasOwnProperty("errors"))
            if (!$util.isString(message.errors))
                return "errors: string expected";
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.updatedProfile);
            if (error)
                return "updatedProfile." + error;
        }
        return null;
    };

    /**
     * Creates a WithdrawProfileReviewResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WithdrawProfileReviewResponse} WithdrawProfileReviewResponse
     */
    WithdrawProfileReviewResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.WithdrawProfileReviewResponse)
            return object;
        var message = new $root.WithdrawProfileReviewResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.errors != null)
            message.errors = String(object.errors);
        if (object.updatedProfile != null) {
            if (typeof object.updatedProfile !== "object")
                throw TypeError(".WithdrawProfileReviewResponse.updatedProfile: object expected");
            message.updatedProfile = $root.ApiBusinessProfileProto.fromObject(object.updatedProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a WithdrawProfileReviewResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {WithdrawProfileReviewResponse} message WithdrawProfileReviewResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WithdrawProfileReviewResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.errors = "";
            object.updatedProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.errors != null && message.hasOwnProperty("errors"))
            object.errors = message.errors;
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile"))
            object.updatedProfile = $root.ApiBusinessProfileProto.toObject(message.updatedProfile, options);
        return object;
    };

    /**
     * Converts this WithdrawProfileReviewResponse to JSON.
     * @function toJSON
     * @memberof WithdrawProfileReviewResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WithdrawProfileReviewResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WithdrawProfileReviewResponse
     * @function getTypeUrl
     * @memberof WithdrawProfileReviewResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WithdrawProfileReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WithdrawProfileReviewResponse";
    };

    return WithdrawProfileReviewResponse;
})();

$root.SetLiveVersionRequest = (function() {

    /**
     * Properties of a SetLiveVersionRequest.
     * @exports ISetLiveVersionRequest
     * @interface ISetLiveVersionRequest
     * @property {string|null} [version] SetLiveVersionRequest version
     */

    /**
     * Constructs a new SetLiveVersionRequest.
     * @exports SetLiveVersionRequest
     * @classdesc Represents a SetLiveVersionRequest.
     * @implements ISetLiveVersionRequest
     * @constructor
     * @param {ISetLiveVersionRequest=} [properties] Properties to set
     */
    function SetLiveVersionRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetLiveVersionRequest version.
     * @member {string} version
     * @memberof SetLiveVersionRequest
     * @instance
     */
    SetLiveVersionRequest.prototype.version = "";

    /**
     * Creates a new SetLiveVersionRequest instance using the specified properties.
     * @function create
     * @memberof SetLiveVersionRequest
     * @static
     * @param {ISetLiveVersionRequest=} [properties] Properties to set
     * @returns {SetLiveVersionRequest} SetLiveVersionRequest instance
     */
    SetLiveVersionRequest.create = function create(properties) {
        return new SetLiveVersionRequest(properties);
    };

    /**
     * Encodes the specified SetLiveVersionRequest message. Does not implicitly {@link SetLiveVersionRequest.verify|verify} messages.
     * @function encode
     * @memberof SetLiveVersionRequest
     * @static
     * @param {ISetLiveVersionRequest} message SetLiveVersionRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLiveVersionRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.version);
        return writer;
    };

    /**
     * Encodes the specified SetLiveVersionRequest message, length delimited. Does not implicitly {@link SetLiveVersionRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SetLiveVersionRequest
     * @static
     * @param {ISetLiveVersionRequest} message SetLiveVersionRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLiveVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetLiveVersionRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SetLiveVersionRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SetLiveVersionRequest} SetLiveVersionRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLiveVersionRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SetLiveVersionRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.version = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SetLiveVersionRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SetLiveVersionRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SetLiveVersionRequest} SetLiveVersionRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLiveVersionRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetLiveVersionRequest message.
     * @function verify
     * @memberof SetLiveVersionRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetLiveVersionRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isString(message.version))
                return "version: string expected";
        return null;
    };

    /**
     * Creates a SetLiveVersionRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SetLiveVersionRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SetLiveVersionRequest} SetLiveVersionRequest
     */
    SetLiveVersionRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SetLiveVersionRequest)
            return object;
        var message = new $root.SetLiveVersionRequest();
        if (object.version != null)
            message.version = String(object.version);
        return message;
    };

    /**
     * Creates a plain object from a SetLiveVersionRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SetLiveVersionRequest
     * @static
     * @param {SetLiveVersionRequest} message SetLiveVersionRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetLiveVersionRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.version = "";
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        return object;
    };

    /**
     * Converts this SetLiveVersionRequest to JSON.
     * @function toJSON
     * @memberof SetLiveVersionRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetLiveVersionRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetLiveVersionRequest
     * @function getTypeUrl
     * @memberof SetLiveVersionRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetLiveVersionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SetLiveVersionRequest";
    };

    return SetLiveVersionRequest;
})();

$root.SetLiveVersionResponse = (function() {

    /**
     * Properties of a SetLiveVersionResponse.
     * @exports ISetLiveVersionResponse
     * @interface ISetLiveVersionResponse
     * @property {boolean|null} [okay] SetLiveVersionResponse okay
     * @property {IApiBusinessProto|null} [updatedBusiness] SetLiveVersionResponse updatedBusiness
     * @property {string|null} [error] SetLiveVersionResponse error
     */

    /**
     * Constructs a new SetLiveVersionResponse.
     * @exports SetLiveVersionResponse
     * @classdesc Represents a SetLiveVersionResponse.
     * @implements ISetLiveVersionResponse
     * @constructor
     * @param {ISetLiveVersionResponse=} [properties] Properties to set
     */
    function SetLiveVersionResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetLiveVersionResponse okay.
     * @member {boolean} okay
     * @memberof SetLiveVersionResponse
     * @instance
     */
    SetLiveVersionResponse.prototype.okay = false;

    /**
     * SetLiveVersionResponse updatedBusiness.
     * @member {IApiBusinessProto|null|undefined} updatedBusiness
     * @memberof SetLiveVersionResponse
     * @instance
     */
    SetLiveVersionResponse.prototype.updatedBusiness = null;

    /**
     * SetLiveVersionResponse error.
     * @member {string} error
     * @memberof SetLiveVersionResponse
     * @instance
     */
    SetLiveVersionResponse.prototype.error = "";

    /**
     * Creates a new SetLiveVersionResponse instance using the specified properties.
     * @function create
     * @memberof SetLiveVersionResponse
     * @static
     * @param {ISetLiveVersionResponse=} [properties] Properties to set
     * @returns {SetLiveVersionResponse} SetLiveVersionResponse instance
     */
    SetLiveVersionResponse.create = function create(properties) {
        return new SetLiveVersionResponse(properties);
    };

    /**
     * Encodes the specified SetLiveVersionResponse message. Does not implicitly {@link SetLiveVersionResponse.verify|verify} messages.
     * @function encode
     * @memberof SetLiveVersionResponse
     * @static
     * @param {ISetLiveVersionResponse} message SetLiveVersionResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLiveVersionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.updatedBusiness != null && Object.hasOwnProperty.call(message, "updatedBusiness"))
            $root.ApiBusinessProto.encode(message.updatedBusiness, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.error);
        return writer;
    };

    /**
     * Encodes the specified SetLiveVersionResponse message, length delimited. Does not implicitly {@link SetLiveVersionResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SetLiveVersionResponse
     * @static
     * @param {ISetLiveVersionResponse} message SetLiveVersionResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLiveVersionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetLiveVersionResponse message from the specified reader or buffer.
     * @function decode
     * @memberof SetLiveVersionResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SetLiveVersionResponse} SetLiveVersionResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLiveVersionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SetLiveVersionResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.updatedBusiness = $root.ApiBusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.error = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SetLiveVersionResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SetLiveVersionResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SetLiveVersionResponse} SetLiveVersionResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLiveVersionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetLiveVersionResponse message.
     * @function verify
     * @memberof SetLiveVersionResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetLiveVersionResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.updatedBusiness != null && message.hasOwnProperty("updatedBusiness")) {
            var error = $root.ApiBusinessProto.verify(message.updatedBusiness);
            if (error)
                return "updatedBusiness." + error;
        }
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        return null;
    };

    /**
     * Creates a SetLiveVersionResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SetLiveVersionResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SetLiveVersionResponse} SetLiveVersionResponse
     */
    SetLiveVersionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.SetLiveVersionResponse)
            return object;
        var message = new $root.SetLiveVersionResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.updatedBusiness != null) {
            if (typeof object.updatedBusiness !== "object")
                throw TypeError(".SetLiveVersionResponse.updatedBusiness: object expected");
            message.updatedBusiness = $root.ApiBusinessProto.fromObject(object.updatedBusiness);
        }
        if (object.error != null)
            message.error = String(object.error);
        return message;
    };

    /**
     * Creates a plain object from a SetLiveVersionResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SetLiveVersionResponse
     * @static
     * @param {SetLiveVersionResponse} message SetLiveVersionResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetLiveVersionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.updatedBusiness = null;
            object.error = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.updatedBusiness != null && message.hasOwnProperty("updatedBusiness"))
            object.updatedBusiness = $root.ApiBusinessProto.toObject(message.updatedBusiness, options);
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        return object;
    };

    /**
     * Converts this SetLiveVersionResponse to JSON.
     * @function toJSON
     * @memberof SetLiveVersionResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetLiveVersionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetLiveVersionResponse
     * @function getTypeUrl
     * @memberof SetLiveVersionResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetLiveVersionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SetLiveVersionResponse";
    };

    return SetLiveVersionResponse;
})();

$root.GetLoginsForBusinessRequest = (function() {

    /**
     * Properties of a GetLoginsForBusinessRequest.
     * @exports IGetLoginsForBusinessRequest
     * @interface IGetLoginsForBusinessRequest
     * @property {Array.<string>|null} [providerIds] GetLoginsForBusinessRequest providerIds
     */

    /**
     * Constructs a new GetLoginsForBusinessRequest.
     * @exports GetLoginsForBusinessRequest
     * @classdesc Represents a GetLoginsForBusinessRequest.
     * @implements IGetLoginsForBusinessRequest
     * @constructor
     * @param {IGetLoginsForBusinessRequest=} [properties] Properties to set
     */
    function GetLoginsForBusinessRequest(properties) {
        this.providerIds = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetLoginsForBusinessRequest providerIds.
     * @member {Array.<string>} providerIds
     * @memberof GetLoginsForBusinessRequest
     * @instance
     */
    GetLoginsForBusinessRequest.prototype.providerIds = $util.emptyArray;

    /**
     * Creates a new GetLoginsForBusinessRequest instance using the specified properties.
     * @function create
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {IGetLoginsForBusinessRequest=} [properties] Properties to set
     * @returns {GetLoginsForBusinessRequest} GetLoginsForBusinessRequest instance
     */
    GetLoginsForBusinessRequest.create = function create(properties) {
        return new GetLoginsForBusinessRequest(properties);
    };

    /**
     * Encodes the specified GetLoginsForBusinessRequest message. Does not implicitly {@link GetLoginsForBusinessRequest.verify|verify} messages.
     * @function encode
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {IGetLoginsForBusinessRequest} message GetLoginsForBusinessRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetLoginsForBusinessRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerIds != null && message.providerIds.length)
            for (var i = 0; i < message.providerIds.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerIds[i]);
        return writer;
    };

    /**
     * Encodes the specified GetLoginsForBusinessRequest message, length delimited. Does not implicitly {@link GetLoginsForBusinessRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {IGetLoginsForBusinessRequest} message GetLoginsForBusinessRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetLoginsForBusinessRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetLoginsForBusinessRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetLoginsForBusinessRequest} GetLoginsForBusinessRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetLoginsForBusinessRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetLoginsForBusinessRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.providerIds && message.providerIds.length))
                        message.providerIds = [];
                    message.providerIds.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetLoginsForBusinessRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetLoginsForBusinessRequest} GetLoginsForBusinessRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetLoginsForBusinessRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetLoginsForBusinessRequest message.
     * @function verify
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetLoginsForBusinessRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerIds != null && message.hasOwnProperty("providerIds")) {
            if (!Array.isArray(message.providerIds))
                return "providerIds: array expected";
            for (var i = 0; i < message.providerIds.length; ++i)
                if (!$util.isString(message.providerIds[i]))
                    return "providerIds: string[] expected";
        }
        return null;
    };

    /**
     * Creates a GetLoginsForBusinessRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetLoginsForBusinessRequest} GetLoginsForBusinessRequest
     */
    GetLoginsForBusinessRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetLoginsForBusinessRequest)
            return object;
        var message = new $root.GetLoginsForBusinessRequest();
        if (object.providerIds) {
            if (!Array.isArray(object.providerIds))
                throw TypeError(".GetLoginsForBusinessRequest.providerIds: array expected");
            message.providerIds = [];
            for (var i = 0; i < object.providerIds.length; ++i)
                message.providerIds[i] = String(object.providerIds[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetLoginsForBusinessRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {GetLoginsForBusinessRequest} message GetLoginsForBusinessRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetLoginsForBusinessRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.providerIds = [];
        if (message.providerIds && message.providerIds.length) {
            object.providerIds = [];
            for (var j = 0; j < message.providerIds.length; ++j)
                object.providerIds[j] = message.providerIds[j];
        }
        return object;
    };

    /**
     * Converts this GetLoginsForBusinessRequest to JSON.
     * @function toJSON
     * @memberof GetLoginsForBusinessRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetLoginsForBusinessRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetLoginsForBusinessRequest
     * @function getTypeUrl
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetLoginsForBusinessRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetLoginsForBusinessRequest";
    };

    return GetLoginsForBusinessRequest;
})();

$root.GetLoginsForBusinessResponse = (function() {

    /**
     * Properties of a GetLoginsForBusinessResponse.
     * @exports IGetLoginsForBusinessResponse
     * @interface IGetLoginsForBusinessResponse
     * @property {boolean|null} [okay] GetLoginsForBusinessResponse okay
     * @property {Array.<IApiUserProto>|null} [provider] GetLoginsForBusinessResponse provider
     */

    /**
     * Constructs a new GetLoginsForBusinessResponse.
     * @exports GetLoginsForBusinessResponse
     * @classdesc Represents a GetLoginsForBusinessResponse.
     * @implements IGetLoginsForBusinessResponse
     * @constructor
     * @param {IGetLoginsForBusinessResponse=} [properties] Properties to set
     */
    function GetLoginsForBusinessResponse(properties) {
        this.provider = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetLoginsForBusinessResponse okay.
     * @member {boolean} okay
     * @memberof GetLoginsForBusinessResponse
     * @instance
     */
    GetLoginsForBusinessResponse.prototype.okay = false;

    /**
     * GetLoginsForBusinessResponse provider.
     * @member {Array.<IApiUserProto>} provider
     * @memberof GetLoginsForBusinessResponse
     * @instance
     */
    GetLoginsForBusinessResponse.prototype.provider = $util.emptyArray;

    /**
     * Creates a new GetLoginsForBusinessResponse instance using the specified properties.
     * @function create
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {IGetLoginsForBusinessResponse=} [properties] Properties to set
     * @returns {GetLoginsForBusinessResponse} GetLoginsForBusinessResponse instance
     */
    GetLoginsForBusinessResponse.create = function create(properties) {
        return new GetLoginsForBusinessResponse(properties);
    };

    /**
     * Encodes the specified GetLoginsForBusinessResponse message. Does not implicitly {@link GetLoginsForBusinessResponse.verify|verify} messages.
     * @function encode
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {IGetLoginsForBusinessResponse} message GetLoginsForBusinessResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetLoginsForBusinessResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && message.provider.length)
            for (var i = 0; i < message.provider.length; ++i)
                $root.ApiUserProto.encode(message.provider[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetLoginsForBusinessResponse message, length delimited. Does not implicitly {@link GetLoginsForBusinessResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {IGetLoginsForBusinessResponse} message GetLoginsForBusinessResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetLoginsForBusinessResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetLoginsForBusinessResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetLoginsForBusinessResponse} GetLoginsForBusinessResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetLoginsForBusinessResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetLoginsForBusinessResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.provider && message.provider.length))
                        message.provider = [];
                    message.provider.push($root.ApiUserProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetLoginsForBusinessResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetLoginsForBusinessResponse} GetLoginsForBusinessResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetLoginsForBusinessResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetLoginsForBusinessResponse message.
     * @function verify
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetLoginsForBusinessResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            if (!Array.isArray(message.provider))
                return "provider: array expected";
            for (var i = 0; i < message.provider.length; ++i) {
                var error = $root.ApiUserProto.verify(message.provider[i]);
                if (error)
                    return "provider." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetLoginsForBusinessResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetLoginsForBusinessResponse} GetLoginsForBusinessResponse
     */
    GetLoginsForBusinessResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetLoginsForBusinessResponse)
            return object;
        var message = new $root.GetLoginsForBusinessResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider) {
            if (!Array.isArray(object.provider))
                throw TypeError(".GetLoginsForBusinessResponse.provider: array expected");
            message.provider = [];
            for (var i = 0; i < object.provider.length; ++i) {
                if (typeof object.provider[i] !== "object")
                    throw TypeError(".GetLoginsForBusinessResponse.provider: object expected");
                message.provider[i] = $root.ApiUserProto.fromObject(object.provider[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetLoginsForBusinessResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {GetLoginsForBusinessResponse} message GetLoginsForBusinessResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetLoginsForBusinessResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.provider = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider && message.provider.length) {
            object.provider = [];
            for (var j = 0; j < message.provider.length; ++j)
                object.provider[j] = $root.ApiUserProto.toObject(message.provider[j], options);
        }
        return object;
    };

    /**
     * Converts this GetLoginsForBusinessResponse to JSON.
     * @function toJSON
     * @memberof GetLoginsForBusinessResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetLoginsForBusinessResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetLoginsForBusinessResponse
     * @function getTypeUrl
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetLoginsForBusinessResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetLoginsForBusinessResponse";
    };

    return GetLoginsForBusinessResponse;
})();

$root.AcceptLoginInviteRequest = (function() {

    /**
     * Properties of an AcceptLoginInviteRequest.
     * @exports IAcceptLoginInviteRequest
     * @interface IAcceptLoginInviteRequest
     * @property {string|null} [providerId] AcceptLoginInviteRequest providerId
     * @property {string|null} [inviteCode] AcceptLoginInviteRequest inviteCode
     */

    /**
     * Constructs a new AcceptLoginInviteRequest.
     * @exports AcceptLoginInviteRequest
     * @classdesc Represents an AcceptLoginInviteRequest.
     * @implements IAcceptLoginInviteRequest
     * @constructor
     * @param {IAcceptLoginInviteRequest=} [properties] Properties to set
     */
    function AcceptLoginInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptLoginInviteRequest providerId.
     * @member {string} providerId
     * @memberof AcceptLoginInviteRequest
     * @instance
     */
    AcceptLoginInviteRequest.prototype.providerId = "";

    /**
     * AcceptLoginInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof AcceptLoginInviteRequest
     * @instance
     */
    AcceptLoginInviteRequest.prototype.inviteCode = "";

    /**
     * Creates a new AcceptLoginInviteRequest instance using the specified properties.
     * @function create
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {IAcceptLoginInviteRequest=} [properties] Properties to set
     * @returns {AcceptLoginInviteRequest} AcceptLoginInviteRequest instance
     */
    AcceptLoginInviteRequest.create = function create(properties) {
        return new AcceptLoginInviteRequest(properties);
    };

    /**
     * Encodes the specified AcceptLoginInviteRequest message. Does not implicitly {@link AcceptLoginInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {IAcceptLoginInviteRequest} message AcceptLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptLoginInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified AcceptLoginInviteRequest message, length delimited. Does not implicitly {@link AcceptLoginInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {IAcceptLoginInviteRequest} message AcceptLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptLoginInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptLoginInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptLoginInviteRequest} AcceptLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptLoginInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptLoginInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptLoginInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptLoginInviteRequest} AcceptLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptLoginInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptLoginInviteRequest message.
     * @function verify
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptLoginInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates an AcceptLoginInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptLoginInviteRequest} AcceptLoginInviteRequest
     */
    AcceptLoginInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptLoginInviteRequest)
            return object;
        var message = new $root.AcceptLoginInviteRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from an AcceptLoginInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {AcceptLoginInviteRequest} message AcceptLoginInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptLoginInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.providerId = "";
            object.inviteCode = "";
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this AcceptLoginInviteRequest to JSON.
     * @function toJSON
     * @memberof AcceptLoginInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptLoginInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptLoginInviteRequest
     * @function getTypeUrl
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptLoginInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptLoginInviteRequest";
    };

    return AcceptLoginInviteRequest;
})();

$root.AcceptLoginInviteResponse = (function() {

    /**
     * Properties of an AcceptLoginInviteResponse.
     * @exports IAcceptLoginInviteResponse
     * @interface IAcceptLoginInviteResponse
     * @property {boolean|null} [okay] AcceptLoginInviteResponse okay
     * @property {IApiUserProto|null} [provider] AcceptLoginInviteResponse provider
     * @property {IApiBusinessProfileProto|null} [businessProfile] AcceptLoginInviteResponse businessProfile
     * @property {IApiBusinessProto|null} [business] AcceptLoginInviteResponse business
     */

    /**
     * Constructs a new AcceptLoginInviteResponse.
     * @exports AcceptLoginInviteResponse
     * @classdesc Represents an AcceptLoginInviteResponse.
     * @implements IAcceptLoginInviteResponse
     * @constructor
     * @param {IAcceptLoginInviteResponse=} [properties] Properties to set
     */
    function AcceptLoginInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptLoginInviteResponse okay.
     * @member {boolean} okay
     * @memberof AcceptLoginInviteResponse
     * @instance
     */
    AcceptLoginInviteResponse.prototype.okay = false;

    /**
     * AcceptLoginInviteResponse provider.
     * @member {IApiUserProto|null|undefined} provider
     * @memberof AcceptLoginInviteResponse
     * @instance
     */
    AcceptLoginInviteResponse.prototype.provider = null;

    /**
     * AcceptLoginInviteResponse businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof AcceptLoginInviteResponse
     * @instance
     */
    AcceptLoginInviteResponse.prototype.businessProfile = null;

    /**
     * AcceptLoginInviteResponse business.
     * @member {IApiBusinessProto|null|undefined} business
     * @memberof AcceptLoginInviteResponse
     * @instance
     */
    AcceptLoginInviteResponse.prototype.business = null;

    /**
     * Creates a new AcceptLoginInviteResponse instance using the specified properties.
     * @function create
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {IAcceptLoginInviteResponse=} [properties] Properties to set
     * @returns {AcceptLoginInviteResponse} AcceptLoginInviteResponse instance
     */
    AcceptLoginInviteResponse.create = function create(properties) {
        return new AcceptLoginInviteResponse(properties);
    };

    /**
     * Encodes the specified AcceptLoginInviteResponse message. Does not implicitly {@link AcceptLoginInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {IAcceptLoginInviteResponse} message AcceptLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptLoginInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiUserProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.business != null && Object.hasOwnProperty.call(message, "business"))
            $root.ApiBusinessProto.encode(message.business, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AcceptLoginInviteResponse message, length delimited. Does not implicitly {@link AcceptLoginInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {IAcceptLoginInviteResponse} message AcceptLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptLoginInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptLoginInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptLoginInviteResponse} AcceptLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptLoginInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptLoginInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ApiUserProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.business = $root.ApiBusinessProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptLoginInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptLoginInviteResponse} AcceptLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptLoginInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptLoginInviteResponse message.
     * @function verify
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptLoginInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiUserProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        if (message.business != null && message.hasOwnProperty("business")) {
            var error = $root.ApiBusinessProto.verify(message.business);
            if (error)
                return "business." + error;
        }
        return null;
    };

    /**
     * Creates an AcceptLoginInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptLoginInviteResponse} AcceptLoginInviteResponse
     */
    AcceptLoginInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptLoginInviteResponse)
            return object;
        var message = new $root.AcceptLoginInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".AcceptLoginInviteResponse.provider: object expected");
            message.provider = $root.ApiUserProto.fromObject(object.provider);
        }
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".AcceptLoginInviteResponse.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        if (object.business != null) {
            if (typeof object.business !== "object")
                throw TypeError(".AcceptLoginInviteResponse.business: object expected");
            message.business = $root.ApiBusinessProto.fromObject(object.business);
        }
        return message;
    };

    /**
     * Creates a plain object from an AcceptLoginInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {AcceptLoginInviteResponse} message AcceptLoginInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptLoginInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
            object.businessProfile = null;
            object.business = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiUserProto.toObject(message.provider, options);
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        if (message.business != null && message.hasOwnProperty("business"))
            object.business = $root.ApiBusinessProto.toObject(message.business, options);
        return object;
    };

    /**
     * Converts this AcceptLoginInviteResponse to JSON.
     * @function toJSON
     * @memberof AcceptLoginInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptLoginInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptLoginInviteResponse
     * @function getTypeUrl
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptLoginInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptLoginInviteResponse";
    };

    return AcceptLoginInviteResponse;
})();

$root.CheckLoginInviteRequest = (function() {

    /**
     * Properties of a CheckLoginInviteRequest.
     * @exports ICheckLoginInviteRequest
     * @interface ICheckLoginInviteRequest
     * @property {string|null} [providerId] CheckLoginInviteRequest providerId
     * @property {string|null} [inviteCode] CheckLoginInviteRequest inviteCode
     */

    /**
     * Constructs a new CheckLoginInviteRequest.
     * @exports CheckLoginInviteRequest
     * @classdesc Represents a CheckLoginInviteRequest.
     * @implements ICheckLoginInviteRequest
     * @constructor
     * @param {ICheckLoginInviteRequest=} [properties] Properties to set
     */
    function CheckLoginInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckLoginInviteRequest providerId.
     * @member {string} providerId
     * @memberof CheckLoginInviteRequest
     * @instance
     */
    CheckLoginInviteRequest.prototype.providerId = "";

    /**
     * CheckLoginInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof CheckLoginInviteRequest
     * @instance
     */
    CheckLoginInviteRequest.prototype.inviteCode = "";

    /**
     * Creates a new CheckLoginInviteRequest instance using the specified properties.
     * @function create
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {ICheckLoginInviteRequest=} [properties] Properties to set
     * @returns {CheckLoginInviteRequest} CheckLoginInviteRequest instance
     */
    CheckLoginInviteRequest.create = function create(properties) {
        return new CheckLoginInviteRequest(properties);
    };

    /**
     * Encodes the specified CheckLoginInviteRequest message. Does not implicitly {@link CheckLoginInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {ICheckLoginInviteRequest} message CheckLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckLoginInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified CheckLoginInviteRequest message, length delimited. Does not implicitly {@link CheckLoginInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {ICheckLoginInviteRequest} message CheckLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckLoginInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckLoginInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckLoginInviteRequest} CheckLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckLoginInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckLoginInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckLoginInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckLoginInviteRequest} CheckLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckLoginInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckLoginInviteRequest message.
     * @function verify
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckLoginInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a CheckLoginInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckLoginInviteRequest} CheckLoginInviteRequest
     */
    CheckLoginInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckLoginInviteRequest)
            return object;
        var message = new $root.CheckLoginInviteRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a CheckLoginInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {CheckLoginInviteRequest} message CheckLoginInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckLoginInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.providerId = "";
            object.inviteCode = "";
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this CheckLoginInviteRequest to JSON.
     * @function toJSON
     * @memberof CheckLoginInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckLoginInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckLoginInviteRequest
     * @function getTypeUrl
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckLoginInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckLoginInviteRequest";
    };

    return CheckLoginInviteRequest;
})();

$root.CheckLoginInviteResponse = (function() {

    /**
     * Properties of a CheckLoginInviteResponse.
     * @exports ICheckLoginInviteResponse
     * @interface ICheckLoginInviteResponse
     * @property {boolean|null} [okay] CheckLoginInviteResponse okay
     * @property {string|null} [businessName] CheckLoginInviteResponse businessName
     */

    /**
     * Constructs a new CheckLoginInviteResponse.
     * @exports CheckLoginInviteResponse
     * @classdesc Represents a CheckLoginInviteResponse.
     * @implements ICheckLoginInviteResponse
     * @constructor
     * @param {ICheckLoginInviteResponse=} [properties] Properties to set
     */
    function CheckLoginInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckLoginInviteResponse okay.
     * @member {boolean} okay
     * @memberof CheckLoginInviteResponse
     * @instance
     */
    CheckLoginInviteResponse.prototype.okay = false;

    /**
     * CheckLoginInviteResponse businessName.
     * @member {string} businessName
     * @memberof CheckLoginInviteResponse
     * @instance
     */
    CheckLoginInviteResponse.prototype.businessName = "";

    /**
     * Creates a new CheckLoginInviteResponse instance using the specified properties.
     * @function create
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {ICheckLoginInviteResponse=} [properties] Properties to set
     * @returns {CheckLoginInviteResponse} CheckLoginInviteResponse instance
     */
    CheckLoginInviteResponse.create = function create(properties) {
        return new CheckLoginInviteResponse(properties);
    };

    /**
     * Encodes the specified CheckLoginInviteResponse message. Does not implicitly {@link CheckLoginInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {ICheckLoginInviteResponse} message CheckLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckLoginInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessName);
        return writer;
    };

    /**
     * Encodes the specified CheckLoginInviteResponse message, length delimited. Does not implicitly {@link CheckLoginInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {ICheckLoginInviteResponse} message CheckLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckLoginInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckLoginInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckLoginInviteResponse} CheckLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckLoginInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckLoginInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.businessName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckLoginInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckLoginInviteResponse} CheckLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckLoginInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckLoginInviteResponse message.
     * @function verify
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckLoginInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.businessName != null && message.hasOwnProperty("businessName"))
            if (!$util.isString(message.businessName))
                return "businessName: string expected";
        return null;
    };

    /**
     * Creates a CheckLoginInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckLoginInviteResponse} CheckLoginInviteResponse
     */
    CheckLoginInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckLoginInviteResponse)
            return object;
        var message = new $root.CheckLoginInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.businessName != null)
            message.businessName = String(object.businessName);
        return message;
    };

    /**
     * Creates a plain object from a CheckLoginInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {CheckLoginInviteResponse} message CheckLoginInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckLoginInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.businessName = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.businessName != null && message.hasOwnProperty("businessName"))
            object.businessName = message.businessName;
        return object;
    };

    /**
     * Converts this CheckLoginInviteResponse to JSON.
     * @function toJSON
     * @memberof CheckLoginInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckLoginInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckLoginInviteResponse
     * @function getTypeUrl
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckLoginInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckLoginInviteResponse";
    };

    return CheckLoginInviteResponse;
})();

$root.GenerateLoginInviteRequest = (function() {

    /**
     * Properties of a GenerateLoginInviteRequest.
     * @exports IGenerateLoginInviteRequest
     * @interface IGenerateLoginInviteRequest
     * @property {string|null} [providerId] GenerateLoginInviteRequest providerId
     */

    /**
     * Constructs a new GenerateLoginInviteRequest.
     * @exports GenerateLoginInviteRequest
     * @classdesc Represents a GenerateLoginInviteRequest.
     * @implements IGenerateLoginInviteRequest
     * @constructor
     * @param {IGenerateLoginInviteRequest=} [properties] Properties to set
     */
    function GenerateLoginInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateLoginInviteRequest providerId.
     * @member {string} providerId
     * @memberof GenerateLoginInviteRequest
     * @instance
     */
    GenerateLoginInviteRequest.prototype.providerId = "";

    /**
     * Creates a new GenerateLoginInviteRequest instance using the specified properties.
     * @function create
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {IGenerateLoginInviteRequest=} [properties] Properties to set
     * @returns {GenerateLoginInviteRequest} GenerateLoginInviteRequest instance
     */
    GenerateLoginInviteRequest.create = function create(properties) {
        return new GenerateLoginInviteRequest(properties);
    };

    /**
     * Encodes the specified GenerateLoginInviteRequest message. Does not implicitly {@link GenerateLoginInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {IGenerateLoginInviteRequest} message GenerateLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateLoginInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        return writer;
    };

    /**
     * Encodes the specified GenerateLoginInviteRequest message, length delimited. Does not implicitly {@link GenerateLoginInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {IGenerateLoginInviteRequest} message GenerateLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateLoginInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateLoginInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateLoginInviteRequest} GenerateLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateLoginInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateLoginInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateLoginInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateLoginInviteRequest} GenerateLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateLoginInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateLoginInviteRequest message.
     * @function verify
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateLoginInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        return null;
    };

    /**
     * Creates a GenerateLoginInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateLoginInviteRequest} GenerateLoginInviteRequest
     */
    GenerateLoginInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateLoginInviteRequest)
            return object;
        var message = new $root.GenerateLoginInviteRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        return message;
    };

    /**
     * Creates a plain object from a GenerateLoginInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {GenerateLoginInviteRequest} message GenerateLoginInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateLoginInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.providerId = "";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        return object;
    };

    /**
     * Converts this GenerateLoginInviteRequest to JSON.
     * @function toJSON
     * @memberof GenerateLoginInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateLoginInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateLoginInviteRequest
     * @function getTypeUrl
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateLoginInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateLoginInviteRequest";
    };

    return GenerateLoginInviteRequest;
})();

$root.GenerateLoginInviteResponse = (function() {

    /**
     * Properties of a GenerateLoginInviteResponse.
     * @exports IGenerateLoginInviteResponse
     * @interface IGenerateLoginInviteResponse
     * @property {boolean|null} [okay] GenerateLoginInviteResponse okay
     * @property {string|null} [inviteCode] GenerateLoginInviteResponse inviteCode
     */

    /**
     * Constructs a new GenerateLoginInviteResponse.
     * @exports GenerateLoginInviteResponse
     * @classdesc Represents a GenerateLoginInviteResponse.
     * @implements IGenerateLoginInviteResponse
     * @constructor
     * @param {IGenerateLoginInviteResponse=} [properties] Properties to set
     */
    function GenerateLoginInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateLoginInviteResponse okay.
     * @member {boolean} okay
     * @memberof GenerateLoginInviteResponse
     * @instance
     */
    GenerateLoginInviteResponse.prototype.okay = false;

    /**
     * GenerateLoginInviteResponse inviteCode.
     * @member {string} inviteCode
     * @memberof GenerateLoginInviteResponse
     * @instance
     */
    GenerateLoginInviteResponse.prototype.inviteCode = "";

    /**
     * Creates a new GenerateLoginInviteResponse instance using the specified properties.
     * @function create
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {IGenerateLoginInviteResponse=} [properties] Properties to set
     * @returns {GenerateLoginInviteResponse} GenerateLoginInviteResponse instance
     */
    GenerateLoginInviteResponse.create = function create(properties) {
        return new GenerateLoginInviteResponse(properties);
    };

    /**
     * Encodes the specified GenerateLoginInviteResponse message. Does not implicitly {@link GenerateLoginInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {IGenerateLoginInviteResponse} message GenerateLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateLoginInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified GenerateLoginInviteResponse message, length delimited. Does not implicitly {@link GenerateLoginInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {IGenerateLoginInviteResponse} message GenerateLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateLoginInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateLoginInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateLoginInviteResponse} GenerateLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateLoginInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateLoginInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateLoginInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateLoginInviteResponse} GenerateLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateLoginInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateLoginInviteResponse message.
     * @function verify
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateLoginInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a GenerateLoginInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateLoginInviteResponse} GenerateLoginInviteResponse
     */
    GenerateLoginInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateLoginInviteResponse)
            return object;
        var message = new $root.GenerateLoginInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a GenerateLoginInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {GenerateLoginInviteResponse} message GenerateLoginInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateLoginInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.inviteCode = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this GenerateLoginInviteResponse to JSON.
     * @function toJSON
     * @memberof GenerateLoginInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateLoginInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateLoginInviteResponse
     * @function getTypeUrl
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateLoginInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateLoginInviteResponse";
    };

    return GenerateLoginInviteResponse;
})();

$root.UpdateProviderSurveyRequest = (function() {

    /**
     * Properties of an UpdateProviderSurveyRequest.
     * @exports IUpdateProviderSurveyRequest
     * @interface IUpdateProviderSurveyRequest
     * @property {space.kenko.proto.IProviderSurveyProto|null} [survey] UpdateProviderSurveyRequest survey
     * @property {boolean|null} [surveyComplete] UpdateProviderSurveyRequest surveyComplete
     */

    /**
     * Constructs a new UpdateProviderSurveyRequest.
     * @exports UpdateProviderSurveyRequest
     * @classdesc Represents an UpdateProviderSurveyRequest.
     * @implements IUpdateProviderSurveyRequest
     * @constructor
     * @param {IUpdateProviderSurveyRequest=} [properties] Properties to set
     */
    function UpdateProviderSurveyRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateProviderSurveyRequest survey.
     * @member {space.kenko.proto.IProviderSurveyProto|null|undefined} survey
     * @memberof UpdateProviderSurveyRequest
     * @instance
     */
    UpdateProviderSurveyRequest.prototype.survey = null;

    /**
     * UpdateProviderSurveyRequest surveyComplete.
     * @member {boolean} surveyComplete
     * @memberof UpdateProviderSurveyRequest
     * @instance
     */
    UpdateProviderSurveyRequest.prototype.surveyComplete = false;

    /**
     * Creates a new UpdateProviderSurveyRequest instance using the specified properties.
     * @function create
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {IUpdateProviderSurveyRequest=} [properties] Properties to set
     * @returns {UpdateProviderSurveyRequest} UpdateProviderSurveyRequest instance
     */
    UpdateProviderSurveyRequest.create = function create(properties) {
        return new UpdateProviderSurveyRequest(properties);
    };

    /**
     * Encodes the specified UpdateProviderSurveyRequest message. Does not implicitly {@link UpdateProviderSurveyRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {IUpdateProviderSurveyRequest} message UpdateProviderSurveyRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderSurveyRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.survey != null && Object.hasOwnProperty.call(message, "survey"))
            $root.space.kenko.proto.ProviderSurveyProto.encode(message.survey, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.surveyComplete != null && Object.hasOwnProperty.call(message, "surveyComplete"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.surveyComplete);
        return writer;
    };

    /**
     * Encodes the specified UpdateProviderSurveyRequest message, length delimited. Does not implicitly {@link UpdateProviderSurveyRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {IUpdateProviderSurveyRequest} message UpdateProviderSurveyRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderSurveyRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateProviderSurveyRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateProviderSurveyRequest} UpdateProviderSurveyRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderSurveyRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateProviderSurveyRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.survey = $root.space.kenko.proto.ProviderSurveyProto.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.surveyComplete = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateProviderSurveyRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateProviderSurveyRequest} UpdateProviderSurveyRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderSurveyRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateProviderSurveyRequest message.
     * @function verify
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateProviderSurveyRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.survey != null && message.hasOwnProperty("survey")) {
            var error = $root.space.kenko.proto.ProviderSurveyProto.verify(message.survey);
            if (error)
                return "survey." + error;
        }
        if (message.surveyComplete != null && message.hasOwnProperty("surveyComplete"))
            if (typeof message.surveyComplete !== "boolean")
                return "surveyComplete: boolean expected";
        return null;
    };

    /**
     * Creates an UpdateProviderSurveyRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateProviderSurveyRequest} UpdateProviderSurveyRequest
     */
    UpdateProviderSurveyRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateProviderSurveyRequest)
            return object;
        var message = new $root.UpdateProviderSurveyRequest();
        if (object.survey != null) {
            if (typeof object.survey !== "object")
                throw TypeError(".UpdateProviderSurveyRequest.survey: object expected");
            message.survey = $root.space.kenko.proto.ProviderSurveyProto.fromObject(object.survey);
        }
        if (object.surveyComplete != null)
            message.surveyComplete = Boolean(object.surveyComplete);
        return message;
    };

    /**
     * Creates a plain object from an UpdateProviderSurveyRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {UpdateProviderSurveyRequest} message UpdateProviderSurveyRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateProviderSurveyRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.survey = null;
            object.surveyComplete = false;
        }
        if (message.survey != null && message.hasOwnProperty("survey"))
            object.survey = $root.space.kenko.proto.ProviderSurveyProto.toObject(message.survey, options);
        if (message.surveyComplete != null && message.hasOwnProperty("surveyComplete"))
            object.surveyComplete = message.surveyComplete;
        return object;
    };

    /**
     * Converts this UpdateProviderSurveyRequest to JSON.
     * @function toJSON
     * @memberof UpdateProviderSurveyRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateProviderSurveyRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateProviderSurveyRequest
     * @function getTypeUrl
     * @memberof UpdateProviderSurveyRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateProviderSurveyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateProviderSurveyRequest";
    };

    return UpdateProviderSurveyRequest;
})();

$root.UpdateProviderSurveyResponse = (function() {

    /**
     * Properties of an UpdateProviderSurveyResponse.
     * @exports IUpdateProviderSurveyResponse
     * @interface IUpdateProviderSurveyResponse
     * @property {boolean|null} [okay] UpdateProviderSurveyResponse okay
     * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] UpdateProviderSurveyResponse errors
     * @property {IApiBusinessProto|null} [business] UpdateProviderSurveyResponse business
     * @property {IApiBusinessProfileProto|null} [profile] UpdateProviderSurveyResponse profile
     */

    /**
     * Constructs a new UpdateProviderSurveyResponse.
     * @exports UpdateProviderSurveyResponse
     * @classdesc Represents an UpdateProviderSurveyResponse.
     * @implements IUpdateProviderSurveyResponse
     * @constructor
     * @param {IUpdateProviderSurveyResponse=} [properties] Properties to set
     */
    function UpdateProviderSurveyResponse(properties) {
        this.errors = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateProviderSurveyResponse okay.
     * @member {boolean} okay
     * @memberof UpdateProviderSurveyResponse
     * @instance
     */
    UpdateProviderSurveyResponse.prototype.okay = false;

    /**
     * UpdateProviderSurveyResponse errors.
     * @member {Array.<space.kenko.proto.IErrorProto>} errors
     * @memberof UpdateProviderSurveyResponse
     * @instance
     */
    UpdateProviderSurveyResponse.prototype.errors = $util.emptyArray;

    /**
     * UpdateProviderSurveyResponse business.
     * @member {IApiBusinessProto|null|undefined} business
     * @memberof UpdateProviderSurveyResponse
     * @instance
     */
    UpdateProviderSurveyResponse.prototype.business = null;

    /**
     * UpdateProviderSurveyResponse profile.
     * @member {IApiBusinessProfileProto|null|undefined} profile
     * @memberof UpdateProviderSurveyResponse
     * @instance
     */
    UpdateProviderSurveyResponse.prototype.profile = null;

    /**
     * Creates a new UpdateProviderSurveyResponse instance using the specified properties.
     * @function create
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {IUpdateProviderSurveyResponse=} [properties] Properties to set
     * @returns {UpdateProviderSurveyResponse} UpdateProviderSurveyResponse instance
     */
    UpdateProviderSurveyResponse.create = function create(properties) {
        return new UpdateProviderSurveyResponse(properties);
    };

    /**
     * Encodes the specified UpdateProviderSurveyResponse message. Does not implicitly {@link UpdateProviderSurveyResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {IUpdateProviderSurveyResponse} message UpdateProviderSurveyResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderSurveyResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.errors != null && message.errors.length)
            for (var i = 0; i < message.errors.length; ++i)
                $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.business != null && Object.hasOwnProperty.call(message, "business"))
            $root.ApiBusinessProto.encode(message.business, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ApiBusinessProfileProto.encode(message.profile, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateProviderSurveyResponse message, length delimited. Does not implicitly {@link UpdateProviderSurveyResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {IUpdateProviderSurveyResponse} message UpdateProviderSurveyResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderSurveyResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateProviderSurveyResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateProviderSurveyResponse} UpdateProviderSurveyResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderSurveyResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateProviderSurveyResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.errors && message.errors.length))
                        message.errors = [];
                    message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.business = $root.ApiBusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.profile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateProviderSurveyResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateProviderSurveyResponse} UpdateProviderSurveyResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderSurveyResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateProviderSurveyResponse message.
     * @function verify
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateProviderSurveyResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.errors != null && message.hasOwnProperty("errors")) {
            if (!Array.isArray(message.errors))
                return "errors: array expected";
            for (var i = 0; i < message.errors.length; ++i) {
                var error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                if (error)
                    return "errors." + error;
            }
        }
        if (message.business != null && message.hasOwnProperty("business")) {
            var error = $root.ApiBusinessProto.verify(message.business);
            if (error)
                return "business." + error;
        }
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateProviderSurveyResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateProviderSurveyResponse} UpdateProviderSurveyResponse
     */
    UpdateProviderSurveyResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateProviderSurveyResponse)
            return object;
        var message = new $root.UpdateProviderSurveyResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.errors) {
            if (!Array.isArray(object.errors))
                throw TypeError(".UpdateProviderSurveyResponse.errors: array expected");
            message.errors = [];
            for (var i = 0; i < object.errors.length; ++i) {
                if (typeof object.errors[i] !== "object")
                    throw TypeError(".UpdateProviderSurveyResponse.errors: object expected");
                message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
            }
        }
        if (object.business != null) {
            if (typeof object.business !== "object")
                throw TypeError(".UpdateProviderSurveyResponse.business: object expected");
            message.business = $root.ApiBusinessProto.fromObject(object.business);
        }
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".UpdateProviderSurveyResponse.profile: object expected");
            message.profile = $root.ApiBusinessProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateProviderSurveyResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {UpdateProviderSurveyResponse} message UpdateProviderSurveyResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateProviderSurveyResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.errors = [];
        if (options.defaults) {
            object.okay = false;
            object.business = null;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.errors && message.errors.length) {
            object.errors = [];
            for (var j = 0; j < message.errors.length; ++j)
                object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
        }
        if (message.business != null && message.hasOwnProperty("business"))
            object.business = $root.ApiBusinessProto.toObject(message.business, options);
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ApiBusinessProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this UpdateProviderSurveyResponse to JSON.
     * @function toJSON
     * @memberof UpdateProviderSurveyResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateProviderSurveyResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateProviderSurveyResponse
     * @function getTypeUrl
     * @memberof UpdateProviderSurveyResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateProviderSurveyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateProviderSurveyResponse";
    };

    return UpdateProviderSurveyResponse;
})();

$root.AdminBusinessSearchRequest = (function() {

    /**
     * Properties of an AdminBusinessSearchRequest.
     * @exports IAdminBusinessSearchRequest
     * @interface IAdminBusinessSearchRequest
     * @property {string|null} [query] AdminBusinessSearchRequest query
     */

    /**
     * Constructs a new AdminBusinessSearchRequest.
     * @exports AdminBusinessSearchRequest
     * @classdesc Represents an AdminBusinessSearchRequest.
     * @implements IAdminBusinessSearchRequest
     * @constructor
     * @param {IAdminBusinessSearchRequest=} [properties] Properties to set
     */
    function AdminBusinessSearchRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminBusinessSearchRequest query.
     * @member {string} query
     * @memberof AdminBusinessSearchRequest
     * @instance
     */
    AdminBusinessSearchRequest.prototype.query = "";

    /**
     * Creates a new AdminBusinessSearchRequest instance using the specified properties.
     * @function create
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {IAdminBusinessSearchRequest=} [properties] Properties to set
     * @returns {AdminBusinessSearchRequest} AdminBusinessSearchRequest instance
     */
    AdminBusinessSearchRequest.create = function create(properties) {
        return new AdminBusinessSearchRequest(properties);
    };

    /**
     * Encodes the specified AdminBusinessSearchRequest message. Does not implicitly {@link AdminBusinessSearchRequest.verify|verify} messages.
     * @function encode
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {IAdminBusinessSearchRequest} message AdminBusinessSearchRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessSearchRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.query != null && Object.hasOwnProperty.call(message, "query"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.query);
        return writer;
    };

    /**
     * Encodes the specified AdminBusinessSearchRequest message, length delimited. Does not implicitly {@link AdminBusinessSearchRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {IAdminBusinessSearchRequest} message AdminBusinessSearchRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessSearchRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminBusinessSearchRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminBusinessSearchRequest} AdminBusinessSearchRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessSearchRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminBusinessSearchRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.query = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminBusinessSearchRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminBusinessSearchRequest} AdminBusinessSearchRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessSearchRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminBusinessSearchRequest message.
     * @function verify
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminBusinessSearchRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.query != null && message.hasOwnProperty("query"))
            if (!$util.isString(message.query))
                return "query: string expected";
        return null;
    };

    /**
     * Creates an AdminBusinessSearchRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminBusinessSearchRequest} AdminBusinessSearchRequest
     */
    AdminBusinessSearchRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminBusinessSearchRequest)
            return object;
        var message = new $root.AdminBusinessSearchRequest();
        if (object.query != null)
            message.query = String(object.query);
        return message;
    };

    /**
     * Creates a plain object from an AdminBusinessSearchRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {AdminBusinessSearchRequest} message AdminBusinessSearchRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminBusinessSearchRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.query = "";
        if (message.query != null && message.hasOwnProperty("query"))
            object.query = message.query;
        return object;
    };

    /**
     * Converts this AdminBusinessSearchRequest to JSON.
     * @function toJSON
     * @memberof AdminBusinessSearchRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminBusinessSearchRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminBusinessSearchRequest
     * @function getTypeUrl
     * @memberof AdminBusinessSearchRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminBusinessSearchRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminBusinessSearchRequest";
    };

    return AdminBusinessSearchRequest;
})();

$root.AdminBusinessSearchResponse = (function() {

    /**
     * Properties of an AdminBusinessSearchResponse.
     * @exports IAdminBusinessSearchResponse
     * @interface IAdminBusinessSearchResponse
     * @property {boolean|null} [okay] AdminBusinessSearchResponse okay
     * @property {Array.<AdminBusinessSearchResponse.IBusinessSearchResult>|null} [searchResult] AdminBusinessSearchResponse searchResult
     */

    /**
     * Constructs a new AdminBusinessSearchResponse.
     * @exports AdminBusinessSearchResponse
     * @classdesc Represents an AdminBusinessSearchResponse.
     * @implements IAdminBusinessSearchResponse
     * @constructor
     * @param {IAdminBusinessSearchResponse=} [properties] Properties to set
     */
    function AdminBusinessSearchResponse(properties) {
        this.searchResult = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminBusinessSearchResponse okay.
     * @member {boolean} okay
     * @memberof AdminBusinessSearchResponse
     * @instance
     */
    AdminBusinessSearchResponse.prototype.okay = false;

    /**
     * AdminBusinessSearchResponse searchResult.
     * @member {Array.<AdminBusinessSearchResponse.IBusinessSearchResult>} searchResult
     * @memberof AdminBusinessSearchResponse
     * @instance
     */
    AdminBusinessSearchResponse.prototype.searchResult = $util.emptyArray;

    /**
     * Creates a new AdminBusinessSearchResponse instance using the specified properties.
     * @function create
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {IAdminBusinessSearchResponse=} [properties] Properties to set
     * @returns {AdminBusinessSearchResponse} AdminBusinessSearchResponse instance
     */
    AdminBusinessSearchResponse.create = function create(properties) {
        return new AdminBusinessSearchResponse(properties);
    };

    /**
     * Encodes the specified AdminBusinessSearchResponse message. Does not implicitly {@link AdminBusinessSearchResponse.verify|verify} messages.
     * @function encode
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {IAdminBusinessSearchResponse} message AdminBusinessSearchResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessSearchResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.searchResult != null && message.searchResult.length)
            for (var i = 0; i < message.searchResult.length; ++i)
                $root.AdminBusinessSearchResponse.BusinessSearchResult.encode(message.searchResult[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AdminBusinessSearchResponse message, length delimited. Does not implicitly {@link AdminBusinessSearchResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {IAdminBusinessSearchResponse} message AdminBusinessSearchResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessSearchResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminBusinessSearchResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminBusinessSearchResponse} AdminBusinessSearchResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessSearchResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminBusinessSearchResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.searchResult && message.searchResult.length))
                        message.searchResult = [];
                    message.searchResult.push($root.AdminBusinessSearchResponse.BusinessSearchResult.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminBusinessSearchResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminBusinessSearchResponse} AdminBusinessSearchResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessSearchResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminBusinessSearchResponse message.
     * @function verify
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminBusinessSearchResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.searchResult != null && message.hasOwnProperty("searchResult")) {
            if (!Array.isArray(message.searchResult))
                return "searchResult: array expected";
            for (var i = 0; i < message.searchResult.length; ++i) {
                var error = $root.AdminBusinessSearchResponse.BusinessSearchResult.verify(message.searchResult[i]);
                if (error)
                    return "searchResult." + error;
            }
        }
        return null;
    };

    /**
     * Creates an AdminBusinessSearchResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminBusinessSearchResponse} AdminBusinessSearchResponse
     */
    AdminBusinessSearchResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminBusinessSearchResponse)
            return object;
        var message = new $root.AdminBusinessSearchResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.searchResult) {
            if (!Array.isArray(object.searchResult))
                throw TypeError(".AdminBusinessSearchResponse.searchResult: array expected");
            message.searchResult = [];
            for (var i = 0; i < object.searchResult.length; ++i) {
                if (typeof object.searchResult[i] !== "object")
                    throw TypeError(".AdminBusinessSearchResponse.searchResult: object expected");
                message.searchResult[i] = $root.AdminBusinessSearchResponse.BusinessSearchResult.fromObject(object.searchResult[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an AdminBusinessSearchResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {AdminBusinessSearchResponse} message AdminBusinessSearchResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminBusinessSearchResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.searchResult = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.searchResult && message.searchResult.length) {
            object.searchResult = [];
            for (var j = 0; j < message.searchResult.length; ++j)
                object.searchResult[j] = $root.AdminBusinessSearchResponse.BusinessSearchResult.toObject(message.searchResult[j], options);
        }
        return object;
    };

    /**
     * Converts this AdminBusinessSearchResponse to JSON.
     * @function toJSON
     * @memberof AdminBusinessSearchResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminBusinessSearchResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminBusinessSearchResponse
     * @function getTypeUrl
     * @memberof AdminBusinessSearchResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminBusinessSearchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminBusinessSearchResponse";
    };

    AdminBusinessSearchResponse.BusinessSearchResult = (function() {

        /**
         * Properties of a BusinessSearchResult.
         * @memberof AdminBusinessSearchResponse
         * @interface IBusinessSearchResult
         * @property {IApiBusinessProto|null} [business] BusinessSearchResult business
         * @property {IApiBusinessProfileProto|null} [profile] BusinessSearchResult profile
         */

        /**
         * Constructs a new BusinessSearchResult.
         * @memberof AdminBusinessSearchResponse
         * @classdesc Represents a BusinessSearchResult.
         * @implements IBusinessSearchResult
         * @constructor
         * @param {AdminBusinessSearchResponse.IBusinessSearchResult=} [properties] Properties to set
         */
        function BusinessSearchResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BusinessSearchResult business.
         * @member {IApiBusinessProto|null|undefined} business
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @instance
         */
        BusinessSearchResult.prototype.business = null;

        /**
         * BusinessSearchResult profile.
         * @member {IApiBusinessProfileProto|null|undefined} profile
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @instance
         */
        BusinessSearchResult.prototype.profile = null;

        /**
         * Creates a new BusinessSearchResult instance using the specified properties.
         * @function create
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {AdminBusinessSearchResponse.IBusinessSearchResult=} [properties] Properties to set
         * @returns {AdminBusinessSearchResponse.BusinessSearchResult} BusinessSearchResult instance
         */
        BusinessSearchResult.create = function create(properties) {
            return new BusinessSearchResult(properties);
        };

        /**
         * Encodes the specified BusinessSearchResult message. Does not implicitly {@link AdminBusinessSearchResponse.BusinessSearchResult.verify|verify} messages.
         * @function encode
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {AdminBusinessSearchResponse.IBusinessSearchResult} message BusinessSearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BusinessSearchResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.business != null && Object.hasOwnProperty.call(message, "business"))
                $root.ApiBusinessProto.encode(message.business, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.ApiBusinessProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BusinessSearchResult message, length delimited. Does not implicitly {@link AdminBusinessSearchResponse.BusinessSearchResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {AdminBusinessSearchResponse.IBusinessSearchResult} message BusinessSearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BusinessSearchResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BusinessSearchResult message from the specified reader or buffer.
         * @function decode
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AdminBusinessSearchResponse.BusinessSearchResult} BusinessSearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BusinessSearchResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminBusinessSearchResponse.BusinessSearchResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.business = $root.ApiBusinessProto.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.profile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BusinessSearchResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AdminBusinessSearchResponse.BusinessSearchResult} BusinessSearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BusinessSearchResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BusinessSearchResult message.
         * @function verify
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BusinessSearchResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.business != null && message.hasOwnProperty("business")) {
                var error = $root.ApiBusinessProto.verify(message.business);
                if (error)
                    return "business." + error;
            }
            if (message.profile != null && message.hasOwnProperty("profile")) {
                var error = $root.ApiBusinessProfileProto.verify(message.profile);
                if (error)
                    return "profile." + error;
            }
            return null;
        };

        /**
         * Creates a BusinessSearchResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AdminBusinessSearchResponse.BusinessSearchResult} BusinessSearchResult
         */
        BusinessSearchResult.fromObject = function fromObject(object) {
            if (object instanceof $root.AdminBusinessSearchResponse.BusinessSearchResult)
                return object;
            var message = new $root.AdminBusinessSearchResponse.BusinessSearchResult();
            if (object.business != null) {
                if (typeof object.business !== "object")
                    throw TypeError(".AdminBusinessSearchResponse.BusinessSearchResult.business: object expected");
                message.business = $root.ApiBusinessProto.fromObject(object.business);
            }
            if (object.profile != null) {
                if (typeof object.profile !== "object")
                    throw TypeError(".AdminBusinessSearchResponse.BusinessSearchResult.profile: object expected");
                message.profile = $root.ApiBusinessProfileProto.fromObject(object.profile);
            }
            return message;
        };

        /**
         * Creates a plain object from a BusinessSearchResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {AdminBusinessSearchResponse.BusinessSearchResult} message BusinessSearchResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BusinessSearchResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.business = null;
                object.profile = null;
            }
            if (message.business != null && message.hasOwnProperty("business"))
                object.business = $root.ApiBusinessProto.toObject(message.business, options);
            if (message.profile != null && message.hasOwnProperty("profile"))
                object.profile = $root.ApiBusinessProfileProto.toObject(message.profile, options);
            return object;
        };

        /**
         * Converts this BusinessSearchResult to JSON.
         * @function toJSON
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BusinessSearchResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BusinessSearchResult
         * @function getTypeUrl
         * @memberof AdminBusinessSearchResponse.BusinessSearchResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BusinessSearchResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/AdminBusinessSearchResponse.BusinessSearchResult";
        };

        return BusinessSearchResult;
    })();

    return AdminBusinessSearchResponse;
})();

$root.space = (function() {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    var space = {};

    space.kenko = (function() {

        /**
         * Namespace kenko.
         * @memberof space
         * @namespace
         */
        var kenko = {};

        kenko.proto = (function() {

            /**
             * Namespace proto.
             * @memberof space.kenko
             * @namespace
             */
            var proto = {};

            proto.LocalTimeProto = (function() {

                /**
                 * Properties of a LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalTimeProto
                 * @property {number|null} [hour] LocalTimeProto hour
                 * @property {number|null} [minute] LocalTimeProto minute
                 */

                /**
                 * Constructs a new LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalTimeProto.
                 * @implements ILocalTimeProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 */
                function LocalTimeProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalTimeProto hour.
                 * @member {number} hour
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.hour = 0;

                /**
                 * LocalTimeProto minute.
                 * @member {number} minute
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.minute = 0;

                /**
                 * Creates a new LocalTimeProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto instance
                 */
                LocalTimeProto.create = function create(properties) {
                    return new LocalTimeProto(properties);
                };

                /**
                 * Encodes the specified LocalTimeProto message. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hour);
                    if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minute);
                    return writer;
                };

                /**
                 * Encodes the specified LocalTimeProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalTimeProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hour = reader.int32();
                                break;
                            }
                        case 2: {
                                message.minute = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalTimeProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalTimeProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        if (!$util.isInteger(message.hour))
                            return "hour: integer expected";
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        if (!$util.isInteger(message.minute))
                            return "minute: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalTimeProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 */
                LocalTimeProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalTimeProto)
                        return object;
                    var message = new $root.space.kenko.proto.LocalTimeProto();
                    if (object.hour != null)
                        message.hour = object.hour | 0;
                    if (object.minute != null)
                        message.minute = object.minute | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalTimeProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.LocalTimeProto} message LocalTimeProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalTimeProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hour = 0;
                        object.minute = 0;
                    }
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        object.hour = message.hour;
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        object.minute = message.minute;
                    return object;
                };

                /**
                 * Converts this LocalTimeProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalTimeProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalTimeProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalTimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalTimeProto";
                };

                return LocalTimeProto;
            })();

            proto.LocalDateProto = (function() {

                /**
                 * Properties of a LocalDateProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalDateProto
                 * @property {number|null} [year] LocalDateProto year
                 * @property {number|null} [month] LocalDateProto month
                 * @property {number|null} [date] LocalDateProto date
                 */

                /**
                 * Constructs a new LocalDateProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalDateProto.
                 * @implements ILocalDateProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalDateProto=} [properties] Properties to set
                 */
                function LocalDateProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalDateProto year.
                 * @member {number} year
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.year = 0;

                /**
                 * LocalDateProto month.
                 * @member {number} month
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.month = 0;

                /**
                 * LocalDateProto date.
                 * @member {number} date
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.date = 0;

                /**
                 * Creates a new LocalDateProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto instance
                 */
                LocalDateProto.create = function create(properties) {
                    return new LocalDateProto(properties);
                };

                /**
                 * Encodes the specified LocalDateProto message. Does not implicitly {@link space.kenko.proto.LocalDateProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto} message LocalDateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalDateProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.date);
                    return writer;
                };

                /**
                 * Encodes the specified LocalDateProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalDateProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto} message LocalDateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalDateProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalDateProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalDateProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalDateProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.year = reader.int32();
                                break;
                            }
                        case 2: {
                                message.month = reader.int32();
                                break;
                            }
                        case 3: {
                                message.date = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalDateProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalDateProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalDateProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalDateProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isInteger(message.month))
                            return "month: integer expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isInteger(message.date))
                            return "date: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalDateProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 */
                LocalDateProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalDateProto)
                        return object;
                    var message = new $root.space.kenko.proto.LocalDateProto();
                    if (object.year != null)
                        message.year = object.year | 0;
                    if (object.month != null)
                        message.month = object.month | 0;
                    if (object.date != null)
                        message.date = object.date | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalDateProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.LocalDateProto} message LocalDateProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalDateProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.year = 0;
                        object.month = 0;
                        object.date = 0;
                    }
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    return object;
                };

                /**
                 * Converts this LocalDateProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalDateProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalDateProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalDateProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalDateProto";
                };

                return LocalDateProto;
            })();

            proto.PriceProto = (function() {

                /**
                 * Properties of a PriceProto.
                 * @memberof space.kenko.proto
                 * @interface IPriceProto
                 * @property {number|null} [price] PriceProto price
                 * @property {string|null} [currencyCode] PriceProto currencyCode
                 */

                /**
                 * Constructs a new PriceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PriceProto.
                 * @implements IPriceProto
                 * @constructor
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 */
                function PriceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PriceProto price.
                 * @member {number} price
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.price = 0;

                /**
                 * PriceProto currencyCode.
                 * @member {string} currencyCode
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.currencyCode = "";

                /**
                 * Creates a new PriceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PriceProto} PriceProto instance
                 */
                PriceProto.create = function create(properties) {
                    return new PriceProto(properties);
                };

                /**
                 * Encodes the specified PriceProto message. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.price);
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                    return writer;
                };

                /**
                 * Encodes the specified PriceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PriceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.price = reader.int32();
                                break;
                            }
                        case 2: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PriceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PriceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (!$util.isInteger(message.price))
                            return "price: integer expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    return null;
                };

                /**
                 * Creates a PriceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 */
                PriceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PriceProto)
                        return object;
                    var message = new $root.space.kenko.proto.PriceProto();
                    if (object.price != null)
                        message.price = object.price | 0;
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    return message;
                };

                /**
                 * Creates a plain object from a PriceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.PriceProto} message PriceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.price = 0;
                        object.currencyCode = "";
                    }
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = message.price;
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    return object;
                };

                /**
                 * Converts this PriceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PriceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PriceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PriceProto";
                };

                return PriceProto;
            })();

            proto.ErrorProto = (function() {

                /**
                 * Properties of an ErrorProto.
                 * @memberof space.kenko.proto
                 * @interface IErrorProto
                 * @property {string|null} [path] ErrorProto path
                 * @property {string|null} [error] ErrorProto error
                 */

                /**
                 * Constructs a new ErrorProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an ErrorProto.
                 * @implements IErrorProto
                 * @constructor
                 * @param {space.kenko.proto.IErrorProto=} [properties] Properties to set
                 */
                function ErrorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ErrorProto path.
                 * @member {string} path
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 */
                ErrorProto.prototype.path = "";

                /**
                 * ErrorProto error.
                 * @member {string} error
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 */
                ErrorProto.prototype.error = "";

                /**
                 * Creates a new ErrorProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto instance
                 */
                ErrorProto.create = function create(properties) {
                    return new ErrorProto(properties);
                };

                /**
                 * Encodes the specified ErrorProto message. Does not implicitly {@link space.kenko.proto.ErrorProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto} message ErrorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ErrorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
                    return writer;
                };

                /**
                 * Encodes the specified ErrorProto message, length delimited. Does not implicitly {@link space.kenko.proto.ErrorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto} message ErrorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ErrorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ErrorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ErrorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ErrorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.path = reader.string();
                                break;
                            }
                        case 2: {
                                message.error = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ErrorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ErrorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ErrorProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ErrorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path"))
                        if (!$util.isString(message.path))
                            return "path: string expected";
                    if (message.error != null && message.hasOwnProperty("error"))
                        if (!$util.isString(message.error))
                            return "error: string expected";
                    return null;
                };

                /**
                 * Creates an ErrorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 */
                ErrorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ErrorProto)
                        return object;
                    var message = new $root.space.kenko.proto.ErrorProto();
                    if (object.path != null)
                        message.path = String(object.path);
                    if (object.error != null)
                        message.error = String(object.error);
                    return message;
                };

                /**
                 * Creates a plain object from an ErrorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.ErrorProto} message ErrorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ErrorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.path = "";
                        object.error = "";
                    }
                    if (message.path != null && message.hasOwnProperty("path"))
                        object.path = message.path;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = message.error;
                    return object;
                };

                /**
                 * Converts this ErrorProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ErrorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ErrorProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ErrorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ErrorProto";
                };

                return ErrorProto;
            })();

            proto.BusinessProto = (function() {

                /**
                 * Properties of a BusinessProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessProto
                 * @property {string|null} [businessName] BusinessProto businessName
                 * @property {string|null} [businessDescription] BusinessProto businessDescription
                 * @property {string|null} [websiteUrl] BusinessProto websiteUrl
                 * @property {string|null} [phoneNumber] BusinessProto phoneNumber
                 * @property {string|null} [email] BusinessProto email
                 * @property {Array.<string>|null} [imageUrl] BusinessProto imageUrl
                 * @property {string|null} [shortUrl] BusinessProto shortUrl
                 * @property {space.kenko.proto.IVirtualAppointmentInfo|null} [virtualAppointmentInfo] BusinessProto virtualAppointmentInfo
                 * @property {Array.<string>|null} [servicesOffered] BusinessProto servicesOffered
                 */

                /**
                 * Constructs a new BusinessProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessProto.
                 * @implements IBusinessProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 */
                function BusinessProto(properties) {
                    this.imageUrl = [];
                    this.servicesOffered = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessProto businessName.
                 * @member {string} businessName
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessName = "";

                /**
                 * BusinessProto businessDescription.
                 * @member {string} businessDescription
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessDescription = "";

                /**
                 * BusinessProto websiteUrl.
                 * @member {string} websiteUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.websiteUrl = "";

                /**
                 * BusinessProto phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.phoneNumber = "";

                /**
                 * BusinessProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.email = "";

                /**
                 * BusinessProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * BusinessProto shortUrl.
                 * @member {string} shortUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.shortUrl = "";

                /**
                 * BusinessProto virtualAppointmentInfo.
                 * @member {space.kenko.proto.IVirtualAppointmentInfo|null|undefined} virtualAppointmentInfo
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.virtualAppointmentInfo = null;

                /**
                 * BusinessProto servicesOffered.
                 * @member {Array.<string>} servicesOffered
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.servicesOffered = $util.emptyArray;

                /**
                 * Creates a new BusinessProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto instance
                 */
                BusinessProto.create = function create(properties) {
                    return new BusinessProto(properties);
                };

                /**
                 * Encodes the specified BusinessProto message. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.businessDescription != null && Object.hasOwnProperty.call(message, "businessDescription"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessDescription);
                    if (message.websiteUrl != null && Object.hasOwnProperty.call(message, "websiteUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.websiteUrl);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phoneNumber);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.imageUrl[i]);
                    if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.shortUrl);
                    if (message.virtualAppointmentInfo != null && Object.hasOwnProperty.call(message, "virtualAppointmentInfo"))
                        $root.space.kenko.proto.VirtualAppointmentInfo.encode(message.virtualAppointmentInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.servicesOffered != null && message.servicesOffered.length)
                        for (var i = 0; i < message.servicesOffered.length; ++i)
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.servicesOffered[i]);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessDescription = reader.string();
                                break;
                            }
                        case 3: {
                                message.websiteUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.shortUrl = reader.string();
                                break;
                            }
                        case 8: {
                                message.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                if (!(message.servicesOffered && message.servicesOffered.length))
                                    message.servicesOffered = [];
                                message.servicesOffered.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        if (!$util.isString(message.businessDescription))
                            return "businessDescription: string expected";
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        if (!$util.isString(message.websiteUrl))
                            return "websiteUrl: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        if (!$util.isString(message.shortUrl))
                            return "shortUrl: string expected";
                    if (message.virtualAppointmentInfo != null && message.hasOwnProperty("virtualAppointmentInfo")) {
                        var error = $root.space.kenko.proto.VirtualAppointmentInfo.verify(message.virtualAppointmentInfo);
                        if (error)
                            return "virtualAppointmentInfo." + error;
                    }
                    if (message.servicesOffered != null && message.hasOwnProperty("servicesOffered")) {
                        if (!Array.isArray(message.servicesOffered))
                            return "servicesOffered: array expected";
                        for (var i = 0; i < message.servicesOffered.length; ++i)
                            if (!$util.isString(message.servicesOffered[i]))
                                return "servicesOffered: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a BusinessProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 */
                BusinessProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessProto)
                        return object;
                    var message = new $root.space.kenko.proto.BusinessProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessDescription != null)
                        message.businessDescription = String(object.businessDescription);
                    if (object.websiteUrl != null)
                        message.websiteUrl = String(object.websiteUrl);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (var i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    if (object.shortUrl != null)
                        message.shortUrl = String(object.shortUrl);
                    if (object.virtualAppointmentInfo != null) {
                        if (typeof object.virtualAppointmentInfo !== "object")
                            throw TypeError(".space.kenko.proto.BusinessProto.virtualAppointmentInfo: object expected");
                        message.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.fromObject(object.virtualAppointmentInfo);
                    }
                    if (object.servicesOffered) {
                        if (!Array.isArray(object.servicesOffered))
                            throw TypeError(".space.kenko.proto.BusinessProto.servicesOffered: array expected");
                        message.servicesOffered = [];
                        for (var i = 0; i < object.servicesOffered.length; ++i)
                            message.servicesOffered[i] = String(object.servicesOffered[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.BusinessProto} message BusinessProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.imageUrl = [];
                        object.servicesOffered = [];
                    }
                    if (options.defaults) {
                        object.businessName = "";
                        object.businessDescription = "";
                        object.websiteUrl = "";
                        object.phoneNumber = "";
                        object.email = "";
                        object.shortUrl = "";
                        object.virtualAppointmentInfo = null;
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        object.businessDescription = message.businessDescription;
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        object.websiteUrl = message.websiteUrl;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (var j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        object.shortUrl = message.shortUrl;
                    if (message.virtualAppointmentInfo != null && message.hasOwnProperty("virtualAppointmentInfo"))
                        object.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.toObject(message.virtualAppointmentInfo, options);
                    if (message.servicesOffered && message.servicesOffered.length) {
                        object.servicesOffered = [];
                        for (var j = 0; j < message.servicesOffered.length; ++j)
                            object.servicesOffered[j] = message.servicesOffered[j];
                    }
                    return object;
                };

                /**
                 * Converts this BusinessProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessProto";
                };

                return BusinessProto;
            })();

            proto.VirtualAppointmentInfo = (function() {

                /**
                 * Properties of a VirtualAppointmentInfo.
                 * @memberof space.kenko.proto
                 * @interface IVirtualAppointmentInfo
                 * @property {boolean|null} [acceptsVirtualAppointments] VirtualAppointmentInfo acceptsVirtualAppointments
                 */

                /**
                 * Constructs a new VirtualAppointmentInfo.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a VirtualAppointmentInfo.
                 * @implements IVirtualAppointmentInfo
                 * @constructor
                 * @param {space.kenko.proto.IVirtualAppointmentInfo=} [properties] Properties to set
                 */
                function VirtualAppointmentInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VirtualAppointmentInfo acceptsVirtualAppointments.
                 * @member {boolean} acceptsVirtualAppointments
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @instance
                 */
                VirtualAppointmentInfo.prototype.acceptsVirtualAppointments = false;

                /**
                 * Creates a new VirtualAppointmentInfo instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo=} [properties] Properties to set
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo instance
                 */
                VirtualAppointmentInfo.create = function create(properties) {
                    return new VirtualAppointmentInfo(properties);
                };

                /**
                 * Encodes the specified VirtualAppointmentInfo message. Does not implicitly {@link space.kenko.proto.VirtualAppointmentInfo.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo} message VirtualAppointmentInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VirtualAppointmentInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.acceptsVirtualAppointments != null && Object.hasOwnProperty.call(message, "acceptsVirtualAppointments"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.acceptsVirtualAppointments);
                    return writer;
                };

                /**
                 * Encodes the specified VirtualAppointmentInfo message, length delimited. Does not implicitly {@link space.kenko.proto.VirtualAppointmentInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo} message VirtualAppointmentInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VirtualAppointmentInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VirtualAppointmentInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VirtualAppointmentInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.VirtualAppointmentInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.acceptsVirtualAppointments = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VirtualAppointmentInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VirtualAppointmentInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VirtualAppointmentInfo message.
                 * @function verify
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VirtualAppointmentInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.acceptsVirtualAppointments != null && message.hasOwnProperty("acceptsVirtualAppointments"))
                        if (typeof message.acceptsVirtualAppointments !== "boolean")
                            return "acceptsVirtualAppointments: boolean expected";
                    return null;
                };

                /**
                 * Creates a VirtualAppointmentInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 */
                VirtualAppointmentInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.VirtualAppointmentInfo)
                        return object;
                    var message = new $root.space.kenko.proto.VirtualAppointmentInfo();
                    if (object.acceptsVirtualAppointments != null)
                        message.acceptsVirtualAppointments = Boolean(object.acceptsVirtualAppointments);
                    return message;
                };

                /**
                 * Creates a plain object from a VirtualAppointmentInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.VirtualAppointmentInfo} message VirtualAppointmentInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VirtualAppointmentInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.acceptsVirtualAppointments = false;
                    if (message.acceptsVirtualAppointments != null && message.hasOwnProperty("acceptsVirtualAppointments"))
                        object.acceptsVirtualAppointments = message.acceptsVirtualAppointments;
                    return object;
                };

                /**
                 * Converts this VirtualAppointmentInfo to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VirtualAppointmentInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VirtualAppointmentInfo
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VirtualAppointmentInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.VirtualAppointmentInfo";
                };

                return VirtualAppointmentInfo;
            })();

            /**
             * ProviderApprovalState enum.
             * @name space.kenko.proto.ProviderApprovalState
             * @enum {number}
             * @property {number} APPROVAL_STATE_UNKNOWN=0 APPROVAL_STATE_UNKNOWN value
             * @property {number} NEW_PROVIDER_MANUAL_APPROVE=10 NEW_PROVIDER_MANUAL_APPROVE value
             * @property {number} NEW_PROVIDER_AUTO_APPROVE=11 NEW_PROVIDER_AUTO_APPROVE value
             * @property {number} AWAITING_REVIEW=20 AWAITING_REVIEW value
             * @property {number} PROVIDER_APPROVED=30 PROVIDER_APPROVED value
             * @property {number} PROVIDER_REJECTED=40 PROVIDER_REJECTED value
             */
            proto.ProviderApprovalState = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "APPROVAL_STATE_UNKNOWN"] = 0;
                values[valuesById[10] = "NEW_PROVIDER_MANUAL_APPROVE"] = 10;
                values[valuesById[11] = "NEW_PROVIDER_AUTO_APPROVE"] = 11;
                values[valuesById[20] = "AWAITING_REVIEW"] = 20;
                values[valuesById[30] = "PROVIDER_APPROVED"] = 30;
                values[valuesById[40] = "PROVIDER_REJECTED"] = 40;
                return values;
            })();

            /**
             * ApprovalStatus enum.
             * @name space.kenko.proto.ApprovalStatus
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} IN_REVIEW=1 IN_REVIEW value
             * @property {number} APPROVED=2 APPROVED value
             * @property {number} REJECTED=3 REJECTED value
             * @property {number} WITHDRAWN=4 WITHDRAWN value
             */
            proto.ApprovalStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "IN_REVIEW"] = 1;
                values[valuesById[2] = "APPROVED"] = 2;
                values[valuesById[3] = "REJECTED"] = 3;
                values[valuesById[4] = "WITHDRAWN"] = 4;
                return values;
            })();

            proto.ProfileReviewProto = (function() {

                /**
                 * Properties of a ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @interface IProfileReviewProto
                 * @property {space.kenko.proto.ApprovalStatus|null} [status] ProfileReviewProto status
                 * @property {Array.<string>|null} [messages] ProfileReviewProto messages
                 */

                /**
                 * Constructs a new ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProfileReviewProto.
                 * @implements IProfileReviewProto
                 * @constructor
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 */
                function ProfileReviewProto(properties) {
                    this.messages = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProfileReviewProto status.
                 * @member {space.kenko.proto.ApprovalStatus} status
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.status = 0;

                /**
                 * ProfileReviewProto messages.
                 * @member {Array.<string>} messages
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.messages = $util.emptyArray;

                /**
                 * Creates a new ProfileReviewProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto instance
                 */
                ProfileReviewProto.create = function create(properties) {
                    return new ProfileReviewProto(properties);
                };

                /**
                 * Encodes the specified ProfileReviewProto message. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.messages != null && message.messages.length)
                        for (var i = 0; i < message.messages.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.messages[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ProfileReviewProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProfileReviewProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.messages && message.messages.length))
                                    message.messages = [];
                                message.messages.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProfileReviewProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProfileReviewProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.messages != null && message.hasOwnProperty("messages")) {
                        if (!Array.isArray(message.messages))
                            return "messages: array expected";
                        for (var i = 0; i < message.messages.length; ++i)
                            if (!$util.isString(message.messages[i]))
                                return "messages: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ProfileReviewProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 */
                ProfileReviewProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProfileReviewProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProfileReviewProto();
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "IN_REVIEW":
                    case 1:
                        message.status = 1;
                        break;
                    case "APPROVED":
                    case 2:
                        message.status = 2;
                        break;
                    case "REJECTED":
                    case 3:
                        message.status = 3;
                        break;
                    case "WITHDRAWN":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    if (object.messages) {
                        if (!Array.isArray(object.messages))
                            throw TypeError(".space.kenko.proto.ProfileReviewProto.messages: array expected");
                        message.messages = [];
                        for (var i = 0; i < object.messages.length; ++i)
                            message.messages[i] = String(object.messages[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProfileReviewProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.ProfileReviewProto} message ProfileReviewProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProfileReviewProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.messages = [];
                    if (options.defaults)
                        object.status = options.enums === String ? "UNKNOWN" : 0;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.space.kenko.proto.ApprovalStatus[message.status] === undefined ? message.status : $root.space.kenko.proto.ApprovalStatus[message.status] : message.status;
                    if (message.messages && message.messages.length) {
                        object.messages = [];
                        for (var j = 0; j < message.messages.length; ++j)
                            object.messages[j] = message.messages[j];
                    }
                    return object;
                };

                /**
                 * Converts this ProfileReviewProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProfileReviewProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProfileReviewProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProfileReviewProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProfileReviewProto";
                };

                return ProfileReviewProto;
            })();

            proto.BusinessLocationProto = (function() {

                /**
                 * Properties of a BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessLocationProto
                 * @property {string|null} [locationId] BusinessLocationProto locationId
                 * @property {string|null} [locationName] BusinessLocationProto locationName
                 * @property {string|null} [address] BusinessLocationProto address
                 * @property {string|null} [phone] BusinessLocationProto phone
                 * @property {string|null} [email] BusinessLocationProto email
                 * @property {number|null} [lat] BusinessLocationProto lat
                 * @property {number|null} [lng] BusinessLocationProto lng
                 * @property {string|null} [timeZone] BusinessLocationProto timeZone
                 * @property {boolean|null} ["private"] BusinessLocationProto private
                 * @property {boolean|null} [disabled] BusinessLocationProto disabled
                 * @property {Array.<string>|null} [imageUrl] BusinessLocationProto imageUrl
                 * @property {boolean|null} [hasBeenPublished] BusinessLocationProto hasBeenPublished
                 */

                /**
                 * Constructs a new BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessLocationProto.
                 * @implements IBusinessLocationProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 */
                function BusinessLocationProto(properties) {
                    this.imageUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessLocationProto locationId.
                 * @member {string} locationId
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.locationId = "";

                /**
                 * BusinessLocationProto locationName.
                 * @member {string} locationName
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.locationName = "";

                /**
                 * BusinessLocationProto address.
                 * @member {string} address
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.address = "";

                /**
                 * BusinessLocationProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.phone = "";

                /**
                 * BusinessLocationProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.email = "";

                /**
                 * BusinessLocationProto lat.
                 * @member {number} lat
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lat = 0;

                /**
                 * BusinessLocationProto lng.
                 * @member {number} lng
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lng = 0;

                /**
                 * BusinessLocationProto timeZone.
                 * @member {string} timeZone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.timeZone = "";

                /**
                 * BusinessLocationProto private.
                 * @member {boolean} private
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype["private"] = false;

                /**
                 * BusinessLocationProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.disabled = false;

                /**
                 * BusinessLocationProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * BusinessLocationProto hasBeenPublished.
                 * @member {boolean} hasBeenPublished
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.hasBeenPublished = false;

                /**
                 * Creates a new BusinessLocationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto instance
                 */
                BusinessLocationProto.create = function create(properties) {
                    return new BusinessLocationProto(properties);
                };

                /**
                 * Encodes the specified BusinessLocationProto message. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationName);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.lat);
                    if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                        writer.uint32(/* id 7, wireType 1 =*/57).double(message.lng);
                    if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.timeZone);
                    if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message["private"]);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.disabled);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.imageUrl[i]);
                    if (message.hasBeenPublished != null && Object.hasOwnProperty.call(message, "hasBeenPublished"))
                        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.hasBeenPublished);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessLocationProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessLocationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 3: {
                                message.address = reader.string();
                                break;
                            }
                        case 4: {
                                message.phone = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                message.lat = reader.double();
                                break;
                            }
                        case 7: {
                                message.lng = reader.double();
                                break;
                            }
                        case 8: {
                                message.timeZone = reader.string();
                                break;
                            }
                        case 9: {
                                message["private"] = reader.bool();
                                break;
                            }
                        case 10: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 11: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        case 12: {
                                message.hasBeenPublished = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessLocationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessLocationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (typeof message.lat !== "number")
                            return "lat: number expected";
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        if (typeof message.lng !== "number")
                            return "lng: number expected";
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        if (!$util.isString(message.timeZone))
                            return "timeZone: string expected";
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        if (typeof message["private"] !== "boolean")
                            return "private: boolean expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    if (message.hasBeenPublished != null && message.hasOwnProperty("hasBeenPublished"))
                        if (typeof message.hasBeenPublished !== "boolean")
                            return "hasBeenPublished: boolean expected";
                    return null;
                };

                /**
                 * Creates a BusinessLocationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 */
                BusinessLocationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessLocationProto)
                        return object;
                    var message = new $root.space.kenko.proto.BusinessLocationProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.lat != null)
                        message.lat = Number(object.lat);
                    if (object.lng != null)
                        message.lng = Number(object.lng);
                    if (object.timeZone != null)
                        message.timeZone = String(object.timeZone);
                    if (object["private"] != null)
                        message["private"] = Boolean(object["private"]);
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessLocationProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (var i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    if (object.hasBeenPublished != null)
                        message.hasBeenPublished = Boolean(object.hasBeenPublished);
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessLocationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.BusinessLocationProto} message BusinessLocationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessLocationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrl = [];
                    if (options.defaults) {
                        object.locationId = "";
                        object.locationName = "";
                        object.address = "";
                        object.phone = "";
                        object.email = "";
                        object.lat = 0;
                        object.lng = 0;
                        object.timeZone = "";
                        object["private"] = false;
                        object.disabled = false;
                        object.hasBeenPublished = false;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        object.timeZone = message.timeZone;
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        object["private"] = message["private"];
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (var j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    if (message.hasBeenPublished != null && message.hasOwnProperty("hasBeenPublished"))
                        object.hasBeenPublished = message.hasBeenPublished;
                    return object;
                };

                /**
                 * Converts this BusinessLocationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessLocationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessLocationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessLocationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessLocationProto";
                };

                return BusinessLocationProto;
            })();

            proto.UserProfileProto = (function() {

                /**
                 * Properties of a UserProfileProto.
                 * @memberof space.kenko.proto
                 * @interface IUserProfileProto
                 * @property {string|null} [firstName] UserProfileProto firstName
                 * @property {string|null} [lastName] UserProfileProto lastName
                 * @property {string|null} [mobileNumber] UserProfileProto mobileNumber
                 * @property {boolean|null} [mobileNumberVerified] UserProfileProto mobileNumberVerified
                 * @property {string|null} [countryCode] UserProfileProto countryCode
                 * @property {boolean|null} [agreedToTerms] UserProfileProto agreedToTerms
                 */

                /**
                 * Constructs a new UserProfileProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a UserProfileProto.
                 * @implements IUserProfileProto
                 * @constructor
                 * @param {space.kenko.proto.IUserProfileProto=} [properties] Properties to set
                 */
                function UserProfileProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserProfileProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.firstName = "";

                /**
                 * UserProfileProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.lastName = "";

                /**
                 * UserProfileProto mobileNumber.
                 * @member {string} mobileNumber
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.mobileNumber = "";

                /**
                 * UserProfileProto mobileNumberVerified.
                 * @member {boolean} mobileNumberVerified
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.mobileNumberVerified = false;

                /**
                 * UserProfileProto countryCode.
                 * @member {string} countryCode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.countryCode = "";

                /**
                 * UserProfileProto agreedToTerms.
                 * @member {boolean} agreedToTerms
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.agreedToTerms = false;

                /**
                 * Creates a new UserProfileProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto instance
                 */
                UserProfileProto.create = function create(properties) {
                    return new UserProfileProto(properties);
                };

                /**
                 * Encodes the specified UserProfileProto message. Does not implicitly {@link space.kenko.proto.UserProfileProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto} message UserProfileProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    if (message.mobileNumber != null && Object.hasOwnProperty.call(message, "mobileNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.mobileNumber);
                    if (message.mobileNumberVerified != null && Object.hasOwnProperty.call(message, "mobileNumberVerified"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.mobileNumberVerified);
                    if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.countryCode);
                    if (message.agreedToTerms != null && Object.hasOwnProperty.call(message, "agreedToTerms"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.agreedToTerms);
                    return writer;
                };

                /**
                 * Encodes the specified UserProfileProto message, length delimited. Does not implicitly {@link space.kenko.proto.UserProfileProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto} message UserProfileProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserProfileProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UserProfileProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 2: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 3: {
                                message.mobileNumber = reader.string();
                                break;
                            }
                        case 4: {
                                message.mobileNumberVerified = reader.bool();
                                break;
                            }
                        case 5: {
                                message.countryCode = reader.string();
                                break;
                            }
                        case 6: {
                                message.agreedToTerms = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserProfileProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserProfileProto message.
                 * @function verify
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProfileProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.mobileNumber != null && message.hasOwnProperty("mobileNumber"))
                        if (!$util.isString(message.mobileNumber))
                            return "mobileNumber: string expected";
                    if (message.mobileNumberVerified != null && message.hasOwnProperty("mobileNumberVerified"))
                        if (typeof message.mobileNumberVerified !== "boolean")
                            return "mobileNumberVerified: boolean expected";
                    if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                        if (!$util.isString(message.countryCode))
                            return "countryCode: string expected";
                    if (message.agreedToTerms != null && message.hasOwnProperty("agreedToTerms"))
                        if (typeof message.agreedToTerms !== "boolean")
                            return "agreedToTerms: boolean expected";
                    return null;
                };

                /**
                 * Creates a UserProfileProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 */
                UserProfileProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UserProfileProto)
                        return object;
                    var message = new $root.space.kenko.proto.UserProfileProto();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.mobileNumber != null)
                        message.mobileNumber = String(object.mobileNumber);
                    if (object.mobileNumberVerified != null)
                        message.mobileNumberVerified = Boolean(object.mobileNumberVerified);
                    if (object.countryCode != null)
                        message.countryCode = String(object.countryCode);
                    if (object.agreedToTerms != null)
                        message.agreedToTerms = Boolean(object.agreedToTerms);
                    return message;
                };

                /**
                 * Creates a plain object from a UserProfileProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.UserProfileProto} message UserProfileProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProfileProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                        object.mobileNumber = "";
                        object.mobileNumberVerified = false;
                        object.countryCode = "";
                        object.agreedToTerms = false;
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.mobileNumber != null && message.hasOwnProperty("mobileNumber"))
                        object.mobileNumber = message.mobileNumber;
                    if (message.mobileNumberVerified != null && message.hasOwnProperty("mobileNumberVerified"))
                        object.mobileNumberVerified = message.mobileNumberVerified;
                    if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                        object.countryCode = message.countryCode;
                    if (message.agreedToTerms != null && message.hasOwnProperty("agreedToTerms"))
                        object.agreedToTerms = message.agreedToTerms;
                    return object;
                };

                /**
                 * Converts this UserProfileProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProfileProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserProfileProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UserProfileProto";
                };

                return UserProfileProto;
            })();

            proto.AddressProto = (function() {

                /**
                 * Properties of an AddressProto.
                 * @memberof space.kenko.proto
                 * @interface IAddressProto
                 * @property {string|null} [formattedAddress] AddressProto formattedAddress
                 * @property {number|null} [lat] AddressProto lat
                 * @property {number|null} [lng] AddressProto lng
                 */

                /**
                 * Constructs a new AddressProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an AddressProto.
                 * @implements IAddressProto
                 * @constructor
                 * @param {space.kenko.proto.IAddressProto=} [properties] Properties to set
                 */
                function AddressProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AddressProto formattedAddress.
                 * @member {string} formattedAddress
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.formattedAddress = "";

                /**
                 * AddressProto lat.
                 * @member {number} lat
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.lat = 0;

                /**
                 * AddressProto lng.
                 * @member {number} lng
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.lng = 0;

                /**
                 * Creates a new AddressProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.AddressProto} AddressProto instance
                 */
                AddressProto.create = function create(properties) {
                    return new AddressProto(properties);
                };

                /**
                 * Encodes the specified AddressProto message. Does not implicitly {@link space.kenko.proto.AddressProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto} message AddressProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.formattedAddress != null && Object.hasOwnProperty.call(message, "formattedAddress"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.formattedAddress);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.lat);
                    if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.lng);
                    return writer;
                };

                /**
                 * Encodes the specified AddressProto message, length delimited. Does not implicitly {@link space.kenko.proto.AddressProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto} message AddressProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AddressProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.AddressProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.formattedAddress = reader.string();
                                break;
                            }
                        case 2: {
                                message.lat = reader.double();
                                break;
                            }
                        case 3: {
                                message.lng = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AddressProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AddressProto message.
                 * @function verify
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddressProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formattedAddress != null && message.hasOwnProperty("formattedAddress"))
                        if (!$util.isString(message.formattedAddress))
                            return "formattedAddress: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (typeof message.lat !== "number")
                            return "lat: number expected";
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        if (typeof message.lng !== "number")
                            return "lng: number expected";
                    return null;
                };

                /**
                 * Creates an AddressProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 */
                AddressProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.AddressProto)
                        return object;
                    var message = new $root.space.kenko.proto.AddressProto();
                    if (object.formattedAddress != null)
                        message.formattedAddress = String(object.formattedAddress);
                    if (object.lat != null)
                        message.lat = Number(object.lat);
                    if (object.lng != null)
                        message.lng = Number(object.lng);
                    return message;
                };

                /**
                 * Creates a plain object from an AddressProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.AddressProto} message AddressProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddressProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.formattedAddress = "";
                        object.lat = 0;
                        object.lng = 0;
                    }
                    if (message.formattedAddress != null && message.hasOwnProperty("formattedAddress"))
                        object.formattedAddress = message.formattedAddress;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
                    return object;
                };

                /**
                 * Converts this AddressProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddressProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AddressProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.AddressProto";
                };

                return AddressProto;
            })();

            proto.ProviderBioProto = (function() {

                /**
                 * Properties of a ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderBioProto
                 * @property {string|null} [providerId] ProviderBioProto providerId
                 * @property {string|null} [firstName] ProviderBioProto firstName
                 * @property {string|null} [lastName] ProviderBioProto lastName
                 * @property {boolean|null} [isPractitioner] ProviderBioProto isPractitioner
                 * @property {string|null} [bio] ProviderBioProto bio
                 * @property {Array.<string>|null} [imageUrls] ProviderBioProto imageUrls
                 * @property {string|null} [email] ProviderBioProto email
                 * @property {string|null} [phone] ProviderBioProto phone
                 * @property {string|null} [jobTitle] ProviderBioProto jobTitle
                 * @property {Array.<space.kenko.proto.IEducationProto>|null} [education] ProviderBioProto education
                 * @property {Array.<space.kenko.proto.ILicenseOrCredentialsProto>|null} [credentials] ProviderBioProto credentials
                 * @property {Array.<string>|null} [languages] ProviderBioProto languages
                 * @property {boolean|null} [disabled] ProviderBioProto disabled
                 * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] ProviderBioProto errors
                 */

                /**
                 * Constructs a new ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderBioProto.
                 * @implements IProviderBioProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 */
                function ProviderBioProto(properties) {
                    this.imageUrls = [];
                    this.education = [];
                    this.credentials = [];
                    this.languages = [];
                    this.errors = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderBioProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.providerId = "";

                /**
                 * ProviderBioProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.firstName = "";

                /**
                 * ProviderBioProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.lastName = "";

                /**
                 * ProviderBioProto isPractitioner.
                 * @member {boolean} isPractitioner
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.isPractitioner = false;

                /**
                 * ProviderBioProto bio.
                 * @member {string} bio
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.bio = "";

                /**
                 * ProviderBioProto imageUrls.
                 * @member {Array.<string>} imageUrls
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.imageUrls = $util.emptyArray;

                /**
                 * ProviderBioProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.email = "";

                /**
                 * ProviderBioProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.phone = "";

                /**
                 * ProviderBioProto jobTitle.
                 * @member {string} jobTitle
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.jobTitle = "";

                /**
                 * ProviderBioProto education.
                 * @member {Array.<space.kenko.proto.IEducationProto>} education
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.education = $util.emptyArray;

                /**
                 * ProviderBioProto credentials.
                 * @member {Array.<space.kenko.proto.ILicenseOrCredentialsProto>} credentials
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.credentials = $util.emptyArray;

                /**
                 * ProviderBioProto languages.
                 * @member {Array.<string>} languages
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.languages = $util.emptyArray;

                /**
                 * ProviderBioProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.disabled = false;

                /**
                 * ProviderBioProto errors.
                 * @member {Array.<space.kenko.proto.IErrorProto>} errors
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.errors = $util.emptyArray;

                /**
                 * Creates a new ProviderBioProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto instance
                 */
                ProviderBioProto.create = function create(properties) {
                    return new ProviderBioProto(properties);
                };

                /**
                 * Encodes the specified ProviderBioProto message. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                    if (message.bio != null && Object.hasOwnProperty.call(message, "bio"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.bio);
                    if (message.imageUrls != null && message.imageUrls.length)
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.imageUrls[i]);
                    if (message.isPractitioner != null && Object.hasOwnProperty.call(message, "isPractitioner"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isPractitioner);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.email);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.phone);
                    if (message.jobTitle != null && Object.hasOwnProperty.call(message, "jobTitle"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jobTitle);
                    if (message.education != null && message.education.length)
                        for (var i = 0; i < message.education.length; ++i)
                            $root.space.kenko.proto.EducationProto.encode(message.education[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.credentials != null && message.credentials.length)
                        for (var i = 0; i < message.credentials.length; ++i)
                            $root.space.kenko.proto.LicenseOrCredentialsProto.encode(message.credentials[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.languages != null && message.languages.length)
                        for (var i = 0; i < message.languages.length; ++i)
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.languages[i]);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 100, wireType 0 =*/800).bool(message.disabled);
                    if (message.errors != null && message.errors.length)
                        for (var i = 0; i < message.errors.length; ++i)
                            $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ProviderBioProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderBioProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 3: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 6: {
                                message.isPractitioner = reader.bool();
                                break;
                            }
                        case 4: {
                                message.bio = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.imageUrls && message.imageUrls.length))
                                    message.imageUrls = [];
                                message.imageUrls.push(reader.string());
                                break;
                            }
                        case 8: {
                                message.email = reader.string();
                                break;
                            }
                        case 9: {
                                message.phone = reader.string();
                                break;
                            }
                        case 10: {
                                message.jobTitle = reader.string();
                                break;
                            }
                        case 11: {
                                if (!(message.education && message.education.length))
                                    message.education = [];
                                message.education.push($root.space.kenko.proto.EducationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 12: {
                                if (!(message.credentials && message.credentials.length))
                                    message.credentials = [];
                                message.credentials.push($root.space.kenko.proto.LicenseOrCredentialsProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 13: {
                                if (!(message.languages && message.languages.length))
                                    message.languages = [];
                                message.languages.push(reader.string());
                                break;
                            }
                        case 100: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 101: {
                                if (!(message.errors && message.errors.length))
                                    message.errors = [];
                                message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderBioProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderBioProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.isPractitioner != null && message.hasOwnProperty("isPractitioner"))
                        if (typeof message.isPractitioner !== "boolean")
                            return "isPractitioner: boolean expected";
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        if (!$util.isString(message.bio))
                            return "bio: string expected";
                    if (message.imageUrls != null && message.hasOwnProperty("imageUrls")) {
                        if (!Array.isArray(message.imageUrls))
                            return "imageUrls: array expected";
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            if (!$util.isString(message.imageUrls[i]))
                                return "imageUrls: string[] expected";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        if (!$util.isString(message.jobTitle))
                            return "jobTitle: string expected";
                    if (message.education != null && message.hasOwnProperty("education")) {
                        if (!Array.isArray(message.education))
                            return "education: array expected";
                        for (var i = 0; i < message.education.length; ++i) {
                            var error = $root.space.kenko.proto.EducationProto.verify(message.education[i]);
                            if (error)
                                return "education." + error;
                        }
                    }
                    if (message.credentials != null && message.hasOwnProperty("credentials")) {
                        if (!Array.isArray(message.credentials))
                            return "credentials: array expected";
                        for (var i = 0; i < message.credentials.length; ++i) {
                            var error = $root.space.kenko.proto.LicenseOrCredentialsProto.verify(message.credentials[i]);
                            if (error)
                                return "credentials." + error;
                        }
                    }
                    if (message.languages != null && message.hasOwnProperty("languages")) {
                        if (!Array.isArray(message.languages))
                            return "languages: array expected";
                        for (var i = 0; i < message.languages.length; ++i)
                            if (!$util.isString(message.languages[i]))
                                return "languages: string[] expected";
                    }
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (var i = 0; i < message.errors.length; ++i) {
                            var error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ProviderBioProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 */
                ProviderBioProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderBioProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderBioProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.isPractitioner != null)
                        message.isPractitioner = Boolean(object.isPractitioner);
                    if (object.bio != null)
                        message.bio = String(object.bio);
                    if (object.imageUrls) {
                        if (!Array.isArray(object.imageUrls))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.imageUrls: array expected");
                        message.imageUrls = [];
                        for (var i = 0; i < object.imageUrls.length; ++i)
                            message.imageUrls[i] = String(object.imageUrls[i]);
                    }
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.jobTitle != null)
                        message.jobTitle = String(object.jobTitle);
                    if (object.education) {
                        if (!Array.isArray(object.education))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.education: array expected");
                        message.education = [];
                        for (var i = 0; i < object.education.length; ++i) {
                            if (typeof object.education[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.education: object expected");
                            message.education[i] = $root.space.kenko.proto.EducationProto.fromObject(object.education[i]);
                        }
                    }
                    if (object.credentials) {
                        if (!Array.isArray(object.credentials))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.credentials: array expected");
                        message.credentials = [];
                        for (var i = 0; i < object.credentials.length; ++i) {
                            if (typeof object.credentials[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.credentials: object expected");
                            message.credentials[i] = $root.space.kenko.proto.LicenseOrCredentialsProto.fromObject(object.credentials[i]);
                        }
                    }
                    if (object.languages) {
                        if (!Array.isArray(object.languages))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.languages: array expected");
                        message.languages = [];
                        for (var i = 0; i < object.languages.length; ++i)
                            message.languages[i] = String(object.languages[i]);
                    }
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.errors: array expected");
                        message.errors = [];
                        for (var i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.errors: object expected");
                            message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderBioProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.ProviderBioProto} message ProviderBioProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderBioProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.imageUrls = [];
                        object.education = [];
                        object.credentials = [];
                        object.languages = [];
                        object.errors = [];
                    }
                    if (options.defaults) {
                        object.providerId = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.bio = "";
                        object.isPractitioner = false;
                        object.email = "";
                        object.phone = "";
                        object.jobTitle = "";
                        object.disabled = false;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        object.bio = message.bio;
                    if (message.imageUrls && message.imageUrls.length) {
                        object.imageUrls = [];
                        for (var j = 0; j < message.imageUrls.length; ++j)
                            object.imageUrls[j] = message.imageUrls[j];
                    }
                    if (message.isPractitioner != null && message.hasOwnProperty("isPractitioner"))
                        object.isPractitioner = message.isPractitioner;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        object.jobTitle = message.jobTitle;
                    if (message.education && message.education.length) {
                        object.education = [];
                        for (var j = 0; j < message.education.length; ++j)
                            object.education[j] = $root.space.kenko.proto.EducationProto.toObject(message.education[j], options);
                    }
                    if (message.credentials && message.credentials.length) {
                        object.credentials = [];
                        for (var j = 0; j < message.credentials.length; ++j)
                            object.credentials[j] = $root.space.kenko.proto.LicenseOrCredentialsProto.toObject(message.credentials[j], options);
                    }
                    if (message.languages && message.languages.length) {
                        object.languages = [];
                        for (var j = 0; j < message.languages.length; ++j)
                            object.languages[j] = message.languages[j];
                    }
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (var j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ProviderBioProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderBioProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderBioProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderBioProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderBioProto";
                };

                return ProviderBioProto;
            })();

            proto.SpecialtyProto = (function() {

                /**
                 * Properties of a SpecialtyProto.
                 * @memberof space.kenko.proto
                 * @interface ISpecialtyProto
                 * @property {string|null} [specialty] SpecialtyProto specialty
                 * @property {number|null} [yearsExperience] SpecialtyProto yearsExperience
                 */

                /**
                 * Constructs a new SpecialtyProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a SpecialtyProto.
                 * @implements ISpecialtyProto
                 * @constructor
                 * @param {space.kenko.proto.ISpecialtyProto=} [properties] Properties to set
                 */
                function SpecialtyProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SpecialtyProto specialty.
                 * @member {string} specialty
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 */
                SpecialtyProto.prototype.specialty = "";

                /**
                 * SpecialtyProto yearsExperience.
                 * @member {number} yearsExperience
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 */
                SpecialtyProto.prototype.yearsExperience = 0;

                /**
                 * Creates a new SpecialtyProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto instance
                 */
                SpecialtyProto.create = function create(properties) {
                    return new SpecialtyProto(properties);
                };

                /**
                 * Encodes the specified SpecialtyProto message. Does not implicitly {@link space.kenko.proto.SpecialtyProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto} message SpecialtyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.specialty != null && Object.hasOwnProperty.call(message, "specialty"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.specialty);
                    if (message.yearsExperience != null && Object.hasOwnProperty.call(message, "yearsExperience"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.yearsExperience);
                    return writer;
                };

                /**
                 * Encodes the specified SpecialtyProto message, length delimited. Does not implicitly {@link space.kenko.proto.SpecialtyProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto} message SpecialtyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SpecialtyProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.SpecialtyProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.specialty = reader.string();
                                break;
                            }
                        case 2: {
                                message.yearsExperience = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SpecialtyProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SpecialtyProto message.
                 * @function verify
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpecialtyProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.specialty != null && message.hasOwnProperty("specialty"))
                        if (!$util.isString(message.specialty))
                            return "specialty: string expected";
                    if (message.yearsExperience != null && message.hasOwnProperty("yearsExperience"))
                        if (!$util.isInteger(message.yearsExperience))
                            return "yearsExperience: integer expected";
                    return null;
                };

                /**
                 * Creates a SpecialtyProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 */
                SpecialtyProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.SpecialtyProto)
                        return object;
                    var message = new $root.space.kenko.proto.SpecialtyProto();
                    if (object.specialty != null)
                        message.specialty = String(object.specialty);
                    if (object.yearsExperience != null)
                        message.yearsExperience = object.yearsExperience | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a SpecialtyProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.SpecialtyProto} message SpecialtyProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpecialtyProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.specialty = "";
                        object.yearsExperience = 0;
                    }
                    if (message.specialty != null && message.hasOwnProperty("specialty"))
                        object.specialty = message.specialty;
                    if (message.yearsExperience != null && message.hasOwnProperty("yearsExperience"))
                        object.yearsExperience = message.yearsExperience;
                    return object;
                };

                /**
                 * Converts this SpecialtyProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpecialtyProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SpecialtyProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpecialtyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.SpecialtyProto";
                };

                return SpecialtyProto;
            })();

            proto.EducationProto = (function() {

                /**
                 * Properties of an EducationProto.
                 * @memberof space.kenko.proto
                 * @interface IEducationProto
                 * @property {string|null} [school] EducationProto school
                 * @property {string|null} [degree] EducationProto degree
                 * @property {number|null} [yearCompleted] EducationProto yearCompleted
                 */

                /**
                 * Constructs a new EducationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an EducationProto.
                 * @implements IEducationProto
                 * @constructor
                 * @param {space.kenko.proto.IEducationProto=} [properties] Properties to set
                 */
                function EducationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EducationProto school.
                 * @member {string} school
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.school = "";

                /**
                 * EducationProto degree.
                 * @member {string} degree
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.degree = "";

                /**
                 * EducationProto yearCompleted.
                 * @member {number} yearCompleted
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.yearCompleted = 0;

                /**
                 * Creates a new EducationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.EducationProto} EducationProto instance
                 */
                EducationProto.create = function create(properties) {
                    return new EducationProto(properties);
                };

                /**
                 * Encodes the specified EducationProto message. Does not implicitly {@link space.kenko.proto.EducationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto} message EducationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EducationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.school != null && Object.hasOwnProperty.call(message, "school"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.school);
                    if (message.degree != null && Object.hasOwnProperty.call(message, "degree"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.degree);
                    if (message.yearCompleted != null && Object.hasOwnProperty.call(message, "yearCompleted"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.yearCompleted);
                    return writer;
                };

                /**
                 * Encodes the specified EducationProto message, length delimited. Does not implicitly {@link space.kenko.proto.EducationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto} message EducationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EducationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EducationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EducationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.EducationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.school = reader.string();
                                break;
                            }
                        case 2: {
                                message.degree = reader.string();
                                break;
                            }
                        case 3: {
                                message.yearCompleted = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EducationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EducationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EducationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EducationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.school != null && message.hasOwnProperty("school"))
                        if (!$util.isString(message.school))
                            return "school: string expected";
                    if (message.degree != null && message.hasOwnProperty("degree"))
                        if (!$util.isString(message.degree))
                            return "degree: string expected";
                    if (message.yearCompleted != null && message.hasOwnProperty("yearCompleted"))
                        if (!$util.isInteger(message.yearCompleted))
                            return "yearCompleted: integer expected";
                    return null;
                };

                /**
                 * Creates an EducationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 */
                EducationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.EducationProto)
                        return object;
                    var message = new $root.space.kenko.proto.EducationProto();
                    if (object.school != null)
                        message.school = String(object.school);
                    if (object.degree != null)
                        message.degree = String(object.degree);
                    if (object.yearCompleted != null)
                        message.yearCompleted = object.yearCompleted | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an EducationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.EducationProto} message EducationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EducationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.school = "";
                        object.degree = "";
                        object.yearCompleted = 0;
                    }
                    if (message.school != null && message.hasOwnProperty("school"))
                        object.school = message.school;
                    if (message.degree != null && message.hasOwnProperty("degree"))
                        object.degree = message.degree;
                    if (message.yearCompleted != null && message.hasOwnProperty("yearCompleted"))
                        object.yearCompleted = message.yearCompleted;
                    return object;
                };

                /**
                 * Converts this EducationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EducationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EducationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EducationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.EducationProto";
                };

                return EducationProto;
            })();

            proto.LicenseOrCredentialsProto = (function() {

                /**
                 * Properties of a LicenseOrCredentialsProto.
                 * @memberof space.kenko.proto
                 * @interface ILicenseOrCredentialsProto
                 * @property {string|null} [licenseOrCredential] LicenseOrCredentialsProto licenseOrCredential
                 * @property {Array.<string>|null} [supportDocumentationUrl] LicenseOrCredentialsProto supportDocumentationUrl
                 */

                /**
                 * Constructs a new LicenseOrCredentialsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LicenseOrCredentialsProto.
                 * @implements ILicenseOrCredentialsProto
                 * @constructor
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto=} [properties] Properties to set
                 */
                function LicenseOrCredentialsProto(properties) {
                    this.supportDocumentationUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LicenseOrCredentialsProto licenseOrCredential.
                 * @member {string} licenseOrCredential
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 */
                LicenseOrCredentialsProto.prototype.licenseOrCredential = "";

                /**
                 * LicenseOrCredentialsProto supportDocumentationUrl.
                 * @member {Array.<string>} supportDocumentationUrl
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 */
                LicenseOrCredentialsProto.prototype.supportDocumentationUrl = $util.emptyArray;

                /**
                 * Creates a new LicenseOrCredentialsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto instance
                 */
                LicenseOrCredentialsProto.create = function create(properties) {
                    return new LicenseOrCredentialsProto(properties);
                };

                /**
                 * Encodes the specified LicenseOrCredentialsProto message. Does not implicitly {@link space.kenko.proto.LicenseOrCredentialsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto} message LicenseOrCredentialsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LicenseOrCredentialsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.licenseOrCredential != null && Object.hasOwnProperty.call(message, "licenseOrCredential"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.licenseOrCredential);
                    if (message.supportDocumentationUrl != null && message.supportDocumentationUrl.length)
                        for (var i = 0; i < message.supportDocumentationUrl.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.supportDocumentationUrl[i]);
                    return writer;
                };

                /**
                 * Encodes the specified LicenseOrCredentialsProto message, length delimited. Does not implicitly {@link space.kenko.proto.LicenseOrCredentialsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto} message LicenseOrCredentialsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LicenseOrCredentialsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LicenseOrCredentialsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LicenseOrCredentialsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LicenseOrCredentialsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.licenseOrCredential = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.supportDocumentationUrl && message.supportDocumentationUrl.length))
                                    message.supportDocumentationUrl = [];
                                message.supportDocumentationUrl.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LicenseOrCredentialsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LicenseOrCredentialsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LicenseOrCredentialsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LicenseOrCredentialsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseOrCredential != null && message.hasOwnProperty("licenseOrCredential"))
                        if (!$util.isString(message.licenseOrCredential))
                            return "licenseOrCredential: string expected";
                    if (message.supportDocumentationUrl != null && message.hasOwnProperty("supportDocumentationUrl")) {
                        if (!Array.isArray(message.supportDocumentationUrl))
                            return "supportDocumentationUrl: array expected";
                        for (var i = 0; i < message.supportDocumentationUrl.length; ++i)
                            if (!$util.isString(message.supportDocumentationUrl[i]))
                                return "supportDocumentationUrl: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a LicenseOrCredentialsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 */
                LicenseOrCredentialsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LicenseOrCredentialsProto)
                        return object;
                    var message = new $root.space.kenko.proto.LicenseOrCredentialsProto();
                    if (object.licenseOrCredential != null)
                        message.licenseOrCredential = String(object.licenseOrCredential);
                    if (object.supportDocumentationUrl) {
                        if (!Array.isArray(object.supportDocumentationUrl))
                            throw TypeError(".space.kenko.proto.LicenseOrCredentialsProto.supportDocumentationUrl: array expected");
                        message.supportDocumentationUrl = [];
                        for (var i = 0; i < object.supportDocumentationUrl.length; ++i)
                            message.supportDocumentationUrl[i] = String(object.supportDocumentationUrl[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LicenseOrCredentialsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.LicenseOrCredentialsProto} message LicenseOrCredentialsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LicenseOrCredentialsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.supportDocumentationUrl = [];
                    if (options.defaults)
                        object.licenseOrCredential = "";
                    if (message.licenseOrCredential != null && message.hasOwnProperty("licenseOrCredential"))
                        object.licenseOrCredential = message.licenseOrCredential;
                    if (message.supportDocumentationUrl && message.supportDocumentationUrl.length) {
                        object.supportDocumentationUrl = [];
                        for (var j = 0; j < message.supportDocumentationUrl.length; ++j)
                            object.supportDocumentationUrl[j] = message.supportDocumentationUrl[j];
                    }
                    return object;
                };

                /**
                 * Converts this LicenseOrCredentialsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LicenseOrCredentialsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LicenseOrCredentialsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LicenseOrCredentialsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LicenseOrCredentialsProto";
                };

                return LicenseOrCredentialsProto;
            })();

            proto.PracticeDetailsProto = (function() {

                /**
                 * Properties of a PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface IPracticeDetailsProto
                 * @property {Array.<space.kenko.proto.IServiceProto>|null} [services] PracticeDetailsProto services
                 */

                /**
                 * Constructs a new PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PracticeDetailsProto.
                 * @implements IPracticeDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 */
                function PracticeDetailsProto(properties) {
                    this.services = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PracticeDetailsProto services.
                 * @member {Array.<space.kenko.proto.IServiceProto>} services
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 */
                PracticeDetailsProto.prototype.services = $util.emptyArray;

                /**
                 * Creates a new PracticeDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto instance
                 */
                PracticeDetailsProto.create = function create(properties) {
                    return new PracticeDetailsProto(properties);
                };

                /**
                 * Encodes the specified PracticeDetailsProto message. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.services != null && message.services.length)
                        for (var i = 0; i < message.services.length; ++i)
                            $root.space.kenko.proto.ServiceProto.encode(message.services[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PracticeDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PracticeDetailsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.services && message.services.length))
                                    message.services = [];
                                message.services.push($root.space.kenko.proto.ServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PracticeDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PracticeDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.services != null && message.hasOwnProperty("services")) {
                        if (!Array.isArray(message.services))
                            return "services: array expected";
                        for (var i = 0; i < message.services.length; ++i) {
                            var error = $root.space.kenko.proto.ServiceProto.verify(message.services[i]);
                            if (error)
                                return "services." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PracticeDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 */
                PracticeDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PracticeDetailsProto)
                        return object;
                    var message = new $root.space.kenko.proto.PracticeDetailsProto();
                    if (object.services) {
                        if (!Array.isArray(object.services))
                            throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: array expected");
                        message.services = [];
                        for (var i = 0; i < object.services.length; ++i) {
                            if (typeof object.services[i] !== "object")
                                throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: object expected");
                            message.services[i] = $root.space.kenko.proto.ServiceProto.fromObject(object.services[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PracticeDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.PracticeDetailsProto} message PracticeDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PracticeDetailsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.services = [];
                    if (message.services && message.services.length) {
                        object.services = [];
                        for (var j = 0; j < message.services.length; ++j)
                            object.services[j] = $root.space.kenko.proto.ServiceProto.toObject(message.services[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PracticeDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PracticeDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PracticeDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PracticeDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PracticeDetailsProto";
                };

                return PracticeDetailsProto;
            })();

            proto.StudioDetailsProto = (function() {

                /**
                 * Properties of a StudioDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface IStudioDetailsProto
                 */

                /**
                 * Constructs a new StudioDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a StudioDetailsProto.
                 * @implements IStudioDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.IStudioDetailsProto=} [properties] Properties to set
                 */
                function StudioDetailsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new StudioDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {space.kenko.proto.IStudioDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.StudioDetailsProto} StudioDetailsProto instance
                 */
                StudioDetailsProto.create = function create(properties) {
                    return new StudioDetailsProto(properties);
                };

                /**
                 * Encodes the specified StudioDetailsProto message. Does not implicitly {@link space.kenko.proto.StudioDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {space.kenko.proto.IStudioDetailsProto} message StudioDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StudioDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified StudioDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.StudioDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {space.kenko.proto.IStudioDetailsProto} message StudioDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StudioDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StudioDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.StudioDetailsProto} StudioDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StudioDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.StudioDetailsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StudioDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.StudioDetailsProto} StudioDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StudioDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StudioDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StudioDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a StudioDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.StudioDetailsProto} StudioDetailsProto
                 */
                StudioDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.StudioDetailsProto)
                        return object;
                    return new $root.space.kenko.proto.StudioDetailsProto();
                };

                /**
                 * Creates a plain object from a StudioDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {space.kenko.proto.StudioDetailsProto} message StudioDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StudioDetailsProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this StudioDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StudioDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StudioDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StudioDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.StudioDetailsProto";
                };

                return StudioDetailsProto;
            })();

            proto.ServiceProto = (function() {

                /**
                 * Properties of a ServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceProto
                 * @property {string|null} [sku] ServiceProto sku
                 * @property {string|null} [name] ServiceProto name
                 * @property {string|null} [description] ServiceProto description
                 * @property {number|null} [lengthMinutes] ServiceProto lengthMinutes
                 * @property {string|null} [category] ServiceProto category
                 * @property {Array.<number>|null} [availableLocations] ServiceProto availableLocations
                 * @property {boolean|null} [virtualAvailable] ServiceProto virtualAvailable
                 * @property {Array.<space.kenko.proto.IPractitionerServiceProto>|null} [offeredBy] ServiceProto offeredBy
                 */

                /**
                 * Constructs a new ServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceProto.
                 * @implements IServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 */
                function ServiceProto(properties) {
                    this.availableLocations = [];
                    this.offeredBy = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.sku = "";

                /**
                 * ServiceProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.name = "";

                /**
                 * ServiceProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.description = "";

                /**
                 * ServiceProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.category = "";

                /**
                 * ServiceProto availableLocations.
                 * @member {Array.<number>} availableLocations
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.availableLocations = $util.emptyArray;

                /**
                 * ServiceProto virtualAvailable.
                 * @member {boolean} virtualAvailable
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.virtualAvailable = false;

                /**
                 * ServiceProto offeredBy.
                 * @member {Array.<space.kenko.proto.IPractitionerServiceProto>} offeredBy
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.offeredBy = $util.emptyArray;

                /**
                 * Creates a new ServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto instance
                 */
                ServiceProto.create = function create(properties) {
                    return new ServiceProto(properties);
                };

                /**
                 * Encodes the specified ServiceProto message. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.category);
                    if (message.availableLocations != null && message.availableLocations.length) {
                        writer.uint32(/* id 6, wireType 2 =*/50).fork();
                        for (var i = 0; i < message.availableLocations.length; ++i)
                            writer.int32(message.availableLocations[i]);
                        writer.ldelim();
                    }
                    if (message.virtualAvailable != null && Object.hasOwnProperty.call(message, "virtualAvailable"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.virtualAvailable);
                    if (message.offeredBy != null && message.offeredBy.length)
                        for (var i = 0; i < message.offeredBy.length; ++i)
                            $root.space.kenko.proto.PractitionerServiceProto.encode(message.offeredBy[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.category = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.availableLocations && message.availableLocations.length))
                                    message.availableLocations = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.availableLocations.push(reader.int32());
                                } else
                                    message.availableLocations.push(reader.int32());
                                break;
                            }
                        case 7: {
                                message.virtualAvailable = reader.bool();
                                break;
                            }
                        case 8: {
                                if (!(message.offeredBy && message.offeredBy.length))
                                    message.offeredBy = [];
                                message.offeredBy.push($root.space.kenko.proto.PractitionerServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.availableLocations != null && message.hasOwnProperty("availableLocations")) {
                        if (!Array.isArray(message.availableLocations))
                            return "availableLocations: array expected";
                        for (var i = 0; i < message.availableLocations.length; ++i)
                            if (!$util.isInteger(message.availableLocations[i]))
                                return "availableLocations: integer[] expected";
                    }
                    if (message.virtualAvailable != null && message.hasOwnProperty("virtualAvailable"))
                        if (typeof message.virtualAvailable !== "boolean")
                            return "virtualAvailable: boolean expected";
                    if (message.offeredBy != null && message.hasOwnProperty("offeredBy")) {
                        if (!Array.isArray(message.offeredBy))
                            return "offeredBy: array expected";
                        for (var i = 0; i < message.offeredBy.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerServiceProto.verify(message.offeredBy[i]);
                            if (error)
                                return "offeredBy." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 */
                ServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceProto)
                        return object;
                    var message = new $root.space.kenko.proto.ServiceProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.availableLocations) {
                        if (!Array.isArray(object.availableLocations))
                            throw TypeError(".space.kenko.proto.ServiceProto.availableLocations: array expected");
                        message.availableLocations = [];
                        for (var i = 0; i < object.availableLocations.length; ++i)
                            message.availableLocations[i] = object.availableLocations[i] | 0;
                    }
                    if (object.virtualAvailable != null)
                        message.virtualAvailable = Boolean(object.virtualAvailable);
                    if (object.offeredBy) {
                        if (!Array.isArray(object.offeredBy))
                            throw TypeError(".space.kenko.proto.ServiceProto.offeredBy: array expected");
                        message.offeredBy = [];
                        for (var i = 0; i < object.offeredBy.length; ++i) {
                            if (typeof object.offeredBy[i] !== "object")
                                throw TypeError(".space.kenko.proto.ServiceProto.offeredBy: object expected");
                            message.offeredBy[i] = $root.space.kenko.proto.PractitionerServiceProto.fromObject(object.offeredBy[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.ServiceProto} message ServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.availableLocations = [];
                        object.offeredBy = [];
                    }
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.category = "";
                        object.virtualAvailable = false;
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.availableLocations && message.availableLocations.length) {
                        object.availableLocations = [];
                        for (var j = 0; j < message.availableLocations.length; ++j)
                            object.availableLocations[j] = message.availableLocations[j];
                    }
                    if (message.virtualAvailable != null && message.hasOwnProperty("virtualAvailable"))
                        object.virtualAvailable = message.virtualAvailable;
                    if (message.offeredBy && message.offeredBy.length) {
                        object.offeredBy = [];
                        for (var j = 0; j < message.offeredBy.length; ++j)
                            object.offeredBy[j] = $root.space.kenko.proto.PractitionerServiceProto.toObject(message.offeredBy[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceProto";
                };

                return ServiceProto;
            })();

            proto.PractitionerServiceProto = (function() {

                /**
                 * Properties of a PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerServiceProto
                 * @property {string|null} [providerId] PractitionerServiceProto providerId
                 * @property {space.kenko.proto.IPriceProto|null} [price] PractitionerServiceProto price
                 */

                /**
                 * Constructs a new PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerServiceProto.
                 * @implements IPractitionerServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 */
                function PractitionerServiceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerServiceProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.providerId = "";

                /**
                 * PractitionerServiceProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.price = null;

                /**
                 * Creates a new PractitionerServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto instance
                 */
                PractitionerServiceProto.create = function create(properties) {
                    return new PractitionerServiceProto(properties);
                };

                /**
                 * Encodes the specified PractitionerServiceProto message. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerServiceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 */
                PractitionerServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerServiceProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerServiceProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerServiceProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.PractitionerServiceProto} message PractitionerServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.providerId = "";
                        object.price = null;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    return object;
                };

                /**
                 * Converts this PractitionerServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerServiceProto";
                };

                return PractitionerServiceProto;
            })();

            proto.ServiceSchemaProto = (function() {

                /**
                 * Properties of a ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceSchemaProto
                 * @property {string|null} [sku] ServiceSchemaProto sku
                 * @property {string|null} [name] ServiceSchemaProto name
                 * @property {string|null} [description] ServiceSchemaProto description
                 * @property {number|null} [lengthMinutes] ServiceSchemaProto lengthMinutes
                 * @property {space.kenko.proto.IPriceProto|null} [price] ServiceSchemaProto price
                 * @property {string|null} [category] ServiceSchemaProto category
                 */

                /**
                 * Constructs a new ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceSchemaProto.
                 * @implements IServiceSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 */
                function ServiceSchemaProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceSchemaProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.sku = "";

                /**
                 * ServiceSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.name = "";

                /**
                 * ServiceSchemaProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.description = "";

                /**
                 * ServiceSchemaProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceSchemaProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.price = null;

                /**
                 * ServiceSchemaProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.category = "";

                /**
                 * Creates a new ServiceSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto instance
                 */
                ServiceSchemaProto.create = function create(properties) {
                    return new ServiceSchemaProto(properties);
                };

                /**
                 * Encodes the specified ServiceSchemaProto message. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.category);
                    return writer;
                };

                /**
                 * Encodes the specified ServiceSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.category = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    return null;
                };

                /**
                 * Creates a ServiceSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 */
                ServiceSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ServiceSchemaProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.ServiceSchemaProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    if (object.category != null)
                        message.category = String(object.category);
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.ServiceSchemaProto} message ServiceSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.price = null;
                        object.category = "";
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    return object;
                };

                /**
                 * Converts this ServiceSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceSchemaProto";
                };

                return ServiceSchemaProto;
            })();

            proto.InvitationProtos = (function() {

                /**
                 * Properties of an InvitationProtos.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProtos
                 * @property {Array.<space.kenko.proto.IInvitationProto>|null} [invitations] InvitationProtos invitations
                 */

                /**
                 * Constructs a new InvitationProtos.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProtos.
                 * @implements IInvitationProtos
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 */
                function InvitationProtos(properties) {
                    this.invitations = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProtos invitations.
                 * @member {Array.<space.kenko.proto.IInvitationProto>} invitations
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 */
                InvitationProtos.prototype.invitations = $util.emptyArray;

                /**
                 * Creates a new InvitationProtos instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos instance
                 */
                InvitationProtos.create = function create(properties) {
                    return new InvitationProtos(properties);
                };

                /**
                 * Encodes the specified InvitationProtos message. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invitations != null && message.invitations.length)
                        for (var i = 0; i < message.invitations.length; ++i)
                            $root.space.kenko.proto.InvitationProto.encode(message.invitations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProtos message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProtos();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.invitations && message.invitations.length))
                                    message.invitations = [];
                                message.invitations.push($root.space.kenko.proto.InvitationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProtos message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProtos.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invitations != null && message.hasOwnProperty("invitations")) {
                        if (!Array.isArray(message.invitations))
                            return "invitations: array expected";
                        for (var i = 0; i < message.invitations.length; ++i) {
                            var error = $root.space.kenko.proto.InvitationProto.verify(message.invitations[i]);
                            if (error)
                                return "invitations." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an InvitationProtos message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 */
                InvitationProtos.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProtos)
                        return object;
                    var message = new $root.space.kenko.proto.InvitationProtos();
                    if (object.invitations) {
                        if (!Array.isArray(object.invitations))
                            throw TypeError(".space.kenko.proto.InvitationProtos.invitations: array expected");
                        message.invitations = [];
                        for (var i = 0; i < object.invitations.length; ++i) {
                            if (typeof object.invitations[i] !== "object")
                                throw TypeError(".space.kenko.proto.InvitationProtos.invitations: object expected");
                            message.invitations[i] = $root.space.kenko.proto.InvitationProto.fromObject(object.invitations[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProtos message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.InvitationProtos} message InvitationProtos
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProtos.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.invitations = [];
                    if (message.invitations && message.invitations.length) {
                        object.invitations = [];
                        for (var j = 0; j < message.invitations.length; ++j)
                            object.invitations[j] = $root.space.kenko.proto.InvitationProto.toObject(message.invitations[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this InvitationProtos to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProtos.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProtos
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProtos.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProtos";
                };

                return InvitationProtos;
            })();

            proto.InvitationProto = (function() {

                /**
                 * Properties of an InvitationProto.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProto
                 * @property {string|null} [inviteCode] InvitationProto inviteCode
                 * @property {number|Long|null} [generatedTimestamp] InvitationProto generatedTimestamp
                 * @property {boolean|null} [alphaProviderAutoApprove] InvitationProto alphaProviderAutoApprove
                 */

                /**
                 * Constructs a new InvitationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProto.
                 * @implements IInvitationProto
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 */
                function InvitationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProto inviteCode.
                 * @member {string} inviteCode
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.inviteCode = "";

                /**
                 * InvitationProto generatedTimestamp.
                 * @member {number|Long} generatedTimestamp
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.generatedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * InvitationProto alphaProviderAutoApprove.
                 * @member {boolean} alphaProviderAutoApprove
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.alphaProviderAutoApprove = false;

                /**
                 * Creates a new InvitationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto instance
                 */
                InvitationProto.create = function create(properties) {
                    return new InvitationProto(properties);
                };

                /**
                 * Encodes the specified InvitationProto message. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
                    if (message.generatedTimestamp != null && Object.hasOwnProperty.call(message, "generatedTimestamp"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generatedTimestamp);
                    if (message.alphaProviderAutoApprove != null && Object.hasOwnProperty.call(message, "alphaProviderAutoApprove"))
                        writer.uint32(/* id 1000, wireType 0 =*/8000).bool(message.alphaProviderAutoApprove);
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProto message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inviteCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.generatedTimestamp = reader.int64();
                                break;
                            }
                        case 1000: {
                                message.alphaProviderAutoApprove = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        if (!$util.isString(message.inviteCode))
                            return "inviteCode: string expected";
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (!$util.isInteger(message.generatedTimestamp) && !(message.generatedTimestamp && $util.isInteger(message.generatedTimestamp.low) && $util.isInteger(message.generatedTimestamp.high)))
                            return "generatedTimestamp: integer|Long expected";
                    if (message.alphaProviderAutoApprove != null && message.hasOwnProperty("alphaProviderAutoApprove"))
                        if (typeof message.alphaProviderAutoApprove !== "boolean")
                            return "alphaProviderAutoApprove: boolean expected";
                    return null;
                };

                /**
                 * Creates an InvitationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 */
                InvitationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProto)
                        return object;
                    var message = new $root.space.kenko.proto.InvitationProto();
                    if (object.inviteCode != null)
                        message.inviteCode = String(object.inviteCode);
                    if (object.generatedTimestamp != null)
                        if ($util.Long)
                            (message.generatedTimestamp = $util.Long.fromValue(object.generatedTimestamp)).unsigned = false;
                        else if (typeof object.generatedTimestamp === "string")
                            message.generatedTimestamp = parseInt(object.generatedTimestamp, 10);
                        else if (typeof object.generatedTimestamp === "number")
                            message.generatedTimestamp = object.generatedTimestamp;
                        else if (typeof object.generatedTimestamp === "object")
                            message.generatedTimestamp = new $util.LongBits(object.generatedTimestamp.low >>> 0, object.generatedTimestamp.high >>> 0).toNumber();
                    if (object.alphaProviderAutoApprove != null)
                        message.alphaProviderAutoApprove = Boolean(object.alphaProviderAutoApprove);
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.InvitationProto} message InvitationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.inviteCode = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedTimestamp = options.longs === String ? "0" : 0;
                        object.alphaProviderAutoApprove = false;
                    }
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        object.inviteCode = message.inviteCode;
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (typeof message.generatedTimestamp === "number")
                            object.generatedTimestamp = options.longs === String ? String(message.generatedTimestamp) : message.generatedTimestamp;
                        else
                            object.generatedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.generatedTimestamp) : options.longs === Number ? new $util.LongBits(message.generatedTimestamp.low >>> 0, message.generatedTimestamp.high >>> 0).toNumber() : message.generatedTimestamp;
                    if (message.alphaProviderAutoApprove != null && message.hasOwnProperty("alphaProviderAutoApprove"))
                        object.alphaProviderAutoApprove = message.alphaProviderAutoApprove;
                    return object;
                };

                /**
                 * Converts this InvitationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProto";
                };

                return InvitationProto;
            })();

            proto.UserProfileSchemaProto = (function() {

                /**
                 * Properties of a UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IUserProfileSchemaProto
                 * @property {Array.<space.kenko.proto.IClientAppointmentSchemaProto>|null} [appointments] UserProfileSchemaProto appointments
                 * @property {string|null} [name] UserProfileSchemaProto name
                 * @property {space.kenko.proto.IWellnessSurveySchemaProto|null} [wellnessSurvey] UserProfileSchemaProto wellnessSurvey
                 */

                /**
                 * Constructs a new UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a UserProfileSchemaProto.
                 * @implements IUserProfileSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 */
                function UserProfileSchemaProto(properties) {
                    this.appointments = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserProfileSchemaProto appointments.
                 * @member {Array.<space.kenko.proto.IClientAppointmentSchemaProto>} appointments
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.appointments = $util.emptyArray;

                /**
                 * UserProfileSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.name = "";

                /**
                 * UserProfileSchemaProto wellnessSurvey.
                 * @member {space.kenko.proto.IWellnessSurveySchemaProto|null|undefined} wellnessSurvey
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.wellnessSurvey = null;

                /**
                 * Creates a new UserProfileSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto instance
                 */
                UserProfileSchemaProto.create = function create(properties) {
                    return new UserProfileSchemaProto(properties);
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointments != null && message.appointments.length)
                        for (var i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.ClientAppointmentSchemaProto.encode(message.appointments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.wellnessSurvey != null && Object.hasOwnProperty.call(message, "wellnessSurvey"))
                        $root.space.kenko.proto.WellnessSurveySchemaProto.encode(message.wellnessSurvey, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.ClientAppointmentSchemaProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserProfileSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProfileSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (var i = 0; i < message.appointments.length; ++i) {
                            var error = $root.space.kenko.proto.ClientAppointmentSchemaProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey")) {
                        var error = $root.space.kenko.proto.WellnessSurveySchemaProto.verify(message.wellnessSurvey);
                        if (error)
                            return "wellnessSurvey." + error;
                    }
                    return null;
                };

                /**
                 * Creates a UserProfileSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 */
                UserProfileSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UserProfileSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: array expected");
                        message.appointments = [];
                        for (var i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.ClientAppointmentSchemaProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.wellnessSurvey != null) {
                        if (typeof object.wellnessSurvey !== "object")
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.wellnessSurvey: object expected");
                        message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.fromObject(object.wellnessSurvey);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a UserProfileSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.UserProfileSchemaProto} message UserProfileSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProfileSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.appointments = [];
                    if (options.defaults) {
                        object.name = "";
                        object.wellnessSurvey = null;
                    }
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (var j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.ClientAppointmentSchemaProto.toObject(message.appointments[j], options);
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey"))
                        object.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.toObject(message.wellnessSurvey, options);
                    return object;
                };

                /**
                 * Converts this UserProfileSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProfileSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserProfileSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProfileSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UserProfileSchemaProto";
                };

                return UserProfileSchemaProto;
            })();

            proto.WellnessSurveySchemaProto = (function() {

                /**
                 * Properties of a WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IWellnessSurveySchemaProto
                 * @property {Array.<string>|null} [holisticHealthInterests] WellnessSurveySchemaProto holisticHealthInterests
                 * @property {string|null} [familiarityWithEasternDietaryPractices] WellnessSurveySchemaProto familiarityWithEasternDietaryPractices
                 * @property {Array.<string>|null} [fitnessGoals] WellnessSurveySchemaProto fitnessGoals
                 * @property {string|null} [currentSkincareRoutine] WellnessSurveySchemaProto currentSkincareRoutine
                 * @property {Array.<string>|null} [hairRelatedConcerns] WellnessSurveySchemaProto hairRelatedConcerns
                 */

                /**
                 * Constructs a new WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a WellnessSurveySchemaProto.
                 * @implements IWellnessSurveySchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 */
                function WellnessSurveySchemaProto(properties) {
                    this.holisticHealthInterests = [];
                    this.fitnessGoals = [];
                    this.hairRelatedConcerns = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WellnessSurveySchemaProto holisticHealthInterests.
                 * @member {Array.<string>} holisticHealthInterests
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.holisticHealthInterests = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto familiarityWithEasternDietaryPractices.
                 * @member {string} familiarityWithEasternDietaryPractices
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.familiarityWithEasternDietaryPractices = "";

                /**
                 * WellnessSurveySchemaProto fitnessGoals.
                 * @member {Array.<string>} fitnessGoals
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.fitnessGoals = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto currentSkincareRoutine.
                 * @member {string} currentSkincareRoutine
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.currentSkincareRoutine = "";

                /**
                 * WellnessSurveySchemaProto hairRelatedConcerns.
                 * @member {Array.<string>} hairRelatedConcerns
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.hairRelatedConcerns = $util.emptyArray;

                /**
                 * Creates a new WellnessSurveySchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto instance
                 */
                WellnessSurveySchemaProto.create = function create(properties) {
                    return new WellnessSurveySchemaProto(properties);
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.holisticHealthInterests != null && message.holisticHealthInterests.length)
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.holisticHealthInterests[i]);
                    if (message.familiarityWithEasternDietaryPractices != null && Object.hasOwnProperty.call(message, "familiarityWithEasternDietaryPractices"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.familiarityWithEasternDietaryPractices);
                    if (message.fitnessGoals != null && message.fitnessGoals.length)
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fitnessGoals[i]);
                    if (message.currentSkincareRoutine != null && Object.hasOwnProperty.call(message, "currentSkincareRoutine"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentSkincareRoutine);
                    if (message.hairRelatedConcerns != null && message.hairRelatedConcerns.length)
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.hairRelatedConcerns[i]);
                    return writer;
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.holisticHealthInterests && message.holisticHealthInterests.length))
                                    message.holisticHealthInterests = [];
                                message.holisticHealthInterests.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.familiarityWithEasternDietaryPractices = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fitnessGoals && message.fitnessGoals.length))
                                    message.fitnessGoals = [];
                                message.fitnessGoals.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.currentSkincareRoutine = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.hairRelatedConcerns && message.hairRelatedConcerns.length))
                                    message.hairRelatedConcerns = [];
                                message.hairRelatedConcerns.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WellnessSurveySchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WellnessSurveySchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.holisticHealthInterests != null && message.hasOwnProperty("holisticHealthInterests")) {
                        if (!Array.isArray(message.holisticHealthInterests))
                            return "holisticHealthInterests: array expected";
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            if (!$util.isString(message.holisticHealthInterests[i]))
                                return "holisticHealthInterests: string[] expected";
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        if (!$util.isString(message.familiarityWithEasternDietaryPractices))
                            return "familiarityWithEasternDietaryPractices: string expected";
                    if (message.fitnessGoals != null && message.hasOwnProperty("fitnessGoals")) {
                        if (!Array.isArray(message.fitnessGoals))
                            return "fitnessGoals: array expected";
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            if (!$util.isString(message.fitnessGoals[i]))
                                return "fitnessGoals: string[] expected";
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        if (!$util.isString(message.currentSkincareRoutine))
                            return "currentSkincareRoutine: string expected";
                    if (message.hairRelatedConcerns != null && message.hasOwnProperty("hairRelatedConcerns")) {
                        if (!Array.isArray(message.hairRelatedConcerns))
                            return "hairRelatedConcerns: array expected";
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            if (!$util.isString(message.hairRelatedConcerns[i]))
                                return "hairRelatedConcerns: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a WellnessSurveySchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 */
                WellnessSurveySchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.WellnessSurveySchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    if (object.holisticHealthInterests) {
                        if (!Array.isArray(object.holisticHealthInterests))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.holisticHealthInterests: array expected");
                        message.holisticHealthInterests = [];
                        for (var i = 0; i < object.holisticHealthInterests.length; ++i)
                            message.holisticHealthInterests[i] = String(object.holisticHealthInterests[i]);
                    }
                    if (object.familiarityWithEasternDietaryPractices != null)
                        message.familiarityWithEasternDietaryPractices = String(object.familiarityWithEasternDietaryPractices);
                    if (object.fitnessGoals) {
                        if (!Array.isArray(object.fitnessGoals))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.fitnessGoals: array expected");
                        message.fitnessGoals = [];
                        for (var i = 0; i < object.fitnessGoals.length; ++i)
                            message.fitnessGoals[i] = String(object.fitnessGoals[i]);
                    }
                    if (object.currentSkincareRoutine != null)
                        message.currentSkincareRoutine = String(object.currentSkincareRoutine);
                    if (object.hairRelatedConcerns) {
                        if (!Array.isArray(object.hairRelatedConcerns))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.hairRelatedConcerns: array expected");
                        message.hairRelatedConcerns = [];
                        for (var i = 0; i < object.hairRelatedConcerns.length; ++i)
                            message.hairRelatedConcerns[i] = String(object.hairRelatedConcerns[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WellnessSurveySchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.WellnessSurveySchemaProto} message WellnessSurveySchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WellnessSurveySchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.holisticHealthInterests = [];
                        object.fitnessGoals = [];
                        object.hairRelatedConcerns = [];
                    }
                    if (options.defaults) {
                        object.familiarityWithEasternDietaryPractices = "";
                        object.currentSkincareRoutine = "";
                    }
                    if (message.holisticHealthInterests && message.holisticHealthInterests.length) {
                        object.holisticHealthInterests = [];
                        for (var j = 0; j < message.holisticHealthInterests.length; ++j)
                            object.holisticHealthInterests[j] = message.holisticHealthInterests[j];
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        object.familiarityWithEasternDietaryPractices = message.familiarityWithEasternDietaryPractices;
                    if (message.fitnessGoals && message.fitnessGoals.length) {
                        object.fitnessGoals = [];
                        for (var j = 0; j < message.fitnessGoals.length; ++j)
                            object.fitnessGoals[j] = message.fitnessGoals[j];
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        object.currentSkincareRoutine = message.currentSkincareRoutine;
                    if (message.hairRelatedConcerns && message.hairRelatedConcerns.length) {
                        object.hairRelatedConcerns = [];
                        for (var j = 0; j < message.hairRelatedConcerns.length; ++j)
                            object.hairRelatedConcerns[j] = message.hairRelatedConcerns[j];
                    }
                    return object;
                };

                /**
                 * Converts this WellnessSurveySchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WellnessSurveySchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WellnessSurveySchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WellnessSurveySchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.WellnessSurveySchemaProto";
                };

                return WellnessSurveySchemaProto;
            })();

            proto.ClientAppointmentSchemaProto = (function() {

                /**
                 * Properties of a ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IClientAppointmentSchemaProto
                 * @property {string|null} [appointmentId] ClientAppointmentSchemaProto appointmentId
                 * @property {number|Long|null} [startTimeMs] ClientAppointmentSchemaProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] ClientAppointmentSchemaProto endTimeMs
                 * @property {string|null} [serviceName] ClientAppointmentSchemaProto serviceName
                 * @property {string|null} [serviceSku] ClientAppointmentSchemaProto serviceSku
                 * @property {string|null} [providerName] ClientAppointmentSchemaProto providerName
                 * @property {string|null} [providerId] ClientAppointmentSchemaProto providerId
                 * @property {string|null} [businessId] ClientAppointmentSchemaProto businessId
                 * @property {string|null} [address] ClientAppointmentSchemaProto address
                 * @property {space.kenko.proto.IPriceProto|null} [price] ClientAppointmentSchemaProto price
                 */

                /**
                 * Constructs a new ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ClientAppointmentSchemaProto.
                 * @implements IClientAppointmentSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 */
                function ClientAppointmentSchemaProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ClientAppointmentSchemaProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.appointmentId = "";

                /**
                 * ClientAppointmentSchemaProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto serviceName.
                 * @member {string} serviceName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.serviceName = "";

                /**
                 * ClientAppointmentSchemaProto serviceSku.
                 * @member {string} serviceSku
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.serviceSku = "";

                /**
                 * ClientAppointmentSchemaProto providerName.
                 * @member {string} providerName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.providerName = "";

                /**
                 * ClientAppointmentSchemaProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.providerId = "";

                /**
                 * ClientAppointmentSchemaProto businessId.
                 * @member {string} businessId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.businessId = "";

                /**
                 * ClientAppointmentSchemaProto address.
                 * @member {string} address
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.address = "";

                /**
                 * ClientAppointmentSchemaProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.price = null;

                /**
                 * Creates a new ClientAppointmentSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto instance
                 */
                ClientAppointmentSchemaProto.create = function create(properties) {
                    return new ClientAppointmentSchemaProto(properties);
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.serviceName != null && Object.hasOwnProperty.call(message, "serviceName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceName);
                    if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerName);
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.providerId);
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.businessId);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.address);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.serviceSku != null && Object.hasOwnProperty.call(message, "serviceSku"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.serviceSku);
                    return writer;
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.serviceName = reader.string();
                                break;
                            }
                        case 10: {
                                message.serviceSku = reader.string();
                                break;
                            }
                        case 5: {
                                message.providerName = reader.string();
                                break;
                            }
                        case 6: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 7: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 8: {
                                message.address = reader.string();
                                break;
                            }
                        case 9: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClientAppointmentSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClientAppointmentSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        if (!$util.isString(message.serviceName))
                            return "serviceName: string expected";
                    if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
                        if (!$util.isString(message.serviceSku))
                            return "serviceSku: string expected";
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        if (!$util.isString(message.providerName))
                            return "providerName: string expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ClientAppointmentSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 */
                ClientAppointmentSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ClientAppointmentSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.serviceName != null)
                        message.serviceName = String(object.serviceName);
                    if (object.serviceSku != null)
                        message.serviceSku = String(object.serviceSku);
                    if (object.providerName != null)
                        message.providerName = String(object.providerName);
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.ClientAppointmentSchemaProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ClientAppointmentSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.ClientAppointmentSchemaProto} message ClientAppointmentSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClientAppointmentSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.serviceName = "";
                        object.providerName = "";
                        object.providerId = "";
                        object.businessId = "";
                        object.address = "";
                        object.price = null;
                        object.serviceSku = "";
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        object.serviceName = message.serviceName;
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        object.providerName = message.providerName;
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
                        object.serviceSku = message.serviceSku;
                    return object;
                };

                /**
                 * Converts this ClientAppointmentSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClientAppointmentSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ClientAppointmentSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClientAppointmentSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ClientAppointmentSchemaProto";
                };

                return ClientAppointmentSchemaProto;
            })();

            proto.ProviderSurveyProto = (function() {

                /**
                 * Properties of a ProviderSurveyProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderSurveyProto
                 * @property {string|null} [businessName] ProviderSurveyProto businessName
                 * @property {string|null} [businessPhone] ProviderSurveyProto businessPhone
                 * @property {string|null} [businessEmail] ProviderSurveyProto businessEmail
                 * @property {string|null} [website] ProviderSurveyProto website
                 * @property {Array.<string>|null} [servicesOffered] ProviderSurveyProto servicesOffered
                 * @property {space.kenko.proto.SurveyRole|null} [personsBusinessRole] ProviderSurveyProto personsBusinessRole
                 * @property {Array.<space.kenko.proto.ISpecialtyProto>|null} [specialty] ProviderSurveyProto specialty
                 * @property {Array.<space.kenko.proto.IEducationProto>|null} [education] ProviderSurveyProto education
                 * @property {Array.<space.kenko.proto.ILicenseOrCredentialsProto>|null} [licenseCredentials] ProviderSurveyProto licenseCredentials
                 * @property {Array.<space.kenko.proto.SurveySessionType>|null} [sessionTypes] ProviderSurveyProto sessionTypes
                 * @property {Array.<space.kenko.proto.SurveyApptLocations>|null} [appointmentLocations] ProviderSurveyProto appointmentLocations
                 * @property {space.kenko.proto.IAddressProto|null} [address] ProviderSurveyProto address
                 * @property {string|null} [surveyCompletedByProviderId] ProviderSurveyProto surveyCompletedByProviderId
                 * @property {string|null} [mostExperiencedPractitionerName] ProviderSurveyProto mostExperiencedPractitionerName
                 * @property {string|null} [mostExperiencedPractitionerTitle] ProviderSurveyProto mostExperiencedPractitionerTitle
                 * @property {string|null} [surveyCompletedByUserName] ProviderSurveyProto surveyCompletedByUserName
                 * @property {string|null} [surveyCompletedByEmail] ProviderSurveyProto surveyCompletedByEmail
                 * @property {number|null} [howManyPractitionersInPractice] ProviderSurveyProto howManyPractitionersInPractice
                 * @property {space.kenko.proto.SurveyPracticeYearsInOperation|null} [yearsInOperation] ProviderSurveyProto yearsInOperation
                 */

                /**
                 * Constructs a new ProviderSurveyProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderSurveyProto.
                 * @implements IProviderSurveyProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderSurveyProto=} [properties] Properties to set
                 */
                function ProviderSurveyProto(properties) {
                    this.servicesOffered = [];
                    this.specialty = [];
                    this.education = [];
                    this.licenseCredentials = [];
                    this.sessionTypes = [];
                    this.appointmentLocations = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderSurveyProto businessName.
                 * @member {string} businessName
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.businessName = "";

                /**
                 * ProviderSurveyProto businessPhone.
                 * @member {string} businessPhone
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.businessPhone = "";

                /**
                 * ProviderSurveyProto businessEmail.
                 * @member {string} businessEmail
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.businessEmail = "";

                /**
                 * ProviderSurveyProto website.
                 * @member {string} website
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.website = "";

                /**
                 * ProviderSurveyProto servicesOffered.
                 * @member {Array.<string>} servicesOffered
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.servicesOffered = $util.emptyArray;

                /**
                 * ProviderSurveyProto personsBusinessRole.
                 * @member {space.kenko.proto.SurveyRole} personsBusinessRole
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.personsBusinessRole = 0;

                /**
                 * ProviderSurveyProto specialty.
                 * @member {Array.<space.kenko.proto.ISpecialtyProto>} specialty
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.specialty = $util.emptyArray;

                /**
                 * ProviderSurveyProto education.
                 * @member {Array.<space.kenko.proto.IEducationProto>} education
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.education = $util.emptyArray;

                /**
                 * ProviderSurveyProto licenseCredentials.
                 * @member {Array.<space.kenko.proto.ILicenseOrCredentialsProto>} licenseCredentials
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.licenseCredentials = $util.emptyArray;

                /**
                 * ProviderSurveyProto sessionTypes.
                 * @member {Array.<space.kenko.proto.SurveySessionType>} sessionTypes
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.sessionTypes = $util.emptyArray;

                /**
                 * ProviderSurveyProto appointmentLocations.
                 * @member {Array.<space.kenko.proto.SurveyApptLocations>} appointmentLocations
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.appointmentLocations = $util.emptyArray;

                /**
                 * ProviderSurveyProto address.
                 * @member {space.kenko.proto.IAddressProto|null|undefined} address
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.address = null;

                /**
                 * ProviderSurveyProto surveyCompletedByProviderId.
                 * @member {string} surveyCompletedByProviderId
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.surveyCompletedByProviderId = "";

                /**
                 * ProviderSurveyProto mostExperiencedPractitionerName.
                 * @member {string} mostExperiencedPractitionerName
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.mostExperiencedPractitionerName = "";

                /**
                 * ProviderSurveyProto mostExperiencedPractitionerTitle.
                 * @member {string} mostExperiencedPractitionerTitle
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.mostExperiencedPractitionerTitle = "";

                /**
                 * ProviderSurveyProto surveyCompletedByUserName.
                 * @member {string} surveyCompletedByUserName
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.surveyCompletedByUserName = "";

                /**
                 * ProviderSurveyProto surveyCompletedByEmail.
                 * @member {string} surveyCompletedByEmail
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.surveyCompletedByEmail = "";

                /**
                 * ProviderSurveyProto howManyPractitionersInPractice.
                 * @member {number} howManyPractitionersInPractice
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.howManyPractitionersInPractice = 0;

                /**
                 * ProviderSurveyProto yearsInOperation.
                 * @member {space.kenko.proto.SurveyPracticeYearsInOperation} yearsInOperation
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.yearsInOperation = 0;

                /**
                 * Creates a new ProviderSurveyProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {space.kenko.proto.IProviderSurveyProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderSurveyProto} ProviderSurveyProto instance
                 */
                ProviderSurveyProto.create = function create(properties) {
                    return new ProviderSurveyProto(properties);
                };

                /**
                 * Encodes the specified ProviderSurveyProto message. Does not implicitly {@link space.kenko.proto.ProviderSurveyProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {space.kenko.proto.IProviderSurveyProto} message ProviderSurveyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderSurveyProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.businessPhone != null && Object.hasOwnProperty.call(message, "businessPhone"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessPhone);
                    if (message.businessEmail != null && Object.hasOwnProperty.call(message, "businessEmail"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessEmail);
                    if (message.website != null && Object.hasOwnProperty.call(message, "website"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.website);
                    if (message.servicesOffered != null && message.servicesOffered.length)
                        for (var i = 0; i < message.servicesOffered.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.servicesOffered[i]);
                    if (message.personsBusinessRole != null && Object.hasOwnProperty.call(message, "personsBusinessRole"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.personsBusinessRole);
                    if (message.specialty != null && message.specialty.length)
                        for (var i = 0; i < message.specialty.length; ++i)
                            $root.space.kenko.proto.SpecialtyProto.encode(message.specialty[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.education != null && message.education.length)
                        for (var i = 0; i < message.education.length; ++i)
                            $root.space.kenko.proto.EducationProto.encode(message.education[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.licenseCredentials != null && message.licenseCredentials.length)
                        for (var i = 0; i < message.licenseCredentials.length; ++i)
                            $root.space.kenko.proto.LicenseOrCredentialsProto.encode(message.licenseCredentials[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.sessionTypes != null && message.sessionTypes.length) {
                        writer.uint32(/* id 11, wireType 2 =*/90).fork();
                        for (var i = 0; i < message.sessionTypes.length; ++i)
                            writer.int32(message.sessionTypes[i]);
                        writer.ldelim();
                    }
                    if (message.appointmentLocations != null && message.appointmentLocations.length) {
                        writer.uint32(/* id 12, wireType 2 =*/98).fork();
                        for (var i = 0; i < message.appointmentLocations.length; ++i)
                            writer.int32(message.appointmentLocations[i]);
                        writer.ldelim();
                    }
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        $root.space.kenko.proto.AddressProto.encode(message.address, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.surveyCompletedByProviderId != null && Object.hasOwnProperty.call(message, "surveyCompletedByProviderId"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.surveyCompletedByProviderId);
                    if (message.mostExperiencedPractitionerName != null && Object.hasOwnProperty.call(message, "mostExperiencedPractitionerName"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.mostExperiencedPractitionerName);
                    if (message.mostExperiencedPractitionerTitle != null && Object.hasOwnProperty.call(message, "mostExperiencedPractitionerTitle"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.mostExperiencedPractitionerTitle);
                    if (message.surveyCompletedByUserName != null && Object.hasOwnProperty.call(message, "surveyCompletedByUserName"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.surveyCompletedByUserName);
                    if (message.surveyCompletedByEmail != null && Object.hasOwnProperty.call(message, "surveyCompletedByEmail"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.surveyCompletedByEmail);
                    if (message.howManyPractitionersInPractice != null && Object.hasOwnProperty.call(message, "howManyPractitionersInPractice"))
                        writer.uint32(/* id 19, wireType 0 =*/152).int32(message.howManyPractitionersInPractice);
                    if (message.yearsInOperation != null && Object.hasOwnProperty.call(message, "yearsInOperation"))
                        writer.uint32(/* id 20, wireType 0 =*/160).int32(message.yearsInOperation);
                    return writer;
                };

                /**
                 * Encodes the specified ProviderSurveyProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderSurveyProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {space.kenko.proto.IProviderSurveyProto} message ProviderSurveyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderSurveyProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderSurveyProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderSurveyProto} ProviderSurveyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderSurveyProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderSurveyProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessPhone = reader.string();
                                break;
                            }
                        case 3: {
                                message.businessEmail = reader.string();
                                break;
                            }
                        case 4: {
                                message.website = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.servicesOffered && message.servicesOffered.length))
                                    message.servicesOffered = [];
                                message.servicesOffered.push(reader.string());
                                break;
                            }
                        case 6: {
                                message.personsBusinessRole = reader.int32();
                                break;
                            }
                        case 7: {
                                if (!(message.specialty && message.specialty.length))
                                    message.specialty = [];
                                message.specialty.push($root.space.kenko.proto.SpecialtyProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 8: {
                                if (!(message.education && message.education.length))
                                    message.education = [];
                                message.education.push($root.space.kenko.proto.EducationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 9: {
                                if (!(message.licenseCredentials && message.licenseCredentials.length))
                                    message.licenseCredentials = [];
                                message.licenseCredentials.push($root.space.kenko.proto.LicenseOrCredentialsProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 11: {
                                if (!(message.sessionTypes && message.sessionTypes.length))
                                    message.sessionTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.sessionTypes.push(reader.int32());
                                } else
                                    message.sessionTypes.push(reader.int32());
                                break;
                            }
                        case 12: {
                                if (!(message.appointmentLocations && message.appointmentLocations.length))
                                    message.appointmentLocations = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.appointmentLocations.push(reader.int32());
                                } else
                                    message.appointmentLocations.push(reader.int32());
                                break;
                            }
                        case 13: {
                                message.address = $root.space.kenko.proto.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.surveyCompletedByProviderId = reader.string();
                                break;
                            }
                        case 15: {
                                message.mostExperiencedPractitionerName = reader.string();
                                break;
                            }
                        case 16: {
                                message.mostExperiencedPractitionerTitle = reader.string();
                                break;
                            }
                        case 17: {
                                message.surveyCompletedByUserName = reader.string();
                                break;
                            }
                        case 18: {
                                message.surveyCompletedByEmail = reader.string();
                                break;
                            }
                        case 19: {
                                message.howManyPractitionersInPractice = reader.int32();
                                break;
                            }
                        case 20: {
                                message.yearsInOperation = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderSurveyProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderSurveyProto} ProviderSurveyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderSurveyProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderSurveyProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderSurveyProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessPhone != null && message.hasOwnProperty("businessPhone"))
                        if (!$util.isString(message.businessPhone))
                            return "businessPhone: string expected";
                    if (message.businessEmail != null && message.hasOwnProperty("businessEmail"))
                        if (!$util.isString(message.businessEmail))
                            return "businessEmail: string expected";
                    if (message.website != null && message.hasOwnProperty("website"))
                        if (!$util.isString(message.website))
                            return "website: string expected";
                    if (message.servicesOffered != null && message.hasOwnProperty("servicesOffered")) {
                        if (!Array.isArray(message.servicesOffered))
                            return "servicesOffered: array expected";
                        for (var i = 0; i < message.servicesOffered.length; ++i)
                            if (!$util.isString(message.servicesOffered[i]))
                                return "servicesOffered: string[] expected";
                    }
                    if (message.personsBusinessRole != null && message.hasOwnProperty("personsBusinessRole"))
                        switch (message.personsBusinessRole) {
                        default:
                            return "personsBusinessRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.specialty != null && message.hasOwnProperty("specialty")) {
                        if (!Array.isArray(message.specialty))
                            return "specialty: array expected";
                        for (var i = 0; i < message.specialty.length; ++i) {
                            var error = $root.space.kenko.proto.SpecialtyProto.verify(message.specialty[i]);
                            if (error)
                                return "specialty." + error;
                        }
                    }
                    if (message.education != null && message.hasOwnProperty("education")) {
                        if (!Array.isArray(message.education))
                            return "education: array expected";
                        for (var i = 0; i < message.education.length; ++i) {
                            var error = $root.space.kenko.proto.EducationProto.verify(message.education[i]);
                            if (error)
                                return "education." + error;
                        }
                    }
                    if (message.licenseCredentials != null && message.hasOwnProperty("licenseCredentials")) {
                        if (!Array.isArray(message.licenseCredentials))
                            return "licenseCredentials: array expected";
                        for (var i = 0; i < message.licenseCredentials.length; ++i) {
                            var error = $root.space.kenko.proto.LicenseOrCredentialsProto.verify(message.licenseCredentials[i]);
                            if (error)
                                return "licenseCredentials." + error;
                        }
                    }
                    if (message.sessionTypes != null && message.hasOwnProperty("sessionTypes")) {
                        if (!Array.isArray(message.sessionTypes))
                            return "sessionTypes: array expected";
                        for (var i = 0; i < message.sessionTypes.length; ++i)
                            switch (message.sessionTypes[i]) {
                            default:
                                return "sessionTypes: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.appointmentLocations != null && message.hasOwnProperty("appointmentLocations")) {
                        if (!Array.isArray(message.appointmentLocations))
                            return "appointmentLocations: array expected";
                        for (var i = 0; i < message.appointmentLocations.length; ++i)
                            switch (message.appointmentLocations[i]) {
                            default:
                                return "appointmentLocations: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        var error = $root.space.kenko.proto.AddressProto.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.surveyCompletedByProviderId != null && message.hasOwnProperty("surveyCompletedByProviderId"))
                        if (!$util.isString(message.surveyCompletedByProviderId))
                            return "surveyCompletedByProviderId: string expected";
                    if (message.mostExperiencedPractitionerName != null && message.hasOwnProperty("mostExperiencedPractitionerName"))
                        if (!$util.isString(message.mostExperiencedPractitionerName))
                            return "mostExperiencedPractitionerName: string expected";
                    if (message.mostExperiencedPractitionerTitle != null && message.hasOwnProperty("mostExperiencedPractitionerTitle"))
                        if (!$util.isString(message.mostExperiencedPractitionerTitle))
                            return "mostExperiencedPractitionerTitle: string expected";
                    if (message.surveyCompletedByUserName != null && message.hasOwnProperty("surveyCompletedByUserName"))
                        if (!$util.isString(message.surveyCompletedByUserName))
                            return "surveyCompletedByUserName: string expected";
                    if (message.surveyCompletedByEmail != null && message.hasOwnProperty("surveyCompletedByEmail"))
                        if (!$util.isString(message.surveyCompletedByEmail))
                            return "surveyCompletedByEmail: string expected";
                    if (message.howManyPractitionersInPractice != null && message.hasOwnProperty("howManyPractitionersInPractice"))
                        if (!$util.isInteger(message.howManyPractitionersInPractice))
                            return "howManyPractitionersInPractice: integer expected";
                    if (message.yearsInOperation != null && message.hasOwnProperty("yearsInOperation"))
                        switch (message.yearsInOperation) {
                        default:
                            return "yearsInOperation: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ProviderSurveyProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderSurveyProto} ProviderSurveyProto
                 */
                ProviderSurveyProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderSurveyProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderSurveyProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessPhone != null)
                        message.businessPhone = String(object.businessPhone);
                    if (object.businessEmail != null)
                        message.businessEmail = String(object.businessEmail);
                    if (object.website != null)
                        message.website = String(object.website);
                    if (object.servicesOffered) {
                        if (!Array.isArray(object.servicesOffered))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.servicesOffered: array expected");
                        message.servicesOffered = [];
                        for (var i = 0; i < object.servicesOffered.length; ++i)
                            message.servicesOffered[i] = String(object.servicesOffered[i]);
                    }
                    switch (object.personsBusinessRole) {
                    default:
                        if (typeof object.personsBusinessRole === "number") {
                            message.personsBusinessRole = object.personsBusinessRole;
                            break;
                        }
                        break;
                    case "ROLE_UNKNOWN":
                    case 0:
                        message.personsBusinessRole = 0;
                        break;
                    case "OFFICE_MANAGER_OR_STAFF":
                    case 1:
                        message.personsBusinessRole = 1;
                        break;
                    case "PRACTITIONER":
                    case 2:
                        message.personsBusinessRole = 2;
                        break;
                    case "BUSINESS_OWNER_AND_PRACTITIONER":
                    case 3:
                        message.personsBusinessRole = 3;
                        break;
                    case "BUSINESS_OWNER_NON_PRACTITIONER":
                    case 4:
                        message.personsBusinessRole = 4;
                        break;
                    case "OTHER":
                    case 5:
                        message.personsBusinessRole = 5;
                        break;
                    }
                    if (object.specialty) {
                        if (!Array.isArray(object.specialty))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.specialty: array expected");
                        message.specialty = [];
                        for (var i = 0; i < object.specialty.length; ++i) {
                            if (typeof object.specialty[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSurveyProto.specialty: object expected");
                            message.specialty[i] = $root.space.kenko.proto.SpecialtyProto.fromObject(object.specialty[i]);
                        }
                    }
                    if (object.education) {
                        if (!Array.isArray(object.education))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.education: array expected");
                        message.education = [];
                        for (var i = 0; i < object.education.length; ++i) {
                            if (typeof object.education[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSurveyProto.education: object expected");
                            message.education[i] = $root.space.kenko.proto.EducationProto.fromObject(object.education[i]);
                        }
                    }
                    if (object.licenseCredentials) {
                        if (!Array.isArray(object.licenseCredentials))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.licenseCredentials: array expected");
                        message.licenseCredentials = [];
                        for (var i = 0; i < object.licenseCredentials.length; ++i) {
                            if (typeof object.licenseCredentials[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSurveyProto.licenseCredentials: object expected");
                            message.licenseCredentials[i] = $root.space.kenko.proto.LicenseOrCredentialsProto.fromObject(object.licenseCredentials[i]);
                        }
                    }
                    if (object.sessionTypes) {
                        if (!Array.isArray(object.sessionTypes))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.sessionTypes: array expected");
                        message.sessionTypes = [];
                        for (var i = 0; i < object.sessionTypes.length; ++i)
                            switch (object.sessionTypes[i]) {
                            default:
                                if (typeof object.sessionTypes[i] === "number") {
                                    message.sessionTypes[i] = object.sessionTypes[i];
                                    break;
                                }
                            case "SESSION_TYPE_UNKNOWN":
                            case 0:
                                message.sessionTypes[i] = 0;
                                break;
                            case "PRIVATE_SESSIONS":
                            case 1:
                                message.sessionTypes[i] = 1;
                                break;
                            case "SEMI_PRIVATE_SESSIONS":
                            case 2:
                                message.sessionTypes[i] = 2;
                                break;
                            case "GROUP_CLASSES":
                            case 3:
                                message.sessionTypes[i] = 3;
                                break;
                            }
                    }
                    if (object.appointmentLocations) {
                        if (!Array.isArray(object.appointmentLocations))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.appointmentLocations: array expected");
                        message.appointmentLocations = [];
                        for (var i = 0; i < object.appointmentLocations.length; ++i)
                            switch (object.appointmentLocations[i]) {
                            default:
                                if (typeof object.appointmentLocations[i] === "number") {
                                    message.appointmentLocations[i] = object.appointmentLocations[i];
                                    break;
                                }
                            case "APPT_LOCATION_UNKNOWN":
                            case 0:
                                message.appointmentLocations[i] = 0;
                                break;
                            case "IN_PERSON_AT_BUSINESS":
                            case 1:
                                message.appointmentLocations[i] = 1;
                                break;
                            case "VIRTUAL_APPOINTMENTS":
                            case 2:
                                message.appointmentLocations[i] = 2;
                                break;
                            case "IN_HOME_APPOINTMENTS":
                            case 3:
                                message.appointmentLocations[i] = 3;
                                break;
                            }
                    }
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.address: object expected");
                        message.address = $root.space.kenko.proto.AddressProto.fromObject(object.address);
                    }
                    if (object.surveyCompletedByProviderId != null)
                        message.surveyCompletedByProviderId = String(object.surveyCompletedByProviderId);
                    if (object.mostExperiencedPractitionerName != null)
                        message.mostExperiencedPractitionerName = String(object.mostExperiencedPractitionerName);
                    if (object.mostExperiencedPractitionerTitle != null)
                        message.mostExperiencedPractitionerTitle = String(object.mostExperiencedPractitionerTitle);
                    if (object.surveyCompletedByUserName != null)
                        message.surveyCompletedByUserName = String(object.surveyCompletedByUserName);
                    if (object.surveyCompletedByEmail != null)
                        message.surveyCompletedByEmail = String(object.surveyCompletedByEmail);
                    if (object.howManyPractitionersInPractice != null)
                        message.howManyPractitionersInPractice = object.howManyPractitionersInPractice | 0;
                    switch (object.yearsInOperation) {
                    default:
                        if (typeof object.yearsInOperation === "number") {
                            message.yearsInOperation = object.yearsInOperation;
                            break;
                        }
                        break;
                    case "YEARS_UNKNOWN":
                    case 0:
                        message.yearsInOperation = 0;
                        break;
                    case "LESS_THAN_1":
                    case 1:
                        message.yearsInOperation = 1;
                        break;
                    case "ONE_TO_THREE":
                    case 2:
                        message.yearsInOperation = 2;
                        break;
                    case "FOUR_TO_SEVEN":
                    case 3:
                        message.yearsInOperation = 3;
                        break;
                    case "EIGHT_TO_TEN":
                    case 4:
                        message.yearsInOperation = 4;
                        break;
                    case "MORE_THAN_TEN":
                    case 5:
                        message.yearsInOperation = 5;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderSurveyProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {space.kenko.proto.ProviderSurveyProto} message ProviderSurveyProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderSurveyProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.servicesOffered = [];
                        object.specialty = [];
                        object.education = [];
                        object.licenseCredentials = [];
                        object.sessionTypes = [];
                        object.appointmentLocations = [];
                    }
                    if (options.defaults) {
                        object.businessName = "";
                        object.businessPhone = "";
                        object.businessEmail = "";
                        object.website = "";
                        object.personsBusinessRole = options.enums === String ? "ROLE_UNKNOWN" : 0;
                        object.address = null;
                        object.surveyCompletedByProviderId = "";
                        object.mostExperiencedPractitionerName = "";
                        object.mostExperiencedPractitionerTitle = "";
                        object.surveyCompletedByUserName = "";
                        object.surveyCompletedByEmail = "";
                        object.howManyPractitionersInPractice = 0;
                        object.yearsInOperation = options.enums === String ? "YEARS_UNKNOWN" : 0;
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.businessPhone != null && message.hasOwnProperty("businessPhone"))
                        object.businessPhone = message.businessPhone;
                    if (message.businessEmail != null && message.hasOwnProperty("businessEmail"))
                        object.businessEmail = message.businessEmail;
                    if (message.website != null && message.hasOwnProperty("website"))
                        object.website = message.website;
                    if (message.servicesOffered && message.servicesOffered.length) {
                        object.servicesOffered = [];
                        for (var j = 0; j < message.servicesOffered.length; ++j)
                            object.servicesOffered[j] = message.servicesOffered[j];
                    }
                    if (message.personsBusinessRole != null && message.hasOwnProperty("personsBusinessRole"))
                        object.personsBusinessRole = options.enums === String ? $root.space.kenko.proto.SurveyRole[message.personsBusinessRole] === undefined ? message.personsBusinessRole : $root.space.kenko.proto.SurveyRole[message.personsBusinessRole] : message.personsBusinessRole;
                    if (message.specialty && message.specialty.length) {
                        object.specialty = [];
                        for (var j = 0; j < message.specialty.length; ++j)
                            object.specialty[j] = $root.space.kenko.proto.SpecialtyProto.toObject(message.specialty[j], options);
                    }
                    if (message.education && message.education.length) {
                        object.education = [];
                        for (var j = 0; j < message.education.length; ++j)
                            object.education[j] = $root.space.kenko.proto.EducationProto.toObject(message.education[j], options);
                    }
                    if (message.licenseCredentials && message.licenseCredentials.length) {
                        object.licenseCredentials = [];
                        for (var j = 0; j < message.licenseCredentials.length; ++j)
                            object.licenseCredentials[j] = $root.space.kenko.proto.LicenseOrCredentialsProto.toObject(message.licenseCredentials[j], options);
                    }
                    if (message.sessionTypes && message.sessionTypes.length) {
                        object.sessionTypes = [];
                        for (var j = 0; j < message.sessionTypes.length; ++j)
                            object.sessionTypes[j] = options.enums === String ? $root.space.kenko.proto.SurveySessionType[message.sessionTypes[j]] === undefined ? message.sessionTypes[j] : $root.space.kenko.proto.SurveySessionType[message.sessionTypes[j]] : message.sessionTypes[j];
                    }
                    if (message.appointmentLocations && message.appointmentLocations.length) {
                        object.appointmentLocations = [];
                        for (var j = 0; j < message.appointmentLocations.length; ++j)
                            object.appointmentLocations[j] = options.enums === String ? $root.space.kenko.proto.SurveyApptLocations[message.appointmentLocations[j]] === undefined ? message.appointmentLocations[j] : $root.space.kenko.proto.SurveyApptLocations[message.appointmentLocations[j]] : message.appointmentLocations[j];
                    }
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.space.kenko.proto.AddressProto.toObject(message.address, options);
                    if (message.surveyCompletedByProviderId != null && message.hasOwnProperty("surveyCompletedByProviderId"))
                        object.surveyCompletedByProviderId = message.surveyCompletedByProviderId;
                    if (message.mostExperiencedPractitionerName != null && message.hasOwnProperty("mostExperiencedPractitionerName"))
                        object.mostExperiencedPractitionerName = message.mostExperiencedPractitionerName;
                    if (message.mostExperiencedPractitionerTitle != null && message.hasOwnProperty("mostExperiencedPractitionerTitle"))
                        object.mostExperiencedPractitionerTitle = message.mostExperiencedPractitionerTitle;
                    if (message.surveyCompletedByUserName != null && message.hasOwnProperty("surveyCompletedByUserName"))
                        object.surveyCompletedByUserName = message.surveyCompletedByUserName;
                    if (message.surveyCompletedByEmail != null && message.hasOwnProperty("surveyCompletedByEmail"))
                        object.surveyCompletedByEmail = message.surveyCompletedByEmail;
                    if (message.howManyPractitionersInPractice != null && message.hasOwnProperty("howManyPractitionersInPractice"))
                        object.howManyPractitionersInPractice = message.howManyPractitionersInPractice;
                    if (message.yearsInOperation != null && message.hasOwnProperty("yearsInOperation"))
                        object.yearsInOperation = options.enums === String ? $root.space.kenko.proto.SurveyPracticeYearsInOperation[message.yearsInOperation] === undefined ? message.yearsInOperation : $root.space.kenko.proto.SurveyPracticeYearsInOperation[message.yearsInOperation] : message.yearsInOperation;
                    return object;
                };

                /**
                 * Converts this ProviderSurveyProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderSurveyProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderSurveyProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderSurveyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderSurveyProto";
                };

                return ProviderSurveyProto;
            })();

            /**
             * SurveyRole enum.
             * @name space.kenko.proto.SurveyRole
             * @enum {number}
             * @property {number} ROLE_UNKNOWN=0 ROLE_UNKNOWN value
             * @property {number} OFFICE_MANAGER_OR_STAFF=1 OFFICE_MANAGER_OR_STAFF value
             * @property {number} PRACTITIONER=2 PRACTITIONER value
             * @property {number} BUSINESS_OWNER_AND_PRACTITIONER=3 BUSINESS_OWNER_AND_PRACTITIONER value
             * @property {number} BUSINESS_OWNER_NON_PRACTITIONER=4 BUSINESS_OWNER_NON_PRACTITIONER value
             * @property {number} OTHER=5 OTHER value
             */
            proto.SurveyRole = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ROLE_UNKNOWN"] = 0;
                values[valuesById[1] = "OFFICE_MANAGER_OR_STAFF"] = 1;
                values[valuesById[2] = "PRACTITIONER"] = 2;
                values[valuesById[3] = "BUSINESS_OWNER_AND_PRACTITIONER"] = 3;
                values[valuesById[4] = "BUSINESS_OWNER_NON_PRACTITIONER"] = 4;
                values[valuesById[5] = "OTHER"] = 5;
                return values;
            })();

            /**
             * SurveySessionType enum.
             * @name space.kenko.proto.SurveySessionType
             * @enum {number}
             * @property {number} SESSION_TYPE_UNKNOWN=0 SESSION_TYPE_UNKNOWN value
             * @property {number} PRIVATE_SESSIONS=1 PRIVATE_SESSIONS value
             * @property {number} SEMI_PRIVATE_SESSIONS=2 SEMI_PRIVATE_SESSIONS value
             * @property {number} GROUP_CLASSES=3 GROUP_CLASSES value
             */
            proto.SurveySessionType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SESSION_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "PRIVATE_SESSIONS"] = 1;
                values[valuesById[2] = "SEMI_PRIVATE_SESSIONS"] = 2;
                values[valuesById[3] = "GROUP_CLASSES"] = 3;
                return values;
            })();

            /**
             * SurveyApptLocations enum.
             * @name space.kenko.proto.SurveyApptLocations
             * @enum {number}
             * @property {number} APPT_LOCATION_UNKNOWN=0 APPT_LOCATION_UNKNOWN value
             * @property {number} IN_PERSON_AT_BUSINESS=1 IN_PERSON_AT_BUSINESS value
             * @property {number} VIRTUAL_APPOINTMENTS=2 VIRTUAL_APPOINTMENTS value
             * @property {number} IN_HOME_APPOINTMENTS=3 IN_HOME_APPOINTMENTS value
             */
            proto.SurveyApptLocations = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "APPT_LOCATION_UNKNOWN"] = 0;
                values[valuesById[1] = "IN_PERSON_AT_BUSINESS"] = 1;
                values[valuesById[2] = "VIRTUAL_APPOINTMENTS"] = 2;
                values[valuesById[3] = "IN_HOME_APPOINTMENTS"] = 3;
                return values;
            })();

            /**
             * SurveyPracticeYearsInOperation enum.
             * @name space.kenko.proto.SurveyPracticeYearsInOperation
             * @enum {number}
             * @property {number} YEARS_UNKNOWN=0 YEARS_UNKNOWN value
             * @property {number} LESS_THAN_1=1 LESS_THAN_1 value
             * @property {number} ONE_TO_THREE=2 ONE_TO_THREE value
             * @property {number} FOUR_TO_SEVEN=3 FOUR_TO_SEVEN value
             * @property {number} EIGHT_TO_TEN=4 EIGHT_TO_TEN value
             * @property {number} MORE_THAN_TEN=5 MORE_THAN_TEN value
             */
            proto.SurveyPracticeYearsInOperation = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "YEARS_UNKNOWN"] = 0;
                values[valuesById[1] = "LESS_THAN_1"] = 1;
                values[valuesById[2] = "ONE_TO_THREE"] = 2;
                values[valuesById[3] = "FOUR_TO_SEVEN"] = 3;
                values[valuesById[4] = "EIGHT_TO_TEN"] = 4;
                values[valuesById[5] = "MORE_THAN_TEN"] = 5;
                return values;
            })();

            proto.CalendarId = (function() {

                /**
                 * Properties of a CalendarId.
                 * @memberof space.kenko.proto
                 * @interface ICalendarId
                 * @property {string|null} [businessId] CalendarId businessId
                 * @property {string|null} [calendarId] CalendarId calendarId
                 */

                /**
                 * Constructs a new CalendarId.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a CalendarId.
                 * @implements ICalendarId
                 * @constructor
                 * @param {space.kenko.proto.ICalendarId=} [properties] Properties to set
                 */
                function CalendarId(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CalendarId businessId.
                 * @member {string} businessId
                 * @memberof space.kenko.proto.CalendarId
                 * @instance
                 */
                CalendarId.prototype.businessId = "";

                /**
                 * CalendarId calendarId.
                 * @member {string} calendarId
                 * @memberof space.kenko.proto.CalendarId
                 * @instance
                 */
                CalendarId.prototype.calendarId = "";

                /**
                 * Creates a new CalendarId instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {space.kenko.proto.ICalendarId=} [properties] Properties to set
                 * @returns {space.kenko.proto.CalendarId} CalendarId instance
                 */
                CalendarId.create = function create(properties) {
                    return new CalendarId(properties);
                };

                /**
                 * Encodes the specified CalendarId message. Does not implicitly {@link space.kenko.proto.CalendarId.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {space.kenko.proto.ICalendarId} message CalendarId message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarId.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.calendarId != null && Object.hasOwnProperty.call(message, "calendarId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.calendarId);
                    return writer;
                };

                /**
                 * Encodes the specified CalendarId message, length delimited. Does not implicitly {@link space.kenko.proto.CalendarId.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {space.kenko.proto.ICalendarId} message CalendarId message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarId.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CalendarId message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.CalendarId} CalendarId
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarId.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.CalendarId();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.calendarId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CalendarId message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.CalendarId} CalendarId
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarId.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CalendarId message.
                 * @function verify
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CalendarId.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.calendarId != null && message.hasOwnProperty("calendarId"))
                        if (!$util.isString(message.calendarId))
                            return "calendarId: string expected";
                    return null;
                };

                /**
                 * Creates a CalendarId message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.CalendarId} CalendarId
                 */
                CalendarId.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.CalendarId)
                        return object;
                    var message = new $root.space.kenko.proto.CalendarId();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.calendarId != null)
                        message.calendarId = String(object.calendarId);
                    return message;
                };

                /**
                 * Creates a plain object from a CalendarId message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {space.kenko.proto.CalendarId} message CalendarId
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CalendarId.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessId = "";
                        object.calendarId = "";
                    }
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.calendarId != null && message.hasOwnProperty("calendarId"))
                        object.calendarId = message.calendarId;
                    return object;
                };

                /**
                 * Converts this CalendarId to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.CalendarId
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CalendarId.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CalendarId
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.CalendarId
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CalendarId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.CalendarId";
                };

                return CalendarId;
            })();

            proto.EventProto = (function() {

                /**
                 * Properties of an EventProto.
                 * @memberof space.kenko.proto
                 * @interface IEventProto
                 * @property {space.kenko.proto.ICalendarId|null} [calendarId] EventProto calendarId
                 * @property {string|null} [eventId] EventProto eventId
                 * @property {string|null} [timeZoneId] EventProto timeZoneId
                 * @property {Array.<space.kenko.proto.IEventInstanceProto>|null} [instances] EventProto instances
                 * @property {space.kenko.proto.IRepeatStrategyProto|null} [repeatStrategy] EventProto repeatStrategy
                 */

                /**
                 * Constructs a new EventProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an EventProto.
                 * @implements IEventProto
                 * @constructor
                 * @param {space.kenko.proto.IEventProto=} [properties] Properties to set
                 */
                function EventProto(properties) {
                    this.instances = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EventProto calendarId.
                 * @member {space.kenko.proto.ICalendarId|null|undefined} calendarId
                 * @memberof space.kenko.proto.EventProto
                 * @instance
                 */
                EventProto.prototype.calendarId = null;

                /**
                 * EventProto eventId.
                 * @member {string} eventId
                 * @memberof space.kenko.proto.EventProto
                 * @instance
                 */
                EventProto.prototype.eventId = "";

                /**
                 * EventProto timeZoneId.
                 * @member {string} timeZoneId
                 * @memberof space.kenko.proto.EventProto
                 * @instance
                 */
                EventProto.prototype.timeZoneId = "";

                /**
                 * EventProto instances.
                 * @member {Array.<space.kenko.proto.IEventInstanceProto>} instances
                 * @memberof space.kenko.proto.EventProto
                 * @instance
                 */
                EventProto.prototype.instances = $util.emptyArray;

                /**
                 * EventProto repeatStrategy.
                 * @member {space.kenko.proto.IRepeatStrategyProto|null|undefined} repeatStrategy
                 * @memberof space.kenko.proto.EventProto
                 * @instance
                 */
                EventProto.prototype.repeatStrategy = null;

                /**
                 * Creates a new EventProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {space.kenko.proto.IEventProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.EventProto} EventProto instance
                 */
                EventProto.create = function create(properties) {
                    return new EventProto(properties);
                };

                /**
                 * Encodes the specified EventProto message. Does not implicitly {@link space.kenko.proto.EventProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {space.kenko.proto.IEventProto} message EventProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.calendarId != null && Object.hasOwnProperty.call(message, "calendarId"))
                        $root.space.kenko.proto.CalendarId.encode(message.calendarId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.eventId);
                    if (message.timeZoneId != null && Object.hasOwnProperty.call(message, "timeZoneId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.timeZoneId);
                    if (message.instances != null && message.instances.length)
                        for (var i = 0; i < message.instances.length; ++i)
                            $root.space.kenko.proto.EventInstanceProto.encode(message.instances[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.repeatStrategy != null && Object.hasOwnProperty.call(message, "repeatStrategy"))
                        $root.space.kenko.proto.RepeatStrategyProto.encode(message.repeatStrategy, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified EventProto message, length delimited. Does not implicitly {@link space.kenko.proto.EventProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {space.kenko.proto.IEventProto} message EventProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EventProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.EventProto} EventProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.EventProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.calendarId = $root.space.kenko.proto.CalendarId.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.eventId = reader.string();
                                break;
                            }
                        case 3: {
                                message.timeZoneId = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.instances && message.instances.length))
                                    message.instances = [];
                                message.instances.push($root.space.kenko.proto.EventInstanceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                message.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EventProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.EventProto} EventProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EventProto message.
                 * @function verify
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EventProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.calendarId != null && message.hasOwnProperty("calendarId")) {
                        var error = $root.space.kenko.proto.CalendarId.verify(message.calendarId);
                        if (error)
                            return "calendarId." + error;
                    }
                    if (message.eventId != null && message.hasOwnProperty("eventId"))
                        if (!$util.isString(message.eventId))
                            return "eventId: string expected";
                    if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
                        if (!$util.isString(message.timeZoneId))
                            return "timeZoneId: string expected";
                    if (message.instances != null && message.hasOwnProperty("instances")) {
                        if (!Array.isArray(message.instances))
                            return "instances: array expected";
                        for (var i = 0; i < message.instances.length; ++i) {
                            var error = $root.space.kenko.proto.EventInstanceProto.verify(message.instances[i]);
                            if (error)
                                return "instances." + error;
                        }
                    }
                    if (message.repeatStrategy != null && message.hasOwnProperty("repeatStrategy")) {
                        var error = $root.space.kenko.proto.RepeatStrategyProto.verify(message.repeatStrategy);
                        if (error)
                            return "repeatStrategy." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EventProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.EventProto} EventProto
                 */
                EventProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.EventProto)
                        return object;
                    var message = new $root.space.kenko.proto.EventProto();
                    if (object.calendarId != null) {
                        if (typeof object.calendarId !== "object")
                            throw TypeError(".space.kenko.proto.EventProto.calendarId: object expected");
                        message.calendarId = $root.space.kenko.proto.CalendarId.fromObject(object.calendarId);
                    }
                    if (object.eventId != null)
                        message.eventId = String(object.eventId);
                    if (object.timeZoneId != null)
                        message.timeZoneId = String(object.timeZoneId);
                    if (object.instances) {
                        if (!Array.isArray(object.instances))
                            throw TypeError(".space.kenko.proto.EventProto.instances: array expected");
                        message.instances = [];
                        for (var i = 0; i < object.instances.length; ++i) {
                            if (typeof object.instances[i] !== "object")
                                throw TypeError(".space.kenko.proto.EventProto.instances: object expected");
                            message.instances[i] = $root.space.kenko.proto.EventInstanceProto.fromObject(object.instances[i]);
                        }
                    }
                    if (object.repeatStrategy != null) {
                        if (typeof object.repeatStrategy !== "object")
                            throw TypeError(".space.kenko.proto.EventProto.repeatStrategy: object expected");
                        message.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.fromObject(object.repeatStrategy);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EventProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {space.kenko.proto.EventProto} message EventProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EventProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.instances = [];
                    if (options.defaults) {
                        object.calendarId = null;
                        object.eventId = "";
                        object.timeZoneId = "";
                        object.repeatStrategy = null;
                    }
                    if (message.calendarId != null && message.hasOwnProperty("calendarId"))
                        object.calendarId = $root.space.kenko.proto.CalendarId.toObject(message.calendarId, options);
                    if (message.eventId != null && message.hasOwnProperty("eventId"))
                        object.eventId = message.eventId;
                    if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
                        object.timeZoneId = message.timeZoneId;
                    if (message.instances && message.instances.length) {
                        object.instances = [];
                        for (var j = 0; j < message.instances.length; ++j)
                            object.instances[j] = $root.space.kenko.proto.EventInstanceProto.toObject(message.instances[j], options);
                    }
                    if (message.repeatStrategy != null && message.hasOwnProperty("repeatStrategy"))
                        object.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.toObject(message.repeatStrategy, options);
                    return object;
                };

                /**
                 * Converts this EventProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.EventProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EventProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EventProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.EventProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EventProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.EventProto";
                };

                return EventProto;
            })();

            proto.EventInstanceProto = (function() {

                /**
                 * Properties of an EventInstanceProto.
                 * @memberof space.kenko.proto
                 * @interface IEventInstanceProto
                 * @property {space.kenko.proto.ILocalDateProto|null} [originalDate] EventInstanceProto originalDate
                 * @property {space.kenko.proto.IEventDetailsProto|null} [details] EventInstanceProto details
                 */

                /**
                 * Constructs a new EventInstanceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an EventInstanceProto.
                 * @implements IEventInstanceProto
                 * @constructor
                 * @param {space.kenko.proto.IEventInstanceProto=} [properties] Properties to set
                 */
                function EventInstanceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EventInstanceProto originalDate.
                 * @member {space.kenko.proto.ILocalDateProto|null|undefined} originalDate
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @instance
                 */
                EventInstanceProto.prototype.originalDate = null;

                /**
                 * EventInstanceProto details.
                 * @member {space.kenko.proto.IEventDetailsProto|null|undefined} details
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @instance
                 */
                EventInstanceProto.prototype.details = null;

                /**
                 * Creates a new EventInstanceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {space.kenko.proto.IEventInstanceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.EventInstanceProto} EventInstanceProto instance
                 */
                EventInstanceProto.create = function create(properties) {
                    return new EventInstanceProto(properties);
                };

                /**
                 * Encodes the specified EventInstanceProto message. Does not implicitly {@link space.kenko.proto.EventInstanceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {space.kenko.proto.IEventInstanceProto} message EventInstanceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventInstanceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.originalDate != null && Object.hasOwnProperty.call(message, "originalDate"))
                        $root.space.kenko.proto.LocalDateProto.encode(message.originalDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.details != null && Object.hasOwnProperty.call(message, "details"))
                        $root.space.kenko.proto.EventDetailsProto.encode(message.details, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified EventInstanceProto message, length delimited. Does not implicitly {@link space.kenko.proto.EventInstanceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {space.kenko.proto.IEventInstanceProto} message EventInstanceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventInstanceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EventInstanceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.EventInstanceProto} EventInstanceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventInstanceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.EventInstanceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.originalDate = $root.space.kenko.proto.LocalDateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.details = $root.space.kenko.proto.EventDetailsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EventInstanceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.EventInstanceProto} EventInstanceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventInstanceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EventInstanceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EventInstanceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.originalDate != null && message.hasOwnProperty("originalDate")) {
                        var error = $root.space.kenko.proto.LocalDateProto.verify(message.originalDate);
                        if (error)
                            return "originalDate." + error;
                    }
                    if (message.details != null && message.hasOwnProperty("details")) {
                        var error = $root.space.kenko.proto.EventDetailsProto.verify(message.details);
                        if (error)
                            return "details." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EventInstanceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.EventInstanceProto} EventInstanceProto
                 */
                EventInstanceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.EventInstanceProto)
                        return object;
                    var message = new $root.space.kenko.proto.EventInstanceProto();
                    if (object.originalDate != null) {
                        if (typeof object.originalDate !== "object")
                            throw TypeError(".space.kenko.proto.EventInstanceProto.originalDate: object expected");
                        message.originalDate = $root.space.kenko.proto.LocalDateProto.fromObject(object.originalDate);
                    }
                    if (object.details != null) {
                        if (typeof object.details !== "object")
                            throw TypeError(".space.kenko.proto.EventInstanceProto.details: object expected");
                        message.details = $root.space.kenko.proto.EventDetailsProto.fromObject(object.details);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EventInstanceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {space.kenko.proto.EventInstanceProto} message EventInstanceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EventInstanceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.originalDate = null;
                        object.details = null;
                    }
                    if (message.originalDate != null && message.hasOwnProperty("originalDate"))
                        object.originalDate = $root.space.kenko.proto.LocalDateProto.toObject(message.originalDate, options);
                    if (message.details != null && message.hasOwnProperty("details"))
                        object.details = $root.space.kenko.proto.EventDetailsProto.toObject(message.details, options);
                    return object;
                };

                /**
                 * Converts this EventInstanceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EventInstanceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EventInstanceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.EventInstanceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EventInstanceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.EventInstanceProto";
                };

                return EventInstanceProto;
            })();

            proto.EventInstanceProto2 = (function() {

                /**
                 * Properties of an EventInstanceProto2.
                 * @memberof space.kenko.proto
                 * @interface IEventInstanceProto2
                 * @property {string|null} [businessId] EventInstanceProto2 businessId
                 * @property {string|null} [calendarId] EventInstanceProto2 calendarId
                 * @property {string|null} [eventId] EventInstanceProto2 eventId
                 * @property {string|null} [timeZoneId] EventInstanceProto2 timeZoneId
                 * @property {space.kenko.proto.ILocalDateProto|null} [originalDate] EventInstanceProto2 originalDate
                 * @property {space.kenko.proto.IEventDetailsProto|null} [details] EventInstanceProto2 details
                 * @property {space.kenko.proto.IRepeatStrategyProto|null} [repeatStrategy] EventInstanceProto2 repeatStrategy
                 */

                /**
                 * Constructs a new EventInstanceProto2.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an EventInstanceProto2.
                 * @implements IEventInstanceProto2
                 * @constructor
                 * @param {space.kenko.proto.IEventInstanceProto2=} [properties] Properties to set
                 */
                function EventInstanceProto2(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EventInstanceProto2 businessId.
                 * @member {string} businessId
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @instance
                 */
                EventInstanceProto2.prototype.businessId = "";

                /**
                 * EventInstanceProto2 calendarId.
                 * @member {string} calendarId
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @instance
                 */
                EventInstanceProto2.prototype.calendarId = "";

                /**
                 * EventInstanceProto2 eventId.
                 * @member {string} eventId
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @instance
                 */
                EventInstanceProto2.prototype.eventId = "";

                /**
                 * EventInstanceProto2 timeZoneId.
                 * @member {string} timeZoneId
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @instance
                 */
                EventInstanceProto2.prototype.timeZoneId = "";

                /**
                 * EventInstanceProto2 originalDate.
                 * @member {space.kenko.proto.ILocalDateProto|null|undefined} originalDate
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @instance
                 */
                EventInstanceProto2.prototype.originalDate = null;

                /**
                 * EventInstanceProto2 details.
                 * @member {space.kenko.proto.IEventDetailsProto|null|undefined} details
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @instance
                 */
                EventInstanceProto2.prototype.details = null;

                /**
                 * EventInstanceProto2 repeatStrategy.
                 * @member {space.kenko.proto.IRepeatStrategyProto|null|undefined} repeatStrategy
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @instance
                 */
                EventInstanceProto2.prototype.repeatStrategy = null;

                /**
                 * Creates a new EventInstanceProto2 instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {space.kenko.proto.IEventInstanceProto2=} [properties] Properties to set
                 * @returns {space.kenko.proto.EventInstanceProto2} EventInstanceProto2 instance
                 */
                EventInstanceProto2.create = function create(properties) {
                    return new EventInstanceProto2(properties);
                };

                /**
                 * Encodes the specified EventInstanceProto2 message. Does not implicitly {@link space.kenko.proto.EventInstanceProto2.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {space.kenko.proto.IEventInstanceProto2} message EventInstanceProto2 message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventInstanceProto2.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.calendarId != null && Object.hasOwnProperty.call(message, "calendarId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.calendarId);
                    if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.eventId);
                    if (message.timeZoneId != null && Object.hasOwnProperty.call(message, "timeZoneId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.timeZoneId);
                    if (message.originalDate != null && Object.hasOwnProperty.call(message, "originalDate"))
                        $root.space.kenko.proto.LocalDateProto.encode(message.originalDate, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.details != null && Object.hasOwnProperty.call(message, "details"))
                        $root.space.kenko.proto.EventDetailsProto.encode(message.details, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.repeatStrategy != null && Object.hasOwnProperty.call(message, "repeatStrategy"))
                        $root.space.kenko.proto.RepeatStrategyProto.encode(message.repeatStrategy, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified EventInstanceProto2 message, length delimited. Does not implicitly {@link space.kenko.proto.EventInstanceProto2.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {space.kenko.proto.IEventInstanceProto2} message EventInstanceProto2 message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventInstanceProto2.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EventInstanceProto2 message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.EventInstanceProto2} EventInstanceProto2
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventInstanceProto2.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.EventInstanceProto2();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.calendarId = reader.string();
                                break;
                            }
                        case 3: {
                                message.eventId = reader.string();
                                break;
                            }
                        case 4: {
                                message.timeZoneId = reader.string();
                                break;
                            }
                        case 10: {
                                message.originalDate = $root.space.kenko.proto.LocalDateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.details = $root.space.kenko.proto.EventDetailsProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EventInstanceProto2 message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.EventInstanceProto2} EventInstanceProto2
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventInstanceProto2.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EventInstanceProto2 message.
                 * @function verify
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EventInstanceProto2.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.calendarId != null && message.hasOwnProperty("calendarId"))
                        if (!$util.isString(message.calendarId))
                            return "calendarId: string expected";
                    if (message.eventId != null && message.hasOwnProperty("eventId"))
                        if (!$util.isString(message.eventId))
                            return "eventId: string expected";
                    if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
                        if (!$util.isString(message.timeZoneId))
                            return "timeZoneId: string expected";
                    if (message.originalDate != null && message.hasOwnProperty("originalDate")) {
                        var error = $root.space.kenko.proto.LocalDateProto.verify(message.originalDate);
                        if (error)
                            return "originalDate." + error;
                    }
                    if (message.details != null && message.hasOwnProperty("details")) {
                        var error = $root.space.kenko.proto.EventDetailsProto.verify(message.details);
                        if (error)
                            return "details." + error;
                    }
                    if (message.repeatStrategy != null && message.hasOwnProperty("repeatStrategy")) {
                        var error = $root.space.kenko.proto.RepeatStrategyProto.verify(message.repeatStrategy);
                        if (error)
                            return "repeatStrategy." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EventInstanceProto2 message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.EventInstanceProto2} EventInstanceProto2
                 */
                EventInstanceProto2.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.EventInstanceProto2)
                        return object;
                    var message = new $root.space.kenko.proto.EventInstanceProto2();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.calendarId != null)
                        message.calendarId = String(object.calendarId);
                    if (object.eventId != null)
                        message.eventId = String(object.eventId);
                    if (object.timeZoneId != null)
                        message.timeZoneId = String(object.timeZoneId);
                    if (object.originalDate != null) {
                        if (typeof object.originalDate !== "object")
                            throw TypeError(".space.kenko.proto.EventInstanceProto2.originalDate: object expected");
                        message.originalDate = $root.space.kenko.proto.LocalDateProto.fromObject(object.originalDate);
                    }
                    if (object.details != null) {
                        if (typeof object.details !== "object")
                            throw TypeError(".space.kenko.proto.EventInstanceProto2.details: object expected");
                        message.details = $root.space.kenko.proto.EventDetailsProto.fromObject(object.details);
                    }
                    if (object.repeatStrategy != null) {
                        if (typeof object.repeatStrategy !== "object")
                            throw TypeError(".space.kenko.proto.EventInstanceProto2.repeatStrategy: object expected");
                        message.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.fromObject(object.repeatStrategy);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EventInstanceProto2 message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {space.kenko.proto.EventInstanceProto2} message EventInstanceProto2
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EventInstanceProto2.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessId = "";
                        object.calendarId = "";
                        object.eventId = "";
                        object.timeZoneId = "";
                        object.originalDate = null;
                        object.details = null;
                        object.repeatStrategy = null;
                    }
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.calendarId != null && message.hasOwnProperty("calendarId"))
                        object.calendarId = message.calendarId;
                    if (message.eventId != null && message.hasOwnProperty("eventId"))
                        object.eventId = message.eventId;
                    if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
                        object.timeZoneId = message.timeZoneId;
                    if (message.originalDate != null && message.hasOwnProperty("originalDate"))
                        object.originalDate = $root.space.kenko.proto.LocalDateProto.toObject(message.originalDate, options);
                    if (message.details != null && message.hasOwnProperty("details"))
                        object.details = $root.space.kenko.proto.EventDetailsProto.toObject(message.details, options);
                    if (message.repeatStrategy != null && message.hasOwnProperty("repeatStrategy"))
                        object.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.toObject(message.repeatStrategy, options);
                    return object;
                };

                /**
                 * Converts this EventInstanceProto2 to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EventInstanceProto2.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EventInstanceProto2
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.EventInstanceProto2
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EventInstanceProto2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.EventInstanceProto2";
                };

                return EventInstanceProto2;
            })();

            proto.EventDetailsProto = (function() {

                /**
                 * Properties of an EventDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface IEventDetailsProto
                 * @property {number|Long|null} [startTimestamp] EventDetailsProto startTimestamp
                 * @property {number|Long|null} [endTimestamp] EventDetailsProto endTimestamp
                 * @property {string|null} [title] EventDetailsProto title
                 * @property {space.kenko.proto.IKenkoEventDetails|null} [kenkoDetails] EventDetailsProto kenkoDetails
                 */

                /**
                 * Constructs a new EventDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an EventDetailsProto.
                 * @implements IEventDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.IEventDetailsProto=} [properties] Properties to set
                 */
                function EventDetailsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EventDetailsProto startTimestamp.
                 * @member {number|Long} startTimestamp
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @instance
                 */
                EventDetailsProto.prototype.startTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EventDetailsProto endTimestamp.
                 * @member {number|Long} endTimestamp
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @instance
                 */
                EventDetailsProto.prototype.endTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EventDetailsProto title.
                 * @member {string} title
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @instance
                 */
                EventDetailsProto.prototype.title = "";

                /**
                 * EventDetailsProto kenkoDetails.
                 * @member {space.kenko.proto.IKenkoEventDetails|null|undefined} kenkoDetails
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @instance
                 */
                EventDetailsProto.prototype.kenkoDetails = null;

                /**
                 * Creates a new EventDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {space.kenko.proto.IEventDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.EventDetailsProto} EventDetailsProto instance
                 */
                EventDetailsProto.create = function create(properties) {
                    return new EventDetailsProto(properties);
                };

                /**
                 * Encodes the specified EventDetailsProto message. Does not implicitly {@link space.kenko.proto.EventDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {space.kenko.proto.IEventDetailsProto} message EventDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.startTimestamp != null && Object.hasOwnProperty.call(message, "startTimestamp"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.startTimestamp);
                    if (message.endTimestamp != null && Object.hasOwnProperty.call(message, "endTimestamp"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.endTimestamp);
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
                    if (message.kenkoDetails != null && Object.hasOwnProperty.call(message, "kenkoDetails"))
                        $root.space.kenko.proto.KenkoEventDetails.encode(message.kenkoDetails, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified EventDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.EventDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {space.kenko.proto.IEventDetailsProto} message EventDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EventDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.EventDetailsProto} EventDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.EventDetailsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.startTimestamp = reader.int64();
                                break;
                            }
                        case 2: {
                                message.endTimestamp = reader.int64();
                                break;
                            }
                        case 3: {
                                message.title = reader.string();
                                break;
                            }
                        case 4: {
                                message.kenkoDetails = $root.space.kenko.proto.KenkoEventDetails.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EventDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.EventDetailsProto} EventDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EventDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EventDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.startTimestamp != null && message.hasOwnProperty("startTimestamp"))
                        if (!$util.isInteger(message.startTimestamp) && !(message.startTimestamp && $util.isInteger(message.startTimestamp.low) && $util.isInteger(message.startTimestamp.high)))
                            return "startTimestamp: integer|Long expected";
                    if (message.endTimestamp != null && message.hasOwnProperty("endTimestamp"))
                        if (!$util.isInteger(message.endTimestamp) && !(message.endTimestamp && $util.isInteger(message.endTimestamp.low) && $util.isInteger(message.endTimestamp.high)))
                            return "endTimestamp: integer|Long expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.kenkoDetails != null && message.hasOwnProperty("kenkoDetails")) {
                        var error = $root.space.kenko.proto.KenkoEventDetails.verify(message.kenkoDetails);
                        if (error)
                            return "kenkoDetails." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EventDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.EventDetailsProto} EventDetailsProto
                 */
                EventDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.EventDetailsProto)
                        return object;
                    var message = new $root.space.kenko.proto.EventDetailsProto();
                    if (object.startTimestamp != null)
                        if ($util.Long)
                            (message.startTimestamp = $util.Long.fromValue(object.startTimestamp)).unsigned = false;
                        else if (typeof object.startTimestamp === "string")
                            message.startTimestamp = parseInt(object.startTimestamp, 10);
                        else if (typeof object.startTimestamp === "number")
                            message.startTimestamp = object.startTimestamp;
                        else if (typeof object.startTimestamp === "object")
                            message.startTimestamp = new $util.LongBits(object.startTimestamp.low >>> 0, object.startTimestamp.high >>> 0).toNumber();
                    if (object.endTimestamp != null)
                        if ($util.Long)
                            (message.endTimestamp = $util.Long.fromValue(object.endTimestamp)).unsigned = false;
                        else if (typeof object.endTimestamp === "string")
                            message.endTimestamp = parseInt(object.endTimestamp, 10);
                        else if (typeof object.endTimestamp === "number")
                            message.endTimestamp = object.endTimestamp;
                        else if (typeof object.endTimestamp === "object")
                            message.endTimestamp = new $util.LongBits(object.endTimestamp.low >>> 0, object.endTimestamp.high >>> 0).toNumber();
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.kenkoDetails != null) {
                        if (typeof object.kenkoDetails !== "object")
                            throw TypeError(".space.kenko.proto.EventDetailsProto.kenkoDetails: object expected");
                        message.kenkoDetails = $root.space.kenko.proto.KenkoEventDetails.fromObject(object.kenkoDetails);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EventDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {space.kenko.proto.EventDetailsProto} message EventDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EventDetailsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimestamp = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimestamp = options.longs === String ? "0" : 0;
                        object.title = "";
                        object.kenkoDetails = null;
                    }
                    if (message.startTimestamp != null && message.hasOwnProperty("startTimestamp"))
                        if (typeof message.startTimestamp === "number")
                            object.startTimestamp = options.longs === String ? String(message.startTimestamp) : message.startTimestamp;
                        else
                            object.startTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.startTimestamp) : options.longs === Number ? new $util.LongBits(message.startTimestamp.low >>> 0, message.startTimestamp.high >>> 0).toNumber() : message.startTimestamp;
                    if (message.endTimestamp != null && message.hasOwnProperty("endTimestamp"))
                        if (typeof message.endTimestamp === "number")
                            object.endTimestamp = options.longs === String ? String(message.endTimestamp) : message.endTimestamp;
                        else
                            object.endTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.endTimestamp) : options.longs === Number ? new $util.LongBits(message.endTimestamp.low >>> 0, message.endTimestamp.high >>> 0).toNumber() : message.endTimestamp;
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.kenkoDetails != null && message.hasOwnProperty("kenkoDetails"))
                        object.kenkoDetails = $root.space.kenko.proto.KenkoEventDetails.toObject(message.kenkoDetails, options);
                    return object;
                };

                /**
                 * Converts this EventDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EventDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EventDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.EventDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EventDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.EventDetailsProto";
                };

                return EventDetailsProto;
            })();

            proto.EventInstanceId = (function() {

                /**
                 * Properties of an EventInstanceId.
                 * @memberof space.kenko.proto
                 * @interface IEventInstanceId
                 * @property {string|null} [eventId] EventInstanceId eventId
                 * @property {space.kenko.proto.ILocalDateProto|null} [originalDate] EventInstanceId originalDate
                 */

                /**
                 * Constructs a new EventInstanceId.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an EventInstanceId.
                 * @implements IEventInstanceId
                 * @constructor
                 * @param {space.kenko.proto.IEventInstanceId=} [properties] Properties to set
                 */
                function EventInstanceId(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EventInstanceId eventId.
                 * @member {string} eventId
                 * @memberof space.kenko.proto.EventInstanceId
                 * @instance
                 */
                EventInstanceId.prototype.eventId = "";

                /**
                 * EventInstanceId originalDate.
                 * @member {space.kenko.proto.ILocalDateProto|null|undefined} originalDate
                 * @memberof space.kenko.proto.EventInstanceId
                 * @instance
                 */
                EventInstanceId.prototype.originalDate = null;

                /**
                 * Creates a new EventInstanceId instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {space.kenko.proto.IEventInstanceId=} [properties] Properties to set
                 * @returns {space.kenko.proto.EventInstanceId} EventInstanceId instance
                 */
                EventInstanceId.create = function create(properties) {
                    return new EventInstanceId(properties);
                };

                /**
                 * Encodes the specified EventInstanceId message. Does not implicitly {@link space.kenko.proto.EventInstanceId.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {space.kenko.proto.IEventInstanceId} message EventInstanceId message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventInstanceId.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.eventId);
                    if (message.originalDate != null && Object.hasOwnProperty.call(message, "originalDate"))
                        $root.space.kenko.proto.LocalDateProto.encode(message.originalDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified EventInstanceId message, length delimited. Does not implicitly {@link space.kenko.proto.EventInstanceId.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {space.kenko.proto.IEventInstanceId} message EventInstanceId message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EventInstanceId.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EventInstanceId message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.EventInstanceId} EventInstanceId
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventInstanceId.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.EventInstanceId();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.eventId = reader.string();
                                break;
                            }
                        case 2: {
                                message.originalDate = $root.space.kenko.proto.LocalDateProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EventInstanceId message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.EventInstanceId} EventInstanceId
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EventInstanceId.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EventInstanceId message.
                 * @function verify
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EventInstanceId.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.eventId != null && message.hasOwnProperty("eventId"))
                        if (!$util.isString(message.eventId))
                            return "eventId: string expected";
                    if (message.originalDate != null && message.hasOwnProperty("originalDate")) {
                        var error = $root.space.kenko.proto.LocalDateProto.verify(message.originalDate);
                        if (error)
                            return "originalDate." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EventInstanceId message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.EventInstanceId} EventInstanceId
                 */
                EventInstanceId.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.EventInstanceId)
                        return object;
                    var message = new $root.space.kenko.proto.EventInstanceId();
                    if (object.eventId != null)
                        message.eventId = String(object.eventId);
                    if (object.originalDate != null) {
                        if (typeof object.originalDate !== "object")
                            throw TypeError(".space.kenko.proto.EventInstanceId.originalDate: object expected");
                        message.originalDate = $root.space.kenko.proto.LocalDateProto.fromObject(object.originalDate);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EventInstanceId message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {space.kenko.proto.EventInstanceId} message EventInstanceId
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EventInstanceId.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.eventId = "";
                        object.originalDate = null;
                    }
                    if (message.eventId != null && message.hasOwnProperty("eventId"))
                        object.eventId = message.eventId;
                    if (message.originalDate != null && message.hasOwnProperty("originalDate"))
                        object.originalDate = $root.space.kenko.proto.LocalDateProto.toObject(message.originalDate, options);
                    return object;
                };

                /**
                 * Converts this EventInstanceId to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.EventInstanceId
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EventInstanceId.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EventInstanceId
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.EventInstanceId
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EventInstanceId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.EventInstanceId";
                };

                return EventInstanceId;
            })();

            proto.GetEventsRequest = (function() {

                /**
                 * Properties of a GetEventsRequest.
                 * @memberof space.kenko.proto
                 * @interface IGetEventsRequest
                 * @property {Array.<space.kenko.proto.ICalendarId>|null} [calendarIds] GetEventsRequest calendarIds
                 * @property {number|Long|null} [startTimestampMs] GetEventsRequest startTimestampMs
                 * @property {number|Long|null} [endTimestampMs] GetEventsRequest endTimestampMs
                 */

                /**
                 * Constructs a new GetEventsRequest.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a GetEventsRequest.
                 * @implements IGetEventsRequest
                 * @constructor
                 * @param {space.kenko.proto.IGetEventsRequest=} [properties] Properties to set
                 */
                function GetEventsRequest(properties) {
                    this.calendarIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetEventsRequest calendarIds.
                 * @member {Array.<space.kenko.proto.ICalendarId>} calendarIds
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @instance
                 */
                GetEventsRequest.prototype.calendarIds = $util.emptyArray;

                /**
                 * GetEventsRequest startTimestampMs.
                 * @member {number|Long} startTimestampMs
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @instance
                 */
                GetEventsRequest.prototype.startTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * GetEventsRequest endTimestampMs.
                 * @member {number|Long} endTimestampMs
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @instance
                 */
                GetEventsRequest.prototype.endTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new GetEventsRequest instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {space.kenko.proto.IGetEventsRequest=} [properties] Properties to set
                 * @returns {space.kenko.proto.GetEventsRequest} GetEventsRequest instance
                 */
                GetEventsRequest.create = function create(properties) {
                    return new GetEventsRequest(properties);
                };

                /**
                 * Encodes the specified GetEventsRequest message. Does not implicitly {@link space.kenko.proto.GetEventsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {space.kenko.proto.IGetEventsRequest} message GetEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEventsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.calendarIds != null && message.calendarIds.length)
                        for (var i = 0; i < message.calendarIds.length; ++i)
                            $root.space.kenko.proto.CalendarId.encode(message.calendarIds[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.startTimestampMs != null && Object.hasOwnProperty.call(message, "startTimestampMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.startTimestampMs);
                    if (message.endTimestampMs != null && Object.hasOwnProperty.call(message, "endTimestampMs"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.endTimestampMs);
                    return writer;
                };

                /**
                 * Encodes the specified GetEventsRequest message, length delimited. Does not implicitly {@link space.kenko.proto.GetEventsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {space.kenko.proto.IGetEventsRequest} message GetEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetEventsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.GetEventsRequest} GetEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEventsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.GetEventsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.calendarIds && message.calendarIds.length))
                                    message.calendarIds = [];
                                message.calendarIds.push($root.space.kenko.proto.CalendarId.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.startTimestampMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.endTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetEventsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.GetEventsRequest} GetEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEventsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetEventsRequest message.
                 * @function verify
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetEventsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.calendarIds != null && message.hasOwnProperty("calendarIds")) {
                        if (!Array.isArray(message.calendarIds))
                            return "calendarIds: array expected";
                        for (var i = 0; i < message.calendarIds.length; ++i) {
                            var error = $root.space.kenko.proto.CalendarId.verify(message.calendarIds[i]);
                            if (error)
                                return "calendarIds." + error;
                        }
                    }
                    if (message.startTimestampMs != null && message.hasOwnProperty("startTimestampMs"))
                        if (!$util.isInteger(message.startTimestampMs) && !(message.startTimestampMs && $util.isInteger(message.startTimestampMs.low) && $util.isInteger(message.startTimestampMs.high)))
                            return "startTimestampMs: integer|Long expected";
                    if (message.endTimestampMs != null && message.hasOwnProperty("endTimestampMs"))
                        if (!$util.isInteger(message.endTimestampMs) && !(message.endTimestampMs && $util.isInteger(message.endTimestampMs.low) && $util.isInteger(message.endTimestampMs.high)))
                            return "endTimestampMs: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a GetEventsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.GetEventsRequest} GetEventsRequest
                 */
                GetEventsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.GetEventsRequest)
                        return object;
                    var message = new $root.space.kenko.proto.GetEventsRequest();
                    if (object.calendarIds) {
                        if (!Array.isArray(object.calendarIds))
                            throw TypeError(".space.kenko.proto.GetEventsRequest.calendarIds: array expected");
                        message.calendarIds = [];
                        for (var i = 0; i < object.calendarIds.length; ++i) {
                            if (typeof object.calendarIds[i] !== "object")
                                throw TypeError(".space.kenko.proto.GetEventsRequest.calendarIds: object expected");
                            message.calendarIds[i] = $root.space.kenko.proto.CalendarId.fromObject(object.calendarIds[i]);
                        }
                    }
                    if (object.startTimestampMs != null)
                        if ($util.Long)
                            (message.startTimestampMs = $util.Long.fromValue(object.startTimestampMs)).unsigned = false;
                        else if (typeof object.startTimestampMs === "string")
                            message.startTimestampMs = parseInt(object.startTimestampMs, 10);
                        else if (typeof object.startTimestampMs === "number")
                            message.startTimestampMs = object.startTimestampMs;
                        else if (typeof object.startTimestampMs === "object")
                            message.startTimestampMs = new $util.LongBits(object.startTimestampMs.low >>> 0, object.startTimestampMs.high >>> 0).toNumber();
                    if (object.endTimestampMs != null)
                        if ($util.Long)
                            (message.endTimestampMs = $util.Long.fromValue(object.endTimestampMs)).unsigned = false;
                        else if (typeof object.endTimestampMs === "string")
                            message.endTimestampMs = parseInt(object.endTimestampMs, 10);
                        else if (typeof object.endTimestampMs === "number")
                            message.endTimestampMs = object.endTimestampMs;
                        else if (typeof object.endTimestampMs === "object")
                            message.endTimestampMs = new $util.LongBits(object.endTimestampMs.low >>> 0, object.endTimestampMs.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a GetEventsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {space.kenko.proto.GetEventsRequest} message GetEventsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetEventsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.calendarIds = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimestampMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimestampMs = options.longs === String ? "0" : 0;
                    }
                    if (message.calendarIds && message.calendarIds.length) {
                        object.calendarIds = [];
                        for (var j = 0; j < message.calendarIds.length; ++j)
                            object.calendarIds[j] = $root.space.kenko.proto.CalendarId.toObject(message.calendarIds[j], options);
                    }
                    if (message.startTimestampMs != null && message.hasOwnProperty("startTimestampMs"))
                        if (typeof message.startTimestampMs === "number")
                            object.startTimestampMs = options.longs === String ? String(message.startTimestampMs) : message.startTimestampMs;
                        else
                            object.startTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimestampMs) : options.longs === Number ? new $util.LongBits(message.startTimestampMs.low >>> 0, message.startTimestampMs.high >>> 0).toNumber() : message.startTimestampMs;
                    if (message.endTimestampMs != null && message.hasOwnProperty("endTimestampMs"))
                        if (typeof message.endTimestampMs === "number")
                            object.endTimestampMs = options.longs === String ? String(message.endTimestampMs) : message.endTimestampMs;
                        else
                            object.endTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimestampMs) : options.longs === Number ? new $util.LongBits(message.endTimestampMs.low >>> 0, message.endTimestampMs.high >>> 0).toNumber() : message.endTimestampMs;
                    return object;
                };

                /**
                 * Converts this GetEventsRequest to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetEventsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetEventsRequest
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.GetEventsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetEventsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.GetEventsRequest";
                };

                return GetEventsRequest;
            })();

            proto.GetEventsResponse = (function() {

                /**
                 * Properties of a GetEventsResponse.
                 * @memberof space.kenko.proto
                 * @interface IGetEventsResponse
                 * @property {Array.<space.kenko.proto.IEventInstanceProto2>|null} [events] GetEventsResponse events
                 */

                /**
                 * Constructs a new GetEventsResponse.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a GetEventsResponse.
                 * @implements IGetEventsResponse
                 * @constructor
                 * @param {space.kenko.proto.IGetEventsResponse=} [properties] Properties to set
                 */
                function GetEventsResponse(properties) {
                    this.events = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetEventsResponse events.
                 * @member {Array.<space.kenko.proto.IEventInstanceProto2>} events
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @instance
                 */
                GetEventsResponse.prototype.events = $util.emptyArray;

                /**
                 * Creates a new GetEventsResponse instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {space.kenko.proto.IGetEventsResponse=} [properties] Properties to set
                 * @returns {space.kenko.proto.GetEventsResponse} GetEventsResponse instance
                 */
                GetEventsResponse.create = function create(properties) {
                    return new GetEventsResponse(properties);
                };

                /**
                 * Encodes the specified GetEventsResponse message. Does not implicitly {@link space.kenko.proto.GetEventsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {space.kenko.proto.IGetEventsResponse} message GetEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEventsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.events != null && message.events.length)
                        for (var i = 0; i < message.events.length; ++i)
                            $root.space.kenko.proto.EventInstanceProto2.encode(message.events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetEventsResponse message, length delimited. Does not implicitly {@link space.kenko.proto.GetEventsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {space.kenko.proto.IGetEventsResponse} message GetEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEventsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetEventsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.GetEventsResponse} GetEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEventsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.GetEventsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.events && message.events.length))
                                    message.events = [];
                                message.events.push($root.space.kenko.proto.EventInstanceProto2.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetEventsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.GetEventsResponse} GetEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEventsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetEventsResponse message.
                 * @function verify
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetEventsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.events != null && message.hasOwnProperty("events")) {
                        if (!Array.isArray(message.events))
                            return "events: array expected";
                        for (var i = 0; i < message.events.length; ++i) {
                            var error = $root.space.kenko.proto.EventInstanceProto2.verify(message.events[i]);
                            if (error)
                                return "events." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetEventsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.GetEventsResponse} GetEventsResponse
                 */
                GetEventsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.GetEventsResponse)
                        return object;
                    var message = new $root.space.kenko.proto.GetEventsResponse();
                    if (object.events) {
                        if (!Array.isArray(object.events))
                            throw TypeError(".space.kenko.proto.GetEventsResponse.events: array expected");
                        message.events = [];
                        for (var i = 0; i < object.events.length; ++i) {
                            if (typeof object.events[i] !== "object")
                                throw TypeError(".space.kenko.proto.GetEventsResponse.events: object expected");
                            message.events[i] = $root.space.kenko.proto.EventInstanceProto2.fromObject(object.events[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetEventsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {space.kenko.proto.GetEventsResponse} message GetEventsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetEventsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.events = [];
                    if (message.events && message.events.length) {
                        object.events = [];
                        for (var j = 0; j < message.events.length; ++j)
                            object.events[j] = $root.space.kenko.proto.EventInstanceProto2.toObject(message.events[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetEventsResponse to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetEventsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for GetEventsResponse
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.GetEventsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetEventsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.GetEventsResponse";
                };

                return GetEventsResponse;
            })();

            proto.CreateEventRequest = (function() {

                /**
                 * Properties of a CreateEventRequest.
                 * @memberof space.kenko.proto
                 * @interface ICreateEventRequest
                 * @property {space.kenko.proto.IEventInstanceProto2|null} [event] CreateEventRequest event
                 * @property {number|Long|null} [clientStartTimestampMs] CreateEventRequest clientStartTimestampMs
                 * @property {number|Long|null} [clientEndTimestampMs] CreateEventRequest clientEndTimestampMs
                 */

                /**
                 * Constructs a new CreateEventRequest.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a CreateEventRequest.
                 * @implements ICreateEventRequest
                 * @constructor
                 * @param {space.kenko.proto.ICreateEventRequest=} [properties] Properties to set
                 */
                function CreateEventRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateEventRequest event.
                 * @member {space.kenko.proto.IEventInstanceProto2|null|undefined} event
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @instance
                 */
                CreateEventRequest.prototype.event = null;

                /**
                 * CreateEventRequest clientStartTimestampMs.
                 * @member {number|Long} clientStartTimestampMs
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @instance
                 */
                CreateEventRequest.prototype.clientStartTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * CreateEventRequest clientEndTimestampMs.
                 * @member {number|Long} clientEndTimestampMs
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @instance
                 */
                CreateEventRequest.prototype.clientEndTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new CreateEventRequest instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {space.kenko.proto.ICreateEventRequest=} [properties] Properties to set
                 * @returns {space.kenko.proto.CreateEventRequest} CreateEventRequest instance
                 */
                CreateEventRequest.create = function create(properties) {
                    return new CreateEventRequest(properties);
                };

                /**
                 * Encodes the specified CreateEventRequest message. Does not implicitly {@link space.kenko.proto.CreateEventRequest.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {space.kenko.proto.ICreateEventRequest} message CreateEventRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateEventRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                        $root.space.kenko.proto.EventInstanceProto2.encode(message.event, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.clientStartTimestampMs != null && Object.hasOwnProperty.call(message, "clientStartTimestampMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.clientStartTimestampMs);
                    if (message.clientEndTimestampMs != null && Object.hasOwnProperty.call(message, "clientEndTimestampMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.clientEndTimestampMs);
                    return writer;
                };

                /**
                 * Encodes the specified CreateEventRequest message, length delimited. Does not implicitly {@link space.kenko.proto.CreateEventRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {space.kenko.proto.ICreateEventRequest} message CreateEventRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateEventRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.CreateEventRequest} CreateEventRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateEventRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.CreateEventRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.event = $root.space.kenko.proto.EventInstanceProto2.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.clientStartTimestampMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.clientEndTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateEventRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.CreateEventRequest} CreateEventRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateEventRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateEventRequest message.
                 * @function verify
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateEventRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.event != null && message.hasOwnProperty("event")) {
                        var error = $root.space.kenko.proto.EventInstanceProto2.verify(message.event);
                        if (error)
                            return "event." + error;
                    }
                    if (message.clientStartTimestampMs != null && message.hasOwnProperty("clientStartTimestampMs"))
                        if (!$util.isInteger(message.clientStartTimestampMs) && !(message.clientStartTimestampMs && $util.isInteger(message.clientStartTimestampMs.low) && $util.isInteger(message.clientStartTimestampMs.high)))
                            return "clientStartTimestampMs: integer|Long expected";
                    if (message.clientEndTimestampMs != null && message.hasOwnProperty("clientEndTimestampMs"))
                        if (!$util.isInteger(message.clientEndTimestampMs) && !(message.clientEndTimestampMs && $util.isInteger(message.clientEndTimestampMs.low) && $util.isInteger(message.clientEndTimestampMs.high)))
                            return "clientEndTimestampMs: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a CreateEventRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.CreateEventRequest} CreateEventRequest
                 */
                CreateEventRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.CreateEventRequest)
                        return object;
                    var message = new $root.space.kenko.proto.CreateEventRequest();
                    if (object.event != null) {
                        if (typeof object.event !== "object")
                            throw TypeError(".space.kenko.proto.CreateEventRequest.event: object expected");
                        message.event = $root.space.kenko.proto.EventInstanceProto2.fromObject(object.event);
                    }
                    if (object.clientStartTimestampMs != null)
                        if ($util.Long)
                            (message.clientStartTimestampMs = $util.Long.fromValue(object.clientStartTimestampMs)).unsigned = false;
                        else if (typeof object.clientStartTimestampMs === "string")
                            message.clientStartTimestampMs = parseInt(object.clientStartTimestampMs, 10);
                        else if (typeof object.clientStartTimestampMs === "number")
                            message.clientStartTimestampMs = object.clientStartTimestampMs;
                        else if (typeof object.clientStartTimestampMs === "object")
                            message.clientStartTimestampMs = new $util.LongBits(object.clientStartTimestampMs.low >>> 0, object.clientStartTimestampMs.high >>> 0).toNumber();
                    if (object.clientEndTimestampMs != null)
                        if ($util.Long)
                            (message.clientEndTimestampMs = $util.Long.fromValue(object.clientEndTimestampMs)).unsigned = false;
                        else if (typeof object.clientEndTimestampMs === "string")
                            message.clientEndTimestampMs = parseInt(object.clientEndTimestampMs, 10);
                        else if (typeof object.clientEndTimestampMs === "number")
                            message.clientEndTimestampMs = object.clientEndTimestampMs;
                        else if (typeof object.clientEndTimestampMs === "object")
                            message.clientEndTimestampMs = new $util.LongBits(object.clientEndTimestampMs.low >>> 0, object.clientEndTimestampMs.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a CreateEventRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {space.kenko.proto.CreateEventRequest} message CreateEventRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateEventRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.event = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.clientStartTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.clientStartTimestampMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.clientEndTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.clientEndTimestampMs = options.longs === String ? "0" : 0;
                    }
                    if (message.event != null && message.hasOwnProperty("event"))
                        object.event = $root.space.kenko.proto.EventInstanceProto2.toObject(message.event, options);
                    if (message.clientStartTimestampMs != null && message.hasOwnProperty("clientStartTimestampMs"))
                        if (typeof message.clientStartTimestampMs === "number")
                            object.clientStartTimestampMs = options.longs === String ? String(message.clientStartTimestampMs) : message.clientStartTimestampMs;
                        else
                            object.clientStartTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.clientStartTimestampMs) : options.longs === Number ? new $util.LongBits(message.clientStartTimestampMs.low >>> 0, message.clientStartTimestampMs.high >>> 0).toNumber() : message.clientStartTimestampMs;
                    if (message.clientEndTimestampMs != null && message.hasOwnProperty("clientEndTimestampMs"))
                        if (typeof message.clientEndTimestampMs === "number")
                            object.clientEndTimestampMs = options.longs === String ? String(message.clientEndTimestampMs) : message.clientEndTimestampMs;
                        else
                            object.clientEndTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.clientEndTimestampMs) : options.longs === Number ? new $util.LongBits(message.clientEndTimestampMs.low >>> 0, message.clientEndTimestampMs.high >>> 0).toNumber() : message.clientEndTimestampMs;
                    return object;
                };

                /**
                 * Converts this CreateEventRequest to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateEventRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CreateEventRequest
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.CreateEventRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateEventRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.CreateEventRequest";
                };

                return CreateEventRequest;
            })();

            proto.CreateEventResponse = (function() {

                /**
                 * Properties of a CreateEventResponse.
                 * @memberof space.kenko.proto
                 * @interface ICreateEventResponse
                 * @property {boolean|null} [okay] CreateEventResponse okay
                 * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] CreateEventResponse errors
                 * @property {Array.<space.kenko.proto.IEventInstanceProto2>|null} [created] CreateEventResponse created
                 */

                /**
                 * Constructs a new CreateEventResponse.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a CreateEventResponse.
                 * @implements ICreateEventResponse
                 * @constructor
                 * @param {space.kenko.proto.ICreateEventResponse=} [properties] Properties to set
                 */
                function CreateEventResponse(properties) {
                    this.errors = [];
                    this.created = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateEventResponse okay.
                 * @member {boolean} okay
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @instance
                 */
                CreateEventResponse.prototype.okay = false;

                /**
                 * CreateEventResponse errors.
                 * @member {Array.<space.kenko.proto.IErrorProto>} errors
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @instance
                 */
                CreateEventResponse.prototype.errors = $util.emptyArray;

                /**
                 * CreateEventResponse created.
                 * @member {Array.<space.kenko.proto.IEventInstanceProto2>} created
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @instance
                 */
                CreateEventResponse.prototype.created = $util.emptyArray;

                /**
                 * Creates a new CreateEventResponse instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {space.kenko.proto.ICreateEventResponse=} [properties] Properties to set
                 * @returns {space.kenko.proto.CreateEventResponse} CreateEventResponse instance
                 */
                CreateEventResponse.create = function create(properties) {
                    return new CreateEventResponse(properties);
                };

                /**
                 * Encodes the specified CreateEventResponse message. Does not implicitly {@link space.kenko.proto.CreateEventResponse.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {space.kenko.proto.ICreateEventResponse} message CreateEventResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateEventResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
                    if (message.errors != null && message.errors.length)
                        for (var i = 0; i < message.errors.length; ++i)
                            $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.created != null && message.created.length)
                        for (var i = 0; i < message.created.length; ++i)
                            $root.space.kenko.proto.EventInstanceProto2.encode(message.created[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreateEventResponse message, length delimited. Does not implicitly {@link space.kenko.proto.CreateEventResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {space.kenko.proto.ICreateEventResponse} message CreateEventResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateEventResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.CreateEventResponse} CreateEventResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateEventResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.CreateEventResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.okay = reader.bool();
                                break;
                            }
                        case 2: {
                                if (!(message.errors && message.errors.length))
                                    message.errors = [];
                                message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.created && message.created.length))
                                    message.created = [];
                                message.created.push($root.space.kenko.proto.EventInstanceProto2.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateEventResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.CreateEventResponse} CreateEventResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateEventResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateEventResponse message.
                 * @function verify
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateEventResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        if (typeof message.okay !== "boolean")
                            return "okay: boolean expected";
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (var i = 0; i < message.errors.length; ++i) {
                            var error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.created != null && message.hasOwnProperty("created")) {
                        if (!Array.isArray(message.created))
                            return "created: array expected";
                        for (var i = 0; i < message.created.length; ++i) {
                            var error = $root.space.kenko.proto.EventInstanceProto2.verify(message.created[i]);
                            if (error)
                                return "created." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateEventResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.CreateEventResponse} CreateEventResponse
                 */
                CreateEventResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.CreateEventResponse)
                        return object;
                    var message = new $root.space.kenko.proto.CreateEventResponse();
                    if (object.okay != null)
                        message.okay = Boolean(object.okay);
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".space.kenko.proto.CreateEventResponse.errors: array expected");
                        message.errors = [];
                        for (var i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".space.kenko.proto.CreateEventResponse.errors: object expected");
                            message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
                        }
                    }
                    if (object.created) {
                        if (!Array.isArray(object.created))
                            throw TypeError(".space.kenko.proto.CreateEventResponse.created: array expected");
                        message.created = [];
                        for (var i = 0; i < object.created.length; ++i) {
                            if (typeof object.created[i] !== "object")
                                throw TypeError(".space.kenko.proto.CreateEventResponse.created: object expected");
                            message.created[i] = $root.space.kenko.proto.EventInstanceProto2.fromObject(object.created[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateEventResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {space.kenko.proto.CreateEventResponse} message CreateEventResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateEventResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.errors = [];
                        object.created = [];
                    }
                    if (options.defaults)
                        object.okay = false;
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        object.okay = message.okay;
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (var j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
                    }
                    if (message.created && message.created.length) {
                        object.created = [];
                        for (var j = 0; j < message.created.length; ++j)
                            object.created[j] = $root.space.kenko.proto.EventInstanceProto2.toObject(message.created[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CreateEventResponse to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateEventResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CreateEventResponse
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.CreateEventResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateEventResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.CreateEventResponse";
                };

                return CreateEventResponse;
            })();

            proto.UpdateFutureInstancesRequest = (function() {

                /**
                 * Properties of an UpdateFutureInstancesRequest.
                 * @memberof space.kenko.proto
                 * @interface IUpdateFutureInstancesRequest
                 * @property {space.kenko.proto.ICalendarId|null} [calendarId] UpdateFutureInstancesRequest calendarId
                 * @property {space.kenko.proto.IEventInstanceProto|null} [instance] UpdateFutureInstancesRequest instance
                 * @property {space.kenko.proto.IRepeatStrategyProto|null} [repeatStrategy] UpdateFutureInstancesRequest repeatStrategy
                 */

                /**
                 * Constructs a new UpdateFutureInstancesRequest.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an UpdateFutureInstancesRequest.
                 * @implements IUpdateFutureInstancesRequest
                 * @constructor
                 * @param {space.kenko.proto.IUpdateFutureInstancesRequest=} [properties] Properties to set
                 */
                function UpdateFutureInstancesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateFutureInstancesRequest calendarId.
                 * @member {space.kenko.proto.ICalendarId|null|undefined} calendarId
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @instance
                 */
                UpdateFutureInstancesRequest.prototype.calendarId = null;

                /**
                 * UpdateFutureInstancesRequest instance.
                 * @member {space.kenko.proto.IEventInstanceProto|null|undefined} instance
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @instance
                 */
                UpdateFutureInstancesRequest.prototype.instance = null;

                /**
                 * UpdateFutureInstancesRequest repeatStrategy.
                 * @member {space.kenko.proto.IRepeatStrategyProto|null|undefined} repeatStrategy
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @instance
                 */
                UpdateFutureInstancesRequest.prototype.repeatStrategy = null;

                /**
                 * Creates a new UpdateFutureInstancesRequest instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {space.kenko.proto.IUpdateFutureInstancesRequest=} [properties] Properties to set
                 * @returns {space.kenko.proto.UpdateFutureInstancesRequest} UpdateFutureInstancesRequest instance
                 */
                UpdateFutureInstancesRequest.create = function create(properties) {
                    return new UpdateFutureInstancesRequest(properties);
                };

                /**
                 * Encodes the specified UpdateFutureInstancesRequest message. Does not implicitly {@link space.kenko.proto.UpdateFutureInstancesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {space.kenko.proto.IUpdateFutureInstancesRequest} message UpdateFutureInstancesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateFutureInstancesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.calendarId != null && Object.hasOwnProperty.call(message, "calendarId"))
                        $root.space.kenko.proto.CalendarId.encode(message.calendarId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.instance != null && Object.hasOwnProperty.call(message, "instance"))
                        $root.space.kenko.proto.EventInstanceProto.encode(message.instance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.repeatStrategy != null && Object.hasOwnProperty.call(message, "repeatStrategy"))
                        $root.space.kenko.proto.RepeatStrategyProto.encode(message.repeatStrategy, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateFutureInstancesRequest message, length delimited. Does not implicitly {@link space.kenko.proto.UpdateFutureInstancesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {space.kenko.proto.IUpdateFutureInstancesRequest} message UpdateFutureInstancesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateFutureInstancesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateFutureInstancesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UpdateFutureInstancesRequest} UpdateFutureInstancesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateFutureInstancesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UpdateFutureInstancesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.calendarId = $root.space.kenko.proto.CalendarId.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.instance = $root.space.kenko.proto.EventInstanceProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateFutureInstancesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UpdateFutureInstancesRequest} UpdateFutureInstancesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateFutureInstancesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateFutureInstancesRequest message.
                 * @function verify
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateFutureInstancesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.calendarId != null && message.hasOwnProperty("calendarId")) {
                        var error = $root.space.kenko.proto.CalendarId.verify(message.calendarId);
                        if (error)
                            return "calendarId." + error;
                    }
                    if (message.instance != null && message.hasOwnProperty("instance")) {
                        var error = $root.space.kenko.proto.EventInstanceProto.verify(message.instance);
                        if (error)
                            return "instance." + error;
                    }
                    if (message.repeatStrategy != null && message.hasOwnProperty("repeatStrategy")) {
                        var error = $root.space.kenko.proto.RepeatStrategyProto.verify(message.repeatStrategy);
                        if (error)
                            return "repeatStrategy." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateFutureInstancesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UpdateFutureInstancesRequest} UpdateFutureInstancesRequest
                 */
                UpdateFutureInstancesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UpdateFutureInstancesRequest)
                        return object;
                    var message = new $root.space.kenko.proto.UpdateFutureInstancesRequest();
                    if (object.calendarId != null) {
                        if (typeof object.calendarId !== "object")
                            throw TypeError(".space.kenko.proto.UpdateFutureInstancesRequest.calendarId: object expected");
                        message.calendarId = $root.space.kenko.proto.CalendarId.fromObject(object.calendarId);
                    }
                    if (object.instance != null) {
                        if (typeof object.instance !== "object")
                            throw TypeError(".space.kenko.proto.UpdateFutureInstancesRequest.instance: object expected");
                        message.instance = $root.space.kenko.proto.EventInstanceProto.fromObject(object.instance);
                    }
                    if (object.repeatStrategy != null) {
                        if (typeof object.repeatStrategy !== "object")
                            throw TypeError(".space.kenko.proto.UpdateFutureInstancesRequest.repeatStrategy: object expected");
                        message.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.fromObject(object.repeatStrategy);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateFutureInstancesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {space.kenko.proto.UpdateFutureInstancesRequest} message UpdateFutureInstancesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateFutureInstancesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.calendarId = null;
                        object.instance = null;
                        object.repeatStrategy = null;
                    }
                    if (message.calendarId != null && message.hasOwnProperty("calendarId"))
                        object.calendarId = $root.space.kenko.proto.CalendarId.toObject(message.calendarId, options);
                    if (message.instance != null && message.hasOwnProperty("instance"))
                        object.instance = $root.space.kenko.proto.EventInstanceProto.toObject(message.instance, options);
                    if (message.repeatStrategy != null && message.hasOwnProperty("repeatStrategy"))
                        object.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.toObject(message.repeatStrategy, options);
                    return object;
                };

                /**
                 * Converts this UpdateFutureInstancesRequest to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateFutureInstancesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UpdateFutureInstancesRequest
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UpdateFutureInstancesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateFutureInstancesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UpdateFutureInstancesRequest";
                };

                return UpdateFutureInstancesRequest;
            })();

            proto.UpdateFutureInstancesResponse = (function() {

                /**
                 * Properties of an UpdateFutureInstancesResponse.
                 * @memberof space.kenko.proto
                 * @interface IUpdateFutureInstancesResponse
                 * @property {boolean|null} [okay] UpdateFutureInstancesResponse okay
                 * @property {space.kenko.proto.IEventProto|null} [originalEvent] UpdateFutureInstancesResponse originalEvent
                 * @property {space.kenko.proto.IEventProto|null} [newEvent] UpdateFutureInstancesResponse newEvent
                 */

                /**
                 * Constructs a new UpdateFutureInstancesResponse.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an UpdateFutureInstancesResponse.
                 * @implements IUpdateFutureInstancesResponse
                 * @constructor
                 * @param {space.kenko.proto.IUpdateFutureInstancesResponse=} [properties] Properties to set
                 */
                function UpdateFutureInstancesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateFutureInstancesResponse okay.
                 * @member {boolean} okay
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @instance
                 */
                UpdateFutureInstancesResponse.prototype.okay = false;

                /**
                 * UpdateFutureInstancesResponse originalEvent.
                 * @member {space.kenko.proto.IEventProto|null|undefined} originalEvent
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @instance
                 */
                UpdateFutureInstancesResponse.prototype.originalEvent = null;

                /**
                 * UpdateFutureInstancesResponse newEvent.
                 * @member {space.kenko.proto.IEventProto|null|undefined} newEvent
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @instance
                 */
                UpdateFutureInstancesResponse.prototype.newEvent = null;

                /**
                 * Creates a new UpdateFutureInstancesResponse instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {space.kenko.proto.IUpdateFutureInstancesResponse=} [properties] Properties to set
                 * @returns {space.kenko.proto.UpdateFutureInstancesResponse} UpdateFutureInstancesResponse instance
                 */
                UpdateFutureInstancesResponse.create = function create(properties) {
                    return new UpdateFutureInstancesResponse(properties);
                };

                /**
                 * Encodes the specified UpdateFutureInstancesResponse message. Does not implicitly {@link space.kenko.proto.UpdateFutureInstancesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {space.kenko.proto.IUpdateFutureInstancesResponse} message UpdateFutureInstancesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateFutureInstancesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
                    if (message.originalEvent != null && Object.hasOwnProperty.call(message, "originalEvent"))
                        $root.space.kenko.proto.EventProto.encode(message.originalEvent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.newEvent != null && Object.hasOwnProperty.call(message, "newEvent"))
                        $root.space.kenko.proto.EventProto.encode(message.newEvent, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateFutureInstancesResponse message, length delimited. Does not implicitly {@link space.kenko.proto.UpdateFutureInstancesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {space.kenko.proto.IUpdateFutureInstancesResponse} message UpdateFutureInstancesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateFutureInstancesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateFutureInstancesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UpdateFutureInstancesResponse} UpdateFutureInstancesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateFutureInstancesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UpdateFutureInstancesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.okay = reader.bool();
                                break;
                            }
                        case 2: {
                                message.originalEvent = $root.space.kenko.proto.EventProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.newEvent = $root.space.kenko.proto.EventProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateFutureInstancesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UpdateFutureInstancesResponse} UpdateFutureInstancesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateFutureInstancesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateFutureInstancesResponse message.
                 * @function verify
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateFutureInstancesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        if (typeof message.okay !== "boolean")
                            return "okay: boolean expected";
                    if (message.originalEvent != null && message.hasOwnProperty("originalEvent")) {
                        var error = $root.space.kenko.proto.EventProto.verify(message.originalEvent);
                        if (error)
                            return "originalEvent." + error;
                    }
                    if (message.newEvent != null && message.hasOwnProperty("newEvent")) {
                        var error = $root.space.kenko.proto.EventProto.verify(message.newEvent);
                        if (error)
                            return "newEvent." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateFutureInstancesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UpdateFutureInstancesResponse} UpdateFutureInstancesResponse
                 */
                UpdateFutureInstancesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UpdateFutureInstancesResponse)
                        return object;
                    var message = new $root.space.kenko.proto.UpdateFutureInstancesResponse();
                    if (object.okay != null)
                        message.okay = Boolean(object.okay);
                    if (object.originalEvent != null) {
                        if (typeof object.originalEvent !== "object")
                            throw TypeError(".space.kenko.proto.UpdateFutureInstancesResponse.originalEvent: object expected");
                        message.originalEvent = $root.space.kenko.proto.EventProto.fromObject(object.originalEvent);
                    }
                    if (object.newEvent != null) {
                        if (typeof object.newEvent !== "object")
                            throw TypeError(".space.kenko.proto.UpdateFutureInstancesResponse.newEvent: object expected");
                        message.newEvent = $root.space.kenko.proto.EventProto.fromObject(object.newEvent);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateFutureInstancesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {space.kenko.proto.UpdateFutureInstancesResponse} message UpdateFutureInstancesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateFutureInstancesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.okay = false;
                        object.originalEvent = null;
                        object.newEvent = null;
                    }
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        object.okay = message.okay;
                    if (message.originalEvent != null && message.hasOwnProperty("originalEvent"))
                        object.originalEvent = $root.space.kenko.proto.EventProto.toObject(message.originalEvent, options);
                    if (message.newEvent != null && message.hasOwnProperty("newEvent"))
                        object.newEvent = $root.space.kenko.proto.EventProto.toObject(message.newEvent, options);
                    return object;
                };

                /**
                 * Converts this UpdateFutureInstancesResponse to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateFutureInstancesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UpdateFutureInstancesResponse
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UpdateFutureInstancesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateFutureInstancesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UpdateFutureInstancesResponse";
                };

                return UpdateFutureInstancesResponse;
            })();

            proto.UpdateInstanceRequest = (function() {

                /**
                 * Properties of an UpdateInstanceRequest.
                 * @memberof space.kenko.proto
                 * @interface IUpdateInstanceRequest
                 * @property {space.kenko.proto.IEventInstanceProto2|null} [instance] UpdateInstanceRequest instance
                 * @property {boolean|null} [updateFutureInstances] UpdateInstanceRequest updateFutureInstances
                 * @property {number|Long|null} [startTimestampMs] UpdateInstanceRequest startTimestampMs
                 * @property {number|Long|null} [endTimestampMs] UpdateInstanceRequest endTimestampMs
                 */

                /**
                 * Constructs a new UpdateInstanceRequest.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an UpdateInstanceRequest.
                 * @implements IUpdateInstanceRequest
                 * @constructor
                 * @param {space.kenko.proto.IUpdateInstanceRequest=} [properties] Properties to set
                 */
                function UpdateInstanceRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateInstanceRequest instance.
                 * @member {space.kenko.proto.IEventInstanceProto2|null|undefined} instance
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @instance
                 */
                UpdateInstanceRequest.prototype.instance = null;

                /**
                 * UpdateInstanceRequest updateFutureInstances.
                 * @member {boolean} updateFutureInstances
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @instance
                 */
                UpdateInstanceRequest.prototype.updateFutureInstances = false;

                /**
                 * UpdateInstanceRequest startTimestampMs.
                 * @member {number|Long} startTimestampMs
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @instance
                 */
                UpdateInstanceRequest.prototype.startTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UpdateInstanceRequest endTimestampMs.
                 * @member {number|Long} endTimestampMs
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @instance
                 */
                UpdateInstanceRequest.prototype.endTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new UpdateInstanceRequest instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {space.kenko.proto.IUpdateInstanceRequest=} [properties] Properties to set
                 * @returns {space.kenko.proto.UpdateInstanceRequest} UpdateInstanceRequest instance
                 */
                UpdateInstanceRequest.create = function create(properties) {
                    return new UpdateInstanceRequest(properties);
                };

                /**
                 * Encodes the specified UpdateInstanceRequest message. Does not implicitly {@link space.kenko.proto.UpdateInstanceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {space.kenko.proto.IUpdateInstanceRequest} message UpdateInstanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateInstanceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.instance != null && Object.hasOwnProperty.call(message, "instance"))
                        $root.space.kenko.proto.EventInstanceProto2.encode(message.instance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.updateFutureInstances != null && Object.hasOwnProperty.call(message, "updateFutureInstances"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.updateFutureInstances);
                    if (message.startTimestampMs != null && Object.hasOwnProperty.call(message, "startTimestampMs"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.startTimestampMs);
                    if (message.endTimestampMs != null && Object.hasOwnProperty.call(message, "endTimestampMs"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.endTimestampMs);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateInstanceRequest message, length delimited. Does not implicitly {@link space.kenko.proto.UpdateInstanceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {space.kenko.proto.IUpdateInstanceRequest} message UpdateInstanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateInstanceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UpdateInstanceRequest} UpdateInstanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateInstanceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UpdateInstanceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.instance = $root.space.kenko.proto.EventInstanceProto2.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.updateFutureInstances = reader.bool();
                                break;
                            }
                        case 4: {
                                message.startTimestampMs = reader.int64();
                                break;
                            }
                        case 5: {
                                message.endTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateInstanceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UpdateInstanceRequest} UpdateInstanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateInstanceRequest message.
                 * @function verify
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateInstanceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.instance != null && message.hasOwnProperty("instance")) {
                        var error = $root.space.kenko.proto.EventInstanceProto2.verify(message.instance);
                        if (error)
                            return "instance." + error;
                    }
                    if (message.updateFutureInstances != null && message.hasOwnProperty("updateFutureInstances"))
                        if (typeof message.updateFutureInstances !== "boolean")
                            return "updateFutureInstances: boolean expected";
                    if (message.startTimestampMs != null && message.hasOwnProperty("startTimestampMs"))
                        if (!$util.isInteger(message.startTimestampMs) && !(message.startTimestampMs && $util.isInteger(message.startTimestampMs.low) && $util.isInteger(message.startTimestampMs.high)))
                            return "startTimestampMs: integer|Long expected";
                    if (message.endTimestampMs != null && message.hasOwnProperty("endTimestampMs"))
                        if (!$util.isInteger(message.endTimestampMs) && !(message.endTimestampMs && $util.isInteger(message.endTimestampMs.low) && $util.isInteger(message.endTimestampMs.high)))
                            return "endTimestampMs: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateInstanceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UpdateInstanceRequest} UpdateInstanceRequest
                 */
                UpdateInstanceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UpdateInstanceRequest)
                        return object;
                    var message = new $root.space.kenko.proto.UpdateInstanceRequest();
                    if (object.instance != null) {
                        if (typeof object.instance !== "object")
                            throw TypeError(".space.kenko.proto.UpdateInstanceRequest.instance: object expected");
                        message.instance = $root.space.kenko.proto.EventInstanceProto2.fromObject(object.instance);
                    }
                    if (object.updateFutureInstances != null)
                        message.updateFutureInstances = Boolean(object.updateFutureInstances);
                    if (object.startTimestampMs != null)
                        if ($util.Long)
                            (message.startTimestampMs = $util.Long.fromValue(object.startTimestampMs)).unsigned = false;
                        else if (typeof object.startTimestampMs === "string")
                            message.startTimestampMs = parseInt(object.startTimestampMs, 10);
                        else if (typeof object.startTimestampMs === "number")
                            message.startTimestampMs = object.startTimestampMs;
                        else if (typeof object.startTimestampMs === "object")
                            message.startTimestampMs = new $util.LongBits(object.startTimestampMs.low >>> 0, object.startTimestampMs.high >>> 0).toNumber();
                    if (object.endTimestampMs != null)
                        if ($util.Long)
                            (message.endTimestampMs = $util.Long.fromValue(object.endTimestampMs)).unsigned = false;
                        else if (typeof object.endTimestampMs === "string")
                            message.endTimestampMs = parseInt(object.endTimestampMs, 10);
                        else if (typeof object.endTimestampMs === "number")
                            message.endTimestampMs = object.endTimestampMs;
                        else if (typeof object.endTimestampMs === "object")
                            message.endTimestampMs = new $util.LongBits(object.endTimestampMs.low >>> 0, object.endTimestampMs.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateInstanceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {space.kenko.proto.UpdateInstanceRequest} message UpdateInstanceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateInstanceRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.instance = null;
                        object.updateFutureInstances = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimestampMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimestampMs = options.longs === String ? "0" : 0;
                    }
                    if (message.instance != null && message.hasOwnProperty("instance"))
                        object.instance = $root.space.kenko.proto.EventInstanceProto2.toObject(message.instance, options);
                    if (message.updateFutureInstances != null && message.hasOwnProperty("updateFutureInstances"))
                        object.updateFutureInstances = message.updateFutureInstances;
                    if (message.startTimestampMs != null && message.hasOwnProperty("startTimestampMs"))
                        if (typeof message.startTimestampMs === "number")
                            object.startTimestampMs = options.longs === String ? String(message.startTimestampMs) : message.startTimestampMs;
                        else
                            object.startTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimestampMs) : options.longs === Number ? new $util.LongBits(message.startTimestampMs.low >>> 0, message.startTimestampMs.high >>> 0).toNumber() : message.startTimestampMs;
                    if (message.endTimestampMs != null && message.hasOwnProperty("endTimestampMs"))
                        if (typeof message.endTimestampMs === "number")
                            object.endTimestampMs = options.longs === String ? String(message.endTimestampMs) : message.endTimestampMs;
                        else
                            object.endTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimestampMs) : options.longs === Number ? new $util.LongBits(message.endTimestampMs.low >>> 0, message.endTimestampMs.high >>> 0).toNumber() : message.endTimestampMs;
                    return object;
                };

                /**
                 * Converts this UpdateInstanceRequest to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateInstanceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UpdateInstanceRequest
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UpdateInstanceRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UpdateInstanceRequest";
                };

                return UpdateInstanceRequest;
            })();

            proto.UpdateInstanceResponse = (function() {

                /**
                 * Properties of an UpdateInstanceResponse.
                 * @memberof space.kenko.proto
                 * @interface IUpdateInstanceResponse
                 * @property {boolean|null} [okay] UpdateInstanceResponse okay
                 * @property {Array.<space.kenko.proto.IEventInstanceProto2>|null} [updatedEvent] UpdateInstanceResponse updatedEvent
                 * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] UpdateInstanceResponse errors
                 * @property {number|Long|null} [previousStartTimestamp] UpdateInstanceResponse previousStartTimestamp
                 */

                /**
                 * Constructs a new UpdateInstanceResponse.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an UpdateInstanceResponse.
                 * @implements IUpdateInstanceResponse
                 * @constructor
                 * @param {space.kenko.proto.IUpdateInstanceResponse=} [properties] Properties to set
                 */
                function UpdateInstanceResponse(properties) {
                    this.updatedEvent = [];
                    this.errors = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateInstanceResponse okay.
                 * @member {boolean} okay
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @instance
                 */
                UpdateInstanceResponse.prototype.okay = false;

                /**
                 * UpdateInstanceResponse updatedEvent.
                 * @member {Array.<space.kenko.proto.IEventInstanceProto2>} updatedEvent
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @instance
                 */
                UpdateInstanceResponse.prototype.updatedEvent = $util.emptyArray;

                /**
                 * UpdateInstanceResponse errors.
                 * @member {Array.<space.kenko.proto.IErrorProto>} errors
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @instance
                 */
                UpdateInstanceResponse.prototype.errors = $util.emptyArray;

                /**
                 * UpdateInstanceResponse previousStartTimestamp.
                 * @member {number|Long} previousStartTimestamp
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @instance
                 */
                UpdateInstanceResponse.prototype.previousStartTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new UpdateInstanceResponse instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {space.kenko.proto.IUpdateInstanceResponse=} [properties] Properties to set
                 * @returns {space.kenko.proto.UpdateInstanceResponse} UpdateInstanceResponse instance
                 */
                UpdateInstanceResponse.create = function create(properties) {
                    return new UpdateInstanceResponse(properties);
                };

                /**
                 * Encodes the specified UpdateInstanceResponse message. Does not implicitly {@link space.kenko.proto.UpdateInstanceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {space.kenko.proto.IUpdateInstanceResponse} message UpdateInstanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateInstanceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
                    if (message.updatedEvent != null && message.updatedEvent.length)
                        for (var i = 0; i < message.updatedEvent.length; ++i)
                            $root.space.kenko.proto.EventInstanceProto2.encode(message.updatedEvent[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.errors != null && message.errors.length)
                        for (var i = 0; i < message.errors.length; ++i)
                            $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.previousStartTimestamp != null && Object.hasOwnProperty.call(message, "previousStartTimestamp"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.previousStartTimestamp);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateInstanceResponse message, length delimited. Does not implicitly {@link space.kenko.proto.UpdateInstanceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {space.kenko.proto.IUpdateInstanceResponse} message UpdateInstanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateInstanceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UpdateInstanceResponse} UpdateInstanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateInstanceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UpdateInstanceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.okay = reader.bool();
                                break;
                            }
                        case 2: {
                                if (!(message.updatedEvent && message.updatedEvent.length))
                                    message.updatedEvent = [];
                                message.updatedEvent.push($root.space.kenko.proto.EventInstanceProto2.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.errors && message.errors.length))
                                    message.errors = [];
                                message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.previousStartTimestamp = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateInstanceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UpdateInstanceResponse} UpdateInstanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateInstanceResponse message.
                 * @function verify
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateInstanceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        if (typeof message.okay !== "boolean")
                            return "okay: boolean expected";
                    if (message.updatedEvent != null && message.hasOwnProperty("updatedEvent")) {
                        if (!Array.isArray(message.updatedEvent))
                            return "updatedEvent: array expected";
                        for (var i = 0; i < message.updatedEvent.length; ++i) {
                            var error = $root.space.kenko.proto.EventInstanceProto2.verify(message.updatedEvent[i]);
                            if (error)
                                return "updatedEvent." + error;
                        }
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (var i = 0; i < message.errors.length; ++i) {
                            var error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.previousStartTimestamp != null && message.hasOwnProperty("previousStartTimestamp"))
                        if (!$util.isInteger(message.previousStartTimestamp) && !(message.previousStartTimestamp && $util.isInteger(message.previousStartTimestamp.low) && $util.isInteger(message.previousStartTimestamp.high)))
                            return "previousStartTimestamp: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateInstanceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UpdateInstanceResponse} UpdateInstanceResponse
                 */
                UpdateInstanceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UpdateInstanceResponse)
                        return object;
                    var message = new $root.space.kenko.proto.UpdateInstanceResponse();
                    if (object.okay != null)
                        message.okay = Boolean(object.okay);
                    if (object.updatedEvent) {
                        if (!Array.isArray(object.updatedEvent))
                            throw TypeError(".space.kenko.proto.UpdateInstanceResponse.updatedEvent: array expected");
                        message.updatedEvent = [];
                        for (var i = 0; i < object.updatedEvent.length; ++i) {
                            if (typeof object.updatedEvent[i] !== "object")
                                throw TypeError(".space.kenko.proto.UpdateInstanceResponse.updatedEvent: object expected");
                            message.updatedEvent[i] = $root.space.kenko.proto.EventInstanceProto2.fromObject(object.updatedEvent[i]);
                        }
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".space.kenko.proto.UpdateInstanceResponse.errors: array expected");
                        message.errors = [];
                        for (var i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".space.kenko.proto.UpdateInstanceResponse.errors: object expected");
                            message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
                        }
                    }
                    if (object.previousStartTimestamp != null)
                        if ($util.Long)
                            (message.previousStartTimestamp = $util.Long.fromValue(object.previousStartTimestamp)).unsigned = false;
                        else if (typeof object.previousStartTimestamp === "string")
                            message.previousStartTimestamp = parseInt(object.previousStartTimestamp, 10);
                        else if (typeof object.previousStartTimestamp === "number")
                            message.previousStartTimestamp = object.previousStartTimestamp;
                        else if (typeof object.previousStartTimestamp === "object")
                            message.previousStartTimestamp = new $util.LongBits(object.previousStartTimestamp.low >>> 0, object.previousStartTimestamp.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateInstanceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {space.kenko.proto.UpdateInstanceResponse} message UpdateInstanceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateInstanceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.updatedEvent = [];
                        object.errors = [];
                    }
                    if (options.defaults) {
                        object.okay = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.previousStartTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.previousStartTimestamp = options.longs === String ? "0" : 0;
                    }
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        object.okay = message.okay;
                    if (message.updatedEvent && message.updatedEvent.length) {
                        object.updatedEvent = [];
                        for (var j = 0; j < message.updatedEvent.length; ++j)
                            object.updatedEvent[j] = $root.space.kenko.proto.EventInstanceProto2.toObject(message.updatedEvent[j], options);
                    }
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (var j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
                    }
                    if (message.previousStartTimestamp != null && message.hasOwnProperty("previousStartTimestamp"))
                        if (typeof message.previousStartTimestamp === "number")
                            object.previousStartTimestamp = options.longs === String ? String(message.previousStartTimestamp) : message.previousStartTimestamp;
                        else
                            object.previousStartTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.previousStartTimestamp) : options.longs === Number ? new $util.LongBits(message.previousStartTimestamp.low >>> 0, message.previousStartTimestamp.high >>> 0).toNumber() : message.previousStartTimestamp;
                    return object;
                };

                /**
                 * Converts this UpdateInstanceResponse to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateInstanceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UpdateInstanceResponse
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UpdateInstanceResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UpdateInstanceResponse";
                };

                return UpdateInstanceResponse;
            })();

            proto.DeleteInstanceRequest = (function() {

                /**
                 * Properties of a DeleteInstanceRequest.
                 * @memberof space.kenko.proto
                 * @interface IDeleteInstanceRequest
                 * @property {space.kenko.proto.ICalendarId|null} [calendarId] DeleteInstanceRequest calendarId
                 * @property {space.kenko.proto.IEventInstanceId|null} [instanceId] DeleteInstanceRequest instanceId
                 * @property {number|Long|null} [startTimestampMs] DeleteInstanceRequest startTimestampMs
                 * @property {number|Long|null} [endTimestampMs] DeleteInstanceRequest endTimestampMs
                 */

                /**
                 * Constructs a new DeleteInstanceRequest.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a DeleteInstanceRequest.
                 * @implements IDeleteInstanceRequest
                 * @constructor
                 * @param {space.kenko.proto.IDeleteInstanceRequest=} [properties] Properties to set
                 */
                function DeleteInstanceRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteInstanceRequest calendarId.
                 * @member {space.kenko.proto.ICalendarId|null|undefined} calendarId
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @instance
                 */
                DeleteInstanceRequest.prototype.calendarId = null;

                /**
                 * DeleteInstanceRequest instanceId.
                 * @member {space.kenko.proto.IEventInstanceId|null|undefined} instanceId
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @instance
                 */
                DeleteInstanceRequest.prototype.instanceId = null;

                /**
                 * DeleteInstanceRequest startTimestampMs.
                 * @member {number|Long} startTimestampMs
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @instance
                 */
                DeleteInstanceRequest.prototype.startTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * DeleteInstanceRequest endTimestampMs.
                 * @member {number|Long} endTimestampMs
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @instance
                 */
                DeleteInstanceRequest.prototype.endTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new DeleteInstanceRequest instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {space.kenko.proto.IDeleteInstanceRequest=} [properties] Properties to set
                 * @returns {space.kenko.proto.DeleteInstanceRequest} DeleteInstanceRequest instance
                 */
                DeleteInstanceRequest.create = function create(properties) {
                    return new DeleteInstanceRequest(properties);
                };

                /**
                 * Encodes the specified DeleteInstanceRequest message. Does not implicitly {@link space.kenko.proto.DeleteInstanceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {space.kenko.proto.IDeleteInstanceRequest} message DeleteInstanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteInstanceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.calendarId != null && Object.hasOwnProperty.call(message, "calendarId"))
                        $root.space.kenko.proto.CalendarId.encode(message.calendarId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.instanceId != null && Object.hasOwnProperty.call(message, "instanceId"))
                        $root.space.kenko.proto.EventInstanceId.encode(message.instanceId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.startTimestampMs != null && Object.hasOwnProperty.call(message, "startTimestampMs"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.startTimestampMs);
                    if (message.endTimestampMs != null && Object.hasOwnProperty.call(message, "endTimestampMs"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.endTimestampMs);
                    return writer;
                };

                /**
                 * Encodes the specified DeleteInstanceRequest message, length delimited. Does not implicitly {@link space.kenko.proto.DeleteInstanceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {space.kenko.proto.IDeleteInstanceRequest} message DeleteInstanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteInstanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DeleteInstanceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.DeleteInstanceRequest} DeleteInstanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteInstanceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.DeleteInstanceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.calendarId = $root.space.kenko.proto.CalendarId.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.instanceId = $root.space.kenko.proto.EventInstanceId.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.startTimestampMs = reader.int64();
                                break;
                            }
                        case 5: {
                                message.endTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DeleteInstanceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.DeleteInstanceRequest} DeleteInstanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteInstanceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DeleteInstanceRequest message.
                 * @function verify
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteInstanceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.calendarId != null && message.hasOwnProperty("calendarId")) {
                        var error = $root.space.kenko.proto.CalendarId.verify(message.calendarId);
                        if (error)
                            return "calendarId." + error;
                    }
                    if (message.instanceId != null && message.hasOwnProperty("instanceId")) {
                        var error = $root.space.kenko.proto.EventInstanceId.verify(message.instanceId);
                        if (error)
                            return "instanceId." + error;
                    }
                    if (message.startTimestampMs != null && message.hasOwnProperty("startTimestampMs"))
                        if (!$util.isInteger(message.startTimestampMs) && !(message.startTimestampMs && $util.isInteger(message.startTimestampMs.low) && $util.isInteger(message.startTimestampMs.high)))
                            return "startTimestampMs: integer|Long expected";
                    if (message.endTimestampMs != null && message.hasOwnProperty("endTimestampMs"))
                        if (!$util.isInteger(message.endTimestampMs) && !(message.endTimestampMs && $util.isInteger(message.endTimestampMs.low) && $util.isInteger(message.endTimestampMs.high)))
                            return "endTimestampMs: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a DeleteInstanceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.DeleteInstanceRequest} DeleteInstanceRequest
                 */
                DeleteInstanceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.DeleteInstanceRequest)
                        return object;
                    var message = new $root.space.kenko.proto.DeleteInstanceRequest();
                    if (object.calendarId != null) {
                        if (typeof object.calendarId !== "object")
                            throw TypeError(".space.kenko.proto.DeleteInstanceRequest.calendarId: object expected");
                        message.calendarId = $root.space.kenko.proto.CalendarId.fromObject(object.calendarId);
                    }
                    if (object.instanceId != null) {
                        if (typeof object.instanceId !== "object")
                            throw TypeError(".space.kenko.proto.DeleteInstanceRequest.instanceId: object expected");
                        message.instanceId = $root.space.kenko.proto.EventInstanceId.fromObject(object.instanceId);
                    }
                    if (object.startTimestampMs != null)
                        if ($util.Long)
                            (message.startTimestampMs = $util.Long.fromValue(object.startTimestampMs)).unsigned = false;
                        else if (typeof object.startTimestampMs === "string")
                            message.startTimestampMs = parseInt(object.startTimestampMs, 10);
                        else if (typeof object.startTimestampMs === "number")
                            message.startTimestampMs = object.startTimestampMs;
                        else if (typeof object.startTimestampMs === "object")
                            message.startTimestampMs = new $util.LongBits(object.startTimestampMs.low >>> 0, object.startTimestampMs.high >>> 0).toNumber();
                    if (object.endTimestampMs != null)
                        if ($util.Long)
                            (message.endTimestampMs = $util.Long.fromValue(object.endTimestampMs)).unsigned = false;
                        else if (typeof object.endTimestampMs === "string")
                            message.endTimestampMs = parseInt(object.endTimestampMs, 10);
                        else if (typeof object.endTimestampMs === "number")
                            message.endTimestampMs = object.endTimestampMs;
                        else if (typeof object.endTimestampMs === "object")
                            message.endTimestampMs = new $util.LongBits(object.endTimestampMs.low >>> 0, object.endTimestampMs.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteInstanceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {space.kenko.proto.DeleteInstanceRequest} message DeleteInstanceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteInstanceRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.calendarId = null;
                        object.instanceId = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimestampMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimestampMs = options.longs === String ? "0" : 0;
                    }
                    if (message.calendarId != null && message.hasOwnProperty("calendarId"))
                        object.calendarId = $root.space.kenko.proto.CalendarId.toObject(message.calendarId, options);
                    if (message.instanceId != null && message.hasOwnProperty("instanceId"))
                        object.instanceId = $root.space.kenko.proto.EventInstanceId.toObject(message.instanceId, options);
                    if (message.startTimestampMs != null && message.hasOwnProperty("startTimestampMs"))
                        if (typeof message.startTimestampMs === "number")
                            object.startTimestampMs = options.longs === String ? String(message.startTimestampMs) : message.startTimestampMs;
                        else
                            object.startTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimestampMs) : options.longs === Number ? new $util.LongBits(message.startTimestampMs.low >>> 0, message.startTimestampMs.high >>> 0).toNumber() : message.startTimestampMs;
                    if (message.endTimestampMs != null && message.hasOwnProperty("endTimestampMs"))
                        if (typeof message.endTimestampMs === "number")
                            object.endTimestampMs = options.longs === String ? String(message.endTimestampMs) : message.endTimestampMs;
                        else
                            object.endTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimestampMs) : options.longs === Number ? new $util.LongBits(message.endTimestampMs.low >>> 0, message.endTimestampMs.high >>> 0).toNumber() : message.endTimestampMs;
                    return object;
                };

                /**
                 * Converts this DeleteInstanceRequest to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteInstanceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeleteInstanceRequest
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.DeleteInstanceRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteInstanceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.DeleteInstanceRequest";
                };

                return DeleteInstanceRequest;
            })();

            proto.DeleteInstanceResponse = (function() {

                /**
                 * Properties of a DeleteInstanceResponse.
                 * @memberof space.kenko.proto
                 * @interface IDeleteInstanceResponse
                 * @property {boolean|null} [okay] DeleteInstanceResponse okay
                 * @property {space.kenko.proto.IEventProto|null} [updatedEvent] DeleteInstanceResponse updatedEvent
                 */

                /**
                 * Constructs a new DeleteInstanceResponse.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a DeleteInstanceResponse.
                 * @implements IDeleteInstanceResponse
                 * @constructor
                 * @param {space.kenko.proto.IDeleteInstanceResponse=} [properties] Properties to set
                 */
                function DeleteInstanceResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteInstanceResponse okay.
                 * @member {boolean} okay
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @instance
                 */
                DeleteInstanceResponse.prototype.okay = false;

                /**
                 * DeleteInstanceResponse updatedEvent.
                 * @member {space.kenko.proto.IEventProto|null|undefined} updatedEvent
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @instance
                 */
                DeleteInstanceResponse.prototype.updatedEvent = null;

                /**
                 * Creates a new DeleteInstanceResponse instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {space.kenko.proto.IDeleteInstanceResponse=} [properties] Properties to set
                 * @returns {space.kenko.proto.DeleteInstanceResponse} DeleteInstanceResponse instance
                 */
                DeleteInstanceResponse.create = function create(properties) {
                    return new DeleteInstanceResponse(properties);
                };

                /**
                 * Encodes the specified DeleteInstanceResponse message. Does not implicitly {@link space.kenko.proto.DeleteInstanceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {space.kenko.proto.IDeleteInstanceResponse} message DeleteInstanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteInstanceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
                    if (message.updatedEvent != null && Object.hasOwnProperty.call(message, "updatedEvent"))
                        $root.space.kenko.proto.EventProto.encode(message.updatedEvent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified DeleteInstanceResponse message, length delimited. Does not implicitly {@link space.kenko.proto.DeleteInstanceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {space.kenko.proto.IDeleteInstanceResponse} message DeleteInstanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteInstanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DeleteInstanceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.DeleteInstanceResponse} DeleteInstanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteInstanceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.DeleteInstanceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.okay = reader.bool();
                                break;
                            }
                        case 2: {
                                message.updatedEvent = $root.space.kenko.proto.EventProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DeleteInstanceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.DeleteInstanceResponse} DeleteInstanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteInstanceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DeleteInstanceResponse message.
                 * @function verify
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteInstanceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        if (typeof message.okay !== "boolean")
                            return "okay: boolean expected";
                    if (message.updatedEvent != null && message.hasOwnProperty("updatedEvent")) {
                        var error = $root.space.kenko.proto.EventProto.verify(message.updatedEvent);
                        if (error)
                            return "updatedEvent." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteInstanceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.DeleteInstanceResponse} DeleteInstanceResponse
                 */
                DeleteInstanceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.DeleteInstanceResponse)
                        return object;
                    var message = new $root.space.kenko.proto.DeleteInstanceResponse();
                    if (object.okay != null)
                        message.okay = Boolean(object.okay);
                    if (object.updatedEvent != null) {
                        if (typeof object.updatedEvent !== "object")
                            throw TypeError(".space.kenko.proto.DeleteInstanceResponse.updatedEvent: object expected");
                        message.updatedEvent = $root.space.kenko.proto.EventProto.fromObject(object.updatedEvent);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteInstanceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {space.kenko.proto.DeleteInstanceResponse} message DeleteInstanceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteInstanceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.okay = false;
                        object.updatedEvent = null;
                    }
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        object.okay = message.okay;
                    if (message.updatedEvent != null && message.hasOwnProperty("updatedEvent"))
                        object.updatedEvent = $root.space.kenko.proto.EventProto.toObject(message.updatedEvent, options);
                    return object;
                };

                /**
                 * Converts this DeleteInstanceResponse to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteInstanceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DeleteInstanceResponse
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.DeleteInstanceResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteInstanceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.DeleteInstanceResponse";
                };

                return DeleteInstanceResponse;
            })();

            proto.DebugDeleteCalendarEventsRequest = (function() {

                /**
                 * Properties of a DebugDeleteCalendarEventsRequest.
                 * @memberof space.kenko.proto
                 * @interface IDebugDeleteCalendarEventsRequest
                 * @property {string|null} [businessId] DebugDeleteCalendarEventsRequest businessId
                 */

                /**
                 * Constructs a new DebugDeleteCalendarEventsRequest.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a DebugDeleteCalendarEventsRequest.
                 * @implements IDebugDeleteCalendarEventsRequest
                 * @constructor
                 * @param {space.kenko.proto.IDebugDeleteCalendarEventsRequest=} [properties] Properties to set
                 */
                function DebugDeleteCalendarEventsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DebugDeleteCalendarEventsRequest businessId.
                 * @member {string} businessId
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @instance
                 */
                DebugDeleteCalendarEventsRequest.prototype.businessId = "";

                /**
                 * Creates a new DebugDeleteCalendarEventsRequest instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {space.kenko.proto.IDebugDeleteCalendarEventsRequest=} [properties] Properties to set
                 * @returns {space.kenko.proto.DebugDeleteCalendarEventsRequest} DebugDeleteCalendarEventsRequest instance
                 */
                DebugDeleteCalendarEventsRequest.create = function create(properties) {
                    return new DebugDeleteCalendarEventsRequest(properties);
                };

                /**
                 * Encodes the specified DebugDeleteCalendarEventsRequest message. Does not implicitly {@link space.kenko.proto.DebugDeleteCalendarEventsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {space.kenko.proto.IDebugDeleteCalendarEventsRequest} message DebugDeleteCalendarEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DebugDeleteCalendarEventsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified DebugDeleteCalendarEventsRequest message, length delimited. Does not implicitly {@link space.kenko.proto.DebugDeleteCalendarEventsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {space.kenko.proto.IDebugDeleteCalendarEventsRequest} message DebugDeleteCalendarEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DebugDeleteCalendarEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DebugDeleteCalendarEventsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.DebugDeleteCalendarEventsRequest} DebugDeleteCalendarEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DebugDeleteCalendarEventsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.DebugDeleteCalendarEventsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DebugDeleteCalendarEventsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.DebugDeleteCalendarEventsRequest} DebugDeleteCalendarEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DebugDeleteCalendarEventsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DebugDeleteCalendarEventsRequest message.
                 * @function verify
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DebugDeleteCalendarEventsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a DebugDeleteCalendarEventsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.DebugDeleteCalendarEventsRequest} DebugDeleteCalendarEventsRequest
                 */
                DebugDeleteCalendarEventsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.DebugDeleteCalendarEventsRequest)
                        return object;
                    var message = new $root.space.kenko.proto.DebugDeleteCalendarEventsRequest();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a DebugDeleteCalendarEventsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {space.kenko.proto.DebugDeleteCalendarEventsRequest} message DebugDeleteCalendarEventsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DebugDeleteCalendarEventsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.businessId = "";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this DebugDeleteCalendarEventsRequest to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DebugDeleteCalendarEventsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DebugDeleteCalendarEventsRequest
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DebugDeleteCalendarEventsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.DebugDeleteCalendarEventsRequest";
                };

                return DebugDeleteCalendarEventsRequest;
            })();

            proto.DebugDeleteCalendarEventsResponse = (function() {

                /**
                 * Properties of a DebugDeleteCalendarEventsResponse.
                 * @memberof space.kenko.proto
                 * @interface IDebugDeleteCalendarEventsResponse
                 * @property {boolean|null} [okay] DebugDeleteCalendarEventsResponse okay
                 */

                /**
                 * Constructs a new DebugDeleteCalendarEventsResponse.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a DebugDeleteCalendarEventsResponse.
                 * @implements IDebugDeleteCalendarEventsResponse
                 * @constructor
                 * @param {space.kenko.proto.IDebugDeleteCalendarEventsResponse=} [properties] Properties to set
                 */
                function DebugDeleteCalendarEventsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DebugDeleteCalendarEventsResponse okay.
                 * @member {boolean} okay
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @instance
                 */
                DebugDeleteCalendarEventsResponse.prototype.okay = false;

                /**
                 * Creates a new DebugDeleteCalendarEventsResponse instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {space.kenko.proto.IDebugDeleteCalendarEventsResponse=} [properties] Properties to set
                 * @returns {space.kenko.proto.DebugDeleteCalendarEventsResponse} DebugDeleteCalendarEventsResponse instance
                 */
                DebugDeleteCalendarEventsResponse.create = function create(properties) {
                    return new DebugDeleteCalendarEventsResponse(properties);
                };

                /**
                 * Encodes the specified DebugDeleteCalendarEventsResponse message. Does not implicitly {@link space.kenko.proto.DebugDeleteCalendarEventsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {space.kenko.proto.IDebugDeleteCalendarEventsResponse} message DebugDeleteCalendarEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DebugDeleteCalendarEventsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
                    return writer;
                };

                /**
                 * Encodes the specified DebugDeleteCalendarEventsResponse message, length delimited. Does not implicitly {@link space.kenko.proto.DebugDeleteCalendarEventsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {space.kenko.proto.IDebugDeleteCalendarEventsResponse} message DebugDeleteCalendarEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DebugDeleteCalendarEventsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DebugDeleteCalendarEventsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.DebugDeleteCalendarEventsResponse} DebugDeleteCalendarEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DebugDeleteCalendarEventsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.DebugDeleteCalendarEventsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.okay = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DebugDeleteCalendarEventsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.DebugDeleteCalendarEventsResponse} DebugDeleteCalendarEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DebugDeleteCalendarEventsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DebugDeleteCalendarEventsResponse message.
                 * @function verify
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DebugDeleteCalendarEventsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        if (typeof message.okay !== "boolean")
                            return "okay: boolean expected";
                    return null;
                };

                /**
                 * Creates a DebugDeleteCalendarEventsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.DebugDeleteCalendarEventsResponse} DebugDeleteCalendarEventsResponse
                 */
                DebugDeleteCalendarEventsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.DebugDeleteCalendarEventsResponse)
                        return object;
                    var message = new $root.space.kenko.proto.DebugDeleteCalendarEventsResponse();
                    if (object.okay != null)
                        message.okay = Boolean(object.okay);
                    return message;
                };

                /**
                 * Creates a plain object from a DebugDeleteCalendarEventsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {space.kenko.proto.DebugDeleteCalendarEventsResponse} message DebugDeleteCalendarEventsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DebugDeleteCalendarEventsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.okay = false;
                    if (message.okay != null && message.hasOwnProperty("okay"))
                        object.okay = message.okay;
                    return object;
                };

                /**
                 * Converts this DebugDeleteCalendarEventsResponse to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DebugDeleteCalendarEventsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DebugDeleteCalendarEventsResponse
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.DebugDeleteCalendarEventsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DebugDeleteCalendarEventsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.DebugDeleteCalendarEventsResponse";
                };

                return DebugDeleteCalendarEventsResponse;
            })();

            proto.CalendarEventProto = (function() {

                /**
                 * Properties of a CalendarEventProto.
                 * @memberof space.kenko.proto
                 * @interface ICalendarEventProto
                 * @property {string|null} [timeZoneId] CalendarEventProto timeZoneId
                 * @property {space.kenko.proto.ILocalDateProto|null} [startDate] CalendarEventProto startDate
                 * @property {space.kenko.proto.ILocalDateProto|null} [endDate] CalendarEventProto endDate
                 * @property {space.kenko.proto.ICalendarEventDetailsProto|null} [defaultDetails] CalendarEventProto defaultDetails
                 * @property {Array.<space.kenko.proto.ICalendarEventOverrideProto>|null} [overrides] CalendarEventProto overrides
                 * @property {space.kenko.proto.IRepeatStrategyProto|null} [repeatStrategy] CalendarEventProto repeatStrategy
                 */

                /**
                 * Constructs a new CalendarEventProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a CalendarEventProto.
                 * @implements ICalendarEventProto
                 * @constructor
                 * @param {space.kenko.proto.ICalendarEventProto=} [properties] Properties to set
                 */
                function CalendarEventProto(properties) {
                    this.overrides = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CalendarEventProto timeZoneId.
                 * @member {string} timeZoneId
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @instance
                 */
                CalendarEventProto.prototype.timeZoneId = "";

                /**
                 * CalendarEventProto startDate.
                 * @member {space.kenko.proto.ILocalDateProto|null|undefined} startDate
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @instance
                 */
                CalendarEventProto.prototype.startDate = null;

                /**
                 * CalendarEventProto endDate.
                 * @member {space.kenko.proto.ILocalDateProto|null|undefined} endDate
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @instance
                 */
                CalendarEventProto.prototype.endDate = null;

                /**
                 * CalendarEventProto defaultDetails.
                 * @member {space.kenko.proto.ICalendarEventDetailsProto|null|undefined} defaultDetails
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @instance
                 */
                CalendarEventProto.prototype.defaultDetails = null;

                /**
                 * CalendarEventProto overrides.
                 * @member {Array.<space.kenko.proto.ICalendarEventOverrideProto>} overrides
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @instance
                 */
                CalendarEventProto.prototype.overrides = $util.emptyArray;

                /**
                 * CalendarEventProto repeatStrategy.
                 * @member {space.kenko.proto.IRepeatStrategyProto|null|undefined} repeatStrategy
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @instance
                 */
                CalendarEventProto.prototype.repeatStrategy = null;

                /**
                 * Creates a new CalendarEventProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.CalendarEventProto} CalendarEventProto instance
                 */
                CalendarEventProto.create = function create(properties) {
                    return new CalendarEventProto(properties);
                };

                /**
                 * Encodes the specified CalendarEventProto message. Does not implicitly {@link space.kenko.proto.CalendarEventProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventProto} message CalendarEventProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarEventProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.timeZoneId != null && Object.hasOwnProperty.call(message, "timeZoneId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeZoneId);
                    if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                        $root.space.kenko.proto.LocalDateProto.encode(message.startDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                        $root.space.kenko.proto.LocalDateProto.encode(message.endDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.defaultDetails != null && Object.hasOwnProperty.call(message, "defaultDetails"))
                        $root.space.kenko.proto.CalendarEventDetailsProto.encode(message.defaultDetails, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.overrides != null && message.overrides.length)
                        for (var i = 0; i < message.overrides.length; ++i)
                            $root.space.kenko.proto.CalendarEventOverrideProto.encode(message.overrides[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.repeatStrategy != null && Object.hasOwnProperty.call(message, "repeatStrategy"))
                        $root.space.kenko.proto.RepeatStrategyProto.encode(message.repeatStrategy, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CalendarEventProto message, length delimited. Does not implicitly {@link space.kenko.proto.CalendarEventProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventProto} message CalendarEventProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarEventProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CalendarEventProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.CalendarEventProto} CalendarEventProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarEventProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.CalendarEventProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.timeZoneId = reader.string();
                                break;
                            }
                        case 3: {
                                message.startDate = $root.space.kenko.proto.LocalDateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.endDate = $root.space.kenko.proto.LocalDateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.defaultDetails = $root.space.kenko.proto.CalendarEventDetailsProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                if (!(message.overrides && message.overrides.length))
                                    message.overrides = [];
                                message.overrides.push($root.space.kenko.proto.CalendarEventOverrideProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 20: {
                                message.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CalendarEventProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.CalendarEventProto} CalendarEventProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarEventProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CalendarEventProto message.
                 * @function verify
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CalendarEventProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
                        if (!$util.isString(message.timeZoneId))
                            return "timeZoneId: string expected";
                    if (message.startDate != null && message.hasOwnProperty("startDate")) {
                        var error = $root.space.kenko.proto.LocalDateProto.verify(message.startDate);
                        if (error)
                            return "startDate." + error;
                    }
                    if (message.endDate != null && message.hasOwnProperty("endDate")) {
                        var error = $root.space.kenko.proto.LocalDateProto.verify(message.endDate);
                        if (error)
                            return "endDate." + error;
                    }
                    if (message.defaultDetails != null && message.hasOwnProperty("defaultDetails")) {
                        var error = $root.space.kenko.proto.CalendarEventDetailsProto.verify(message.defaultDetails);
                        if (error)
                            return "defaultDetails." + error;
                    }
                    if (message.overrides != null && message.hasOwnProperty("overrides")) {
                        if (!Array.isArray(message.overrides))
                            return "overrides: array expected";
                        for (var i = 0; i < message.overrides.length; ++i) {
                            var error = $root.space.kenko.proto.CalendarEventOverrideProto.verify(message.overrides[i]);
                            if (error)
                                return "overrides." + error;
                        }
                    }
                    if (message.repeatStrategy != null && message.hasOwnProperty("repeatStrategy")) {
                        var error = $root.space.kenko.proto.RepeatStrategyProto.verify(message.repeatStrategy);
                        if (error)
                            return "repeatStrategy." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CalendarEventProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.CalendarEventProto} CalendarEventProto
                 */
                CalendarEventProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.CalendarEventProto)
                        return object;
                    var message = new $root.space.kenko.proto.CalendarEventProto();
                    if (object.timeZoneId != null)
                        message.timeZoneId = String(object.timeZoneId);
                    if (object.startDate != null) {
                        if (typeof object.startDate !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventProto.startDate: object expected");
                        message.startDate = $root.space.kenko.proto.LocalDateProto.fromObject(object.startDate);
                    }
                    if (object.endDate != null) {
                        if (typeof object.endDate !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventProto.endDate: object expected");
                        message.endDate = $root.space.kenko.proto.LocalDateProto.fromObject(object.endDate);
                    }
                    if (object.defaultDetails != null) {
                        if (typeof object.defaultDetails !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventProto.defaultDetails: object expected");
                        message.defaultDetails = $root.space.kenko.proto.CalendarEventDetailsProto.fromObject(object.defaultDetails);
                    }
                    if (object.overrides) {
                        if (!Array.isArray(object.overrides))
                            throw TypeError(".space.kenko.proto.CalendarEventProto.overrides: array expected");
                        message.overrides = [];
                        for (var i = 0; i < object.overrides.length; ++i) {
                            if (typeof object.overrides[i] !== "object")
                                throw TypeError(".space.kenko.proto.CalendarEventProto.overrides: object expected");
                            message.overrides[i] = $root.space.kenko.proto.CalendarEventOverrideProto.fromObject(object.overrides[i]);
                        }
                    }
                    if (object.repeatStrategy != null) {
                        if (typeof object.repeatStrategy !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventProto.repeatStrategy: object expected");
                        message.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.fromObject(object.repeatStrategy);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CalendarEventProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {space.kenko.proto.CalendarEventProto} message CalendarEventProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CalendarEventProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.overrides = [];
                    if (options.defaults) {
                        object.timeZoneId = "";
                        object.startDate = null;
                        object.endDate = null;
                        object.defaultDetails = null;
                        object.repeatStrategy = null;
                    }
                    if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
                        object.timeZoneId = message.timeZoneId;
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        object.startDate = $root.space.kenko.proto.LocalDateProto.toObject(message.startDate, options);
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        object.endDate = $root.space.kenko.proto.LocalDateProto.toObject(message.endDate, options);
                    if (message.defaultDetails != null && message.hasOwnProperty("defaultDetails"))
                        object.defaultDetails = $root.space.kenko.proto.CalendarEventDetailsProto.toObject(message.defaultDetails, options);
                    if (message.overrides && message.overrides.length) {
                        object.overrides = [];
                        for (var j = 0; j < message.overrides.length; ++j)
                            object.overrides[j] = $root.space.kenko.proto.CalendarEventOverrideProto.toObject(message.overrides[j], options);
                    }
                    if (message.repeatStrategy != null && message.hasOwnProperty("repeatStrategy"))
                        object.repeatStrategy = $root.space.kenko.proto.RepeatStrategyProto.toObject(message.repeatStrategy, options);
                    return object;
                };

                /**
                 * Converts this CalendarEventProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CalendarEventProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CalendarEventProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.CalendarEventProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CalendarEventProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.CalendarEventProto";
                };

                return CalendarEventProto;
            })();

            proto.RepeatStrategyProto = (function() {

                /**
                 * Properties of a RepeatStrategyProto.
                 * @memberof space.kenko.proto
                 * @interface IRepeatStrategyProto
                 * @property {space.kenko.proto.INoRepeatSchedule|null} [none] RepeatStrategyProto none
                 * @property {space.kenko.proto.IWeeklyRepeatSchedule|null} [weekly] RepeatStrategyProto weekly
                 */

                /**
                 * Constructs a new RepeatStrategyProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a RepeatStrategyProto.
                 * @implements IRepeatStrategyProto
                 * @constructor
                 * @param {space.kenko.proto.IRepeatStrategyProto=} [properties] Properties to set
                 */
                function RepeatStrategyProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RepeatStrategyProto none.
                 * @member {space.kenko.proto.INoRepeatSchedule|null|undefined} none
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @instance
                 */
                RepeatStrategyProto.prototype.none = null;

                /**
                 * RepeatStrategyProto weekly.
                 * @member {space.kenko.proto.IWeeklyRepeatSchedule|null|undefined} weekly
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @instance
                 */
                RepeatStrategyProto.prototype.weekly = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * RepeatStrategyProto RepeatStrategy.
                 * @member {"none"|"weekly"|undefined} RepeatStrategy
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @instance
                 */
                Object.defineProperty(RepeatStrategyProto.prototype, "RepeatStrategy", {
                    get: $util.oneOfGetter($oneOfFields = ["none", "weekly"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new RepeatStrategyProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {space.kenko.proto.IRepeatStrategyProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.RepeatStrategyProto} RepeatStrategyProto instance
                 */
                RepeatStrategyProto.create = function create(properties) {
                    return new RepeatStrategyProto(properties);
                };

                /**
                 * Encodes the specified RepeatStrategyProto message. Does not implicitly {@link space.kenko.proto.RepeatStrategyProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {space.kenko.proto.IRepeatStrategyProto} message RepeatStrategyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RepeatStrategyProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.none != null && Object.hasOwnProperty.call(message, "none"))
                        $root.space.kenko.proto.NoRepeatSchedule.encode(message.none, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.weekly != null && Object.hasOwnProperty.call(message, "weekly"))
                        $root.space.kenko.proto.WeeklyRepeatSchedule.encode(message.weekly, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RepeatStrategyProto message, length delimited. Does not implicitly {@link space.kenko.proto.RepeatStrategyProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {space.kenko.proto.IRepeatStrategyProto} message RepeatStrategyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RepeatStrategyProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RepeatStrategyProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.RepeatStrategyProto} RepeatStrategyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RepeatStrategyProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.RepeatStrategyProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.none = $root.space.kenko.proto.NoRepeatSchedule.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.weekly = $root.space.kenko.proto.WeeklyRepeatSchedule.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RepeatStrategyProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.RepeatStrategyProto} RepeatStrategyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RepeatStrategyProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RepeatStrategyProto message.
                 * @function verify
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RepeatStrategyProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.none != null && message.hasOwnProperty("none")) {
                        properties.RepeatStrategy = 1;
                        {
                            var error = $root.space.kenko.proto.NoRepeatSchedule.verify(message.none);
                            if (error)
                                return "none." + error;
                        }
                    }
                    if (message.weekly != null && message.hasOwnProperty("weekly")) {
                        if (properties.RepeatStrategy === 1)
                            return "RepeatStrategy: multiple values";
                        properties.RepeatStrategy = 1;
                        {
                            var error = $root.space.kenko.proto.WeeklyRepeatSchedule.verify(message.weekly);
                            if (error)
                                return "weekly." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a RepeatStrategyProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.RepeatStrategyProto} RepeatStrategyProto
                 */
                RepeatStrategyProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.RepeatStrategyProto)
                        return object;
                    var message = new $root.space.kenko.proto.RepeatStrategyProto();
                    if (object.none != null) {
                        if (typeof object.none !== "object")
                            throw TypeError(".space.kenko.proto.RepeatStrategyProto.none: object expected");
                        message.none = $root.space.kenko.proto.NoRepeatSchedule.fromObject(object.none);
                    }
                    if (object.weekly != null) {
                        if (typeof object.weekly !== "object")
                            throw TypeError(".space.kenko.proto.RepeatStrategyProto.weekly: object expected");
                        message.weekly = $root.space.kenko.proto.WeeklyRepeatSchedule.fromObject(object.weekly);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RepeatStrategyProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {space.kenko.proto.RepeatStrategyProto} message RepeatStrategyProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RepeatStrategyProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.none != null && message.hasOwnProperty("none")) {
                        object.none = $root.space.kenko.proto.NoRepeatSchedule.toObject(message.none, options);
                        if (options.oneofs)
                            object.RepeatStrategy = "none";
                    }
                    if (message.weekly != null && message.hasOwnProperty("weekly")) {
                        object.weekly = $root.space.kenko.proto.WeeklyRepeatSchedule.toObject(message.weekly, options);
                        if (options.oneofs)
                            object.RepeatStrategy = "weekly";
                    }
                    return object;
                };

                /**
                 * Converts this RepeatStrategyProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RepeatStrategyProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RepeatStrategyProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.RepeatStrategyProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RepeatStrategyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.RepeatStrategyProto";
                };

                return RepeatStrategyProto;
            })();

            proto.CalendarEventOverrideProto = (function() {

                /**
                 * Properties of a CalendarEventOverrideProto.
                 * @memberof space.kenko.proto
                 * @interface ICalendarEventOverrideProto
                 * @property {space.kenko.proto.ILocalDateProto|null} [originalDate] CalendarEventOverrideProto originalDate
                 * @property {space.kenko.proto.ILocalDateProto|null} [date] CalendarEventOverrideProto date
                 * @property {space.kenko.proto.ICalendarEventDetailsProto|null} [details] CalendarEventOverrideProto details
                 */

                /**
                 * Constructs a new CalendarEventOverrideProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a CalendarEventOverrideProto.
                 * @implements ICalendarEventOverrideProto
                 * @constructor
                 * @param {space.kenko.proto.ICalendarEventOverrideProto=} [properties] Properties to set
                 */
                function CalendarEventOverrideProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CalendarEventOverrideProto originalDate.
                 * @member {space.kenko.proto.ILocalDateProto|null|undefined} originalDate
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @instance
                 */
                CalendarEventOverrideProto.prototype.originalDate = null;

                /**
                 * CalendarEventOverrideProto date.
                 * @member {space.kenko.proto.ILocalDateProto|null|undefined} date
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @instance
                 */
                CalendarEventOverrideProto.prototype.date = null;

                /**
                 * CalendarEventOverrideProto details.
                 * @member {space.kenko.proto.ICalendarEventDetailsProto|null|undefined} details
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @instance
                 */
                CalendarEventOverrideProto.prototype.details = null;

                /**
                 * Creates a new CalendarEventOverrideProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventOverrideProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.CalendarEventOverrideProto} CalendarEventOverrideProto instance
                 */
                CalendarEventOverrideProto.create = function create(properties) {
                    return new CalendarEventOverrideProto(properties);
                };

                /**
                 * Encodes the specified CalendarEventOverrideProto message. Does not implicitly {@link space.kenko.proto.CalendarEventOverrideProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventOverrideProto} message CalendarEventOverrideProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarEventOverrideProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.originalDate != null && Object.hasOwnProperty.call(message, "originalDate"))
                        $root.space.kenko.proto.LocalDateProto.encode(message.originalDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        $root.space.kenko.proto.LocalDateProto.encode(message.date, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.details != null && Object.hasOwnProperty.call(message, "details"))
                        $root.space.kenko.proto.CalendarEventDetailsProto.encode(message.details, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CalendarEventOverrideProto message, length delimited. Does not implicitly {@link space.kenko.proto.CalendarEventOverrideProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventOverrideProto} message CalendarEventOverrideProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarEventOverrideProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CalendarEventOverrideProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.CalendarEventOverrideProto} CalendarEventOverrideProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarEventOverrideProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.CalendarEventOverrideProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.originalDate = $root.space.kenko.proto.LocalDateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.date = $root.space.kenko.proto.LocalDateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.details = $root.space.kenko.proto.CalendarEventDetailsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CalendarEventOverrideProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.CalendarEventOverrideProto} CalendarEventOverrideProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarEventOverrideProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CalendarEventOverrideProto message.
                 * @function verify
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CalendarEventOverrideProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.originalDate != null && message.hasOwnProperty("originalDate")) {
                        var error = $root.space.kenko.proto.LocalDateProto.verify(message.originalDate);
                        if (error)
                            return "originalDate." + error;
                    }
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.space.kenko.proto.LocalDateProto.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    if (message.details != null && message.hasOwnProperty("details")) {
                        var error = $root.space.kenko.proto.CalendarEventDetailsProto.verify(message.details);
                        if (error)
                            return "details." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CalendarEventOverrideProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.CalendarEventOverrideProto} CalendarEventOverrideProto
                 */
                CalendarEventOverrideProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.CalendarEventOverrideProto)
                        return object;
                    var message = new $root.space.kenko.proto.CalendarEventOverrideProto();
                    if (object.originalDate != null) {
                        if (typeof object.originalDate !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventOverrideProto.originalDate: object expected");
                        message.originalDate = $root.space.kenko.proto.LocalDateProto.fromObject(object.originalDate);
                    }
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventOverrideProto.date: object expected");
                        message.date = $root.space.kenko.proto.LocalDateProto.fromObject(object.date);
                    }
                    if (object.details != null) {
                        if (typeof object.details !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventOverrideProto.details: object expected");
                        message.details = $root.space.kenko.proto.CalendarEventDetailsProto.fromObject(object.details);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CalendarEventOverrideProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {space.kenko.proto.CalendarEventOverrideProto} message CalendarEventOverrideProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CalendarEventOverrideProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.originalDate = null;
                        object.date = null;
                        object.details = null;
                    }
                    if (message.originalDate != null && message.hasOwnProperty("originalDate"))
                        object.originalDate = $root.space.kenko.proto.LocalDateProto.toObject(message.originalDate, options);
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.space.kenko.proto.LocalDateProto.toObject(message.date, options);
                    if (message.details != null && message.hasOwnProperty("details"))
                        object.details = $root.space.kenko.proto.CalendarEventDetailsProto.toObject(message.details, options);
                    return object;
                };

                /**
                 * Converts this CalendarEventOverrideProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CalendarEventOverrideProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CalendarEventOverrideProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.CalendarEventOverrideProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CalendarEventOverrideProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.CalendarEventOverrideProto";
                };

                return CalendarEventOverrideProto;
            })();

            proto.CalendarEventDetailsProto = (function() {

                /**
                 * Properties of a CalendarEventDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface ICalendarEventDetailsProto
                 * @property {space.kenko.proto.ILocalTimeProto|null} [startTime] CalendarEventDetailsProto startTime
                 * @property {space.kenko.proto.ILocalTimeProto|null} [endTime] CalendarEventDetailsProto endTime
                 * @property {string|null} [title] CalendarEventDetailsProto title
                 * @property {space.kenko.proto.IKenkoEventDetails|null} [kenkoDetails] CalendarEventDetailsProto kenkoDetails
                 */

                /**
                 * Constructs a new CalendarEventDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a CalendarEventDetailsProto.
                 * @implements ICalendarEventDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.ICalendarEventDetailsProto=} [properties] Properties to set
                 */
                function CalendarEventDetailsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CalendarEventDetailsProto startTime.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} startTime
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @instance
                 */
                CalendarEventDetailsProto.prototype.startTime = null;

                /**
                 * CalendarEventDetailsProto endTime.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} endTime
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @instance
                 */
                CalendarEventDetailsProto.prototype.endTime = null;

                /**
                 * CalendarEventDetailsProto title.
                 * @member {string} title
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @instance
                 */
                CalendarEventDetailsProto.prototype.title = "";

                /**
                 * CalendarEventDetailsProto kenkoDetails.
                 * @member {space.kenko.proto.IKenkoEventDetails|null|undefined} kenkoDetails
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @instance
                 */
                CalendarEventDetailsProto.prototype.kenkoDetails = null;

                /**
                 * Creates a new CalendarEventDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.CalendarEventDetailsProto} CalendarEventDetailsProto instance
                 */
                CalendarEventDetailsProto.create = function create(properties) {
                    return new CalendarEventDetailsProto(properties);
                };

                /**
                 * Encodes the specified CalendarEventDetailsProto message. Does not implicitly {@link space.kenko.proto.CalendarEventDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventDetailsProto} message CalendarEventDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarEventDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.startTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.endTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
                    if (message.kenkoDetails != null && Object.hasOwnProperty.call(message, "kenkoDetails"))
                        $root.space.kenko.proto.KenkoEventDetails.encode(message.kenkoDetails, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CalendarEventDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.CalendarEventDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {space.kenko.proto.ICalendarEventDetailsProto} message CalendarEventDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarEventDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CalendarEventDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.CalendarEventDetailsProto} CalendarEventDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarEventDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.CalendarEventDetailsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.startTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.endTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.title = reader.string();
                                break;
                            }
                        case 4: {
                                message.kenkoDetails = $root.space.kenko.proto.KenkoEventDetails.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CalendarEventDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.CalendarEventDetailsProto} CalendarEventDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarEventDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CalendarEventDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CalendarEventDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.startTime != null && message.hasOwnProperty("startTime")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.startTime);
                        if (error)
                            return "startTime." + error;
                    }
                    if (message.endTime != null && message.hasOwnProperty("endTime")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.endTime);
                        if (error)
                            return "endTime." + error;
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.kenkoDetails != null && message.hasOwnProperty("kenkoDetails")) {
                        var error = $root.space.kenko.proto.KenkoEventDetails.verify(message.kenkoDetails);
                        if (error)
                            return "kenkoDetails." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CalendarEventDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.CalendarEventDetailsProto} CalendarEventDetailsProto
                 */
                CalendarEventDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.CalendarEventDetailsProto)
                        return object;
                    var message = new $root.space.kenko.proto.CalendarEventDetailsProto();
                    if (object.startTime != null) {
                        if (typeof object.startTime !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventDetailsProto.startTime: object expected");
                        message.startTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.startTime);
                    }
                    if (object.endTime != null) {
                        if (typeof object.endTime !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventDetailsProto.endTime: object expected");
                        message.endTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.endTime);
                    }
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.kenkoDetails != null) {
                        if (typeof object.kenkoDetails !== "object")
                            throw TypeError(".space.kenko.proto.CalendarEventDetailsProto.kenkoDetails: object expected");
                        message.kenkoDetails = $root.space.kenko.proto.KenkoEventDetails.fromObject(object.kenkoDetails);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CalendarEventDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {space.kenko.proto.CalendarEventDetailsProto} message CalendarEventDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CalendarEventDetailsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.startTime = null;
                        object.endTime = null;
                        object.title = "";
                        object.kenkoDetails = null;
                    }
                    if (message.startTime != null && message.hasOwnProperty("startTime"))
                        object.startTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.startTime, options);
                    if (message.endTime != null && message.hasOwnProperty("endTime"))
                        object.endTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.endTime, options);
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.kenkoDetails != null && message.hasOwnProperty("kenkoDetails"))
                        object.kenkoDetails = $root.space.kenko.proto.KenkoEventDetails.toObject(message.kenkoDetails, options);
                    return object;
                };

                /**
                 * Converts this CalendarEventDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CalendarEventDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CalendarEventDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.CalendarEventDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CalendarEventDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.CalendarEventDetailsProto";
                };

                return CalendarEventDetailsProto;
            })();

            proto.KenkoEventDetails = (function() {

                /**
                 * Properties of a KenkoEventDetails.
                 * @memberof space.kenko.proto
                 * @interface IKenkoEventDetails
                 * @property {space.kenko.proto.IAvailabilityEventType|null} [availability] KenkoEventDetails availability
                 * @property {space.kenko.proto.IAppointmentEventType|null} [appointment] KenkoEventDetails appointment
                 * @property {space.kenko.proto.IClassEventType|null} ["class"] KenkoEventDetails class
                 */

                /**
                 * Constructs a new KenkoEventDetails.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a KenkoEventDetails.
                 * @implements IKenkoEventDetails
                 * @constructor
                 * @param {space.kenko.proto.IKenkoEventDetails=} [properties] Properties to set
                 */
                function KenkoEventDetails(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * KenkoEventDetails availability.
                 * @member {space.kenko.proto.IAvailabilityEventType|null|undefined} availability
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @instance
                 */
                KenkoEventDetails.prototype.availability = null;

                /**
                 * KenkoEventDetails appointment.
                 * @member {space.kenko.proto.IAppointmentEventType|null|undefined} appointment
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @instance
                 */
                KenkoEventDetails.prototype.appointment = null;

                /**
                 * KenkoEventDetails class.
                 * @member {space.kenko.proto.IClassEventType|null|undefined} class
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @instance
                 */
                KenkoEventDetails.prototype["class"] = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * KenkoEventDetails EventType.
                 * @member {"availability"|"appointment"|"class"|undefined} EventType
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @instance
                 */
                Object.defineProperty(KenkoEventDetails.prototype, "EventType", {
                    get: $util.oneOfGetter($oneOfFields = ["availability", "appointment", "class"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new KenkoEventDetails instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {space.kenko.proto.IKenkoEventDetails=} [properties] Properties to set
                 * @returns {space.kenko.proto.KenkoEventDetails} KenkoEventDetails instance
                 */
                KenkoEventDetails.create = function create(properties) {
                    return new KenkoEventDetails(properties);
                };

                /**
                 * Encodes the specified KenkoEventDetails message. Does not implicitly {@link space.kenko.proto.KenkoEventDetails.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {space.kenko.proto.IKenkoEventDetails} message KenkoEventDetails message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KenkoEventDetails.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.availability != null && Object.hasOwnProperty.call(message, "availability"))
                        $root.space.kenko.proto.AvailabilityEventType.encode(message.availability, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.appointment != null && Object.hasOwnProperty.call(message, "appointment"))
                        $root.space.kenko.proto.AppointmentEventType.encode(message.appointment, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message["class"] != null && Object.hasOwnProperty.call(message, "class"))
                        $root.space.kenko.proto.ClassEventType.encode(message["class"], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified KenkoEventDetails message, length delimited. Does not implicitly {@link space.kenko.proto.KenkoEventDetails.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {space.kenko.proto.IKenkoEventDetails} message KenkoEventDetails message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KenkoEventDetails.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a KenkoEventDetails message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.KenkoEventDetails} KenkoEventDetails
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KenkoEventDetails.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.KenkoEventDetails();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 11: {
                                message.availability = $root.space.kenko.proto.AvailabilityEventType.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.appointment = $root.space.kenko.proto.AppointmentEventType.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message["class"] = $root.space.kenko.proto.ClassEventType.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a KenkoEventDetails message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.KenkoEventDetails} KenkoEventDetails
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KenkoEventDetails.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a KenkoEventDetails message.
                 * @function verify
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                KenkoEventDetails.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.availability != null && message.hasOwnProperty("availability")) {
                        properties.EventType = 1;
                        {
                            var error = $root.space.kenko.proto.AvailabilityEventType.verify(message.availability);
                            if (error)
                                return "availability." + error;
                        }
                    }
                    if (message.appointment != null && message.hasOwnProperty("appointment")) {
                        if (properties.EventType === 1)
                            return "EventType: multiple values";
                        properties.EventType = 1;
                        {
                            var error = $root.space.kenko.proto.AppointmentEventType.verify(message.appointment);
                            if (error)
                                return "appointment." + error;
                        }
                    }
                    if (message["class"] != null && message.hasOwnProperty("class")) {
                        if (properties.EventType === 1)
                            return "EventType: multiple values";
                        properties.EventType = 1;
                        {
                            var error = $root.space.kenko.proto.ClassEventType.verify(message["class"]);
                            if (error)
                                return "class." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a KenkoEventDetails message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.KenkoEventDetails} KenkoEventDetails
                 */
                KenkoEventDetails.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.KenkoEventDetails)
                        return object;
                    var message = new $root.space.kenko.proto.KenkoEventDetails();
                    if (object.availability != null) {
                        if (typeof object.availability !== "object")
                            throw TypeError(".space.kenko.proto.KenkoEventDetails.availability: object expected");
                        message.availability = $root.space.kenko.proto.AvailabilityEventType.fromObject(object.availability);
                    }
                    if (object.appointment != null) {
                        if (typeof object.appointment !== "object")
                            throw TypeError(".space.kenko.proto.KenkoEventDetails.appointment: object expected");
                        message.appointment = $root.space.kenko.proto.AppointmentEventType.fromObject(object.appointment);
                    }
                    if (object["class"] != null) {
                        if (typeof object["class"] !== "object")
                            throw TypeError(".space.kenko.proto.KenkoEventDetails.class: object expected");
                        message["class"] = $root.space.kenko.proto.ClassEventType.fromObject(object["class"]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a KenkoEventDetails message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {space.kenko.proto.KenkoEventDetails} message KenkoEventDetails
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                KenkoEventDetails.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.availability != null && message.hasOwnProperty("availability")) {
                        object.availability = $root.space.kenko.proto.AvailabilityEventType.toObject(message.availability, options);
                        if (options.oneofs)
                            object.EventType = "availability";
                    }
                    if (message.appointment != null && message.hasOwnProperty("appointment")) {
                        object.appointment = $root.space.kenko.proto.AppointmentEventType.toObject(message.appointment, options);
                        if (options.oneofs)
                            object.EventType = "appointment";
                    }
                    if (message["class"] != null && message.hasOwnProperty("class")) {
                        object["class"] = $root.space.kenko.proto.ClassEventType.toObject(message["class"], options);
                        if (options.oneofs)
                            object.EventType = "class";
                    }
                    return object;
                };

                /**
                 * Converts this KenkoEventDetails to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                KenkoEventDetails.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for KenkoEventDetails
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.KenkoEventDetails
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                KenkoEventDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.KenkoEventDetails";
                };

                return KenkoEventDetails;
            })();

            /**
             * CalendarUnit enum.
             * @name space.kenko.proto.CalendarUnit
             * @enum {number}
             * @property {number} CALENDAR_UNIT_UNKNOWN=0 CALENDAR_UNIT_UNKNOWN value
             * @property {number} CALENDAR_UNIT_DAY=1 CALENDAR_UNIT_DAY value
             * @property {number} CALENDAR_UNIT_WEEK=2 CALENDAR_UNIT_WEEK value
             * @property {number} CALENDAR_UNIT_MONTH=3 CALENDAR_UNIT_MONTH value
             */
            proto.CalendarUnit = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CALENDAR_UNIT_UNKNOWN"] = 0;
                values[valuesById[1] = "CALENDAR_UNIT_DAY"] = 1;
                values[valuesById[2] = "CALENDAR_UNIT_WEEK"] = 2;
                values[valuesById[3] = "CALENDAR_UNIT_MONTH"] = 3;
                return values;
            })();

            proto.CalendarRepeatSchedule = (function() {

                /**
                 * Properties of a CalendarRepeatSchedule.
                 * @memberof space.kenko.proto
                 * @interface ICalendarRepeatSchedule
                 * @property {space.kenko.proto.CalendarUnit|null} [repeatUnit] CalendarRepeatSchedule repeatUnit
                 * @property {number|null} [repeatPeriod] CalendarRepeatSchedule repeatPeriod
                 * @property {number|null} [daysOfWeekRepeatBitmask] CalendarRepeatSchedule daysOfWeekRepeatBitmask
                 */

                /**
                 * Constructs a new CalendarRepeatSchedule.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a CalendarRepeatSchedule.
                 * @implements ICalendarRepeatSchedule
                 * @constructor
                 * @param {space.kenko.proto.ICalendarRepeatSchedule=} [properties] Properties to set
                 */
                function CalendarRepeatSchedule(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CalendarRepeatSchedule repeatUnit.
                 * @member {space.kenko.proto.CalendarUnit} repeatUnit
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @instance
                 */
                CalendarRepeatSchedule.prototype.repeatUnit = 0;

                /**
                 * CalendarRepeatSchedule repeatPeriod.
                 * @member {number} repeatPeriod
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @instance
                 */
                CalendarRepeatSchedule.prototype.repeatPeriod = 0;

                /**
                 * CalendarRepeatSchedule daysOfWeekRepeatBitmask.
                 * @member {number} daysOfWeekRepeatBitmask
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @instance
                 */
                CalendarRepeatSchedule.prototype.daysOfWeekRepeatBitmask = 0;

                /**
                 * Creates a new CalendarRepeatSchedule instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.ICalendarRepeatSchedule=} [properties] Properties to set
                 * @returns {space.kenko.proto.CalendarRepeatSchedule} CalendarRepeatSchedule instance
                 */
                CalendarRepeatSchedule.create = function create(properties) {
                    return new CalendarRepeatSchedule(properties);
                };

                /**
                 * Encodes the specified CalendarRepeatSchedule message. Does not implicitly {@link space.kenko.proto.CalendarRepeatSchedule.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.ICalendarRepeatSchedule} message CalendarRepeatSchedule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarRepeatSchedule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.repeatUnit != null && Object.hasOwnProperty.call(message, "repeatUnit"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.repeatUnit);
                    if (message.repeatPeriod != null && Object.hasOwnProperty.call(message, "repeatPeriod"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.repeatPeriod);
                    if (message.daysOfWeekRepeatBitmask != null && Object.hasOwnProperty.call(message, "daysOfWeekRepeatBitmask"))
                        writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.daysOfWeekRepeatBitmask);
                    return writer;
                };

                /**
                 * Encodes the specified CalendarRepeatSchedule message, length delimited. Does not implicitly {@link space.kenko.proto.CalendarRepeatSchedule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.ICalendarRepeatSchedule} message CalendarRepeatSchedule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalendarRepeatSchedule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CalendarRepeatSchedule message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.CalendarRepeatSchedule} CalendarRepeatSchedule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarRepeatSchedule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.CalendarRepeatSchedule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 4: {
                                message.repeatUnit = reader.int32();
                                break;
                            }
                        case 5: {
                                message.repeatPeriod = reader.int32();
                                break;
                            }
                        case 6: {
                                message.daysOfWeekRepeatBitmask = reader.uint32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CalendarRepeatSchedule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.CalendarRepeatSchedule} CalendarRepeatSchedule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalendarRepeatSchedule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CalendarRepeatSchedule message.
                 * @function verify
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CalendarRepeatSchedule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.repeatUnit != null && message.hasOwnProperty("repeatUnit"))
                        switch (message.repeatUnit) {
                        default:
                            return "repeatUnit: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.repeatPeriod != null && message.hasOwnProperty("repeatPeriod"))
                        if (!$util.isInteger(message.repeatPeriod))
                            return "repeatPeriod: integer expected";
                    if (message.daysOfWeekRepeatBitmask != null && message.hasOwnProperty("daysOfWeekRepeatBitmask"))
                        if (!$util.isInteger(message.daysOfWeekRepeatBitmask))
                            return "daysOfWeekRepeatBitmask: integer expected";
                    return null;
                };

                /**
                 * Creates a CalendarRepeatSchedule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.CalendarRepeatSchedule} CalendarRepeatSchedule
                 */
                CalendarRepeatSchedule.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.CalendarRepeatSchedule)
                        return object;
                    var message = new $root.space.kenko.proto.CalendarRepeatSchedule();
                    switch (object.repeatUnit) {
                    default:
                        if (typeof object.repeatUnit === "number") {
                            message.repeatUnit = object.repeatUnit;
                            break;
                        }
                        break;
                    case "CALENDAR_UNIT_UNKNOWN":
                    case 0:
                        message.repeatUnit = 0;
                        break;
                    case "CALENDAR_UNIT_DAY":
                    case 1:
                        message.repeatUnit = 1;
                        break;
                    case "CALENDAR_UNIT_WEEK":
                    case 2:
                        message.repeatUnit = 2;
                        break;
                    case "CALENDAR_UNIT_MONTH":
                    case 3:
                        message.repeatUnit = 3;
                        break;
                    }
                    if (object.repeatPeriod != null)
                        message.repeatPeriod = object.repeatPeriod | 0;
                    if (object.daysOfWeekRepeatBitmask != null)
                        message.daysOfWeekRepeatBitmask = object.daysOfWeekRepeatBitmask >>> 0;
                    return message;
                };

                /**
                 * Creates a plain object from a CalendarRepeatSchedule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.CalendarRepeatSchedule} message CalendarRepeatSchedule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CalendarRepeatSchedule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.repeatUnit = options.enums === String ? "CALENDAR_UNIT_UNKNOWN" : 0;
                        object.repeatPeriod = 0;
                        object.daysOfWeekRepeatBitmask = 0;
                    }
                    if (message.repeatUnit != null && message.hasOwnProperty("repeatUnit"))
                        object.repeatUnit = options.enums === String ? $root.space.kenko.proto.CalendarUnit[message.repeatUnit] === undefined ? message.repeatUnit : $root.space.kenko.proto.CalendarUnit[message.repeatUnit] : message.repeatUnit;
                    if (message.repeatPeriod != null && message.hasOwnProperty("repeatPeriod"))
                        object.repeatPeriod = message.repeatPeriod;
                    if (message.daysOfWeekRepeatBitmask != null && message.hasOwnProperty("daysOfWeekRepeatBitmask"))
                        object.daysOfWeekRepeatBitmask = message.daysOfWeekRepeatBitmask;
                    return object;
                };

                /**
                 * Converts this CalendarRepeatSchedule to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CalendarRepeatSchedule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CalendarRepeatSchedule
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.CalendarRepeatSchedule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CalendarRepeatSchedule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.CalendarRepeatSchedule";
                };

                return CalendarRepeatSchedule;
            })();

            /**
             * DayOfWeekProto enum.
             * @name space.kenko.proto.DayOfWeekProto
             * @enum {number}
             * @property {number} DAY_OF_WEEK_UNKNOWN=0 DAY_OF_WEEK_UNKNOWN value
             * @property {number} MONDAY=1 MONDAY value
             * @property {number} TUESDAY=2 TUESDAY value
             * @property {number} WEDNESDAY=3 WEDNESDAY value
             * @property {number} THURSDAY=4 THURSDAY value
             * @property {number} FRIDAY=5 FRIDAY value
             * @property {number} SATURDAY=6 SATURDAY value
             * @property {number} SUNDAY=7 SUNDAY value
             */
            proto.DayOfWeekProto = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DAY_OF_WEEK_UNKNOWN"] = 0;
                values[valuesById[1] = "MONDAY"] = 1;
                values[valuesById[2] = "TUESDAY"] = 2;
                values[valuesById[3] = "WEDNESDAY"] = 3;
                values[valuesById[4] = "THURSDAY"] = 4;
                values[valuesById[5] = "FRIDAY"] = 5;
                values[valuesById[6] = "SATURDAY"] = 6;
                values[valuesById[7] = "SUNDAY"] = 7;
                return values;
            })();

            proto.NoRepeatSchedule = (function() {

                /**
                 * Properties of a NoRepeatSchedule.
                 * @memberof space.kenko.proto
                 * @interface INoRepeatSchedule
                 */

                /**
                 * Constructs a new NoRepeatSchedule.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a NoRepeatSchedule.
                 * @implements INoRepeatSchedule
                 * @constructor
                 * @param {space.kenko.proto.INoRepeatSchedule=} [properties] Properties to set
                 */
                function NoRepeatSchedule(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new NoRepeatSchedule instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.INoRepeatSchedule=} [properties] Properties to set
                 * @returns {space.kenko.proto.NoRepeatSchedule} NoRepeatSchedule instance
                 */
                NoRepeatSchedule.create = function create(properties) {
                    return new NoRepeatSchedule(properties);
                };

                /**
                 * Encodes the specified NoRepeatSchedule message. Does not implicitly {@link space.kenko.proto.NoRepeatSchedule.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.INoRepeatSchedule} message NoRepeatSchedule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoRepeatSchedule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified NoRepeatSchedule message, length delimited. Does not implicitly {@link space.kenko.proto.NoRepeatSchedule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.INoRepeatSchedule} message NoRepeatSchedule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoRepeatSchedule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NoRepeatSchedule message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.NoRepeatSchedule} NoRepeatSchedule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoRepeatSchedule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.NoRepeatSchedule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a NoRepeatSchedule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.NoRepeatSchedule} NoRepeatSchedule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoRepeatSchedule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NoRepeatSchedule message.
                 * @function verify
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NoRepeatSchedule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a NoRepeatSchedule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.NoRepeatSchedule} NoRepeatSchedule
                 */
                NoRepeatSchedule.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.NoRepeatSchedule)
                        return object;
                    return new $root.space.kenko.proto.NoRepeatSchedule();
                };

                /**
                 * Creates a plain object from a NoRepeatSchedule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.NoRepeatSchedule} message NoRepeatSchedule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoRepeatSchedule.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this NoRepeatSchedule to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoRepeatSchedule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NoRepeatSchedule
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.NoRepeatSchedule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoRepeatSchedule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.NoRepeatSchedule";
                };

                return NoRepeatSchedule;
            })();

            proto.WeeklyRepeatSchedule = (function() {

                /**
                 * Properties of a WeeklyRepeatSchedule.
                 * @memberof space.kenko.proto
                 * @interface IWeeklyRepeatSchedule
                 * @property {number|null} [repeatPeriodWeeks] WeeklyRepeatSchedule repeatPeriodWeeks
                 * @property {number|null} [daysOfWeekBitmask] WeeklyRepeatSchedule daysOfWeekBitmask
                 */

                /**
                 * Constructs a new WeeklyRepeatSchedule.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a WeeklyRepeatSchedule.
                 * @implements IWeeklyRepeatSchedule
                 * @constructor
                 * @param {space.kenko.proto.IWeeklyRepeatSchedule=} [properties] Properties to set
                 */
                function WeeklyRepeatSchedule(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WeeklyRepeatSchedule repeatPeriodWeeks.
                 * @member {number} repeatPeriodWeeks
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @instance
                 */
                WeeklyRepeatSchedule.prototype.repeatPeriodWeeks = 0;

                /**
                 * WeeklyRepeatSchedule daysOfWeekBitmask.
                 * @member {number} daysOfWeekBitmask
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @instance
                 */
                WeeklyRepeatSchedule.prototype.daysOfWeekBitmask = 0;

                /**
                 * Creates a new WeeklyRepeatSchedule instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.IWeeklyRepeatSchedule=} [properties] Properties to set
                 * @returns {space.kenko.proto.WeeklyRepeatSchedule} WeeklyRepeatSchedule instance
                 */
                WeeklyRepeatSchedule.create = function create(properties) {
                    return new WeeklyRepeatSchedule(properties);
                };

                /**
                 * Encodes the specified WeeklyRepeatSchedule message. Does not implicitly {@link space.kenko.proto.WeeklyRepeatSchedule.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.IWeeklyRepeatSchedule} message WeeklyRepeatSchedule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WeeklyRepeatSchedule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.repeatPeriodWeeks != null && Object.hasOwnProperty.call(message, "repeatPeriodWeeks"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.repeatPeriodWeeks);
                    if (message.daysOfWeekBitmask != null && Object.hasOwnProperty.call(message, "daysOfWeekBitmask"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.daysOfWeekBitmask);
                    return writer;
                };

                /**
                 * Encodes the specified WeeklyRepeatSchedule message, length delimited. Does not implicitly {@link space.kenko.proto.WeeklyRepeatSchedule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.IWeeklyRepeatSchedule} message WeeklyRepeatSchedule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WeeklyRepeatSchedule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WeeklyRepeatSchedule message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.WeeklyRepeatSchedule} WeeklyRepeatSchedule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WeeklyRepeatSchedule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.WeeklyRepeatSchedule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.repeatPeriodWeeks = reader.uint32();
                                break;
                            }
                        case 2: {
                                message.daysOfWeekBitmask = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WeeklyRepeatSchedule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.WeeklyRepeatSchedule} WeeklyRepeatSchedule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WeeklyRepeatSchedule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WeeklyRepeatSchedule message.
                 * @function verify
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WeeklyRepeatSchedule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.repeatPeriodWeeks != null && message.hasOwnProperty("repeatPeriodWeeks"))
                        if (!$util.isInteger(message.repeatPeriodWeeks))
                            return "repeatPeriodWeeks: integer expected";
                    if (message.daysOfWeekBitmask != null && message.hasOwnProperty("daysOfWeekBitmask"))
                        if (!$util.isInteger(message.daysOfWeekBitmask))
                            return "daysOfWeekBitmask: integer expected";
                    return null;
                };

                /**
                 * Creates a WeeklyRepeatSchedule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.WeeklyRepeatSchedule} WeeklyRepeatSchedule
                 */
                WeeklyRepeatSchedule.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.WeeklyRepeatSchedule)
                        return object;
                    var message = new $root.space.kenko.proto.WeeklyRepeatSchedule();
                    if (object.repeatPeriodWeeks != null)
                        message.repeatPeriodWeeks = object.repeatPeriodWeeks >>> 0;
                    if (object.daysOfWeekBitmask != null)
                        message.daysOfWeekBitmask = object.daysOfWeekBitmask | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a WeeklyRepeatSchedule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {space.kenko.proto.WeeklyRepeatSchedule} message WeeklyRepeatSchedule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WeeklyRepeatSchedule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.repeatPeriodWeeks = 0;
                        object.daysOfWeekBitmask = 0;
                    }
                    if (message.repeatPeriodWeeks != null && message.hasOwnProperty("repeatPeriodWeeks"))
                        object.repeatPeriodWeeks = message.repeatPeriodWeeks;
                    if (message.daysOfWeekBitmask != null && message.hasOwnProperty("daysOfWeekBitmask"))
                        object.daysOfWeekBitmask = message.daysOfWeekBitmask;
                    return object;
                };

                /**
                 * Converts this WeeklyRepeatSchedule to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WeeklyRepeatSchedule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WeeklyRepeatSchedule
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.WeeklyRepeatSchedule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WeeklyRepeatSchedule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.WeeklyRepeatSchedule";
                };

                return WeeklyRepeatSchedule;
            })();

            proto.AvailabilityEventType = (function() {

                /**
                 * Properties of an AvailabilityEventType.
                 * @memberof space.kenko.proto
                 * @interface IAvailabilityEventType
                 * @property {string|null} [providerId] AvailabilityEventType providerId
                 * @property {string|null} [locationId] AvailabilityEventType locationId
                 * @property {boolean|null} [availableForVirtualAppts] AvailabilityEventType availableForVirtualAppts
                 */

                /**
                 * Constructs a new AvailabilityEventType.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an AvailabilityEventType.
                 * @implements IAvailabilityEventType
                 * @constructor
                 * @param {space.kenko.proto.IAvailabilityEventType=} [properties] Properties to set
                 */
                function AvailabilityEventType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AvailabilityEventType providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @instance
                 */
                AvailabilityEventType.prototype.providerId = "";

                /**
                 * AvailabilityEventType locationId.
                 * @member {string} locationId
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @instance
                 */
                AvailabilityEventType.prototype.locationId = "";

                /**
                 * AvailabilityEventType availableForVirtualAppts.
                 * @member {boolean} availableForVirtualAppts
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @instance
                 */
                AvailabilityEventType.prototype.availableForVirtualAppts = false;

                /**
                 * Creates a new AvailabilityEventType instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {space.kenko.proto.IAvailabilityEventType=} [properties] Properties to set
                 * @returns {space.kenko.proto.AvailabilityEventType} AvailabilityEventType instance
                 */
                AvailabilityEventType.create = function create(properties) {
                    return new AvailabilityEventType(properties);
                };

                /**
                 * Encodes the specified AvailabilityEventType message. Does not implicitly {@link space.kenko.proto.AvailabilityEventType.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {space.kenko.proto.IAvailabilityEventType} message AvailabilityEventType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AvailabilityEventType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.availableForVirtualAppts != null && Object.hasOwnProperty.call(message, "availableForVirtualAppts"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.availableForVirtualAppts);
                    return writer;
                };

                /**
                 * Encodes the specified AvailabilityEventType message, length delimited. Does not implicitly {@link space.kenko.proto.AvailabilityEventType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {space.kenko.proto.IAvailabilityEventType} message AvailabilityEventType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AvailabilityEventType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AvailabilityEventType message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.AvailabilityEventType} AvailabilityEventType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AvailabilityEventType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.AvailabilityEventType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.availableForVirtualAppts = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AvailabilityEventType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.AvailabilityEventType} AvailabilityEventType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AvailabilityEventType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AvailabilityEventType message.
                 * @function verify
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AvailabilityEventType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.availableForVirtualAppts != null && message.hasOwnProperty("availableForVirtualAppts"))
                        if (typeof message.availableForVirtualAppts !== "boolean")
                            return "availableForVirtualAppts: boolean expected";
                    return null;
                };

                /**
                 * Creates an AvailabilityEventType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.AvailabilityEventType} AvailabilityEventType
                 */
                AvailabilityEventType.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.AvailabilityEventType)
                        return object;
                    var message = new $root.space.kenko.proto.AvailabilityEventType();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.availableForVirtualAppts != null)
                        message.availableForVirtualAppts = Boolean(object.availableForVirtualAppts);
                    return message;
                };

                /**
                 * Creates a plain object from an AvailabilityEventType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {space.kenko.proto.AvailabilityEventType} message AvailabilityEventType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AvailabilityEventType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.providerId = "";
                        object.locationId = "";
                        object.availableForVirtualAppts = false;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.availableForVirtualAppts != null && message.hasOwnProperty("availableForVirtualAppts"))
                        object.availableForVirtualAppts = message.availableForVirtualAppts;
                    return object;
                };

                /**
                 * Converts this AvailabilityEventType to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AvailabilityEventType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AvailabilityEventType
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.AvailabilityEventType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AvailabilityEventType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.AvailabilityEventType";
                };

                return AvailabilityEventType;
            })();

            proto.AppointmentEventType = (function() {

                /**
                 * Properties of an AppointmentEventType.
                 * @memberof space.kenko.proto
                 * @interface IAppointmentEventType
                 * @property {string|null} [providerId] AppointmentEventType providerId
                 * @property {string|null} [serviceSku] AppointmentEventType serviceSku
                 * @property {string|null} [locationId] AppointmentEventType locationId
                 * @property {boolean|null} [isVirtualAppt] AppointmentEventType isVirtualAppt
                 * @property {string|null} [clientUserId] AppointmentEventType clientUserId
                 * @property {string|null} [clientName] AppointmentEventType clientName
                 * @property {string|null} [clientPhone] AppointmentEventType clientPhone
                 * @property {string|null} [clientEmail] AppointmentEventType clientEmail
                 */

                /**
                 * Constructs a new AppointmentEventType.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an AppointmentEventType.
                 * @implements IAppointmentEventType
                 * @constructor
                 * @param {space.kenko.proto.IAppointmentEventType=} [properties] Properties to set
                 */
                function AppointmentEventType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AppointmentEventType providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 */
                AppointmentEventType.prototype.providerId = "";

                /**
                 * AppointmentEventType serviceSku.
                 * @member {string} serviceSku
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 */
                AppointmentEventType.prototype.serviceSku = "";

                /**
                 * AppointmentEventType locationId.
                 * @member {string} locationId
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 */
                AppointmentEventType.prototype.locationId = "";

                /**
                 * AppointmentEventType isVirtualAppt.
                 * @member {boolean} isVirtualAppt
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 */
                AppointmentEventType.prototype.isVirtualAppt = false;

                /**
                 * AppointmentEventType clientUserId.
                 * @member {string} clientUserId
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 */
                AppointmentEventType.prototype.clientUserId = "";

                /**
                 * AppointmentEventType clientName.
                 * @member {string} clientName
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 */
                AppointmentEventType.prototype.clientName = "";

                /**
                 * AppointmentEventType clientPhone.
                 * @member {string} clientPhone
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 */
                AppointmentEventType.prototype.clientPhone = "";

                /**
                 * AppointmentEventType clientEmail.
                 * @member {string} clientEmail
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 */
                AppointmentEventType.prototype.clientEmail = "";

                /**
                 * Creates a new AppointmentEventType instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {space.kenko.proto.IAppointmentEventType=} [properties] Properties to set
                 * @returns {space.kenko.proto.AppointmentEventType} AppointmentEventType instance
                 */
                AppointmentEventType.create = function create(properties) {
                    return new AppointmentEventType(properties);
                };

                /**
                 * Encodes the specified AppointmentEventType message. Does not implicitly {@link space.kenko.proto.AppointmentEventType.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {space.kenko.proto.IAppointmentEventType} message AppointmentEventType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppointmentEventType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.serviceSku != null && Object.hasOwnProperty.call(message, "serviceSku"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.serviceSku);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.locationId);
                    if (message.isVirtualAppt != null && Object.hasOwnProperty.call(message, "isVirtualAppt"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isVirtualAppt);
                    if (message.clientUserId != null && Object.hasOwnProperty.call(message, "clientUserId"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.clientUserId);
                    if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.clientName);
                    if (message.clientPhone != null && Object.hasOwnProperty.call(message, "clientPhone"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.clientPhone);
                    if (message.clientEmail != null && Object.hasOwnProperty.call(message, "clientEmail"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.clientEmail);
                    return writer;
                };

                /**
                 * Encodes the specified AppointmentEventType message, length delimited. Does not implicitly {@link space.kenko.proto.AppointmentEventType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {space.kenko.proto.IAppointmentEventType} message AppointmentEventType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppointmentEventType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AppointmentEventType message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.AppointmentEventType} AppointmentEventType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppointmentEventType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.AppointmentEventType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.serviceSku = reader.string();
                                break;
                            }
                        case 3: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 4: {
                                message.isVirtualAppt = reader.bool();
                                break;
                            }
                        case 10: {
                                message.clientUserId = reader.string();
                                break;
                            }
                        case 11: {
                                message.clientName = reader.string();
                                break;
                            }
                        case 12: {
                                message.clientPhone = reader.string();
                                break;
                            }
                        case 13: {
                                message.clientEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AppointmentEventType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.AppointmentEventType} AppointmentEventType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppointmentEventType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AppointmentEventType message.
                 * @function verify
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AppointmentEventType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
                        if (!$util.isString(message.serviceSku))
                            return "serviceSku: string expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.isVirtualAppt != null && message.hasOwnProperty("isVirtualAppt"))
                        if (typeof message.isVirtualAppt !== "boolean")
                            return "isVirtualAppt: boolean expected";
                    if (message.clientUserId != null && message.hasOwnProperty("clientUserId"))
                        if (!$util.isString(message.clientUserId))
                            return "clientUserId: string expected";
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        if (!$util.isString(message.clientName))
                            return "clientName: string expected";
                    if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                        if (!$util.isString(message.clientPhone))
                            return "clientPhone: string expected";
                    if (message.clientEmail != null && message.hasOwnProperty("clientEmail"))
                        if (!$util.isString(message.clientEmail))
                            return "clientEmail: string expected";
                    return null;
                };

                /**
                 * Creates an AppointmentEventType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.AppointmentEventType} AppointmentEventType
                 */
                AppointmentEventType.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.AppointmentEventType)
                        return object;
                    var message = new $root.space.kenko.proto.AppointmentEventType();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.serviceSku != null)
                        message.serviceSku = String(object.serviceSku);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.isVirtualAppt != null)
                        message.isVirtualAppt = Boolean(object.isVirtualAppt);
                    if (object.clientUserId != null)
                        message.clientUserId = String(object.clientUserId);
                    if (object.clientName != null)
                        message.clientName = String(object.clientName);
                    if (object.clientPhone != null)
                        message.clientPhone = String(object.clientPhone);
                    if (object.clientEmail != null)
                        message.clientEmail = String(object.clientEmail);
                    return message;
                };

                /**
                 * Creates a plain object from an AppointmentEventType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {space.kenko.proto.AppointmentEventType} message AppointmentEventType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AppointmentEventType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.providerId = "";
                        object.serviceSku = "";
                        object.locationId = "";
                        object.isVirtualAppt = false;
                        object.clientUserId = "";
                        object.clientName = "";
                        object.clientPhone = "";
                        object.clientEmail = "";
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
                        object.serviceSku = message.serviceSku;
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.isVirtualAppt != null && message.hasOwnProperty("isVirtualAppt"))
                        object.isVirtualAppt = message.isVirtualAppt;
                    if (message.clientUserId != null && message.hasOwnProperty("clientUserId"))
                        object.clientUserId = message.clientUserId;
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        object.clientName = message.clientName;
                    if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                        object.clientPhone = message.clientPhone;
                    if (message.clientEmail != null && message.hasOwnProperty("clientEmail"))
                        object.clientEmail = message.clientEmail;
                    return object;
                };

                /**
                 * Converts this AppointmentEventType to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AppointmentEventType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AppointmentEventType
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.AppointmentEventType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AppointmentEventType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.AppointmentEventType";
                };

                return AppointmentEventType;
            })();

            proto.ClassEventType = (function() {

                /**
                 * Properties of a ClassEventType.
                 * @memberof space.kenko.proto
                 * @interface IClassEventType
                 * @property {string|null} [teacherProviderId] ClassEventType teacherProviderId
                 * @property {string|null} [classSku] ClassEventType classSku
                 * @property {string|null} [className] ClassEventType className
                 * @property {string|null} [locationId] ClassEventType locationId
                 * @property {boolean|null} [isVirtualAppt] ClassEventType isVirtualAppt
                 * @property {number|null} [maxAttendees] ClassEventType maxAttendees
                 * @property {space.kenko.proto.IPriceProto|null} [price] ClassEventType price
                 * @property {Array.<space.kenko.proto.IClientInfoProto>|null} [clients] ClassEventType clients
                 */

                /**
                 * Constructs a new ClassEventType.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ClassEventType.
                 * @implements IClassEventType
                 * @constructor
                 * @param {space.kenko.proto.IClassEventType=} [properties] Properties to set
                 */
                function ClassEventType(properties) {
                    this.clients = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ClassEventType teacherProviderId.
                 * @member {string} teacherProviderId
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 */
                ClassEventType.prototype.teacherProviderId = "";

                /**
                 * ClassEventType classSku.
                 * @member {string} classSku
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 */
                ClassEventType.prototype.classSku = "";

                /**
                 * ClassEventType className.
                 * @member {string} className
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 */
                ClassEventType.prototype.className = "";

                /**
                 * ClassEventType locationId.
                 * @member {string} locationId
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 */
                ClassEventType.prototype.locationId = "";

                /**
                 * ClassEventType isVirtualAppt.
                 * @member {boolean} isVirtualAppt
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 */
                ClassEventType.prototype.isVirtualAppt = false;

                /**
                 * ClassEventType maxAttendees.
                 * @member {number} maxAttendees
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 */
                ClassEventType.prototype.maxAttendees = 0;

                /**
                 * ClassEventType price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 */
                ClassEventType.prototype.price = null;

                /**
                 * ClassEventType clients.
                 * @member {Array.<space.kenko.proto.IClientInfoProto>} clients
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 */
                ClassEventType.prototype.clients = $util.emptyArray;

                /**
                 * Creates a new ClassEventType instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {space.kenko.proto.IClassEventType=} [properties] Properties to set
                 * @returns {space.kenko.proto.ClassEventType} ClassEventType instance
                 */
                ClassEventType.create = function create(properties) {
                    return new ClassEventType(properties);
                };

                /**
                 * Encodes the specified ClassEventType message. Does not implicitly {@link space.kenko.proto.ClassEventType.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {space.kenko.proto.IClassEventType} message ClassEventType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClassEventType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teacherProviderId != null && Object.hasOwnProperty.call(message, "teacherProviderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teacherProviderId);
                    if (message.classSku != null && Object.hasOwnProperty.call(message, "classSku"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.classSku);
                    if (message.className != null && Object.hasOwnProperty.call(message, "className"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.className);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.locationId);
                    if (message.isVirtualAppt != null && Object.hasOwnProperty.call(message, "isVirtualAppt"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isVirtualAppt);
                    if (message.maxAttendees != null && Object.hasOwnProperty.call(message, "maxAttendees"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxAttendees);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.clients != null && message.clients.length)
                        for (var i = 0; i < message.clients.length; ++i)
                            $root.space.kenko.proto.ClientInfoProto.encode(message.clients[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ClassEventType message, length delimited. Does not implicitly {@link space.kenko.proto.ClassEventType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {space.kenko.proto.IClassEventType} message ClassEventType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClassEventType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClassEventType message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ClassEventType} ClassEventType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClassEventType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ClassEventType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teacherProviderId = reader.string();
                                break;
                            }
                        case 2: {
                                message.classSku = reader.string();
                                break;
                            }
                        case 3: {
                                message.className = reader.string();
                                break;
                            }
                        case 4: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 5: {
                                message.isVirtualAppt = reader.bool();
                                break;
                            }
                        case 6: {
                                message.maxAttendees = reader.int32();
                                break;
                            }
                        case 7: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                if (!(message.clients && message.clients.length))
                                    message.clients = [];
                                message.clients.push($root.space.kenko.proto.ClientInfoProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClassEventType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ClassEventType} ClassEventType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClassEventType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClassEventType message.
                 * @function verify
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClassEventType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teacherProviderId != null && message.hasOwnProperty("teacherProviderId"))
                        if (!$util.isString(message.teacherProviderId))
                            return "teacherProviderId: string expected";
                    if (message.classSku != null && message.hasOwnProperty("classSku"))
                        if (!$util.isString(message.classSku))
                            return "classSku: string expected";
                    if (message.className != null && message.hasOwnProperty("className"))
                        if (!$util.isString(message.className))
                            return "className: string expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.isVirtualAppt != null && message.hasOwnProperty("isVirtualAppt"))
                        if (typeof message.isVirtualAppt !== "boolean")
                            return "isVirtualAppt: boolean expected";
                    if (message.maxAttendees != null && message.hasOwnProperty("maxAttendees"))
                        if (!$util.isInteger(message.maxAttendees))
                            return "maxAttendees: integer expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.clients != null && message.hasOwnProperty("clients")) {
                        if (!Array.isArray(message.clients))
                            return "clients: array expected";
                        for (var i = 0; i < message.clients.length; ++i) {
                            var error = $root.space.kenko.proto.ClientInfoProto.verify(message.clients[i]);
                            if (error)
                                return "clients." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ClassEventType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ClassEventType} ClassEventType
                 */
                ClassEventType.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ClassEventType)
                        return object;
                    var message = new $root.space.kenko.proto.ClassEventType();
                    if (object.teacherProviderId != null)
                        message.teacherProviderId = String(object.teacherProviderId);
                    if (object.classSku != null)
                        message.classSku = String(object.classSku);
                    if (object.className != null)
                        message.className = String(object.className);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.isVirtualAppt != null)
                        message.isVirtualAppt = Boolean(object.isVirtualAppt);
                    if (object.maxAttendees != null)
                        message.maxAttendees = object.maxAttendees | 0;
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.ClassEventType.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    if (object.clients) {
                        if (!Array.isArray(object.clients))
                            throw TypeError(".space.kenko.proto.ClassEventType.clients: array expected");
                        message.clients = [];
                        for (var i = 0; i < object.clients.length; ++i) {
                            if (typeof object.clients[i] !== "object")
                                throw TypeError(".space.kenko.proto.ClassEventType.clients: object expected");
                            message.clients[i] = $root.space.kenko.proto.ClientInfoProto.fromObject(object.clients[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ClassEventType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {space.kenko.proto.ClassEventType} message ClassEventType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClassEventType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.clients = [];
                    if (options.defaults) {
                        object.teacherProviderId = "";
                        object.classSku = "";
                        object.className = "";
                        object.locationId = "";
                        object.isVirtualAppt = false;
                        object.maxAttendees = 0;
                        object.price = null;
                    }
                    if (message.teacherProviderId != null && message.hasOwnProperty("teacherProviderId"))
                        object.teacherProviderId = message.teacherProviderId;
                    if (message.classSku != null && message.hasOwnProperty("classSku"))
                        object.classSku = message.classSku;
                    if (message.className != null && message.hasOwnProperty("className"))
                        object.className = message.className;
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.isVirtualAppt != null && message.hasOwnProperty("isVirtualAppt"))
                        object.isVirtualAppt = message.isVirtualAppt;
                    if (message.maxAttendees != null && message.hasOwnProperty("maxAttendees"))
                        object.maxAttendees = message.maxAttendees;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    if (message.clients && message.clients.length) {
                        object.clients = [];
                        for (var j = 0; j < message.clients.length; ++j)
                            object.clients[j] = $root.space.kenko.proto.ClientInfoProto.toObject(message.clients[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ClassEventType to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ClassEventType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClassEventType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ClassEventType
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ClassEventType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClassEventType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ClassEventType";
                };

                return ClassEventType;
            })();

            proto.ClientInfoProto = (function() {

                /**
                 * Properties of a ClientInfoProto.
                 * @memberof space.kenko.proto
                 * @interface IClientInfoProto
                 * @property {string|null} [clientUserId] ClientInfoProto clientUserId
                 * @property {string|null} [clientName] ClientInfoProto clientName
                 * @property {string|null} [clientPhone] ClientInfoProto clientPhone
                 * @property {string|null} [clientEmail] ClientInfoProto clientEmail
                 */

                /**
                 * Constructs a new ClientInfoProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ClientInfoProto.
                 * @implements IClientInfoProto
                 * @constructor
                 * @param {space.kenko.proto.IClientInfoProto=} [properties] Properties to set
                 */
                function ClientInfoProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ClientInfoProto clientUserId.
                 * @member {string} clientUserId
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @instance
                 */
                ClientInfoProto.prototype.clientUserId = "";

                /**
                 * ClientInfoProto clientName.
                 * @member {string} clientName
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @instance
                 */
                ClientInfoProto.prototype.clientName = "";

                /**
                 * ClientInfoProto clientPhone.
                 * @member {string} clientPhone
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @instance
                 */
                ClientInfoProto.prototype.clientPhone = "";

                /**
                 * ClientInfoProto clientEmail.
                 * @member {string} clientEmail
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @instance
                 */
                ClientInfoProto.prototype.clientEmail = "";

                /**
                 * Creates a new ClientInfoProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {space.kenko.proto.IClientInfoProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ClientInfoProto} ClientInfoProto instance
                 */
                ClientInfoProto.create = function create(properties) {
                    return new ClientInfoProto(properties);
                };

                /**
                 * Encodes the specified ClientInfoProto message. Does not implicitly {@link space.kenko.proto.ClientInfoProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {space.kenko.proto.IClientInfoProto} message ClientInfoProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientInfoProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientUserId != null && Object.hasOwnProperty.call(message, "clientUserId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientUserId);
                    if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientName);
                    if (message.clientPhone != null && Object.hasOwnProperty.call(message, "clientPhone"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.clientPhone);
                    if (message.clientEmail != null && Object.hasOwnProperty.call(message, "clientEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.clientEmail);
                    return writer;
                };

                /**
                 * Encodes the specified ClientInfoProto message, length delimited. Does not implicitly {@link space.kenko.proto.ClientInfoProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {space.kenko.proto.IClientInfoProto} message ClientInfoProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientInfoProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClientInfoProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ClientInfoProto} ClientInfoProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientInfoProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ClientInfoProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.clientUserId = reader.string();
                                break;
                            }
                        case 2: {
                                message.clientName = reader.string();
                                break;
                            }
                        case 3: {
                                message.clientPhone = reader.string();
                                break;
                            }
                        case 4: {
                                message.clientEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClientInfoProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ClientInfoProto} ClientInfoProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientInfoProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClientInfoProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClientInfoProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientUserId != null && message.hasOwnProperty("clientUserId"))
                        if (!$util.isString(message.clientUserId))
                            return "clientUserId: string expected";
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        if (!$util.isString(message.clientName))
                            return "clientName: string expected";
                    if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                        if (!$util.isString(message.clientPhone))
                            return "clientPhone: string expected";
                    if (message.clientEmail != null && message.hasOwnProperty("clientEmail"))
                        if (!$util.isString(message.clientEmail))
                            return "clientEmail: string expected";
                    return null;
                };

                /**
                 * Creates a ClientInfoProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ClientInfoProto} ClientInfoProto
                 */
                ClientInfoProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ClientInfoProto)
                        return object;
                    var message = new $root.space.kenko.proto.ClientInfoProto();
                    if (object.clientUserId != null)
                        message.clientUserId = String(object.clientUserId);
                    if (object.clientName != null)
                        message.clientName = String(object.clientName);
                    if (object.clientPhone != null)
                        message.clientPhone = String(object.clientPhone);
                    if (object.clientEmail != null)
                        message.clientEmail = String(object.clientEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a ClientInfoProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {space.kenko.proto.ClientInfoProto} message ClientInfoProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClientInfoProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.clientUserId = "";
                        object.clientName = "";
                        object.clientPhone = "";
                        object.clientEmail = "";
                    }
                    if (message.clientUserId != null && message.hasOwnProperty("clientUserId"))
                        object.clientUserId = message.clientUserId;
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        object.clientName = message.clientName;
                    if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                        object.clientPhone = message.clientPhone;
                    if (message.clientEmail != null && message.hasOwnProperty("clientEmail"))
                        object.clientEmail = message.clientEmail;
                    return object;
                };

                /**
                 * Converts this ClientInfoProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClientInfoProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ClientInfoProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ClientInfoProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClientInfoProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ClientInfoProto";
                };

                return ClientInfoProto;
            })();

            return proto;
        })();

        return kenko;
    })();

    return space;
})();

$root.ClientProfileProto = (function() {

    /**
     * Properties of a ClientProfileProto.
     * @exports IClientProfileProto
     * @interface IClientProfileProto
     * @property {string|null} [profileId] ClientProfileProto profileId
     * @property {string|null} [name] ClientProfileProto name
     * @property {Array.<IClientAppointmentProto>|null} [appointments] ClientProfileProto appointments
     * @property {IWellnessSurveyApiProto|null} [wellnessSurveyAnswers] ClientProfileProto wellnessSurveyAnswers
     */

    /**
     * Constructs a new ClientProfileProto.
     * @exports ClientProfileProto
     * @classdesc Represents a ClientProfileProto.
     * @implements IClientProfileProto
     * @constructor
     * @param {IClientProfileProto=} [properties] Properties to set
     */
    function ClientProfileProto(properties) {
        this.appointments = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClientProfileProto profileId.
     * @member {string} profileId
     * @memberof ClientProfileProto
     * @instance
     */
    ClientProfileProto.prototype.profileId = "";

    /**
     * ClientProfileProto name.
     * @member {string} name
     * @memberof ClientProfileProto
     * @instance
     */
    ClientProfileProto.prototype.name = "";

    /**
     * ClientProfileProto appointments.
     * @member {Array.<IClientAppointmentProto>} appointments
     * @memberof ClientProfileProto
     * @instance
     */
    ClientProfileProto.prototype.appointments = $util.emptyArray;

    /**
     * ClientProfileProto wellnessSurveyAnswers.
     * @member {IWellnessSurveyApiProto|null|undefined} wellnessSurveyAnswers
     * @memberof ClientProfileProto
     * @instance
     */
    ClientProfileProto.prototype.wellnessSurveyAnswers = null;

    /**
     * Creates a new ClientProfileProto instance using the specified properties.
     * @function create
     * @memberof ClientProfileProto
     * @static
     * @param {IClientProfileProto=} [properties] Properties to set
     * @returns {ClientProfileProto} ClientProfileProto instance
     */
    ClientProfileProto.create = function create(properties) {
        return new ClientProfileProto(properties);
    };

    /**
     * Encodes the specified ClientProfileProto message. Does not implicitly {@link ClientProfileProto.verify|verify} messages.
     * @function encode
     * @memberof ClientProfileProto
     * @static
     * @param {IClientProfileProto} message ClientProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.profileId != null && Object.hasOwnProperty.call(message, "profileId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.profileId);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.appointments != null && message.appointments.length)
            for (var i = 0; i < message.appointments.length; ++i)
                $root.ClientAppointmentProto.encode(message.appointments[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.wellnessSurveyAnswers != null && Object.hasOwnProperty.call(message, "wellnessSurveyAnswers"))
            $root.WellnessSurveyApiProto.encode(message.wellnessSurveyAnswers, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ClientProfileProto message, length delimited. Does not implicitly {@link ClientProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ClientProfileProto
     * @static
     * @param {IClientProfileProto} message ClientProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClientProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof ClientProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ClientProfileProto} ClientProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientProfileProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.profileId = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.appointments && message.appointments.length))
                        message.appointments = [];
                    message.appointments.push($root.ClientAppointmentProto.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.wellnessSurveyAnswers = $root.WellnessSurveyApiProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ClientProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ClientProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ClientProfileProto} ClientProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClientProfileProto message.
     * @function verify
     * @memberof ClientProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClientProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            if (!$util.isString(message.profileId))
                return "profileId: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.appointments != null && message.hasOwnProperty("appointments")) {
            if (!Array.isArray(message.appointments))
                return "appointments: array expected";
            for (var i = 0; i < message.appointments.length; ++i) {
                var error = $root.ClientAppointmentProto.verify(message.appointments[i]);
                if (error)
                    return "appointments." + error;
            }
        }
        if (message.wellnessSurveyAnswers != null && message.hasOwnProperty("wellnessSurveyAnswers")) {
            var error = $root.WellnessSurveyApiProto.verify(message.wellnessSurveyAnswers);
            if (error)
                return "wellnessSurveyAnswers." + error;
        }
        return null;
    };

    /**
     * Creates a ClientProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ClientProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ClientProfileProto} ClientProfileProto
     */
    ClientProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientProfileProto)
            return object;
        var message = new $root.ClientProfileProto();
        if (object.profileId != null)
            message.profileId = String(object.profileId);
        if (object.name != null)
            message.name = String(object.name);
        if (object.appointments) {
            if (!Array.isArray(object.appointments))
                throw TypeError(".ClientProfileProto.appointments: array expected");
            message.appointments = [];
            for (var i = 0; i < object.appointments.length; ++i) {
                if (typeof object.appointments[i] !== "object")
                    throw TypeError(".ClientProfileProto.appointments: object expected");
                message.appointments[i] = $root.ClientAppointmentProto.fromObject(object.appointments[i]);
            }
        }
        if (object.wellnessSurveyAnswers != null) {
            if (typeof object.wellnessSurveyAnswers !== "object")
                throw TypeError(".ClientProfileProto.wellnessSurveyAnswers: object expected");
            message.wellnessSurveyAnswers = $root.WellnessSurveyApiProto.fromObject(object.wellnessSurveyAnswers);
        }
        return message;
    };

    /**
     * Creates a plain object from a ClientProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ClientProfileProto
     * @static
     * @param {ClientProfileProto} message ClientProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClientProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.appointments = [];
        if (options.defaults) {
            object.profileId = "";
            object.name = "";
            object.wellnessSurveyAnswers = null;
        }
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            object.profileId = message.profileId;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.appointments && message.appointments.length) {
            object.appointments = [];
            for (var j = 0; j < message.appointments.length; ++j)
                object.appointments[j] = $root.ClientAppointmentProto.toObject(message.appointments[j], options);
        }
        if (message.wellnessSurveyAnswers != null && message.hasOwnProperty("wellnessSurveyAnswers"))
            object.wellnessSurveyAnswers = $root.WellnessSurveyApiProto.toObject(message.wellnessSurveyAnswers, options);
        return object;
    };

    /**
     * Converts this ClientProfileProto to JSON.
     * @function toJSON
     * @memberof ClientProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClientProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClientProfileProto
     * @function getTypeUrl
     * @memberof ClientProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClientProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientProfileProto";
    };

    return ClientProfileProto;
})();

$root.WellnessSurveyApiProto = (function() {

    /**
     * Properties of a WellnessSurveyApiProto.
     * @exports IWellnessSurveyApiProto
     * @interface IWellnessSurveyApiProto
     * @property {Array.<string>|null} [holisticHealthInterests] WellnessSurveyApiProto holisticHealthInterests
     * @property {string|null} [familiarityWithEasternDietaryPractices] WellnessSurveyApiProto familiarityWithEasternDietaryPractices
     * @property {Array.<string>|null} [fitnessGoals] WellnessSurveyApiProto fitnessGoals
     * @property {string|null} [currentSkincareRoutine] WellnessSurveyApiProto currentSkincareRoutine
     * @property {Array.<string>|null} [hairRelatedConcerns] WellnessSurveyApiProto hairRelatedConcerns
     */

    /**
     * Constructs a new WellnessSurveyApiProto.
     * @exports WellnessSurveyApiProto
     * @classdesc Represents a WellnessSurveyApiProto.
     * @implements IWellnessSurveyApiProto
     * @constructor
     * @param {IWellnessSurveyApiProto=} [properties] Properties to set
     */
    function WellnessSurveyApiProto(properties) {
        this.holisticHealthInterests = [];
        this.fitnessGoals = [];
        this.hairRelatedConcerns = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WellnessSurveyApiProto holisticHealthInterests.
     * @member {Array.<string>} holisticHealthInterests
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.holisticHealthInterests = $util.emptyArray;

    /**
     * WellnessSurveyApiProto familiarityWithEasternDietaryPractices.
     * @member {string} familiarityWithEasternDietaryPractices
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.familiarityWithEasternDietaryPractices = "";

    /**
     * WellnessSurveyApiProto fitnessGoals.
     * @member {Array.<string>} fitnessGoals
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.fitnessGoals = $util.emptyArray;

    /**
     * WellnessSurveyApiProto currentSkincareRoutine.
     * @member {string} currentSkincareRoutine
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.currentSkincareRoutine = "";

    /**
     * WellnessSurveyApiProto hairRelatedConcerns.
     * @member {Array.<string>} hairRelatedConcerns
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.hairRelatedConcerns = $util.emptyArray;

    /**
     * Creates a new WellnessSurveyApiProto instance using the specified properties.
     * @function create
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {IWellnessSurveyApiProto=} [properties] Properties to set
     * @returns {WellnessSurveyApiProto} WellnessSurveyApiProto instance
     */
    WellnessSurveyApiProto.create = function create(properties) {
        return new WellnessSurveyApiProto(properties);
    };

    /**
     * Encodes the specified WellnessSurveyApiProto message. Does not implicitly {@link WellnessSurveyApiProto.verify|verify} messages.
     * @function encode
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {IWellnessSurveyApiProto} message WellnessSurveyApiProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WellnessSurveyApiProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.holisticHealthInterests != null && message.holisticHealthInterests.length)
            for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.holisticHealthInterests[i]);
        if (message.familiarityWithEasternDietaryPractices != null && Object.hasOwnProperty.call(message, "familiarityWithEasternDietaryPractices"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.familiarityWithEasternDietaryPractices);
        if (message.fitnessGoals != null && message.fitnessGoals.length)
            for (var i = 0; i < message.fitnessGoals.length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fitnessGoals[i]);
        if (message.currentSkincareRoutine != null && Object.hasOwnProperty.call(message, "currentSkincareRoutine"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentSkincareRoutine);
        if (message.hairRelatedConcerns != null && message.hairRelatedConcerns.length)
            for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.hairRelatedConcerns[i]);
        return writer;
    };

    /**
     * Encodes the specified WellnessSurveyApiProto message, length delimited. Does not implicitly {@link WellnessSurveyApiProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {IWellnessSurveyApiProto} message WellnessSurveyApiProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WellnessSurveyApiProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WellnessSurveyApiProto message from the specified reader or buffer.
     * @function decode
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WellnessSurveyApiProto} WellnessSurveyApiProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WellnessSurveyApiProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.WellnessSurveyApiProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.holisticHealthInterests && message.holisticHealthInterests.length))
                        message.holisticHealthInterests = [];
                    message.holisticHealthInterests.push(reader.string());
                    break;
                }
            case 2: {
                    message.familiarityWithEasternDietaryPractices = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.fitnessGoals && message.fitnessGoals.length))
                        message.fitnessGoals = [];
                    message.fitnessGoals.push(reader.string());
                    break;
                }
            case 4: {
                    message.currentSkincareRoutine = reader.string();
                    break;
                }
            case 5: {
                    if (!(message.hairRelatedConcerns && message.hairRelatedConcerns.length))
                        message.hairRelatedConcerns = [];
                    message.hairRelatedConcerns.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WellnessSurveyApiProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WellnessSurveyApiProto} WellnessSurveyApiProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WellnessSurveyApiProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WellnessSurveyApiProto message.
     * @function verify
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WellnessSurveyApiProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.holisticHealthInterests != null && message.hasOwnProperty("holisticHealthInterests")) {
            if (!Array.isArray(message.holisticHealthInterests))
                return "holisticHealthInterests: array expected";
            for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                if (!$util.isString(message.holisticHealthInterests[i]))
                    return "holisticHealthInterests: string[] expected";
        }
        if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
            if (!$util.isString(message.familiarityWithEasternDietaryPractices))
                return "familiarityWithEasternDietaryPractices: string expected";
        if (message.fitnessGoals != null && message.hasOwnProperty("fitnessGoals")) {
            if (!Array.isArray(message.fitnessGoals))
                return "fitnessGoals: array expected";
            for (var i = 0; i < message.fitnessGoals.length; ++i)
                if (!$util.isString(message.fitnessGoals[i]))
                    return "fitnessGoals: string[] expected";
        }
        if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
            if (!$util.isString(message.currentSkincareRoutine))
                return "currentSkincareRoutine: string expected";
        if (message.hairRelatedConcerns != null && message.hasOwnProperty("hairRelatedConcerns")) {
            if (!Array.isArray(message.hairRelatedConcerns))
                return "hairRelatedConcerns: array expected";
            for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                if (!$util.isString(message.hairRelatedConcerns[i]))
                    return "hairRelatedConcerns: string[] expected";
        }
        return null;
    };

    /**
     * Creates a WellnessSurveyApiProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WellnessSurveyApiProto} WellnessSurveyApiProto
     */
    WellnessSurveyApiProto.fromObject = function fromObject(object) {
        if (object instanceof $root.WellnessSurveyApiProto)
            return object;
        var message = new $root.WellnessSurveyApiProto();
        if (object.holisticHealthInterests) {
            if (!Array.isArray(object.holisticHealthInterests))
                throw TypeError(".WellnessSurveyApiProto.holisticHealthInterests: array expected");
            message.holisticHealthInterests = [];
            for (var i = 0; i < object.holisticHealthInterests.length; ++i)
                message.holisticHealthInterests[i] = String(object.holisticHealthInterests[i]);
        }
        if (object.familiarityWithEasternDietaryPractices != null)
            message.familiarityWithEasternDietaryPractices = String(object.familiarityWithEasternDietaryPractices);
        if (object.fitnessGoals) {
            if (!Array.isArray(object.fitnessGoals))
                throw TypeError(".WellnessSurveyApiProto.fitnessGoals: array expected");
            message.fitnessGoals = [];
            for (var i = 0; i < object.fitnessGoals.length; ++i)
                message.fitnessGoals[i] = String(object.fitnessGoals[i]);
        }
        if (object.currentSkincareRoutine != null)
            message.currentSkincareRoutine = String(object.currentSkincareRoutine);
        if (object.hairRelatedConcerns) {
            if (!Array.isArray(object.hairRelatedConcerns))
                throw TypeError(".WellnessSurveyApiProto.hairRelatedConcerns: array expected");
            message.hairRelatedConcerns = [];
            for (var i = 0; i < object.hairRelatedConcerns.length; ++i)
                message.hairRelatedConcerns[i] = String(object.hairRelatedConcerns[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a WellnessSurveyApiProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {WellnessSurveyApiProto} message WellnessSurveyApiProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WellnessSurveyApiProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.holisticHealthInterests = [];
            object.fitnessGoals = [];
            object.hairRelatedConcerns = [];
        }
        if (options.defaults) {
            object.familiarityWithEasternDietaryPractices = "";
            object.currentSkincareRoutine = "";
        }
        if (message.holisticHealthInterests && message.holisticHealthInterests.length) {
            object.holisticHealthInterests = [];
            for (var j = 0; j < message.holisticHealthInterests.length; ++j)
                object.holisticHealthInterests[j] = message.holisticHealthInterests[j];
        }
        if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
            object.familiarityWithEasternDietaryPractices = message.familiarityWithEasternDietaryPractices;
        if (message.fitnessGoals && message.fitnessGoals.length) {
            object.fitnessGoals = [];
            for (var j = 0; j < message.fitnessGoals.length; ++j)
                object.fitnessGoals[j] = message.fitnessGoals[j];
        }
        if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
            object.currentSkincareRoutine = message.currentSkincareRoutine;
        if (message.hairRelatedConcerns && message.hairRelatedConcerns.length) {
            object.hairRelatedConcerns = [];
            for (var j = 0; j < message.hairRelatedConcerns.length; ++j)
                object.hairRelatedConcerns[j] = message.hairRelatedConcerns[j];
        }
        return object;
    };

    /**
     * Converts this WellnessSurveyApiProto to JSON.
     * @function toJSON
     * @memberof WellnessSurveyApiProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WellnessSurveyApiProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WellnessSurveyApiProto
     * @function getTypeUrl
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WellnessSurveyApiProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WellnessSurveyApiProto";
    };

    return WellnessSurveyApiProto;
})();

$root.ClientAppointmentProto = (function() {

    /**
     * Properties of a ClientAppointmentProto.
     * @exports IClientAppointmentProto
     * @interface IClientAppointmentProto
     * @property {string|null} [appointmentId] ClientAppointmentProto appointmentId
     * @property {number|Long|null} [startTimeMs] ClientAppointmentProto startTimeMs
     * @property {number|Long|null} [endTimeMs] ClientAppointmentProto endTimeMs
     * @property {string|null} [serviceName] ClientAppointmentProto serviceName
     * @property {string|null} [serviceSku] ClientAppointmentProto serviceSku
     * @property {string|null} [providerName] ClientAppointmentProto providerName
     * @property {string|null} [providerId] ClientAppointmentProto providerId
     * @property {string|null} [businessId] ClientAppointmentProto businessId
     * @property {string|null} [address] ClientAppointmentProto address
     * @property {space.kenko.proto.IPriceProto|null} [price] ClientAppointmentProto price
     */

    /**
     * Constructs a new ClientAppointmentProto.
     * @exports ClientAppointmentProto
     * @classdesc Represents a ClientAppointmentProto.
     * @implements IClientAppointmentProto
     * @constructor
     * @param {IClientAppointmentProto=} [properties] Properties to set
     */
    function ClientAppointmentProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClientAppointmentProto appointmentId.
     * @member {string} appointmentId
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.appointmentId = "";

    /**
     * ClientAppointmentProto startTimeMs.
     * @member {number|Long} startTimeMs
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ClientAppointmentProto endTimeMs.
     * @member {number|Long} endTimeMs
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ClientAppointmentProto serviceName.
     * @member {string} serviceName
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.serviceName = "";

    /**
     * ClientAppointmentProto serviceSku.
     * @member {string} serviceSku
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.serviceSku = "";

    /**
     * ClientAppointmentProto providerName.
     * @member {string} providerName
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.providerName = "";

    /**
     * ClientAppointmentProto providerId.
     * @member {string} providerId
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.providerId = "";

    /**
     * ClientAppointmentProto businessId.
     * @member {string} businessId
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.businessId = "";

    /**
     * ClientAppointmentProto address.
     * @member {string} address
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.address = "";

    /**
     * ClientAppointmentProto price.
     * @member {space.kenko.proto.IPriceProto|null|undefined} price
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.price = null;

    /**
     * Creates a new ClientAppointmentProto instance using the specified properties.
     * @function create
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto=} [properties] Properties to set
     * @returns {ClientAppointmentProto} ClientAppointmentProto instance
     */
    ClientAppointmentProto.create = function create(properties) {
        return new ClientAppointmentProto(properties);
    };

    /**
     * Encodes the specified ClientAppointmentProto message. Does not implicitly {@link ClientAppointmentProto.verify|verify} messages.
     * @function encode
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto} message ClientAppointmentProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientAppointmentProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
        if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
        if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
        if (message.serviceName != null && Object.hasOwnProperty.call(message, "serviceName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceName);
        if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerName);
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.providerId);
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.businessId);
        if (message.address != null && Object.hasOwnProperty.call(message, "address"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.address);
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.serviceSku != null && Object.hasOwnProperty.call(message, "serviceSku"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.serviceSku);
        return writer;
    };

    /**
     * Encodes the specified ClientAppointmentProto message, length delimited. Does not implicitly {@link ClientAppointmentProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto} message ClientAppointmentProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientAppointmentProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClientAppointmentProto message from the specified reader or buffer.
     * @function decode
     * @memberof ClientAppointmentProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientAppointmentProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientAppointmentProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.appointmentId = reader.string();
                    break;
                }
            case 2: {
                    message.startTimeMs = reader.int64();
                    break;
                }
            case 3: {
                    message.endTimeMs = reader.int64();
                    break;
                }
            case 4: {
                    message.serviceName = reader.string();
                    break;
                }
            case 10: {
                    message.serviceSku = reader.string();
                    break;
                }
            case 5: {
                    message.providerName = reader.string();
                    break;
                }
            case 6: {
                    message.providerId = reader.string();
                    break;
                }
            case 7: {
                    message.businessId = reader.string();
                    break;
                }
            case 8: {
                    message.address = reader.string();
                    break;
                }
            case 9: {
                    message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ClientAppointmentProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ClientAppointmentProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientAppointmentProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClientAppointmentProto message.
     * @function verify
     * @memberof ClientAppointmentProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClientAppointmentProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
            if (!$util.isString(message.appointmentId))
                return "appointmentId: string expected";
        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
            if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                return "startTimeMs: integer|Long expected";
        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
            if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                return "endTimeMs: integer|Long expected";
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            if (!$util.isString(message.serviceName))
                return "serviceName: string expected";
        if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
            if (!$util.isString(message.serviceSku))
                return "serviceSku: string expected";
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            if (!$util.isString(message.providerName))
                return "providerName: string expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.address != null && message.hasOwnProperty("address"))
            if (!$util.isString(message.address))
                return "address: string expected";
        if (message.price != null && message.hasOwnProperty("price")) {
            var error = $root.space.kenko.proto.PriceProto.verify(message.price);
            if (error)
                return "price." + error;
        }
        return null;
    };

    /**
     * Creates a ClientAppointmentProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ClientAppointmentProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     */
    ClientAppointmentProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientAppointmentProto)
            return object;
        var message = new $root.ClientAppointmentProto();
        if (object.appointmentId != null)
            message.appointmentId = String(object.appointmentId);
        if (object.startTimeMs != null)
            if ($util.Long)
                (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
            else if (typeof object.startTimeMs === "string")
                message.startTimeMs = parseInt(object.startTimeMs, 10);
            else if (typeof object.startTimeMs === "number")
                message.startTimeMs = object.startTimeMs;
            else if (typeof object.startTimeMs === "object")
                message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
        if (object.endTimeMs != null)
            if ($util.Long)
                (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
            else if (typeof object.endTimeMs === "string")
                message.endTimeMs = parseInt(object.endTimeMs, 10);
            else if (typeof object.endTimeMs === "number")
                message.endTimeMs = object.endTimeMs;
            else if (typeof object.endTimeMs === "object")
                message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
        if (object.serviceName != null)
            message.serviceName = String(object.serviceName);
        if (object.serviceSku != null)
            message.serviceSku = String(object.serviceSku);
        if (object.providerName != null)
            message.providerName = String(object.providerName);
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.address != null)
            message.address = String(object.address);
        if (object.price != null) {
            if (typeof object.price !== "object")
                throw TypeError(".ClientAppointmentProto.price: object expected");
            message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
        }
        return message;
    };

    /**
     * Creates a plain object from a ClientAppointmentProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ClientAppointmentProto
     * @static
     * @param {ClientAppointmentProto} message ClientAppointmentProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClientAppointmentProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.appointmentId = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.startTimeMs = options.longs === String ? "0" : 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.endTimeMs = options.longs === String ? "0" : 0;
            object.serviceName = "";
            object.providerName = "";
            object.providerId = "";
            object.businessId = "";
            object.address = "";
            object.price = null;
            object.serviceSku = "";
        }
        if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
            object.appointmentId = message.appointmentId;
        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
            if (typeof message.startTimeMs === "number")
                object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
            else
                object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
            if (typeof message.endTimeMs === "number")
                object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
            else
                object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            object.serviceName = message.serviceName;
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            object.providerName = message.providerName;
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.address != null && message.hasOwnProperty("address"))
            object.address = message.address;
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
        if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
            object.serviceSku = message.serviceSku;
        return object;
    };

    /**
     * Converts this ClientAppointmentProto to JSON.
     * @function toJSON
     * @memberof ClientAppointmentProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClientAppointmentProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClientAppointmentProto
     * @function getTypeUrl
     * @memberof ClientAppointmentProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClientAppointmentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientAppointmentProto";
    };

    return ClientAppointmentProto;
})();

$root.com = (function() {

    /**
     * Namespace com.
     * @exports com
     * @namespace
     */
    var com = {};

    com.onkensu = (function() {

        /**
         * Namespace onkensu.
         * @memberof com
         * @namespace
         */
        var onkensu = {};

        onkensu.platform = (function() {

            /**
             * Namespace platform.
             * @memberof com.onkensu
             * @namespace
             */
            var platform = {};

            platform.billing = (function() {

                /**
                 * Namespace billing.
                 * @memberof com.onkensu.platform
                 * @namespace
                 */
                var billing = {};

                billing.CreateConnectedAccountRequest = (function() {

                    /**
                     * Properties of a CreateConnectedAccountRequest.
                     * @memberof com.onkensu.platform.billing
                     * @interface ICreateConnectedAccountRequest
                     */

                    /**
                     * Constructs a new CreateConnectedAccountRequest.
                     * @memberof com.onkensu.platform.billing
                     * @classdesc Represents a CreateConnectedAccountRequest.
                     * @implements ICreateConnectedAccountRequest
                     * @constructor
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountRequest=} [properties] Properties to set
                     */
                    function CreateConnectedAccountRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new CreateConnectedAccountRequest instance using the specified properties.
                     * @function create
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountRequest=} [properties] Properties to set
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountRequest} CreateConnectedAccountRequest instance
                     */
                    CreateConnectedAccountRequest.create = function create(properties) {
                        return new CreateConnectedAccountRequest(properties);
                    };

                    /**
                     * Encodes the specified CreateConnectedAccountRequest message. Does not implicitly {@link com.onkensu.platform.billing.CreateConnectedAccountRequest.verify|verify} messages.
                     * @function encode
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountRequest} message CreateConnectedAccountRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateConnectedAccountRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateConnectedAccountRequest message, length delimited. Does not implicitly {@link com.onkensu.platform.billing.CreateConnectedAccountRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountRequest} message CreateConnectedAccountRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateConnectedAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateConnectedAccountRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountRequest} CreateConnectedAccountRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateConnectedAccountRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.onkensu.platform.billing.CreateConnectedAccountRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateConnectedAccountRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountRequest} CreateConnectedAccountRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateConnectedAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateConnectedAccountRequest message.
                     * @function verify
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateConnectedAccountRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a CreateConnectedAccountRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountRequest} CreateConnectedAccountRequest
                     */
                    CreateConnectedAccountRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.com.onkensu.platform.billing.CreateConnectedAccountRequest)
                            return object;
                        return new $root.com.onkensu.platform.billing.CreateConnectedAccountRequest();
                    };

                    /**
                     * Creates a plain object from a CreateConnectedAccountRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {com.onkensu.platform.billing.CreateConnectedAccountRequest} message CreateConnectedAccountRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateConnectedAccountRequest.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this CreateConnectedAccountRequest to JSON.
                     * @function toJSON
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateConnectedAccountRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for CreateConnectedAccountRequest
                     * @function getTypeUrl
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountRequest
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CreateConnectedAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/com.onkensu.platform.billing.CreateConnectedAccountRequest";
                    };

                    return CreateConnectedAccountRequest;
                })();

                billing.CreateConnectedAccountResponse = (function() {

                    /**
                     * Properties of a CreateConnectedAccountResponse.
                     * @memberof com.onkensu.platform.billing
                     * @interface ICreateConnectedAccountResponse
                     * @property {string|null} [accountId] CreateConnectedAccountResponse accountId
                     */

                    /**
                     * Constructs a new CreateConnectedAccountResponse.
                     * @memberof com.onkensu.platform.billing
                     * @classdesc Represents a CreateConnectedAccountResponse.
                     * @implements ICreateConnectedAccountResponse
                     * @constructor
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountResponse=} [properties] Properties to set
                     */
                    function CreateConnectedAccountResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreateConnectedAccountResponse accountId.
                     * @member {string} accountId
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @instance
                     */
                    CreateConnectedAccountResponse.prototype.accountId = "";

                    /**
                     * Creates a new CreateConnectedAccountResponse instance using the specified properties.
                     * @function create
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountResponse=} [properties] Properties to set
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountResponse} CreateConnectedAccountResponse instance
                     */
                    CreateConnectedAccountResponse.create = function create(properties) {
                        return new CreateConnectedAccountResponse(properties);
                    };

                    /**
                     * Encodes the specified CreateConnectedAccountResponse message. Does not implicitly {@link com.onkensu.platform.billing.CreateConnectedAccountResponse.verify|verify} messages.
                     * @function encode
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountResponse} message CreateConnectedAccountResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateConnectedAccountResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateConnectedAccountResponse message, length delimited. Does not implicitly {@link com.onkensu.platform.billing.CreateConnectedAccountResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountResponse} message CreateConnectedAccountResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateConnectedAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateConnectedAccountResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountResponse} CreateConnectedAccountResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateConnectedAccountResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.onkensu.platform.billing.CreateConnectedAccountResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.accountId = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateConnectedAccountResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountResponse} CreateConnectedAccountResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateConnectedAccountResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateConnectedAccountResponse message.
                     * @function verify
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateConnectedAccountResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isString(message.accountId))
                                return "accountId: string expected";
                        return null;
                    };

                    /**
                     * Creates a CreateConnectedAccountResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountResponse} CreateConnectedAccountResponse
                     */
                    CreateConnectedAccountResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.com.onkensu.platform.billing.CreateConnectedAccountResponse)
                            return object;
                        var message = new $root.com.onkensu.platform.billing.CreateConnectedAccountResponse();
                        if (object.accountId != null)
                            message.accountId = String(object.accountId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreateConnectedAccountResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {com.onkensu.platform.billing.CreateConnectedAccountResponse} message CreateConnectedAccountResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateConnectedAccountResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.accountId = "";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            object.accountId = message.accountId;
                        return object;
                    };

                    /**
                     * Converts this CreateConnectedAccountResponse to JSON.
                     * @function toJSON
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateConnectedAccountResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for CreateConnectedAccountResponse
                     * @function getTypeUrl
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountResponse
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CreateConnectedAccountResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/com.onkensu.platform.billing.CreateConnectedAccountResponse";
                    };

                    return CreateConnectedAccountResponse;
                })();

                billing.CreateConnectedAccountLinkRequest = (function() {

                    /**
                     * Properties of a CreateConnectedAccountLinkRequest.
                     * @memberof com.onkensu.platform.billing
                     * @interface ICreateConnectedAccountLinkRequest
                     * @property {string|null} [accountId] CreateConnectedAccountLinkRequest accountId
                     */

                    /**
                     * Constructs a new CreateConnectedAccountLinkRequest.
                     * @memberof com.onkensu.platform.billing
                     * @classdesc Represents a CreateConnectedAccountLinkRequest.
                     * @implements ICreateConnectedAccountLinkRequest
                     * @constructor
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountLinkRequest=} [properties] Properties to set
                     */
                    function CreateConnectedAccountLinkRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreateConnectedAccountLinkRequest accountId.
                     * @member {string} accountId
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @instance
                     */
                    CreateConnectedAccountLinkRequest.prototype.accountId = "";

                    /**
                     * Creates a new CreateConnectedAccountLinkRequest instance using the specified properties.
                     * @function create
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountLinkRequest=} [properties] Properties to set
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountLinkRequest} CreateConnectedAccountLinkRequest instance
                     */
                    CreateConnectedAccountLinkRequest.create = function create(properties) {
                        return new CreateConnectedAccountLinkRequest(properties);
                    };

                    /**
                     * Encodes the specified CreateConnectedAccountLinkRequest message. Does not implicitly {@link com.onkensu.platform.billing.CreateConnectedAccountLinkRequest.verify|verify} messages.
                     * @function encode
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountLinkRequest} message CreateConnectedAccountLinkRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateConnectedAccountLinkRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateConnectedAccountLinkRequest message, length delimited. Does not implicitly {@link com.onkensu.platform.billing.CreateConnectedAccountLinkRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountLinkRequest} message CreateConnectedAccountLinkRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateConnectedAccountLinkRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateConnectedAccountLinkRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountLinkRequest} CreateConnectedAccountLinkRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateConnectedAccountLinkRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.onkensu.platform.billing.CreateConnectedAccountLinkRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.accountId = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateConnectedAccountLinkRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountLinkRequest} CreateConnectedAccountLinkRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateConnectedAccountLinkRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateConnectedAccountLinkRequest message.
                     * @function verify
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateConnectedAccountLinkRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isString(message.accountId))
                                return "accountId: string expected";
                        return null;
                    };

                    /**
                     * Creates a CreateConnectedAccountLinkRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountLinkRequest} CreateConnectedAccountLinkRequest
                     */
                    CreateConnectedAccountLinkRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.com.onkensu.platform.billing.CreateConnectedAccountLinkRequest)
                            return object;
                        var message = new $root.com.onkensu.platform.billing.CreateConnectedAccountLinkRequest();
                        if (object.accountId != null)
                            message.accountId = String(object.accountId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreateConnectedAccountLinkRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {com.onkensu.platform.billing.CreateConnectedAccountLinkRequest} message CreateConnectedAccountLinkRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateConnectedAccountLinkRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.accountId = "";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            object.accountId = message.accountId;
                        return object;
                    };

                    /**
                     * Converts this CreateConnectedAccountLinkRequest to JSON.
                     * @function toJSON
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateConnectedAccountLinkRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for CreateConnectedAccountLinkRequest
                     * @function getTypeUrl
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkRequest
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CreateConnectedAccountLinkRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/com.onkensu.platform.billing.CreateConnectedAccountLinkRequest";
                    };

                    return CreateConnectedAccountLinkRequest;
                })();

                billing.CreateConnectedAccountLinkResponse = (function() {

                    /**
                     * Properties of a CreateConnectedAccountLinkResponse.
                     * @memberof com.onkensu.platform.billing
                     * @interface ICreateConnectedAccountLinkResponse
                     * @property {string|null} [accountLinkUrl] CreateConnectedAccountLinkResponse accountLinkUrl
                     */

                    /**
                     * Constructs a new CreateConnectedAccountLinkResponse.
                     * @memberof com.onkensu.platform.billing
                     * @classdesc Represents a CreateConnectedAccountLinkResponse.
                     * @implements ICreateConnectedAccountLinkResponse
                     * @constructor
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountLinkResponse=} [properties] Properties to set
                     */
                    function CreateConnectedAccountLinkResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreateConnectedAccountLinkResponse accountLinkUrl.
                     * @member {string} accountLinkUrl
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @instance
                     */
                    CreateConnectedAccountLinkResponse.prototype.accountLinkUrl = "";

                    /**
                     * Creates a new CreateConnectedAccountLinkResponse instance using the specified properties.
                     * @function create
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountLinkResponse=} [properties] Properties to set
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountLinkResponse} CreateConnectedAccountLinkResponse instance
                     */
                    CreateConnectedAccountLinkResponse.create = function create(properties) {
                        return new CreateConnectedAccountLinkResponse(properties);
                    };

                    /**
                     * Encodes the specified CreateConnectedAccountLinkResponse message. Does not implicitly {@link com.onkensu.platform.billing.CreateConnectedAccountLinkResponse.verify|verify} messages.
                     * @function encode
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountLinkResponse} message CreateConnectedAccountLinkResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateConnectedAccountLinkResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accountLinkUrl != null && Object.hasOwnProperty.call(message, "accountLinkUrl"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountLinkUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateConnectedAccountLinkResponse message, length delimited. Does not implicitly {@link com.onkensu.platform.billing.CreateConnectedAccountLinkResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateConnectedAccountLinkResponse} message CreateConnectedAccountLinkResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateConnectedAccountLinkResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateConnectedAccountLinkResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountLinkResponse} CreateConnectedAccountLinkResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateConnectedAccountLinkResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.onkensu.platform.billing.CreateConnectedAccountLinkResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.accountLinkUrl = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateConnectedAccountLinkResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountLinkResponse} CreateConnectedAccountLinkResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateConnectedAccountLinkResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateConnectedAccountLinkResponse message.
                     * @function verify
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateConnectedAccountLinkResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accountLinkUrl != null && message.hasOwnProperty("accountLinkUrl"))
                            if (!$util.isString(message.accountLinkUrl))
                                return "accountLinkUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates a CreateConnectedAccountLinkResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {com.onkensu.platform.billing.CreateConnectedAccountLinkResponse} CreateConnectedAccountLinkResponse
                     */
                    CreateConnectedAccountLinkResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.com.onkensu.platform.billing.CreateConnectedAccountLinkResponse)
                            return object;
                        var message = new $root.com.onkensu.platform.billing.CreateConnectedAccountLinkResponse();
                        if (object.accountLinkUrl != null)
                            message.accountLinkUrl = String(object.accountLinkUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreateConnectedAccountLinkResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {com.onkensu.platform.billing.CreateConnectedAccountLinkResponse} message CreateConnectedAccountLinkResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateConnectedAccountLinkResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.accountLinkUrl = "";
                        if (message.accountLinkUrl != null && message.hasOwnProperty("accountLinkUrl"))
                            object.accountLinkUrl = message.accountLinkUrl;
                        return object;
                    };

                    /**
                     * Converts this CreateConnectedAccountLinkResponse to JSON.
                     * @function toJSON
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateConnectedAccountLinkResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for CreateConnectedAccountLinkResponse
                     * @function getTypeUrl
                     * @memberof com.onkensu.platform.billing.CreateConnectedAccountLinkResponse
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CreateConnectedAccountLinkResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/com.onkensu.platform.billing.CreateConnectedAccountLinkResponse";
                    };

                    return CreateConnectedAccountLinkResponse;
                })();

                return billing;
            })();

            return platform;
        })();

        return onkensu;
    })();

    return com;
})();

module.exports = $root;
