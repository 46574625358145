import {IApiBusinessProfileProto, space} from "./compiled";
import IServiceProto = space.kenko.proto.IServiceProto;
import IProviderBioProto = space.kenko.proto.IProviderBioProto;

type Props = {
  business: IApiBusinessProfileProto | undefined,
  practitionerFilter?: IProviderBioProto | undefined,
  onSelect: (service: IServiceProto) => void;
}

export const ChooseService = (props: Props) => {

  return <div style={{display: 'flex', flexDirection: "column", textAlign: 'start'}}>
    <b>Select Service Type</b>
    <br/>
    {props.business?.practice?.services?.map(service => {

      if (!service.offeredBy || !service.offeredBy.length) {
        return;
      }

      for (let i = 0; i < service.offeredBy.length; i++) {

        const offering = service.offeredBy[i];

        // Show the service if there is no practitioner filter, or the practitioner does offer the service.
        if (!props.practitionerFilter || (props.practitionerFilter.providerId == offering.providerId)) {
          return <button key={'serv' + service.sku} className={'BookingOverlaySelectable'} onClick={() => {
            props.onSelect(service)
          }}>
            <div><b>{service.name} ({service.lengthMinutes} min)</b></div>
            <div>{service.description}</div>
          </button>
        }
      }
    })}
  </div>

}