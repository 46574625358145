import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Routes} from "react-router";

import {BrowserRouter, Route} from 'react-router-dom';
import {Auth0ProviderWithNavigate} from './auth0-provider-with-navigate';
import {ProviderProfileProvider} from "./ProviderProfileProvider";
import App from "./App/App";
import {PolicyPage} from "./App/Policy/PolicyPage";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path={'/privacy'} element={<PolicyPage 
              documentPath={require('../src/App/Policy/privacy.md')}/>}/>
          <Route path={'/cookies'} element={<PolicyPage
              documentPath={require('../src/App/Policy/cookies.md')}/>}/>
          <Route path={'/provider_terms'} element={<PolicyPage
              documentPath={require('../src/App/Policy/provider_tos.md')}/>}/>
          <Route path={'/*'} element={<Auth0ProviderWithNavigate>
            <ProviderProfileProvider>
              <App/>
            </ProviderProfileProvider>
          </Auth0ProviderWithNavigate>}/>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
