import React from 'react';
import './InputTypes.css';
import {CountryDropdown} from "react-country-region-selector";

type Props = {
  label: string;
  value: string | null | undefined;
  error: string | null | undefined,
  onChange: (value: string) => void;
}

export const LabeledCountryInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>
    <CountryDropdown classes={'LabeledTextInput'}
                     valueType='short'
                     onChange={props.onChange}
                     value={props.value || ''}/>
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>
  
}