import {space} from "../../provider_api";
import IErrorProto = space.kenko.proto.IErrorProto;

/**
 * Filter to contain errors starting with the given prefix, and strip the
 * prefix from all paths in the returned response.
 *
 * Use this when sending errors to a subview with multiple fields.
 *
 * Callers probably want to use strings suffixed with dots like 'location.2.'
 * so then subviews can easily access fields like "address"
 */
export const filterErrors = (errors: IErrorProto[] | null | undefined, prefix: string): IErrorProto[] => {

  if (!errors) {
    return []
  }

  return errors
      .filter(error => error.path?.startsWith(prefix))
      .map(error => {
        return {...error, path: error.path?.substring(prefix.length)}
      });

}

/**
 * Finds a single error with the given path.
 */
export const findError = (errors: IErrorProto[] | null | undefined, path: string) => {
  if (!errors) {
    return null;
  }

  for (let i = 0; i < errors.length; i++) {
    if (errors[i].path == path) {
      return errors[i].error;
    }
  }
  return null;
}


// Clears an error in the local copy of the profile, this is used when editing
// that field to clear the red text.
export const clearError = (errors: IErrorProto[] | null | undefined, path: string) => {
  if (!errors) {
    return [];
  }

  return errors.filter(e => {
    return e.path != path;
  })
}