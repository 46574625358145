import {IApiBusinessProfileProto, space} from "../../../../provider_api";
import IProviderBioProto = space.kenko.proto.IProviderBioProto;
import IServiceProto = space.kenko.proto.IServiceProto;
import IPriceProto = space.kenko.proto.IPriceProto;

export const findProvider = (providers: IProviderBioProto[] | null | undefined,
                             providerId: string | null | undefined): IProviderBioProto | null => {

  if (!providers) {
    return null;
  }

  if (!providerId) {
    return null;
  }

  const index = findProviderIndex(providers, providerId);
  return index < 0 ? null : providers[index];
}

export const findProviderIndex = (providers: IProviderBioProto[] | null | undefined,
                                  providerId: string | null | undefined): number => {

  if (!providerId || !providers?.length) {
    return -1;
  }

  for (let i = 0; i < providers.length; i++) {
    let provider = providers[i];
    if (provider.providerId == providerId) {
      return i;
    }
  }

  return -1;
}

export const filterPractitioners = (providers: IProviderBioProto[] | null | undefined) => {
  return providers ? providers.filter(bio => bio.isPractitioner) : [];
}

// Returns all the services this provider offers, and at which price.
export const getServicesForProvider = (
    services: IServiceProto[] | null | undefined,
    providerId: string | null | undefined):
    {
      service: IServiceProto,
      price: IPriceProto
    }[] => {

  if (!services || !providerId) {
    return [];
  }

  const response: {service: IServiceProto, price: IPriceProto}[] = []
  
  for (let i = 0; i < services.length; i++) {
    const service = services[i];
    for (let j = 0; service.offeredBy && j < service.offeredBy.length; j++) {
      const offeredBy = service.offeredBy[j];
      if (offeredBy.providerId == providerId) {
        response.push({
          service: service,
          price: offeredBy.price as IPriceProto
        });
        break;
      }
    }
  }
  
  return response;
}

export const findService = (profile: IApiBusinessProfileProto | undefined | null, sku: string | undefined | null) => {
  if (!profile?.practice?.services || !sku) {
    return null;
  }
  
  for (let i = 0; i < profile.practice.services.length; i++) {
    const service = profile.practice.services[i];
    if (service.sku == sku) {
      return service;
    }
  }
  
  return null;
}

export const findLocation = (profile: IApiBusinessProfileProto | undefined | null, locationId: string | undefined | null) => {
  if (!profile?.locations || !locationId) {
    return null;
  }

  for (let i = 0; i < profile.locations.length; i++) {
    if (profile.locations[i].locationId == locationId) {
      return profile.locations[i];
    }
  }

  return null;
}

export const spanToMinutes = (startMs: number, endMs: number): number => {
  return (endMs - startMs) / 1000 / 60;
}
