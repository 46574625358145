import {getBookingHost} from "../util/HostUtils";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";

export const LoginGate = () => {

  const {loginWithRedirect} = useAuth0();

  const auth0Login = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }

  return <div className={'SplashPageContainer'}>
    <div className={'SplashPageContent'} style={{textAlign: 'center'}}>
      <h2>Kensu for Business</h2>
      <div style={{marginBottom: 20}}>
        Create an account or log in to manage your business.
      </div>
      <div>
        <button className={'ActionButton'} onClick={auth0Login}>Continue</button>
      </div>
      <div className={'BrownText'} style={{margin: 20}}>
        Are you looking to <a href={getBookingHost()}>book an appointment</a>?
      </div>
    </div>
  </div>
}