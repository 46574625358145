import {
  BookAppointmentRequest,
  BookAppointmentResponse,
  IBookAppointmentResponse,
  IClientServiceProto,
  space
} from "./compiled";
import React, {useState} from "react";
import {SendRpc} from "../util";

// TODO I think this is just a horrible hack to get it into rollup
import icon_calendar from './icon_calendar.png';
import icon_close from './icon_close.png';
import {IdToken} from "@auth0/auth0-spa-js";
import IProviderBioProto = space.kenko.proto.IProviderBioProto;
import {useAuth0} from "@auth0/auth0-react";


// This gets passed along in the router
type Props = {
  businessId: string,
  provider: IProviderBioProto,
  service: IClientServiceProto,
  locationId: string,
  isVirtual: boolean,
  appointmentTime: number,
  onCancel: () => void,
  onComplete: (response: IBookAppointmentResponse) => void,
}

export const ConfirmBooking = (props: Props) => {

  const [bookingError, setBookingError] = useState<string>();
  const [loading, setLoading] = useState(true);

  const {getIdTokenClaims} = useAuth0();
  
  const bookAppointment = () => {

    setBookingError('')

    let request = BookAppointmentRequest.encode(
        new BookAppointmentRequest({
          businessId: props.businessId,
          appointmentTime: props.appointmentTime,
          serviceId: props.service.sku,
          providerId: props.provider.providerId,
          locationId: props.locationId,
          virtualAppt: props.isVirtual,
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
    ).finish()

    SendRpc(getIdTokenClaims, 'book_appointment', request)
        .then(r => {
          let response = BookAppointmentResponse.decode(r);
          console.log('response', JSON.stringify(response.toJSON()));
          if (response.okay && response.appointment) {
            props.onComplete(response);
          } else {
            setBookingError('Error booking# ' + response.error)
          }
        })
        .catch(e => {
          setBookingError('Error booking.. ' + e);
        });
  }

  const appointmentDate = new Date(props.appointmentTime);

  return (<>
    <div>Confirm this booking with {props.provider.firstName} {props.provider.lastName} at</div>
    <div>
      {appointmentDate.toLocaleDateString(undefined,
          {weekday: 'long', month: 'short', day: 'numeric'})}
      &nbsp;at&nbsp;
      {appointmentDate.toLocaleTimeString(undefined,
          {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'}
      )}
    </div>

    <button className={'ActionButton'} onClick={() => bookAppointment()}>
      Confirm
    </button>

    {bookingError && <div style={{color: 'red'}}>{bookingError}</div>}

  </>);
}