import {LabeledTextInput} from "../../../components/input/LabeledTextInput";
import React, {useState} from "react";
import {LabeledCountryInput} from "../../../components/input/LabeledCountryInput";
import {AcceptTosInput} from "./AcceptTosInput";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {space, UpdateProviderProfileRequest, UpdateProviderProfileResponse,} from "../../../provider_api";
import {useProviderProfile} from "../../../ProviderProfileProvider";
import {LabeledPhoneInput} from "../../../components/input/LabeledPhoneInput";
import {findError} from "../../../components/input/InputTypes";
import {RpcState} from "../../../RpcState";
import IErrorProto = space.kenko.proto.IErrorProto;
import IUserProfileProto = space.kenko.proto.IUserProfileProto;
import UserProfileProto = space.kenko.proto.UserProfileProto;

export const CreateProviderProfile = () => {

  const {user, getIdTokenClaims} = useAuth0();
  const {setEffectiveUser} = useProviderProfile();

  const [workingProfile, setWorkingProfile] =
      useState<IUserProfileProto>(new UserProfileProto({
        countryCode: 'US'
      }));
  const [errors, setErrors] = useState<IErrorProto[]>([])

  const [rpcState, setRpcState] = useState<RpcState>({state: 'none'});

  const submit = () => {

    setErrors([]);
    setRpcState({state: 'pending'})


    let request = UpdateProviderProfileRequest.encode(new UpdateProviderProfileRequest({
      profile: workingProfile
    })).finish();

    SendRpc(getIdTokenClaims, 'update_provider_profile', request)
        .then(resp => {
          let response = UpdateProviderProfileResponse.decode(resp);
          if (!response.okay) {
            setRpcState({state: 'error', message: 'Please check for errors.'})
            setErrors(response.fatalErrors);
            return;
          }

          setRpcState({state: 'success'})
          setErrors(response.fatalErrors);
          setEffectiveUser(response.provider);

        }).catch(e => {
      setRpcState({state: 'error', message: 'Network error. Please try again later'})
    });
  }

  // This mimics the style of the NewProviderSurvey which follows it
  return <div className={'VerticalScrollingSection'}>
    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent'}>
        <div className='SurveySection'>


          <div className={'SurveyHeader'}>
            <div className={'SurveyHeaderLeft'}>ACCOUNT SETUP</div>

            <div className={'SurveyHeaderButtonContainer'}>
              <button className={'ActionButton'} onClick={submit}
                      disabled={rpcState.state == 'pending'}>
                {rpcState.state == 'pending' &&
                    <img className={"CreateProfileSpinner"}
                         src={require('../../../images/loading_spinner.gif')}/>}
                {rpcState.state != 'pending' && <>Join Kensu</>}
              </button>
            </div>

          </div>

          {rpcState.state == 'error' &&
              <div className={'SurveyErrorHeader'}>
                <div className={'ErrorText'}>{rpcState.message}</div>
              </div>
          }

          <span className={'SurveyTitle'}>Create your profile</span>

          <LabeledTextInput label={'First name'}
                            value={workingProfile.firstName}
                            error={findError(errors, 'firstName')} inputType={'text'}
                            onChange={v => {
                              setWorkingProfile({
                                ...workingProfile,
                                firstName: v
                              })
                            }}/>

          <LabeledTextInput label={'Last name'}
                            value={workingProfile.lastName}
                            error={findError(errors, 'lastName')} inputType={'text'}
                            onChange={v => {
                              setWorkingProfile({
                                ...workingProfile,
                                lastName: v
                              })
                            }}/>

          <LabeledPhoneInput label={'Mobile number'}
                             value={workingProfile.mobileNumber}
                             error={findError(errors, 'mobileNumber')}
                             onChange={v => {
                               setWorkingProfile({
                                 ...workingProfile,
                                 mobileNumber: v
                               })
                             }}/>

          <LabeledCountryInput label={'Country'}
                               value={workingProfile.countryCode}
                               error={findError(errors, 'countryCode')}
                               onChange={v => {
                                 setWorkingProfile({
                                   ...workingProfile,
                                   countryCode: v
                                 })
                               }}/>

          
          <AcceptTosInput value={workingProfile.agreedToTerms ?? false}
                          error={findError(errors, 'agreedToTerms')}
                          onChange={v => {
                            setWorkingProfile({
                              ...workingProfile,
                              agreedToTerms: v
                            })
                          }}/>
          
        </div>
      </div>
    </div>
  </div>;
}