import React from "react";

type Props = {
  onContinue: () => void,
}
/**
 * This is the splash page for a new provider landing on the redeem invite
 * for the first time.
 */
export const WelcomeToKensu = (props: Props) => {
  return <div className={'SplashPageContainer'}>
    <div className={'SplashPageContent'}>
      <div style={{display: "flex", justifyContent: 'end'}}>
        <div>
          <button className='ActionButton' onClick={props.onContinue}>Get Started</button>
        </div>
      </div>
      <h1>Welcome to Kensu!</h1>
      <p>
        You’ve been invited to join our community of holistic health practitioners as a Founding Member and be
        part of Kensu's mission to make holistic care more accessible!
      </p>
      <p>Click ‘Get started’ to create your account.</p>
      <br/>
      <img src={require('../../images/survey_welcome_slice@0.25x.jpg')}/>
    </div>
  </div>
}

