import {DateDiv} from "./DateDiv";
import React, {useEffect, useState} from "react";
import {
  GetAvailableAppointmentsRequest,
  GetAvailableAppointmentsResponse,
  IClientServiceProto,
  space
} from "./compiled";
import './BookingOverlay.css';
import {SendUnauthenticatedRpc} from "../util";
import IProviderBioProto = space.kenko.proto.IProviderBioProto;

interface Props {
  businessId: string;
  providerId: string,
  serviceSku: string;
  onSelected: (timestamp: number) => void;
  onCancel: () => void;
}

export const ChooseTime = (props: Props) => {

  const [response, setResponse] = useState<GetAvailableAppointmentsResponse | null>()

  const [loading, setLoading] = useState(true)
  const [appointmentsError, setAppointmentsError] = useState<string>()

  useEffect(() => {

    setAppointmentsError('');

    SendUnauthenticatedRpc('get_available_appointments',
        GetAvailableAppointmentsRequest.encode(new GetAvailableAppointmentsRequest({
          businessId: props.businessId,
          fromTimestampMs: new Date().getTime(), // millis since epoch
          toTimestampMs: new Date().getTime() + (1000 * 60 * 60 * 24 * 6), // the next week
          sku: props.serviceSku,
          providerId: props.providerId
        }))
            .finish())
        .then(responseBytes => {
          let response = GetAvailableAppointmentsResponse.decode(responseBytes);
          if (!response.okay) {
            setAppointmentsError("Error loading appointments")
            return;
          }

          console.log(response)
          setResponse(response);
        })
        .catch(e => {
          console.log('appointments error', e)
          setAppointmentsError('Error loading appointments: ' + e);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [])

  // The response is just a list of numbers. This categorizes them by date
  // in the local time zone.
  const splitByDate = (dates: Date[] | undefined): Date[][] => {
    if (!dates) {
      return [];
    }

    var subarrays: Date[][] = [];
    var startIndex = 0;
    for (let i = 0; i < dates.length; i++) {

      if (dates[i].getDate() != dates[startIndex].getDate()) {

        // This position in the array has a different date than the last position
        // in the array. Add a new subarray to the return value.
        // Note this is a shallow copy which is very good!
        subarrays.push(dates.slice(startIndex, i));
        startIndex = i;
      }
    }

    if (startIndex < dates.length) {
      subarrays.push(dates.slice(startIndex));
    }

    return subarrays;
  }


  return (<>
    {loading && 'Loading available times...'}
    {appointmentsError && <div style={{color: 'red'}}>{appointmentsError}</div>}

    {!loading && !response?.availableTimes?.length &&
        <div>Sorry, no appointments were found!</div>}

    {response?.availableTimes?.map(value => {
      const provider = value.provider as IProviderBioProto;
      const availableTimes = value.availableTimes
      const dates = availableTimes?.map(value => new Date(value as number));
      const dateSubArrays = splitByDate(dates) || null;

      return <div key={value.provider?.providerId as string}
                  style={{textAlign: 'start'}}>
        <h3 style={{marginTop: 0}}>{provider.firstName + ' ' + provider.lastName}</h3>
        {dateSubArrays?.map((dateSubArray, idx) => {
          return <DateDiv key={'datesub-' + idx}
                          dates={dateSubArray}
                          service={response?.service as IClientServiceProto}
                          onTimeSelected={props.onSelected}/>
        })}
      </div>
    })}
  </>);
}