import {useNavigate, useSearchParams} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import {AcceptProviderInviteRequest, AcceptProviderInviteResponse,} from '../../provider_api';
import {SendRpc} from '../../rpcSender';
import {useAuth0} from '@auth0/auth0-react';
import {useProviderProfile} from "../../ProviderProfileProvider";
import {RpcState} from "../../RpcState";

/**
 * This actually redeems the invite. This page is behind an authentication gate, it's
 * guaranteed that there is indeed a user.
 */
export const RedeemBusinessInvite = () => {

  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('inviteCode');

  const {getIdTokenClaims} = useAuth0();
  const {setEffectiveUser, business, setBusiness} = useProviderProfile();

  const navigate = useNavigate();

  // Prevent duplicate sends in react strict mode.
  const rpcSent = useRef(false);
  const [rpcState, setRpcState] = useState<RpcState>({state: 'pending'});
  const [rpcResponse, setRpcResponse] = useState<AcceptProviderInviteResponse>();

  // The effect for checking an invite code; this only happens if there is no authenticated user.
  useEffect(() => {

    if (rpcSent.current) {
      console.log('not resending rpc')
      return;
    }
    
    // This invite only goes out if there's an invite code and it's been confirmed that
    // there is no authenticated user. This then checks whether the invite is valid.
    if (!inviteCode || business) {
      return;
    }

    rpcSent.current = true;
    let request = AcceptProviderInviteRequest.encode(new AcceptProviderInviteRequest({
      inviteCode: inviteCode,
      accepted: true,
    })).finish();

    SendRpc(getIdTokenClaims, 'action/accept_provider_invite', request).then(r => {
      let response = AcceptProviderInviteResponse.decode(r);
      console.log(response, JSON.stringify(response));
      if (response.okay) {
        // This changes the context value which forces a rerender
        setEffectiveUser(response.provider);
        setBusiness(response.business);
        
        // Clear out the search param and just go to the home page.
        navigate('/');
      } else {
        setRpcState({state: 'error', message: 'Error redeeming your invite'})
      }
    }).catch(e => {
      setRpcState({state: 'error', message: 'Network error redeeming your invite'})
    });
  }, []);


  // Uh, they have a business already? Go to the home screen.
  if (business) {
    navigate('/')
  }

  if (rpcState.state == 'pending') {
    return <div>Redeeming your invite...</div>
  }

  if (rpcState.state == 'success') {
    return <div>You're in!</div>
  }

  return <div className={'ErrorText'}>
    Error redeeming invite code. Please contact us at support@kenko.space.
  </div>
}
